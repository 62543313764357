import { makeVar } from '@apollo/client';
import { NoteQuestion, NotesTemplate } from '@fdha/graphql-api-admin';

export type NotesProps = {
  id: string;
  name: string;
  content: string;
  flagged?: boolean | null;
  created_at?: string;
  is_chart?: boolean;
  questions?: NoteQuestion[];
  patientId?: string;
};

const getInitialNoteValue = (patientId = '') => {
  return {
    id: '',
    name: '',
    content: '',
    flagged: false,
    patientId,
  };
};

const notes = makeVar<NotesProps[]>([]);

const addDraftNote = (patientId: string) => {
  notes([...notes(), getInitialNoteValue(patientId)]);
};

const addDraftNoteFromTemplate = (
  template: NotesTemplate,
  patientId: string
) => {
  const fromTemplate: NotesProps = {
    id: '',
    content: '',
    name: template.name,
    patientId,
    questions: template.questions,
  };

  notes([...notes(), fromTemplate]);
};

const removeDraftNote = (note: NotesProps) => {
  const newNotes = [...notes()];
  const index = newNotes.findIndex(
    (draftNote) =>
      draftNote.id === note.id && draftNote.patientId === note.patientId
  );
  newNotes.splice(index, 1);
  notes(newNotes);
};

export type EditNote = {
  id: string;
  patientId: string;
};

const editNotes = makeVar<EditNote[]>([]);

const editNote = (id: string, patientId: string) => {
  const note = { id, patientId };
  editNotes([...editNotes(), note]);
};

const removeEditNote = (note: NotesProps) => {
  const newNotes = [...editNotes()];
  const index = newNotes.findIndex((editNote) => editNote.id === note.id);

  if (index >= 0) {
    newNotes.splice(index, 1);
    editNotes(newNotes);
  }
};

const isDraftNote = (id: string) => {
  return id === '' || editNotes().some((editNote) => editNote.id === id);
};

const hasDraftNote = (patientId: string) => {
  if (editNotes().some((note) => note.patientId === patientId)) {
    return true;
  }

  return notes().some((note) => note.id === '' && note.patientId === patientId);
};

export {
  notes,
  addDraftNote,
  removeDraftNote,
  editNotes,
  editNote,
  isDraftNote,
  removeEditNote,
  hasDraftNote,
  addDraftNoteFromTemplate,
};
