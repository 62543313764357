import React, { FC } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Comment } from '../../utils';
import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import TimeAgo from '../TimeAgo/TimeAgo';
import RestrictedLabel from '../RestrictedLabel/RestrictedLabel';
import { Typography } from '../Typography/Typography';

import { DeletedByModerator } from './DeletedByModerator';

interface DeletedCommentCardProps {
  comment: Comment;
  isMyComment: boolean;
  isModerator: boolean;
}

export const DeletedCommentCard: FC<DeletedCommentCardProps> = ({
  comment,
  isMyComment,
  isModerator = false,
}) => {
  const theme = useTheme();
  const moderatorTextColor = isModerator
    ? theme.palette.text.secondary
    : grey[800];
  const isBanned = comment.user.isBanned;

  const getDeletedCommentView = () => {
    return (
      <>
        <Box>
          <Box
            p={1.5}
            sx={{
              backgroundColor: theme.palette.action.background,
              borderStartStartRadius: '15px',
              borderTopRightRadius: '15px',
              borderTopLeftRadius: 0,
            }}
          >
            <Stack spacing={1} direction="row">
              <Typography fontWeight="medium" color={moderatorTextColor}>
                {isModerator || isMyComment ? comment.user.name : 'User'}
              </Typography>
              {isBanned && isModerator && <RestrictedLabel />}
            </Stack>
            <Typography
              sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
              color={moderatorTextColor}
              fontStyle={isModerator ? undefined : 'italic'}
            >
              {isModerator ? comment.text : 'Removed by a moderator.'}
            </Typography>
            <TimeAgo date={comment.time} />
          </Box>
          <Box
            p={1.5}
            sx={{
              backgroundColor: grey[300],
              borderBottomLeftRadius: '15px',
              borderBottomRightRadius: '15px',
            }}
          >
            <DeletedByModerator
              entityName="comment"
              removedBy={comment.removedBy?.name}
              isModerator={isModerator}
              removedAt={comment.removedAt}
              isPersisted={comment.isPersisted}
            />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Stack direction="row" spacing={1}>
      {isModerator || isMyComment ? (
        <Avatar
          name={comment.user.name}
          type="imageOnly"
          textPosition="left"
          picture={comment.user.picture}
          testId="USER_AVATAR"
          sx={{ alignSelf: 'flex-start' }}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={10}
          sx={{
            backgroundColor: grey[300],
            height: '40px',
            width: '40px',
          }}
        >
          <Box marginTop={1}>
            <Icon name="person-outline" size="large" fill={grey[800]} />
          </Box>
        </Box>
      )}
      {getDeletedCommentView()}
    </Stack>
  );
};
