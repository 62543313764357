import { Autocomplete } from '@mui/material';
import React from 'react';

import TextField, { TextFieldProps } from '../TextField/TextField';

export interface MultiTextFieldProps
  extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: string[];
  onChange: (value: string[]) => void;
}

export const MultiTextField: React.FC<MultiTextFieldProps> = ({
  value,
  onChange,
  ...textFieldProps
}) => {
  const options: string[] = [];

  return (
    <Autocomplete
      freeSolo
      limitTags={2}
      multiple
      open={false}
      clearOnBlur
      options={options}
      renderInput={(params) => <TextField {...params} {...textFieldProps} />}
      value={value}
      onChange={(_event, value) => onChange(value)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    />
  );
};
