import React, { FC, FocusEvent } from 'react';
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { useTranslation } from '@fdha/common-hooks';

import { TextField, Typography } from '..';

export interface TimeInputProps {
  v2?: boolean;
  title?: string;
  time: string;
  dayPeriod: 'am' | 'pm';
  error: string | false | undefined;
  required?: boolean;
  onChange: (field: string, value: string) => void;
  onBlur?: (e: FocusEvent<any, Element>) => void;
  caption?: string;
}

export const fixTimeDigits = (value: string) => {
  const numbers = value?.replace(/\D+/g, '');
  if (numbers && numbers?.length === 3 && Number(numbers[0]) > 1) {
    const formattedValue = `0${numbers[0]}:${numbers[1]}${numbers[2]}`;
    return formattedValue;
  }
  return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1:$2');
};

export const TimeInput: FC<TimeInputProps> = ({
  v2,
  title = 'Time',
  time,
  dayPeriod,
  error,
  required,
  onChange,
  onBlur,
  caption,
}) => {
  const theme = useTheme();
  const { translate } = useTranslation();
  const timeboxes = [
    { label: 'am', i18nKey: 'activities:fields.time.am' },
    { label: 'pm', i18nKey: 'activities:fields.time.pm' },
  ];

  /************ V2 updates *********************/

  const buttonSx = v2
    ? {
        borderRadius: '10px',
        px: 2,
        height: '42px',
        color: theme.palette.text.primary,
        '&.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: theme.palette.primary.background,
        },
      }
    : {
        borderRadius: '15px',
        px: 2,
        height: '56px',
      };

  /***********************************/

  return (
    <Box>
      <TextField
        v2={v2}
        required={required}
        name="time"
        title={title}
        i18nKeyTitle="activities:fields.time.label"
        placeholder="hh:mm"
        i18nKeyPlaceholder="activities:fields.time.placeholder"
        value={time}
        error={!!error}
        helperText={error}
        onChange={(event) =>
          onChange('time', fixTimeDigits(event.target.value))
        }
        onBlur={onBlur}
        endContent={
          <ToggleButtonGroup
            exclusive
            value={dayPeriod}
            onChange={(_, value) => onChange('dayPeriod', value)}
            data-testid="AM_PM_BUTTON"
          >
            {timeboxes.map((val) => (
              <ToggleButton value={val.label} sx={buttonSx} key={val.label}>
                {translate(val?.i18nKey, val.label.toUpperCase())}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        }
        inputProps={{ maxLength: 5 }}
      />
      {caption && (
        <Typography fontSize={12} color={theme.palette.primary.light}>
          {caption}
        </Typography>
      )}
    </Box>
  );
};
