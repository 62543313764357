import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import Icon from 'react-eva-icons';
import { grey } from '@mui/material/colors';
import { parseISO } from 'date-fns';
import { formatDate, LabelBox } from '@fdha/web-ui-library';

import { ProfileInformationProps } from './ProfileInformation';

const ViewModeProfileInformation = (props: ProfileInformationProps) => {
  return (
    <>
      <Box marginBottom={3} data-testid="PROFILE_SHOW_MODE">
        <Grid container columnSpacing={3} rowSpacing={0}>
          <LabelBox size={4} label="Name" value={props.foodopsUser?.name} />
          <LabelBox size={4} label="Email" value={props.foodopsUser?.email} />
          <LabelBox
            size={4}
            label="Phone number"
            value={props.foodopsUser?.phone_number}
          />
          <LabelBox
            size={4}
            label="Joined on"
            value={
              props.foodopsUser?.created_at &&
              formatDate(parseISO(props.foodopsUser?.created_at))
            }
          />
        </Grid>
      </Box>
      <Box textAlign="end">
        <IconButton
          onClick={() => props.handleEditMode(true)}
          data-testid="EDIT_BUTTON"
        >
          <Icon name="edit-outline" width={24} height={24} fill={grey[600]} />
        </IconButton>
      </Box>
    </>
  );
};

export default ViewModeProfileInformation;
