import { makeVar } from '@apollo/client';
import shortid from 'shortid';
import {
  DateQuestionInitialState,
  FormBuilderQuestionType,
} from '@fdha/graphql-api-admin';

import { OptionProps, QuestionCardType } from '../models/FormBuilderProps';

const getInitialValues = (item: OptionProps) => {
  const initialValue = {
    ...item,
    id: shortid.generate(),
    title: '',
    required: false,
    isEditing: true,
    errorMessage: 'This question requires an answer',
  };

  let customProps = {};

  switch (initialValue.type) {
    case FormBuilderQuestionType.Binary:
      customProps = { possibleAnswers: ['', ''] };
      break;
    case FormBuilderQuestionType.OpenText:
      customProps = { maxLength: 100, placeholderText: '' };
      break;
    case FormBuilderQuestionType.Number:
      customProps = { placeholderText: '' };
      break;
    case FormBuilderQuestionType.MultipleChoice:
    case FormBuilderQuestionType.SingleChoice:
    case FormBuilderQuestionType.Scale:
      customProps = { possibleAnswers: [''] };
      break;
    case FormBuilderQuestionType.Date:
      customProps = {
        customProps: {
          initialState: DateQuestionInitialState.PreloadedWithCurrentDate,
        },
      };
      break;
  }

  return { ...initialValue, ...customProps };
};

const getInitialQuestionValue = () => {
  let questions: QuestionCardType[] = [];
  return questions;
};

const questions = makeVar<QuestionCardType[]>(getInitialQuestionValue());

const setQuestionInitialValue = () => {
  questions(getInitialQuestionValue());
};

const setQuestionValue = (value: QuestionCardType) => {
  const index = questions().findIndex((item) => item.id === value.id);
  if (index !== -1) {
    const newItems = [...questions()];
    newItems[index] = value;
    questions(newItems);
  } else {
    questions([...questions(), value]);
  }
};

const duplicateQuestion = (value: QuestionCardType, index: number) => {
  const newItems = [...questions()];
  newItems.splice(index, 0, value);
  questions(newItems);
};

const addNewQuestion = (item: OptionProps) => {
  questions([...questions(), getInitialValues(item)]);
};

const addNewQuestionAt = (item: OptionProps, index: number) => {
  const newItems = [...questions()];
  newItems.splice(index, 0, getInitialValues(item));
  questions(newItems);
};

const removeQuestion = (index: number) => {
  const newQuestions = [...questions()];
  newQuestions.splice(index, 1);
  replaceQuestions(newQuestions);
};

const replaceQuestions = (value: QuestionCardType[]) => {
  questions(value);
};

export {
  questions,
  setQuestionInitialValue,
  setQuestionValue,
  replaceQuestions,
  removeQuestion,
  addNewQuestionAt,
  addNewQuestion,
  duplicateQuestion,
};
