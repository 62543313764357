import {
  INITIAL_ROWS_PER_PAGE,
  RowsPerPageOptions,
} from '@fdha/web-ui-library';
import { useEffect, useState } from 'react';

import { useLocalStorage } from './useLocalStorage';
import { useReadLocalStorage } from './useReadLocalStorage';

export const useTable = (config: {
  initialRowsPerPage?: RowsPerPageOptions;
  key: string;
}) => {
  const storageRows = useReadLocalStorage('tablePreferencesV2', false);
  const { saveToLocalStorage } = useLocalStorage('tablePreferencesV2', false);

  const key = config.key;
  const initialRowsPerPage = config.initialRowsPerPage;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    initialRowsPerPage || INITIAL_ROWS_PER_PAGE
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const rows = storageRows?.[key];

    if (storageRows !== undefined) {
      if (rows) {
        setRowsPerPage(rows);
      }

      setLoading(false);
    }
  }, [storageRows, key, setRowsPerPage, initialRowsPerPage]);

  const changeRowsPerPage = (rows: RowsPerPageOptions) => {
    saveToLocalStorage({ [key]: rows });
    setRowsPerPage(rows);
  };

  return { page, setPage, rowsPerPage, changeRowsPerPage, loading };
};
