import React, { FC, useMemo } from 'react';
import {
  ChosenMenuOptionsV2,
  DeliveryStatus,
  MealSelections,
  ShipmentOrder,
  TrackingStatus,
} from '@fdha/graphql-api-admin';
import { Box, Divider, Paper, Stack, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  Icon,
  SelectedMenuSection,
  ShipmentStatusCard,
  Typography,
  formatUTCDate,
} from '@fdha/web-ui-library';
import {
  getSectionTypeLabel,
  groupDishes,
  isFull,
  isDeliveryDelayed,
} from '@fdha/common-utils';

import PreviewButtons from './PreviewButtons';

interface DeliveryPreviewProps {
  subjectId?: string;
  deliveryId?: string;
  status?: DeliveryStatus | null;
  openChangesDate?: string;
  isCanceled: boolean;
  isCanceledUpdateComment?: string | null;
  menuOptionSelections?: MealSelections | null;
  selectedMenuOptions?: ChosenMenuOptionsV2 | null;
  shipmentOrder?: ShipmentOrder | null;
  entreeDishesAmount: number;
  snackAmount: number;
  showWeekMenu?: boolean;
  deliveryDate?: string;
  onDishClicked: (id: string, name: string) => void;
  openEdit: () => void;
}

const DeliveryPreview: FC<DeliveryPreviewProps> = ({
  subjectId,
  deliveryId = '',
  status,
  openChangesDate,
  isCanceled,
  isCanceledUpdateComment,
  menuOptionSelections,
  selectedMenuOptions,
  shipmentOrder,
  entreeDishesAmount,
  snackAmount,
  showWeekMenu,
  deliveryDate,
  onDishClicked,
  openEdit,
}) => {
  const theme = useTheme();

  const shipmentLabels = shipmentOrder?.shipmentLabels || [];

  const data = useMemo(
    () =>
      status === DeliveryStatus.Open || showWeekMenu
        ? menuOptionSelections
        : selectedMenuOptions,
    [menuOptionSelections, selectedMenuOptions, status, showWeekMenu]
  );

  const options = useMemo(() => {
    const dishes = groupDishes(data);

    return {
      entreeDishes:
        dishes?.entreeDishes.filter((dish) => dish.quantity > 0) || [],
      snacks: dishes?.snacks.filter((dish) => dish.quantity > 0) || [],
    };
  }, [data]);

  const isQuantityIncorrect = useMemo(() => {
    return (
      !isFull(options.entreeDishes, entreeDishesAmount) ||
      !isFull(options.snacks, snackAmount)
    );
  }, [options, entreeDishesAmount, snackAmount]);

  const hasNoSelections = useMemo(() => {
    if (!options) {
      return true;
    }

    return !options.entreeDishes.length && !options.snacks?.length;
  }, [options]);

  const hasSides = useMemo(() => !!data?.sides?.length, [data]);

  const renderCanceledEmptyState = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
      >
        <Typography variant="body1">
          No dishes selected for this delivery
        </Typography>
      </Box>
    );
  };

  const renderEmptyState = () => {
    if (isCanceled) {
      return renderCanceledEmptyState();
    }

    return (
      <Paper
        sx={{
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          rowGap: 2,
        }}
      >
        <Icon
          name="alert-circle-outline"
          size="xlarge"
          fill={theme.palette.warning.main}
          backgroundColor={theme.palette.warning.lightBg}
        />
        <Typography variant="h6">No selection made yet</Typography>
        {openChangesDate && (
          <Typography
            variant="body1"
            px={3}
            color={theme.palette.text.secondary}
          >
            The subject hasn't made any selections yet, we encourage you to make
            the selections for the patient based on the standard menu and
            accommodating any preferences that they might have, before the
            delivery closes on{' '}
            <b>{formatUTCDate(openChangesDate, 'monthDay')} at 9 AM ET.</b>
          </Typography>
        )}
      </Paper>
    );
  };

  const renderCancelMessage = () => {
    return (
      <Paper
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
        variant="elevation"
        elevation={0}
      >
        <Typography variant="body1">The delivery has been canceled.</Typography>
        <Icon
          name="close-circle-outline"
          size="xlarge"
          backgroundColor={grey[200]}
          fill={theme.palette.text.primary}
        />
      </Paper>
    );
  };

  return (
    <>
      {!!shipmentLabels.length ? (
        <Stack spacing={3}>
          {shipmentLabels.map((label) => {
            const url = label.trackingUrl;

            return (
              <ShipmentStatusCard
                isOwnDelivery={false}
                status={label.trackingStatus}
                key={label.id}
                trackingId={label.trackingNumber ?? undefined}
                onTrackDelivery={
                  url ? () => window.open(url, '_blank') : undefined
                }
                isDelayed={
                  !!deliveryDate &&
                  isDeliveryDelayed(new Date(deliveryDate), [label])
                }
              />
            );
          })}
        </Stack>
      ) : (
        shipmentOrder && (
          <ShipmentStatusCard
            isOwnDelivery={false}
            status={TrackingStatus.BeingPrepared}
          />
        )
      )}
      {isCanceled && renderCancelMessage()}
      {isCanceledUpdateComment && (
        <Paper sx={{ paddingX: 3, paddingY: 2 }}>
          <Box display="flex" alignItems="center" mb={1}>
            <Icon
              name="message-square-outline"
              size="medium"
              fill={theme.palette.text.secondary}
            />
            <Typography ml={1}>
              {isCanceled ? 'Cancelation note' : 'Reactivation note'}
            </Typography>
          </Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {isCanceledUpdateComment}
          </Typography>
        </Paper>
      )}
      {hasNoSelections ? (
        renderEmptyState()
      ) : (
        <Paper>
          <Box m={3}>
            <SelectedMenuSection
              mealType={getSectionTypeLabel('entreeDishes', hasSides)}
              options={options?.entreeDishes}
              hideMealType
              onClick={onDishClicked}
            />
            {options?.snacks.length ? (
              <>
                <Divider />
                <SelectedMenuSection
                  mealType={getSectionTypeLabel('snacks', hasSides)}
                  options={options.snacks}
                  hideMealType
                  onClick={onDishClicked}
                />
              </>
            ) : null}
          </Box>
        </Paper>
      )}
      <PreviewButtons
        isCanceled={!!isCanceled}
        deliveryId={deliveryId}
        subjectId={subjectId}
        isClosed={status === DeliveryStatus.Closed}
        onClickEdit={openEdit}
        showDiscrepancyAlert={
          isQuantityIncorrect &&
          (status === DeliveryStatus.Open || showWeekMenu)
        }
      />
    </>
  );
};

export default DeliveryPreview;
