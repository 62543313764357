import React, { useEffect, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Chip,
  DialogContentText,
  FormControlLabel,
  Paper as MuiPaper,
  SelectChangeEvent,
  Stack,
  Switch,
  styled,
  TextField,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  Button,
  Select,
  SelectOption,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  ListSurveysDocument,
  useAddSurveyMutation,
  useListGroupsQuery,
  useUpdateSurveyMutation,
  useListGroupsFromSurveyQuery,
  ListGroupsFromSurveyDocument,
  Survey,
  FormBuilderQuestionType,
} from '@fdha/graphql-api-admin';

import BasePage from '../../../../components/BasePage/BasePage';
import FormBuilder from '../../../../components/FormBuilder/FormBuilder';
import {
  replaceQuestions,
  setQuestionInitialValue,
  questions as questionsState,
} from '../../../../states/questionBuilderState';
import { INITIAL_SURVEYS } from '../../Settings';
import { validate } from '../../../../utils/createValidation';

import {
  buildSurveyQuestionsPayload,
  convertSurveyQuestionToQuestion,
} from './utils';

const Paper = styled(MuiPaper)(({ theme }) => ({
  width: '100%',
  height: '98px',
  padding: '24px',
  marginBottom: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '0.5px solid',
  borderColor: theme.palette.divider,
}));

const ContainedButton = styled(Button)(() => ({
  marginLeft: '16px',
  padding: '8px 24px',
}));

interface Group {
  id?: string;
  name?: string;
}

interface FormValues {
  name: string;
  notifyCompletion: boolean;
}

interface SelectGroups {
  groups?: Group[];
  isTouched?: boolean;
}

interface StateProps {
  survey: Survey;
}

const CreateSurveys = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const questions = useReactiveVar(questionsState);
  const isMounted = useRef(false);

  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);

  const location = useLocation();
  const state = location.state as StateProps;
  const survey = state?.survey;

  const [addSurvey] = useAddSurveyMutation();
  const [updateSurvey] = useUpdateSurveyMutation();
  const [selectedGroups, setGroupsFromSurvey] = useState<SelectGroups>({
    isTouched: false,
  });
  const initialValues: FormValues = {
    name: survey?.name ?? '',
    notifyCompletion: survey?.notifyCompletion ?? false,
  };

  const GROUPS_LIMIT = 10000;
  const { data } = useListGroupsQuery({
    variables: { first: GROUPS_LIMIT },
    fetchPolicy: 'cache-and-network',
  });

  const groupsFromSurveyData = useListGroupsFromSurveyQuery({
    variables: {
      surveyId: survey?.id || '',
    },
    skip: !survey?.id,
    fetchPolicy: 'cache-and-network',
  });

  const defaultSelectOption = { id: '-1', name: 'All Patients' };
  const defaultGroupListValue = { ...defaultSelectOption, num_users: 0 };
  const groupsList = [defaultGroupListValue].concat(
    data?.groups?.edges.map((edge) => edge.node) || []
  );
  let initialSelectedGroups: Group[] = [];

  if (groupsFromSurveyData.data?.groupsFromSurvey?.length) {
    initialSelectedGroups = groupsFromSurveyData.data.groupsFromSurvey;
  } else {
    initialSelectedGroups = [defaultSelectOption];
  }

  useEffect(() => {
    if (survey) {
      const questions = convertSurveyQuestionToQuestion(survey.questions);

      replaceQuestions(questions);
    } else {
      setQuestionInitialValue();
    }
  }, [survey]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleGoBack = () => {
    setQuestionInitialValue();
    navigate(-1);
  };

  const showErrorSnackbar = (message: string) => {
    showSnackbar({ message, severity: 'error' });
  };

  const getRefetchQueries = () => [
    {
      query: ListSurveysDocument,
      variables: { first: INITIAL_SURVEYS },
    },
    {
      query: ListGroupsFromSurveyDocument,
    },
  ];

  const getSelectedGroups = () =>
    selectedGroups?.groups || initialSelectedGroups;

  const assignGroupSurvey = (newGroup?: Group) => {
    if (!newGroup) {
      return;
    }

    const groups = getSelectedGroups();

    if (newGroup.id === defaultSelectOption.id) {
      setGroupsFromSurvey({
        groups: [defaultSelectOption],
        isTouched: true,
      });
      return;
    }

    let groupsUpdated: Group[];

    if (groups[0].id === defaultSelectOption.id) {
      groupsUpdated = groups.splice(0, 1);
    }

    groupsUpdated = groups.concat({
      id: newGroup?.id || '',
      name: newGroup?.name || '',
    });

    setGroupsFromSurvey({
      groups: groupsUpdated,
      isTouched: true,
    });
  };

  const unassignGroupSurvey = (groupId: string) => {
    if (!groupId) {
      return;
    }

    const groups = getSelectedGroups();
    const groupsFiltered = groups.filter((group) => group.id !== groupId);

    setGroupsFromSurvey({
      groups: !groupsFiltered?.length ? [defaultSelectOption] : groupsFiltered,
      isTouched: true,
    });
  };

  const isAssignGroup = (groupId: string): boolean =>
    !initialSelectedGroups?.find((initGroup) => initGroup.id === groupId) &&
    groupId !== defaultSelectOption.id;

  const isUnassignGroup = (initialGroupId: string): boolean =>
    !selectedGroups?.groups?.find((group) => group.id === initialGroupId) &&
    initialGroupId !== defaultSelectOption.id;

  const getAssignGroups = (): string[] => {
    const assignGroups =
      selectedGroups?.groups?.filter((group) =>
        isAssignGroup(group.id || '')
      ) || [];

    return assignGroups.map((group) => group.id || '');
  };

  const getUnassignGroups = (): string[] => {
    if (!selectedGroups.isTouched) {
      return [];
    }

    const unassignGroups = initialSelectedGroups?.filter((initialGroup) =>
      isUnassignGroup(initialGroup.id || '')
    );

    return unassignGroups.map((group) => group.id || '');
  };

  const handleSubmit = async (values: FormValues, publish: boolean) => {
    const assignGroupsIds = getAssignGroups();
    const unassignGroupsIds = getUnassignGroups();

    const newSurvey = {
      ...values,
      questions: buildSurveyQuestionsPayload(questions),
      groups: {
        assignGroupsIds,
        unassignGroupsIds,
      },
    };

    try {
      setIsSubmittingDraft(true);
      if (survey) {
        await updateSurvey({
          variables: { id: survey.id, survey: newSurvey, publish },
          refetchQueries: getRefetchQueries(),
        });

        handleGoBack();
      } else {
        await addSurvey({
          variables: { survey: newSurvey, publish },
          refetchQueries: getRefetchQueries(),
        });

        handleGoBack();
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (isMounted.current) {
        setIsSubmittingDraft(false);
      }
      closeDialog();
    }
  };

  const isSelectedGroup = (value: string): boolean => {
    const groups = getSelectedGroups();

    if (!groups) {
      return false;
    }

    return !!groups.find((group) => group.id === value);
  };

  const getGroupsOptions = (): SelectOption[] => {
    return (
      groupsList?.map((group) => {
        return {
          label: group.name,
          value: group.id,
        };
      }) || []
    );
  };

  const getDialogContent = () => (
    <DialogContentText>
      Are you sure you want to save and publish the survey? Published surveys
      cannot be edited or deleted later.
    </DialogContentText>
  );

  const getSelectComponentValue = () =>
    getSelectedGroups()?.map((group) => group.id);

  const defaultValueExists = () =>
    getSelectedGroups()[0]?.id !== defaultSelectOption.id;

  const onChangeSelectValue = (e: SelectChangeEvent<unknown>) => {
    const value = Object.assign(new Array<string>(), e.target.value);
    const currentValue = value[value.length - 1];
    const groups = getSelectedGroups();

    if (value?.length > (groups?.length || 0)) {
      const newGroup: Group | undefined = groupsList?.find(
        (group) => group.id === currentValue
      );

      assignGroupSurvey(newGroup);
    } else {
      const groupId = groups?.find(
        (group) => !value.includes(group?.id || '')
      )?.id;

      unassignGroupSurvey(groupId || '');
    }
  };

  return (
    <BasePage>
      <Formik
        initialValues={initialValues}
        validate={(values) => validate(values, questions, showErrorSnackbar)}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting }) => {
          openDialog({
            title: 'Save and publish survey',
            content: getDialogContent(),
            confirmButtonLabel: 'Save and publish',
            cancelButtonLabel: 'Cancel',
            handleConfirm: () => handleSubmit(values, true),
            handleCancel: () => setSubmitting(false),
          });
        }}
      >
        {({ values, errors, touched, handleChange, isSubmitting }) => {
          const errorState = touched.name && errors.name;

          return (
            <Form>
              <Paper>
                <Stack direction="row" spacing={1}>
                  <TextField
                    name="name"
                    placeholder="Survey name"
                    value={values.name}
                    onChange={handleChange}
                    error={!!errorState}
                    helperText={errorState}
                    data-testid="INPUT_SURVEY_NAME"
                  />
                  <FormControlLabel
                    label="Receive Notification when Patient Completes"
                    control={
                      <Switch
                        color="secondary"
                        name="notifyCompletion"
                        checked={values.notifyCompletion}
                        onChange={handleChange}
                      />
                    }
                    data-testid="NOTIFICATION_TOGGLE"
                  />
                </Stack>
                <Box>
                  <Button
                    onClick={handleGoBack}
                    data-testid="CANCEL_BUTTON_CREATE_SURVEY"
                  >
                    Cancel
                  </Button>
                  <ContainedButton
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSubmit(values, false)}
                    disabled={isSubmittingDraft || isSubmitting}
                    data-testid="SAVE_BUTTON_CREATE_SURVEY"
                  >
                    Save
                  </ContainedButton>
                  <ContainedButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmittingDraft || isSubmitting}
                    data-testid="SAVE_AND_PUBLISH_BUTTON_CREATE_SURVEY"
                  >
                    Save and publish
                  </ContainedButton>
                </Box>
              </Paper>
            </Form>
          );
        }}
      </Formik>

      <Box display="flex">
        <Box width="18%">
          <Select
            color="secondary"
            name="groups"
            title="Select groups"
            selectType="checkbox"
            multiple={true}
            useFirstOptionAsDefault={true}
            options={getGroupsOptions()}
            value={getSelectComponentValue()}
            onChange={onChangeSelectValue}
          />
        </Box>

        <Box display="flex" marginLeft="20px" marginTop="24px">
          {defaultValueExists() && (
            <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
              {groupsList
                ?.filter((group) => isSelectedGroup(group.id))
                .map((group) => (
                  <Chip
                    color="secondary"
                    label={group.name}
                    onDelete={() => unassignGroupSurvey(group.id)}
                    data-testid="SELECT_GROUPS_CHIP"
                    key={group.id}
                  />
                ))}
            </Stack>
          )}
        </Box>
      </Box>
      <FormBuilder
        hasLogic
        hasRequiredOption
        notAllowedTypes={[FormBuilderQuestionType.Date]}
      />
    </BasePage>
  );
};

export default CreateSurveys;
