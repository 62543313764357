import { Typography, Box, Grid, IconButton } from '@mui/material';
import React, { FC, Dispatch, SetStateAction } from 'react';
import { parseISO } from 'date-fns';
import { formatDate } from '@fdha/web-ui-library';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';

import { CoachData } from './CoachCard';

interface ViewModeCoachCardProps {
  user?: CoachData;
  handleEditMode: Dispatch<SetStateAction<boolean>>;
  editable: boolean;
}

function CoachCardLabel(props: { label: string; data?: string }) {
  return (
    <Box
      marginTop={4}
      data-testid={props.label.toUpperCase().replace(/ /g, '_')}
    >
      <Typography variant="subtitle2">{props.label}</Typography>
      <Typography sx={{ overflowWrap: 'break-word' }}>
        {props.data || '-'}
      </Typography>
    </Box>
  );
}

const ViewModeCoachCard: FC<ViewModeCoachCardProps> = (props) => {
  return (
    <Box>
      <Grid container rowSpacing={0}>
        <Grid item xs={8}>
          <CoachCardLabel label="Email" data={props.user?.email} />
        </Grid>
        <Grid item xs={4}>
          <CoachCardLabel
            label="Phone number"
            data={props.user?.phone_number}
          />
        </Grid>
        <Grid item xs={6}>
          <CoachCardLabel
            label="Joined on"
            data={
              props.user?.created_at &&
              formatDate(parseISO(props.user.created_at))
            }
          />
        </Grid>
      </Grid>

      {props.editable && (
        <Box textAlign="end">
          <IconButton
            onClick={() => props.handleEditMode(true)}
            data-testid="EDIT_BUTTON"
          >
            <Icon name="edit-outline" width={24} height={24} fill={grey[600]} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ViewModeCoachCard;
