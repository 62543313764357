import React from 'react';
import { Box, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';
import {
  GetFlaggedNoteDocument,
  ListNotesDocument,
  useFlagNoteMutation,
} from '@fdha/graphql-api-admin';

import { isDraftNote, NotesProps } from '../../../../states/notesState';

import EditHeader from './EditHeader';
import PreviewHeader from './PreviewHeader';

interface NoteCardHeaderProps {
  disableEdit?: boolean;
  canFlag?: boolean;
  note: NotesProps;
}

const NoteCardHeader = ({
  disableEdit,
  note,
  canFlag = true,
}: NoteCardHeaderProps) => {
  const [flagNote] = useFlagNoteMutation();

  const iconName = note.flagged ? 'flag' : 'flag-outline';

  const handleFlagNote = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    flagNote({
      variables: { id: note.id, flagged: !note.flagged },
      refetchQueries: [ListNotesDocument, GetFlaggedNoteDocument],
    });
  };

  return (
    <Box
      display="flex"
      flex="1"
      alignItems="center"
      minHeight="65px"
      justifyContent="space-between"
    >
      {!disableEdit && note.id && canFlag && (
        <IconButton
          sx={{ padding: 0, marginRight: '20px' }}
          onClick={handleFlagNote}
          key={iconName}
          data-testid={
            note.flagged ? 'FLAGGED_NOTE_ICON' : 'UNFLAGGED_NOTE_ICON'
          }
        >
          <Icon name={iconName} size="large" fill={grey[800]} />
        </IconButton>
      )}
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        data-testid="NOTE_CARD_HEADER"
      >
        {!disableEdit && isDraftNote(note.id) ? (
          <EditHeader note={note} />
        ) : (
          <PreviewHeader note={note} />
        )}
      </Box>
    </Box>
  );
};

export default NoteCardHeader;
