import { DateQuestionInitialState } from '@fdha/graphql-api-admin';

export const dateQuestionInitialStateHelperTextByValue: Record<
  DateQuestionInitialState,
  string
> = {
  [DateQuestionInitialState.PreloadedWithCurrentDate]: `Preloads user's current date when note is created`,
  [DateQuestionInitialState.NoPreloadedDate]:
    'Field is empty with a MM/DD/YYYY placeholder',
};
