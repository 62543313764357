import React from 'react';
import { ButtonProps, styled, useTheme } from '@mui/material';
import { Button } from '@fdha/web-ui-library';
import Icon from 'react-eva-icons';

const StyledButton = styled(Button)(({ theme, color }) => ({
  padding: '16px 36px 16px 24px',
  backgroundColor:
    color === 'error'
      ? theme.palette.error.lightBg
      : theme.palette.background.paper,
  color: color === 'error' ? theme.palette.error.textDark : 'unset',
  textTransform: 'none',
  border: '1px solid',
  borderRadius: 15,
  borderColor: theme.palette.divider,
  width: '100%',
  justifyContent: 'space-between',
}));

interface Props extends ButtonProps {
  ['data-testid']?: string;
  hideIcon?: boolean;
}

const ManageAccountButton = (props: Props) => {
  const theme = useTheme();

  return (
    <StyledButton
      endIcon={
        !props.hideIcon && (
          <Icon
            name="arrow-ios-forward-outline"
            fill={theme.palette.secondary.contrastText}
          />
        )
      }
      {...props}
    />
  );
};

export default ManageAccountButton;
