import { Autocomplete } from '@fdha/web-ui-library';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { useListPublishedSurveysQuery } from '@fdha/graphql-api-admin';

import { getSurveyName } from '../../utils';

import { AssignSurveySchema } from './AssignSurvey';

const NUMBER_OF_RESULTS = 10000;

const Survey = () => {
  const { data: surveyListData, loading: loadingSurveys } =
    useListPublishedSurveysQuery({
      variables: { first: NUMBER_OF_RESULTS },
      fetchPolicy: 'cache-and-network',
    });

  const surveyOptions = useMemo(
    () =>
      surveyListData?.publishedSurveys.edges.map(({ node }) => ({
        label: getSurveyName(node.name, node.isStructured),
        id: node.id,
      })) ?? [],
    [surveyListData?.publishedSurveys.edges]
  );

  const { setFieldValue, values } = useFormikContext<AssignSurveySchema>();

  return (
    <Autocomplete
      title="Select Survey"
      options={surveyOptions}
      value={values.survey}
      loading={loadingSurveys}
      onChange={(_event, value) => {
        setFieldValue('survey', value);
      }}
      placeholder="Name"
    />
  );
};

export default Survey;
