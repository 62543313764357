import { NetworkStatus } from '@apollo/client';
import { Paper, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import Icon from 'react-eva-icons';
import { Table, SearchableTableHeader } from '@fdha/web-ui-library';
import {
  AssignType,
  LearningModuleOverview,
  UserType,
  useGetPatientUserQuery,
  useListLearningModulesQuery,
} from '@fdha/graphql-api-admin';
import { getContentfulEnv } from '@fdha/common-e2e';

import {
  getHeaderCells,
  renderTableCells,
} from '../../../../utils/learningData';
import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
  useGetUserType,
} from '../../../../hooks';

const LearningTab = () => {
  const params = useParams();
  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'learning',
  });

  const [filterBy, setFilterBy] = useFilterBy<LearningModuleOverview>(
    'title',
    ''
  );
  const [sortBy, setSortBy] = useSortBy<LearningModuleOverview>('title', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const patientId = params.patientId || '';

  const { isCsr } = useGetUserType();

  const headCells = getHeaderCells<LearningModuleOverview>(true, isCsr);

  const contentfulEnv = getContentfulEnv();

  const { data, error, fetchMore, loading, networkStatus } =
    useListLearningModulesQuery({
      variables: {
        userId: patientId,
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
        ...(contentfulEnv && { contentfulEnv }),
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const { data: patientData, loading: loadingPatient } = useGetPatientUserQuery(
    {
      variables: {
        id: patientId,
      },
    }
  );

  const nodes = data?.learningModules.edges.map((edge) => edge.node);
  const pageInfo = data?.learningModules.pageInfo;
  const totalNumberFound = data?.learningModules.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          patientId: patientId,
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const renderRow = (row: LearningModuleOverview) => {
    const isAutoAssigned =
      row?.assignType === AssignType.DhAndCaretaker &&
      [UserType.Caretaker, UserType.Patient].includes(
        patientData?.patientUser?.type as UserType
      );

    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        {renderTableCells(row, patientId, isCsr, isAutoAssigned)}
        <TableCell>
          <Link
            to={row.id}
            state={{ row, patientId, isAutoAssigned }}
            data-testid="COURSES_PREVIEW_BUTTON"
          >
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
              data-testid="COURSES_BUTTON"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  const isLoading = loading || loadingPatient;

  return (
    <>
      <SearchableTableHeader<LearningModuleOverview>
        headCells={headCells}
        defaultSearchField="title"
        onSearchChange={setFilterBy}
      />
      <Paper data-testid="COURSES_TABLE">
        {nodes ? (
          <Table<LearningModuleOverview>
            actions="right"
            headCells={headCells}
            isLoading={isLoading}
            initialOrderBy="title"
            totalRowCount={totalNumberFound}
            renderRow={renderRow}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={changeRowsPerPage}
            rows={nodes}
            withPagination
            onSortChange={setSortBy}
          />
        ) : null}
      </Paper>
    </>
  );
};

export default LearningTab;
