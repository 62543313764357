import React, { FC } from 'react';
import { Button, Icon, Typography } from '@fdha/web-ui-library';
import { Box, Stack, useTheme } from '@mui/material';

import Footer from '../Footer';
import { useDeliveryDialog } from '../../../../hooks';

interface PreviewButtonsProps {
  subjectId?: string;
  deliveryId: string;
  isClosed: boolean;
  isCanceled: boolean;
  showDiscrepancyAlert?: boolean;
  onClickEdit: () => void;
}

const PreviewButtons: FC<PreviewButtonsProps> = ({
  subjectId = '',
  deliveryId,
  isClosed,
  isCanceled,
  showDiscrepancyAlert,
  onClickEdit,
}) => {
  const theme = useTheme();

  const { openCancelDialog, openReactivateDialog } =
    useDeliveryDialog(subjectId);

  return (
    <Footer>
      <Stack
        direction="row"
        spacing={3}
        justifyContent={showDiscrepancyAlert ? 'space-between' : 'flex-end'}
        alignItems="center"
      >
        {showDiscrepancyAlert && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              backgroundColor: theme.palette.info.lightBg,
              borderRadius: '25px',
              padding: '8px 16px',
              flex: 1,
            }}
          >
            <Icon
              name="info-outline"
              size="large"
              fill={theme.palette.info.main}
            />
            <Typography>
              This delivery volume differs from Food Program Information
            </Typography>
          </Box>
        )}
        {isCanceled && deliveryId ? (
          <Button
            variant="text"
            startEvaIcon={{
              name: 'alert-circle-outline',
              fill: theme.palette.grey[600],
            }}
            onClick={() => openReactivateDialog(deliveryId)}
          >
            Reactivate Delivery
          </Button>
        ) : (
          <Stack spacing={1} direction="row" flexWrap="wrap" rowGap={1}>
            {deliveryId && (
              <Button
                variant="text"
                startEvaIcon={{
                  name: 'alert-circle-outline',
                  fill: theme.palette.grey[600],
                }}
                onClick={() => openCancelDialog(deliveryId)}
              >
                Cancel Delivery
              </Button>
            )}
            <Button
              variant="text"
              startEvaIcon={{
                name: 'edit-outline',
                fill: isClosed
                  ? theme.palette.action.disabled
                  : theme.palette.grey[600],
              }}
              disabled={isClosed}
              onClick={onClickEdit}
            >
              Edit Order
            </Button>
          </Stack>
        )}
      </Stack>
    </Footer>
  );
};

export default PreviewButtons;
