import {
  Box,
  CardActionArea,
  Divider,
  Grid,
  Paper,
  Stack,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { FC, useMemo } from 'react';
import {
  getQuantitySelected,
  isFull,
  getSectionTypeLabel,
} from '@fdha/common-utils';
import { cloneDeep, uniq } from 'lodash';

import { Typography } from '../Typography/Typography';
import { MealCard } from '../MealCard/MealCard';
import { CategoryItem, MenuOptionSelection, SectionType } from '../../utils';
import { Icon } from '../Icon/Icon';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';

import QuantitySelector from './QuantitySelector';
import SelectedSubtitle from './SelectedSubtitle';

export interface MenuSectionProps {
  name: SectionType;
  amount: number;
  hasSides: boolean;
  allOptions: MenuOptionSelection[];
  filteredOptions?: MenuOptionSelection[];
  showTags?: boolean;
  setFieldValue: (field: string, value: MenuOptionSelection[]) => void;
  setFieldTouched?: (name: string, isTouched?: boolean) => void;
  onDishClicked: (id: string, name: string) => void;
  v2?: boolean;
}

export const MenuSection: FC<MenuSectionProps> = ({
  name,
  amount,
  hasSides,
  allOptions,
  filteredOptions,
  showTags = true,
  setFieldValue,
  setFieldTouched,
  onDishClicked,
  v2 = false,
}) => {
  const theme = useTheme();

  const visibleOptions = useMemo(
    () => (filteredOptions ? filteredOptions : allOptions),
    [allOptions, filteredOptions]
  );

  const showFilteredEmptyState = useMemo(
    () => filteredOptions?.length === 0,
    [filteredOptions?.length]
  );

  const isDisabled = isFull(allOptions, amount);

  const onChange = (value: number, menuOptionId: string) => {
    setFieldTouched && setFieldTouched(name, true);
    const index = allOptions.findIndex(
      (obj) => obj.menuOptionId === menuOptionId
    );
    if (index !== -1) {
      const newValues = cloneDeep(allOptions);
      newValues[index].quantity = value;
      setFieldValue(name, newValues);
    }
  };
  const i18nKeyTitle =
    name === 'snacks'
      ? hasSides
        ? 'meals:editDelivery.selection.titleSnackSides'
        : 'meals:editDelivery.selection.titleSnacks'
      : 'meals:editDelivery.selection.titleEntrees';

  const i18nKeyFilteredEmptyState =
    name === 'snacks'
      ? 'temporary:meals.editDelivery.selection.filteredEmptyStateSnacksAndSides'
      : 'temporary:meals.editDelivery.selection.filteredEmptyStateEntrees';

  const selectedQuantity = useMemo(
    () => getQuantitySelected(allOptions || []),
    [allOptions]
  );

  /************ V2 updates *********************/

  const HeaderV2 = () => (
    <Box
      py={2}
      px={4}
      sx={{
        backgroundColor: theme.palette.info.lightBg,
      }}
    >
      <Typography
        variant="h6"
        i18nKey={i18nKeyTitle}
        i18nParams={{ total: amount }}
      >
        {`Choose ${amount} <strong>${getSectionTypeLabel(
          name,
          hasSides
        )}</strong>`}
      </Typography>
      <SelectedSubtitle
        type={name}
        amount={amount}
        selectedQuantity={selectedQuantity}
        hasSides={hasSides}
        isFull={isDisabled}
      />
    </Box>
  );

  const HeaderV1 = () => (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <Typography variant="h6">
        Selection for <strong>{getSectionTypeLabel(name, hasSides)}</strong>
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="baseline">
        <Typography variant="body1">{selectedQuantity}</Typography>
        <Typography variant="body2" color={grey[700]}>
          /{amount}
        </Typography>
      </Box>
    </Stack>
  );

  const Header = () => (v2 ? <HeaderV2 /> : <HeaderV1 />);

  const mealCardImageSize = v2 ? '80px' : '60px';
  const cardSx = v2 ? { px: 4 } : {};

  const getMealType = (types: string[]) => (v2 ? types : undefined);
  const boxRowGap = v2 ? {} : 1.5;
  const gridSx = v2 ? { paddingY: 1 } : {};
  const getTags = (item: CategoryItem[]): string[] =>
    uniq(
      item.flatMap((category) => category.tags.map((tag) => tag.name)).sort()
    );

  const FilteredEmptyStateV1 = () => (
    <Box display="flex" alignItems="center" columnGap={1}>
      <Icon
        name="search-outline"
        fill={theme.palette.text.primary}
        size="large"
      />
      <Typography color={theme.palette.text.secondary}>
        No results for <strong>{getSectionTypeLabel(name, hasSides)}</strong>.
        Consider adjusting your filters to allow more items to be displayed.
      </Typography>
    </Box>
  );

  const FilteredEmptyStateV2 = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Paper
        sx={{
          width: '100%',
        }}
      >
        <Typography
          color={theme.palette.text.secondary}
          i18nKey={i18nKeyFilteredEmptyState}
        >
          No results for {getSectionTypeLabel(name, hasSides)}. Consider
          adjusting your filters to allow more items to be displayed.
        </Typography>
      </Paper>
    </Box>
  );

  const renderFilteredEmptyState = () =>
    v2 ? <FilteredEmptyStateV2 /> : <FilteredEmptyStateV1 />;

  /*********************************************/

  return (
    <Box rowGap={boxRowGap} display="flex" flexDirection="column">
      <Header />
      {showFilteredEmptyState && renderFilteredEmptyState()}
      <ConditionalWrapper
        showWrapper={v2}
        wrapper={(children) => (
          <Box
            sx={{
              padding: 0,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            {children}
          </Box>
        )}
      >
        {visibleOptions.map((opt, index) => {
          const tags = showTags
            ? getTags(opt.tagsByCategory || [])
            : getMealType(opt.mealType || []);
          return (
            <Box key={opt.menuOptionId}>
              <Grid container alignItems="center">
                <Grid item xs={10} sx={gridSx}>
                  <CardActionArea
                    onClick={() => onDishClicked(opt.dishId, opt.name)}
                    sx={cardSx}
                  >
                    <MealCard
                      name={opt.name}
                      tags={tags}
                      photoUrl={opt.photoUrl}
                      imageSize={mealCardImageSize}
                    />
                  </CardActionArea>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="flex-end">
                  <QuantitySelector
                    value={opt.quantity}
                    onChange={(value) => onChange(value, opt.menuOptionId)}
                    isDisabled={v2 && isDisabled}
                  />
                </Grid>
              </Grid>
              {v2 && index !== visibleOptions.length - 1 && <Divider />}
            </Box>
          );
        })}
      </ConditionalWrapper>
    </Box>
  );
};
