import React from 'react';
import { DialogContentText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ActionCard,
  ActionItem,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { parseISO } from 'date-fns';
import {
  ListSurveysDocument,
  Survey,
  useDuplicateSurveyMutation,
  useArchiveSurveyMutation,
  useDeleteSurveyMutation,
} from '@fdha/graphql-api-admin';

import AssignSurvey from '../AssignSurvey/AssignSurvey';
import { LEGACY_SURVEY } from '../../utils';

const SurveyCard = (props: { survey: Survey }) => {
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [deleteSurvey] = useDeleteSurveyMutation();
  const [archiveSurvey] = useArchiveSurveyMutation();
  const [duplicateSurvey] = useDuplicateSurveyMutation();

  const updateDate = props.survey.updated_at || props.survey.created_at;

  const handleEditSurvey = () => {
    navigate('/settings/survey/create-survey', {
      state: {
        survey: {
          ...props.survey,
          name: props.survey.name.replace(LEGACY_SURVEY, ''),
        },
      },
    });
  };

  const handleDeleteSurvey = () => {
    openDialog({
      title: 'Are you sure you want to delete this survey?',
      content: (
        <DialogContentText>
          You will not be able to recover it.
        </DialogContentText>
      ),
      confirmButtonLabel: 'Delete',
      cancelButtonLabel: 'Keep',
      handleConfirm: async () => {
        try {
          await deleteSurvey({
            variables: { id: props.survey.id },
            refetchQueries: [ListSurveysDocument],
          });
          showSnackbar({ message: 'Survey deleted!', severity: 'success' });
        } catch (error) {
          showSnackbar({
            message: 'Error to delete survey',
            severity: 'error',
          });
        } finally {
          closeDialog();
        }
      },
    });
  };

  const handleArchiveSurvey = () => {
    openDialog({
      title: 'Are you sure you want to archive this survey?',
      content: (
        <DialogContentText>
          You will not be able to recover it.
        </DialogContentText>
      ),
      confirmButtonLabel: 'Archive',
      cancelButtonLabel: 'Keep',
      handleConfirm: async () => {
        try {
          await archiveSurvey({
            variables: { id: props.survey.id },
            refetchQueries: [ListSurveysDocument],
          });
          showSnackbar({ message: 'Survey archived!', severity: 'success' });
        } catch (error) {
          showSnackbar({
            message: 'Error to archive survey',
            severity: 'error',
          });
        } finally {
          closeDialog();
        }
      },
    });
  };

  const handleAssign = () => {
    openDialog({
      title: '',
      content: (
        <AssignSurvey
          mode="toPatientsAndGroups"
          surveyData={{ id: props.survey.id, name: props.survey.name }}
          onFinish={closeDialog}
        />
      ),
    });
  };

  const handlePreview = () => {
    navigate(`/settings/survey/${props.survey.id}`, {
      state: { row: props.survey },
    });
  };

  const handleDuplicate = async () => {
    openDialog({
      title: 'Are you sure you want to duplicate this survey?',
      content: (
        <DialogContentText>
          Your new survey will be displayed on drafts list.
        </DialogContentText>
      ),
      confirmButtonLabel: 'Duplicate',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        try {
          await duplicateSurvey({
            variables: { surveyId: props.survey.id },
            refetchQueries: [ListSurveysDocument],
          });
          showSnackbar({ message: 'Survey duplicated!', severity: 'success' });
        } catch (error) {
          showSnackbar({
            message: 'Error to duplicate survey',
            severity: 'error',
          });
        } finally {
          closeDialog();
        }
      },
    });
  };

  const getActions = (): ActionItem[] => {
    if (props.survey.archived) {
      return [
        { type: 'preview', onClick: handlePreview },
        { type: 'duplicate', onClick: handleDuplicate },
      ];
    } else if (props.survey.published) {
      return [
        {
          type: 'custom',
          label: 'Assign to Patients',
          icon: 'plus-outline',
          testId: 'ASSIGN_TO_PATIENTS_MENU_OPTION',
          onClick: handleAssign,
        },
        { type: 'preview', onClick: handlePreview },
        { type: 'duplicate', onClick: handleDuplicate },
        { type: 'archive', onClick: handleArchiveSurvey },
      ];
    }
    return [
      { type: 'edit', onClick: handleEditSurvey },
      { type: 'duplicate', onClick: handleDuplicate },
      { type: 'delete', onClick: handleDeleteSurvey },
    ];
  };

  return (
    <ActionCard
      title={props.survey.name}
      actionDate={parseISO(updateDate)}
      actions={getActions()}
    />
  );
};

export default SurveyCard;
