import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, useTheme } from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem as MuiTimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import {
  isAfter,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMinute,
  isToday,
  parseISO,
} from 'date-fns';
import { format } from 'date-fns-tz';

import { RangePeriod } from '../DateRangePicker/DateRangePicker';
import { Activity, Task } from '../../utils';
import { Typography } from '../Typography/Typography';

import { TimelineItem } from './TimelineItem';

interface ActivitiesByDate {
  date: string;
  activities: Activity[];
}

interface TimelineListProps {
  v2?: boolean;
  from: string;
  to: string;
  activitiesByDateList: ActivitiesByDate[];
  mode: RangePeriod;
  dateToScroll?: string;
  previousDate?: string;
  readOnly?: boolean;
  handleSelectActivity: (activity: Activity) => void;
  updateSelectedActivity: (activity: Partial<Activity>) => void;
  onUpdateTask: (data: Task, completed: boolean) => void;
}

const TimelineList: React.FC<TimelineListProps> = ({
  v2,
  from,
  to,
  activitiesByDateList,
  mode,
  dateToScroll,
  previousDate,
  handleSelectActivity,
  updateSelectedActivity,
  onUpdateTask,
  readOnly,
}) => {
  const theme = useTheme();
  const listRef = useRef<HTMLDivElement>(null);
  const listItemsRef = useMemo(() => new Map<number, HTMLLIElement>(), []);
  const todayRef = useRef<HTMLDivElement>();

  /************ V2 updates *********************/

  const dotColor = v2 ? 'primary' : 'secondary';
  const emptyTextVariant = v2 ? 'body1' : 'subtitle2';
  const emptyTextColor = v2 ? theme.palette.text.secondary : 'primary';
  const titleFontSize = v2 ? '16px' : '20px';
  const titleFontWeight = v2 ? 'bold' : 'medium';
  const timelineContentPadding = v2 ? 0.4 : 0;
  const titleFormat = v2 ? 'MMMM dd' : 'MMM dd';

  const renderEmptyState = (isCenter: boolean) => {
    const emptyTextAlign = v2 && isCenter ? 'center' : 'start';

    const today = new Date();
    const isInTodayRange =
      isAfter(today, new Date(from)) && isBefore(today, new Date(to));
    const isInPast = isBefore(new Date(from), today);

    let emptyText = '';
    let i18nKey = '';
    if (v2) {
      if (isInTodayRange) {
        if (mode === RangePeriod.DAY) {
          i18nKey = 'activities:timeline.emptyState.nothingScheduled';
          emptyText = 'You’re all caught up! There’s nothing scheduled today.';
        } else {
          i18nKey = 'activities:timeline.emptyState.noEventsToday';
          emptyText = 'There are no events today.';
        }
      } else if (isInPast) {
        i18nKey = 'activities:timeline.emptyState.noEventsThisDay';
        emptyText = 'No events on this day.';
      } else {
        i18nKey = 'activities:timeline.emptyState.nothingHere';
        emptyText =
          'Nothing here yet. Add a new event to display in your timeline!';
      }
    } else {
      emptyText = 'There are no events.';
    }

    return (
      <Typography
        i18nKey={i18nKey}
        variant={emptyTextVariant}
        color={emptyTextColor}
        textAlign={emptyTextAlign}
      >
        {emptyText}
      </Typography>
    );
  };

  /*********************************************/

  const scrollToSection = useCallback(
    (sectionIndex: number) => {
      listRef.current?.scrollTo({
        top: listItemsRef.get(sectionIndex)?.offsetTop,
        behavior: 'smooth',
      });
    },
    [listItemsRef]
  );

  const scrollToToday = useCallback(
    (dateToScroll: string) => {
      const index = activitiesByDateList.findIndex((section) =>
        isSameDay(parseISO(section.date), parseISO(dateToScroll))
      );

      if (index !== -1) {
        scrollToSection(index);
      }
    },
    [activitiesByDateList, scrollToSection]
  );

  const scrollToHour = useCallback(
    (dateToScroll: string) => {
      const time = parseISO(dateToScroll);

      if (time.getMinutes() >= 30) {
        time.setMinutes(30);
      } else {
        time.setMinutes(0);
      }

      const index = activitiesByDateList.findIndex((section) => {
        const sectionTime = parseISO(section.date);

        const isSameHourAndMinute =
          isSameHour(sectionTime, time) && isSameMinute(sectionTime, time);

        return isSameHourAndMinute || isAfter(sectionTime, time);
      });

      if (index !== -1) {
        scrollToSection(index);
      }
    },
    [activitiesByDateList, scrollToSection]
  );

  useEffect(() => {
    if (dateToScroll && previousDate !== dateToScroll) {
      if (mode === RangePeriod.DAY) {
        scrollToHour(dateToScroll);
      } else {
        scrollToToday(dateToScroll);
      }
    }
  }, [dateToScroll, mode, previousDate, scrollToHour, scrollToToday]);

  const getTitle = (date: string) => {
    let title;
    let i18nKey;
    const isCurrentDate = isToday(parseISO(date));

    if (isCurrentDate && mode !== RangePeriod.DAY) {
      title = 'Today';
      i18nKey = 'activities:today';
    } else if (mode === RangePeriod.DAY) {
      title = format(parseISO(date), 'hh:mm a');
    } else {
      title = format(parseISO(date), titleFormat);
      i18nKey = 'activities:timeline.monthDay';
    }

    return { title, i18nKey };
  };

  const sortActivitiesByCreateOrder = (activities: Activity[]) => {
    if (!activities?.length) {
      return;
    }

    activities.sort((a, b) => {
      const timeA = new Date(a.time).getTime();
      const timeB = new Date(b.time).getTime();

      return timeA !== timeB
        ? timeA - timeB
        : Number(a.instanceId) - Number(b.instanceId);
    });
  };

  // TODO: Verify if has next page
  const isLastItem = (index: number) => {
    return activitiesByDateList.length - 1 === index;
  };

  const renderItem = (item: ActivitiesByDate, index: number) => {
    sortActivitiesByCreateOrder(item.activities);
    const _isLastItem = isLastItem(index);
    const today = isToday(parseISO(item.date));
    const { title, i18nKey } = getTitle(item.date);
    return (
      <MuiTimelineItem
        key={item.date}
        ref={(elem: HTMLLIElement) => listItemsRef.set(index, elem)}
      >
        <TimelineSeparator>
          <TimelineDot color={dotColor} />
          {!_isLastItem && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent
          sx={{
            paddingBottom: !_isLastItem ? 5 : 0,
            paddingTop: timelineContentPadding,
            paddingRight: 1,
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <Box ref={today ? todayRef : undefined}>
            <Box paddingBottom={1}>
              <Typography
                variant="h6"
                component="span"
                fontSize={titleFontSize}
                fontWeight={titleFontWeight}
                i18nKey={i18nKey}
                i18nParams={{ date: item.date }}
              >
                {title}
              </Typography>
            </Box>
            {!item.activities.length && renderEmptyState(false)}
            {item.activities.map((activity: Activity) => {
              return (
                <Box paddingTop={1} key={activity.instanceId}>
                  <TimelineItem
                    v2={v2}
                    item={activity}
                    readOnly={readOnly}
                    handleSelectActivity={handleSelectActivity}
                    updateSelectedActivity={updateSelectedActivity}
                    onUpdateTask={onUpdateTask}
                  />
                </Box>
              );
            })}
          </Box>
        </TimelineContent>
      </MuiTimelineItem>
    );
  };

  return (
    <Box height="100%" overflow="auto" position="relative" ref={listRef}>
      {!activitiesByDateList.length && renderEmptyState(true)}
      <Timeline position="right" sx={{ margin: 0, padding: 0 }}>
        {activitiesByDateList.map((value, index) => {
          return renderItem(value, index);
        })}
      </Timeline>
    </Box>
  );
};

export default TimelineList;
