import React, { FC } from 'react';
import { Button, Typography, pluralize } from '@fdha/web-ui-library';
import { Box, Stack } from '@mui/material';

import Footer from '../Footer';

interface EditButtonsProps {
  entreeDishesAmount: number;
  snackAmount: number;
  onCancel: () => void;
  onSubmit: () => void;
  disabled: boolean;
  showDiscrepancyAlert?: boolean;
}

const EditButtons: FC<EditButtonsProps> = ({
  entreeDishesAmount,
  snackAmount,
  onCancel,
  onSubmit,
  disabled,
  showDiscrepancyAlert,
}) => {
  return (
    <Footer showAlert={showDiscrepancyAlert}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          {pluralize(entreeDishesAmount, 'meal')} selected,{' '}
          {pluralize(snackAmount, 'snack')} selected
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="text" onClick={onCancel}>
            Cancel Changes
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            color="secondary"
            sx={{ px: 6 }}
            disabled={disabled}
          >
            Save selection
          </Button>
        </Stack>
      </Box>
    </Footer>
  );
};

export default EditButtons;
