import { useEffect } from 'react';
import {
  GetSiteStaffBySiteQuery,
  useGetSiteStaffBySiteQuery,
} from '@fdha/graphql-api-admin';
import { IconButton, Paper, Stack, TableCell, TableRow } from '@mui/material';
import {
  HeadCell,
  Icon,
  SearchableTableHeader,
  Table,
  formatUTCDate,
} from '@fdha/web-ui-library';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { NetworkStatus } from '@apollo/client';

import { Avatar } from '../../../../components';
import { getStatus } from '../../../../utils';
import {
  useDebouncedValue,
  useFilterBy,
  useSortBy,
  useTable,
} from '../../../../hooks';

type SiteStaffUserFromSite =
  GetSiteStaffBySiteQuery['siteStaffBySite']['edges'][number]['node'];

const headCells: HeadCell<SiteStaffUserFromSite>[] = [
  { id: 'name', label: 'Site staff name', sortable: true, searchable: true },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
    searchable: true,
  },
  {
    id: 'created_at',
    label: 'Creation date',
    sortable: true,
    searchable: false,
  },
  {
    id: 'is_active',
    label: 'Site staff status',
    sortable: false,
    searchable: false,
  },
];

const SiteStaff = () => {
  const { siteId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { page, rowsPerPage, setPage, changeRowsPerPage } = useTable({
    key: 'siteSiteStaff',
  });

  const [filterBy, setFilterBy] = useFilterBy<SiteStaffUserFromSite>(
    'name',
    ''
  );
  const [sortBy, setSortBy] = useSortBy<SiteStaffUserFromSite>('name', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const { data, loading, networkStatus, fetchMore } =
    useGetSiteStaffBySiteQuery({
      variables: {
        siteId: siteId,
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const nodes = data?.siteStaffBySite.edges.map((edge) => edge.node) || [];
  const pageInfo = data?.siteStaffBySite.pageInfo;
  const totalNumberFound = data?.siteStaffBySite.totalNumberFound;

  const emptyState = filterByDebounced.value
    ? 'No results found'
    : 'No site staff yet';

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const renderRow = (row: SiteStaffUserFromSite) => {
    const siteStaffStatus =
      row.is_active != null ? getStatus(row.is_active) : '-';
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="SITE_STAFF_NAME_CELL">
          <Avatar type="imageText" name={row.name} picture={row.picture} />
        </TableCell>
        <TableCell data-testid="SITE_STAFF_EMAIL_CELL">{row.email}</TableCell>
        <TableCell data-testid="SITE_STAFF_CREATED_AT_CELL">
          {formatUTCDate(row.created_at)}
        </TableCell>
        <TableCell data-testid="SITE_STAFF_STATUS_CELL">
          {siteStaffStatus}
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton
            onClick={() =>
              navigate(`/trials/site-staff/${row.id}`, {
                state: { backRoute: location.pathname },
                replace: true,
              })
            }
            data-testid="SITE_STAFF_PROFILE_BUTTON"
          >
            <Icon
              name="arrow-ios-forward-outline"
              size="large"
              fill={grey[600]}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Stack spacing={2}>
      <SearchableTableHeader<SiteStaffUserFromSite>
        headCells={headCells}
        defaultSearchField="name"
        onSearchChange={setFilterBy}
        disableMargin
      />
      <Paper>
        <Table<SiteStaffUserFromSite>
          withPagination
          actions="right"
          initialOrderBy="name"
          headCells={headCells}
          page={page}
          emptyState={emptyState}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          rows={nodes}
          isLoading={loading}
          totalRowCount={totalNumberFound}
          renderRow={renderRow}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={setSortBy}
        />
      </Paper>
    </Stack>
  );
};

export default SiteStaff;
