import { Box, IconButton, IconButtonProps, styled } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import Icon from 'react-eva-icons';
import { ImageDropzone } from 'react-file-utils';
import {
  ChatAutoComplete,
  EmojiPicker,
  MessageInputProps,
  UploadsPreview,
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react';

import './MessageInput.css';

import Chip from '../../Chip/Chip';
import { GiphyContext } from '../ChannelInner';
import QuotedMessagePreview from '../QuotedMessagePreview/QuotedMessagePreview';

const EmojiButton = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>((props, ref) => {
  // This needs to be a div because emojiPickerRef corresponds to HTMLDivElement
  const Wrapper = styled('div')(({ theme }) => ({
    height: 24,
    margin: '0 20px',
    cursor: 'pointer',
    svg: {
      height: 24,
      width: 24,
      path: { fill: theme.palette.grey[600] },
    },
    ':hover': {
      'svg path': { fill: theme.palette.primary.main },
    },
  }));

  return (
    <Wrapper role="button" {...props} ref={ref}>
      <Icon name="smiling-face-outline" />
    </Wrapper>
  );
});

const GiphyIndicator = () => (
  <Chip
    color="primary"
    label="GIPHY"
    size="small"
    sx={{ fontWeight: 'bold', fontSize: 12, marginLeft: 1 }}
  />
);

const SendButton = (props: IconButtonProps) => {
  const Button = styled(IconButton)(({ theme }) => ({
    margin: '0 20px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    padding: 14,
    ':hover': {
      backgroundColor: theme.palette.primary.main,
    },
    svg: {
      height: 20,
      width: 20,
      path: { fill: theme.palette.primary.contrastText },
    },
  }));

  return (
    <Button {...props}>
      <Icon name="paper-plane-outline" size="medium" />
    </Button>
  );
};

const MessageInput: React.FC<MessageInputProps> = () => {
  const messageInput = useMessageInputContext();
  const { acceptedFiles, maxNumberOfFiles, multipleUploads, quotedMessage } =
    useChannelStateContext();
  const { giphyState, setGiphyState } = useContext(GiphyContext);

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      const { value } = event.target;

      const deletePressed =
        event.nativeEvent instanceof InputEvent &&
        event.nativeEvent.inputType === 'deleteContentBackward';

      if (messageInput.text.length === 1 && deletePressed) {
        setGiphyState(false);
      }

      if (
        !giphyState &&
        messageInput.text.startsWith('/giphy') &&
        !messageInput.numberOfUploads
      ) {
        event.target.value = value.replace('/giphy', '');
        setGiphyState(true);
      }

      messageInput.handleChange(event);
    },
    [giphyState, messageInput, setGiphyState]
  );

  return (
    <>
      {quotedMessage && <QuotedMessagePreview quotedMessage={quotedMessage} />}
      <Box display="flex" alignItems="center" padding="8px" position="relative">
        <EmojiButton
          onClick={messageInput.openEmojiPicker}
          ref={messageInput.emojiPickerRef}
          data-testid="EMOJI_BUTTON"
        />
        <Box
          width="100%"
          minHeight="40px"
          height="fit-content"
          borderRadius="15px"
          border="2px solid"
          borderColor="divider"
          overflow="auto"
          data-testid="MESSAGE_INPUT_BOX"
        >
          <ImageDropzone
            accept={acceptedFiles}
            handleFiles={messageInput.uploadNewFiles}
            multiple={multipleUploads}
            disabled={
              (maxNumberOfFiles !== undefined &&
                messageInput.numberOfUploads >= maxNumberOfFiles) ||
              giphyState
            }
          >
            {giphyState && !messageInput.numberOfUploads && <GiphyIndicator />}
            <UploadsPreview />
            <ChatAutoComplete
              onChange={onChange}
              rows={1}
              placeholder="Send a message"
            />
          </ImageDropzone>
        </Box>
        <SendButton
          onClick={messageInput.handleSubmit}
          data-testid="SEND_BUTTON"
        />
        <EmojiPicker />
      </Box>
    </>
  );
};

export default MessageInput;
