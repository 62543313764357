import React, { useEffect, useMemo, useState } from 'react';
import { Icon, Loader, useDialog } from '@fdha/web-ui-library';
import { Box, IconButton, Tab, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { GroupListing, useGetGroupQuery } from '@fdha/graphql-api-admin';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { useGetUserType } from '../../hooks';
import { AddOrEditGroup, BasePage } from '../../components';

const Group = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdmin } = useGetUserType();
  const { openDialog, closeDialog } = useDialog();

  const [selectedTab, setSelectedTab] = useState('participants');

  const groupId = params.groupId || '';

  const { data: groupData, loading: loadingGroupData } = useGetGroupQuery({
    variables: { id: groupId },
  });

  const group = groupData?.group;

  const tabs = useMemo(() => {
    return [
      {
        value: 'participants',
        label: 'Participants',
        to: './',
        'data-testid': 'PATICIPANTS_TAB',
      },
      ...(isAdmin
        ? [
            {
              value: 'recipes',
              label: 'Recipes',
              to: 'recipes',
              'data-testid': 'RECIPES_TAB',
            },
          ]
        : []),
    ];
  }, [isAdmin]);

  useEffect(() => {
    const pathName = location.pathname.includes('recipes')
      ? 'recipes'
      : 'participants';
    setSelectedTab(pathName);
  }, [location.pathname]);

  const onEdit = (group: GroupListing) => {
    openDialog({
      title: '',
      content: (
        <AddOrEditGroup
          data={group}
          onDelete={() => navigate('/groups')}
          onFinish={closeDialog}
        />
      ),
    });
  };

  return (
    <BasePage data-testId="GROUP_PAGE_CONTAINER">
      <BasePage.BackButton to="/groups" />
      {loadingGroupData ? (
        <Loader />
      ) : group ? (
        <Box>
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography variant="h5">{groupData?.group?.name}</Typography>
            {isAdmin && (
              <IconButton
                sx={{ marginLeft: 1 }}
                onClick={() => onEdit(group)}
                data-testid="EDIT_BUTTON"
              >
                <Icon name="edit-outline" fill={grey[600]} size="large" />
              </IconButton>
            )}
          </Box>
          <TabContext value={selectedTab}>
            <TabList
              variant="scrollable"
              orientation="horizontal"
              value={selectedTab}
              onChange={(_, value) => setSelectedTab(value)}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              {tabs.map((tab) => (
                <Tab
                  value={tab.value}
                  label={tab.label}
                  onClick={() => navigate(tab.to, { replace: true })}
                  key={tab.value}
                  data-testid={tab['data-testid']}
                />
              ))}
            </TabList>
            <TabPanel sx={{ padding: 0, paddingTop: 4 }} value={selectedTab}>
              <Outlet />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <Typography>{`No group found with id ${groupId}`}</Typography>
      )}
    </BasePage>
  );
};

export default Group;
