import React, { useMemo } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import Icon from 'react-eva-icons';
import { grey } from '@mui/material/colors';
import { parseISO } from 'date-fns';
import {
  getAge,
  formatUTCDate,
  formatDate,
  LabelBox,
} from '@fdha/web-ui-library';

import { OnboardingInformationProps } from './OnboardingInformation';

const ViewModeOnboardingInformation = (props: OnboardingInformationProps) => {
  const isCt = props.patientUser?.is_ct;

  const siteStaffEmails = useMemo(
    () =>
      props.patientUser?.site_staff_emails?.map((email) => email).join('; '),
    [props.patientUser?.site_staff_emails]
  );

  return (
    <>
      <Box marginBottom={3} data-testid="ONBOARDING_SHOW_MODE">
        <Grid container columnSpacing={3} rowSpacing={0}>
          <LabelBox size={4} label="Name" value={props.patientUser?.name} />
          <LabelBox size={4} label="Email" value={props.patientUser?.email} />
          <LabelBox
            size={4}
            label="Phone number"
            value={props.patientUser?.phone_number}
          />
          {!props.isCsr && (
            <>
              <LabelBox
                size={4}
                label="Age"
                value={getAge(props.patientUser?.birthdate)}
              />
              <LabelBox
                size={4}
                label="Birth date"
                value={formatUTCDate(props.patientUser?.birthdate)}
              />
            </>
          )}
          <LabelBox
            size={4}
            label="Joined on"
            value={
              props.patientUser?.created_at &&
              formatDate(parseISO(props.patientUser?.created_at))
            }
          />
          {isCt && (
            <LabelBox
              size={4}
              label="Study start"
              value={formatUTCDate(props.patientUser?.study_start)}
            />
          )}
          {isCt && (
            <>
              <LabelBox
                size={4}
                label="Subject Id"
                value={props.patientUser?.subject_id}
              />
              {!props.isCsr && (
                <LabelBox
                  size={4}
                  label="Site staff emails"
                  value={siteStaffEmails}
                />
              )}
              <LabelBox
                size={4}
                label="Associated site"
                value={props.patientUser?.associated_site}
              />
              <LabelBox
                size={4}
                label="Address"
                value={props.patientUser?.address?.street_address}
              />
              <LabelBox
                size={4}
                label="Complement"
                value={props.patientUser?.address?.complement}
              />
              <LabelBox
                size={4}
                label="City"
                value={props.patientUser?.address?.locality}
              />
              <LabelBox
                size={4}
                label="State"
                value={props.patientUser?.address?.region}
              />
              <LabelBox
                size={4}
                label="Zip/Postal code"
                value={props.patientUser?.address?.postal_code}
              />
            </>
          )}
        </Grid>
      </Box>
      <Box textAlign="end">
        <IconButton
          onClick={() => props.handleEditMode(true)}
          data-testid="EDIT_BUTTON"
        >
          <Icon name="edit-outline" width={24} height={24} fill={grey[600]} />
        </IconButton>
      </Box>
    </>
  );
};

export default ViewModeOnboardingInformation;
