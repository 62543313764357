import { DocumentNode } from 'graphql';
import { useSetGroupsToUserMutation } from '@fdha/graphql-api-admin';

export const useAddRemoveGroups = () => {
  const [setGroupsToUser] = useSetGroupsToUserMutation();

  const addRemoveGroups = async (
    userId: string,
    groupIdsToAdd: string[],
    groupIdsToRemove: string[],
    refetchQueries: DocumentNode[]
  ) => {
    if (groupIdsToAdd.length || groupIdsToRemove.length) {
      const updateObj = {
        variables: {
          userId,
          groupIdsToAdd,
          groupIdsToRemove,
        },
        refetchQueries,
      };

      await setGroupsToUser(updateObj);
    }
  };

  const removeGroups = async (
    userId: string,
    groupIdsToRemove: string[],
    refetchQueries: DocumentNode[]
  ) => {
    if (groupIdsToRemove.length) {
      await setGroupsToUser({
        variables: {
          userId,
          groupIdsToRemove,
        },
        refetchQueries,
      });
    }
  };

  return { addRemoveGroups, removeGroups };
};
