import React, { FC } from 'react';
import { TextField } from '@fdha/web-ui-library';

import { QuestionOptionProps } from './OptionsTabPanel';

const TextOption: FC<QuestionOptionProps<string | number>> = ({
  values,
  errorState,
  handleChange,
}) => {
  return (
    <>
      <TextField
        name="placeholderText"
        title="Placeholder text"
        size="small"
        value={values.placeholderText || ''}
        error={!!errorState.placeholderText}
        helperText={errorState.placeholderText}
        onChange={(e) => handleChange('placeholderText', e.target.value)}
        data-testid="OPEN_TEXT_OPTIONS_PLACEHOLDER"
      />
      <TextField
        name="maxLength"
        title="Maximum number of characters"
        type="number"
        size="small"
        value={values.maxLength}
        error={!!errorState.maxLength}
        helperText={errorState.maxLength}
        onChange={(e) => {
          const value =
            e.target.value !== '' ? Number(e.target.value) : e.target.value;
          handleChange('maxLength', value);
        }}
      />
    </>
  );
};

export default TextOption;
