import { getToken } from '@fdha/common-utils';
import { extension } from 'mime-types';

const fetchWithAuthentication = async (url: string) => {
  const jwt = await getToken();
  const authorization = `Bearer ${jwt}`;
  const headers = { authorization };
  return fetch(url, { headers });
};

const filenameFromUrl = (url: string) => new URL(url).pathname.split('/').pop();
const extensionFromContentType = (type?: string) =>
  extension(type ?? '') || undefined;

const doDownloadFile = async (
  url: string,
  filename?: string,
  extension?: string
) => {
  const name = filename ?? 'data';
  const ext = extension ? `.${extension}` : '';
  const nameWithExt = `${name}${ext}`;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', nameWithExt);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

const downloadFile = async (url: string, downloadName?: string) => {
  const response = await fetchWithAuthentication(url);
  const blob = await response.blob();
  const downloadUrl = URL.createObjectURL(blob);
  const contentType = response.headers.get('Content-Type') ?? undefined;
  const filename = downloadName ?? filenameFromUrl(url);
  return doDownloadFile(
    downloadUrl,
    filename,
    extensionFromContentType(contentType)
  );
};

export default downloadFile;
