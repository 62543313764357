import { UserType } from '@fdha/graphql-api-admin';
import { UserResponse } from 'stream-chat';

export type ChatUser = UserResponse<{
  online: boolean;
  image: string;
  name: string;
  userType?: UserType;
  subjectId?: string;
}>;

export const getDisplaySubtitle = (userType?: UserType, subjectId?: string) => {
  switch (userType) {
    case UserType.Caretaker:
      return 'Caretaker';
    case UserType.Patient:
      return 'DH Patient';
    case UserType.ClinicalTrialPatient:
      return subjectId || 'CT Patient';
    default:
      return null;
  }
};
