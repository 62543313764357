import React from 'react';
import {
  CheckboxGroup,
  CheckboxGroupState,
  IconProps,
  RadioGroup,
} from '@fdha/web-ui-library';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import {
  LearningQuiz as LearningQuizType,
  QuizItem,
} from '@fdha/graphql-api-admin';

interface LearningQuizProps {
  data: LearningQuizType;
}

const LearningQuiz: React.FC<LearningQuizProps> = ({ data }) => {
  const theme = useTheme();
  const { palette } = theme;

  const getOptionIcon = (isCorrect: boolean): IconProps => {
    const name = isCorrect ? 'checkmark-circle-2' : 'close-circle';
    const fill = isCorrect ? palette.success.light : palette.error.light;

    return {
      name,
      fill,
      size: 'medium',
      'data-testid': isCorrect ? 'CORRECT_ANSWER' : 'INCORRECT_ANSWER',
    };
  };

  const renderQuestion = (
    { question, options, correctAnswers, userAnswers }: QuizItem,
    index: number
  ) => {
    const questionType =
      correctAnswers.length > 1 ? 'multipleChoice' : 'singleChoice';
    const questionNumber = index + 1;
    const answers = (userAnswers || []) as string[];

    return (
      <Box
        key={index}
        sx={{
          '&:not(:first-of-type)': {
            marginTop: 4,
          },
        }}
      >
        <Typography
          data-testid={`QUESTION_TITLE_${questionNumber}`}
          variant="h6"
          paddingBottom={1}
        >{`${questionNumber}. ${question}`}</Typography>
        <Divider />
        <Box paddingTop={2}>
          {renderOptions(questionType, options, correctAnswers, answers)}
        </Box>
      </Box>
    );
  };

  const renderOptions = (
    type: 'multipleChoice' | 'singleChoice',
    options: string[],
    correctAnswers: string[],
    answers: string[]
  ) => {
    const formattedOptions = options.map((opt) => {
      const isCorrect = correctAnswers.includes(opt);
      return {
        label: opt,
        value: opt,
        icon: answers.length ? getOptionIcon(isCorrect) : undefined,
      };
    });

    if (type === 'singleChoice') {
      return (
        <RadioGroup
          radioProps={{ disabled: true }}
          options={formattedOptions}
          onChange={() => {}}
          value={answers[0] || ''}
        />
      );
    }

    const checkboxValue =
      options?.reduce((acc, option) => {
        acc[option] = answers?.includes(option) ?? false;
        return acc;
      }, {} as CheckboxGroupState) ?? {};

    return (
      <CheckboxGroup
        checkboxProps={{ disabled: true }}
        options={formattedOptions}
        value={checkboxValue}
        onChange={() => {}}
      />
    );
  };

  return (
    <>
      {data.questions.map((question, index) => renderQuestion(question, index))}
    </>
  );
};

export default LearningQuiz;
