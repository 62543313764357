import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  Button,
  TextField,
  useSnackbar,
  getAge,
  formatUTCDate,
  formatDate,
  MaskedTextField,
  addDateValidation,
  MultiTextField,
} from '@fdha/web-ui-library';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import { parseISO } from 'date-fns';
import { useUpdatePatientUserMutation } from '@fdha/graphql-api-admin';

import AddressFormPreferences from '../../../../../components/AddressFormPreferences/AddressFormPreferences';
import { useGetUserType } from '../../../../../hooks';

import { OnboardingInformationProps } from './OnboardingInformation';

interface Information {
  name?: string;
  birthDate?: string;
  interests?: string[];
  studyStart?: string | null;
  subjectId?: string | null;
  associatedSite?: string | null;
  siteStaffEmails: string[];
  address?: string | null;
  complement?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

const EditModeOnboardingInformation = (props: OnboardingInformationProps) => {
  const { showSnackbar } = useSnackbar();

  const [updatePatientUser] = useUpdatePatientUserMutation();

  const { isCsr } = useGetUserType();
  const isCt = props.patientUser?.is_ct;
  const placeholder = 'Type here...';

  const handleCancel = () => {
    props.handleEditMode(false);
    showSnackbar({
      severity: 'warning',
      message: 'Changes not saved',
    });
  };

  const handleSave = async (values: Information) => {
    try {
      await updatePatientUser({
        variables: {
          id: props.patientUser?.id || '',
          props: {
            name: values.name,
            birthdate: values.birthDate,
            subject_id: values.subjectId,
            associated_site: values.associatedSite,
            study_start: values.studyStart,
            address: {
              street_address: values.address,
              locality: values.city,
              region: values.state,
              postal_code: values.zip,
              complement: values.complement,
            },
            site_staff_emails: values.siteStaffEmails,
          },
        },
      });
      props.handleEditMode(false);
      showSnackbar({
        severity: 'success',
        message: 'Changes saved!',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update patient data',
      });
    }
  };

  const initialValues: Information = {
    name: props.patientUser?.name,
    birthDate: formatUTCDate(props.patientUser?.birthdate),
    studyStart: formatUTCDate(props.patientUser?.study_start),
    subjectId: props.patientUser?.subject_id,
    associatedSite: props.patientUser?.associated_site || '',
    siteStaffEmails: props.patientUser?.site_staff_emails || [],
    address: props.patientUser?.address?.street_address,
    complement: props.patientUser?.address?.complement,
    city: props.patientUser?.address?.locality,
    state: props.patientUser?.address?.region,
    zip: props.patientUser?.address?.postal_code,
  };

  const requiredMessage = 'Required fields cannot be empty';

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(requiredMessage),
    birthDate: addDateValidation().required(requiredMessage),
  });

  const ctValidationSchema = Yup.object().shape({
    studyStart: addDateValidation().required(requiredMessage),
    subjectId: Yup.string().trim().required(requiredMessage),
    associatedSite: Yup.string().trim().required(requiredMessage),
    siteStaffEmails: Yup.array().of(
      Yup.string().email('Please type a valid email format')
    ),
    zip: Yup.string()
      .trim()
      .nullable()
      .matches(/^[0-9]+$/, 'Zip code must be exactly 5 numbers')
      .test('len', 'Zip code must be exactly 5 characters', (val) =>
        val ? val.length === 5 : true
      ),
  });

  const handleErrors = (
    errors: FormikErrors<Information>,
    touched: FormikTouched<Information>
  ) => {
    return {
      name: touched.name && errors.name,
      birthDate: touched.birthDate && errors.birthDate,
      studyStart: touched.studyStart && errors.studyStart,
      subjectId: touched.subjectId && errors.subjectId,
      associatedSite: touched.associatedSite && errors.associatedSite,
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        isCt ? validationSchema.concat(ctValidationSchema) : validationSchema
      }
      onSubmit={handleSave}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        isSubmitting,
        errors,
        touched,
      }) => {
        const errorState = handleErrors(errors, touched);

        return (
          <Form>
            <Box marginBottom={3} data-testid="ONBOARDING_EDIT_MODE">
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={4}>
                  <TextField
                    title="Name"
                    value={values.name}
                    name="name"
                    onChange={handleChange}
                    error={!!errorState.name}
                    helperText={errorState.name}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Email"
                    value={props.patientUser?.email}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Phone number"
                    value={props.patientUser?.phone_number}
                    disabled
                  />
                </Grid>
                {!isCsr && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        title="Age"
                        value={getAge(props.patientUser?.birthdate)}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        title="Birth date"
                        value={values.birthDate}
                        name="birthDate"
                        onChange={handleChange}
                        error={!!errorState.birthDate}
                        helperText={errorState.birthDate}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={4}>
                  <TextField
                    title="Joined on"
                    value={formatDate(parseISO(props.patientUser?.created_at))}
                    disabled
                  />
                </Grid>
                {isCt && (
                  <Grid item xs={4}>
                    {!isCsr ? (
                      <MaskedTextField
                        title="Study start"
                        value={values.studyStart || ''}
                        name="studyStart"
                        placeholder="MM/DD/YYYY"
                        onChange={handleChange}
                        mask="date"
                        error={!!errorState.studyStart}
                        helperText={errorState.studyStart}
                      />
                    ) : (
                      <TextField
                        title="Study start"
                        value={values.studyStart || ''}
                        name="studyStart"
                        placeholder="MM/DD/YYYY"
                        disabled
                      />
                    )}
                  </Grid>
                )}
                {isCt && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        title="Subject Id"
                        value={values.subjectId}
                        name="subjectId"
                        placeholder="Type here..."
                        onChange={handleChange}
                        error={!!errorState.subjectId}
                        helperText={errorState.subjectId}
                      />
                    </Grid>
                    {!isCsr && (
                      <Grid item xs={4}>
                        <MultiTextField
                          title="Site staff emails"
                          value={values.siteStaffEmails}
                          error={!!errors.siteStaffEmails}
                          helperText={errors.siteStaffEmails}
                          onChange={(values) =>
                            setFieldValue('siteStaffEmails', values)
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <TextField
                        title="Associated site"
                        value={values.associatedSite}
                        name="associatedSite"
                        placeholder="Type here..."
                        onChange={handleChange}
                        error={!!errorState.associatedSite}
                        helperText={errorState.associatedSite}
                        disabled={isCsr}
                      />
                    </Grid>
                    <AddressFormPreferences>
                      <Grid item xs={4}>
                        <AddressFormPreferences.Address
                          placeholder={placeholder}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AddressFormPreferences.Complement
                          placeholder={placeholder}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AddressFormPreferences.City
                          placeholder={placeholder}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AddressFormPreferences.State
                          placeholder={placeholder}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <AddressFormPreferences.Zip placeholder={placeholder} />
                      </Grid>
                    </AddressFormPreferences>
                  </>
                )}
              </Grid>
            </Box>
            <Box textAlign="end">
              <Button
                onClick={handleCancel}
                sx={{ padding: '8px 34px' }}
                data-testid="CANCEL_EDITION_BUTTON"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: '8px 34px' }}
                type="submit"
                data-testid="SAVE_EDITION_BUTTON"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditModeOnboardingInformation;
