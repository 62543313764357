import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SitesTable } from '../../../../components';

const SitesTab = () => {
  const navigate = useNavigate();

  const handleAddSite = () => {
    navigate('/trials/sites/create-site');
  };

  return <SitesTable tableKey="sites" onAddSite={handleAddSite} />;
};

export default SitesTab;
