import React from 'react';
import { ChannelList, ChannelListProps } from 'stream-chat-react';

import ChannelPreview from '../ChannelPreview/ChannelPreview';

import List from './List';

const ChannelListContainer: React.FC<ChannelListProps> = (props) => {
  return (
    <ChannelList
      {...props}
      List={List}
      Preview={(previewProps) => (
        <ChannelPreview
          {...previewProps}
          customActiveChannel={props.customActiveChannel}
        />
      )}
    />
  );
};

export default ChannelListContainer;
