import { makeVar } from '@apollo/client';

enum ChatDetailsKind {
  Media = 'Media',
}

const selectedChatDetailsKind = makeVar<ChatDetailsKind | null>(null);

const setSelectedChatDetailsKind = (kind?: ChatDetailsKind) => {
  selectedChatDetailsKind(kind);
};

export { ChatDetailsKind, selectedChatDetailsKind, setSelectedChatDetailsKind };
