import React, { FC, useEffect, useState } from 'react';
import { Box, Theme, styled, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import ReactQuill from 'react-quill';

import { modules } from '../../utils';

export interface RichTextEditorProps {
  value: string;
  placeholder: string;
  enabled: boolean;
  onChange?: (newValue: string) => void;
}

const StyledRichTextEditor = styled(ReactQuill, {
  shouldForwardProp: (prop) => prop !== 'hideToolbar' && prop !== 'reactTheme',
})<{ hideToolbar?: boolean; reactTheme: Theme }>(
  ({ reactTheme, hideToolbar, readOnly }) => ({
    '& .ql-toolbar.ql-snow': {
      display: hideToolbar ? 'none' : undefined,
    },
    '& .ql-container.ql-snow': {
      borderBottomRightRadius: '15px',
      borderBottomLeftRadius: '15px',
      minHeight: '167px',
      backgroundColor: readOnly ? grey[100] : undefined,
    },
    '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
      borderTop: hideToolbar ? `1px solid ${reactTheme.palette.divider}` : 0,
      borderTopRightRadius: hideToolbar ? '15px' : 0,
      borderTopLeftRadius: hideToolbar ? '15px' : 0,
      ':hover': {
        borderColor: hideToolbar && !readOnly ? grey[900] : undefined,
      },
    },
  })
);

const RichTextEditor: FC<RichTextEditorProps> = ({
  value,
  placeholder,
  enabled,
  onChange,
}) => {
  const theme = useTheme();

  const [editorFocused, setEditorFocused] = useState(false);

  const hideToolbar = !enabled || !editorFocused;

  useEffect(() => {
    if (!enabled) {
      setEditorFocused(false);
    }
  }, [enabled]);

  return (
    <Box onClick={() => enabled && setEditorFocused(true)}>
      <StyledRichTextEditor
        reactTheme={theme}
        readOnly={!enabled}
        hideToolbar={hideToolbar}
        placeholder={placeholder}
        modules={modules}
        value={value}
        onChange={onChange}
        onBlur={() => setEditorFocused(false)}
      />
    </Box>
  );
};

export default RichTextEditor;
