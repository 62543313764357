import { ApolloClient, ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { DialogProvider, SnackbarProvider, theme } from '@fdha/web-ui-library';
import fdhaConfig from '@fdha/aws-config-admin';
import { BrowserRouter } from 'react-router-dom';
import {
  SystemSettingsProvider,
  TranslationProvider,
} from '@fdha/common-hooks';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { apolloClientConfig } from './config/apollo';
import { SurveyDownloadProvider } from './hooks';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: fdhaConfig.cognito.adminUserPoolId,
      userPoolClientId: fdhaConfig.cognito.adminUserPoolClientId,
    },
  },
});

const client = new ApolloClient(apolloClientConfig);

// TODO: @apollo/client have issues when used with <React.StrictMode/>,
// for example: https://github.com/apollographql/apollo-client/issues/6832.
// Enable it again if / when issues are sorted,

const container = document.getElementById('root');
if (!container) throw new Error('Root element not found');

const root = createRoot(container);

root.render(
  <SystemSettingsProvider>
    <TranslationProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <SnackbarProvider>
              <SurveyDownloadProvider>
                <DialogProvider>
                  <CssBaseline />
                  <App />
                </DialogProvider>
              </SurveyDownloadProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </TranslationProvider>
  </SystemSettingsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
