import { Paper, styled } from '@mui/material';

const StyledChannelListWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  marginRight: '32px',
  maxWidth: 440,
  overflowY: 'hidden',
  paddingTop: '24px',
  paddingBottom: '24px',

  '.str-chat__channel-search': {
    marginBottom: '16px',
    paddingTop: '32px',
    input: {
      background: theme.palette.background.paper,
      border: 'none',
      height: 24,
      color: theme.palette.text.primary,
      fontSize: '1rem',
      ':focus': { boxShadow: 'none' },
    },
  },
  '.result-hashtag': {
    display: 'none',
  },
  '.str-chat__channel-search-container': {
    backgroundColor: theme.palette.background.paper,
    height: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    right: 10,
    top: 88,
  },
}));

export default StyledChannelListWrapper;
