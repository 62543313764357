import React, { FC, useEffect } from 'react';
import { Box, Paper, Stack, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  Button,
  Table,
  HeadCell,
  SearchableTableHeader,
  Avatar,
  pluralize,
  Icon,
} from '@fdha/web-ui-library';
import { Site, SiteStatus, useListSitesQuery } from '@fdha/graphql-api-admin';
import { NetworkStatus } from '@apollo/client';
import { Link, useLocation } from 'react-router-dom';

import {
  useDebouncedValue,
  useFilterBy,
  useSortBy,
  useTable,
} from '../../hooks';
import { getStatus } from '../../utils';

const headCells: HeadCell<Site>[] = [
  { id: 'name', label: 'Site name', sortable: true, searchable: true },
  {
    id: 'siteTrials:site_identification' as keyof Site,
    label: 'Site trial ID',
    sortable: false,
    searchable: false,
  },
  {
    id: 'siteTrials:trial:id' as keyof Site,
    label: 'Trials',
    sortable: false,
    searchable: false,
  },
  {
    id: 'number_of_patients',
    label: 'Patients',
    sortable: true,
    searchable: false,
  },
  {
    id: 'siteTrials:status' as keyof Site,
    label: 'Site status',
    sortable: false,
    searchable: false,
  },
];

interface SitesTableProps {
  tableKey: string;
  trialId?: string;
  onAddSite?: () => void;
}

const SitesTable: FC<SitesTableProps> = ({ tableKey, trialId, onAddSite }) => {
  const location = useLocation();

  const { page, rowsPerPage, setPage, changeRowsPerPage } = useTable({
    key: tableKey,
  });

  const [filterBy, setFilterBy] = useFilterBy<Site>('name', '');
  const [sortBy, setSortBy] = useSortBy<Site>('name', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const { data, loading, networkStatus, fetchMore } = useListSitesQuery({
    variables: {
      trialId,
      first: rowsPerPage,
      filterBy: {
        filterBy: [filterByDebounced],
      },
      sortBy: {
        sortBy: [sortBy],
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const nodes = data?.sites.edges.map((edge) => edge.node) || [];
  const pageInfo = data?.sites.pageInfo;
  const totalNumberFound = data?.sites.totalNumberFound;

  const emptyState = filterByDebounced.value
    ? 'No results found'
    : 'No sites yet';

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const renderRow = (row: Site) => {
    const siteIdentifications =
      row.siteTrials?.map((st) => st.site_identification).join(', ') || '-';
    const trials =
      row.siteTrials?.map((st) => st.trial?.protocol_number).join(', ') || '-';
    const status = !!row.siteTrials?.find(
      (st) => st.status === SiteStatus.Active
    );

    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="SITE_NAME_CELL">
          <Avatar type="iconText" name={row.name} icon="flag-outline" />
        </TableCell>
        <TableCell data-testid="SITE_ID_CELL">{siteIdentifications}</TableCell>
        <TableCell data-testid="TRIALS_CELL">{trials}</TableCell>
        <TableCell data-testid="PATIENTS_CELL">
          {pluralize(row.number_of_patients || 0, 'patient')}
        </TableCell>
        <TableCell data-testid="SITE_STATUS_CELL">
          {getStatus(status)}
        </TableCell>
        <TableCell padding="checkbox">
          <Link
            to={`${location.pathname}/${row.id}`}
            data-testid="SITE_PROFILE_BUTTON"
          >
            <Icon
              name="arrow-ios-forward-outline"
              size="large"
              fill={grey[600]}
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} marginBottom={2}>
        <Box flex={1}>
          <SearchableTableHeader<Site>
            headCells={headCells}
            defaultSearchField="name"
            onSearchChange={setFilterBy}
            disableMargin
          />
        </Box>
        {onAddSite && (
          <Button
            data-testid="NEW_SITE_BUTTON"
            variant="contained"
            color="secondary"
            size="large"
            startEvaIcon={{ name: 'plus-outline' }}
            onClick={onAddSite}
          >
            New site
          </Button>
        )}
      </Stack>
      <Paper>
        <Table<Site>
          withPagination
          actions="right"
          initialOrderBy="name"
          headCells={headCells}
          page={page}
          emptyState={emptyState}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          rows={nodes}
          isLoading={loading}
          totalRowCount={totalNumberFound}
          renderRow={renderRow}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={setSortBy}
        />
      </Paper>
    </>
  );
};

export default SitesTable;
