import {
  Box,
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';

export interface PopoverProps extends Omit<MuiPopoverProps, 'content'> {
  v2?: boolean;
}

export const Popover: React.FC<PropsWithChildren<PopoverProps>> = ({
  children,
  v2,
  ...muiProps
}) => {
  /************ V2 updates *********************/

  const contentStyle = v2 ? { padding: 0 } : { paddingX: 3, paddingY: 2 };

  /*********************************************/

  return (
    <MuiPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onFocus={(event) => event.stopPropagation()}
      disableRestoreFocus
      {...muiProps}
    >
      <Box {...contentStyle}>{children}</Box>
    </MuiPopover>
  );
};
