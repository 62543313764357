import {
  ApolloQueryResult,
  FetchMoreQueryOptions,
  NetworkStatus,
} from '@apollo/client';
import {
  Exact,
  FilterByField,
  GetAllPatientsQuery,
  GetMyPatientsQuery,
  Maybe,
  PageInfo,
  PatientUser,
  SortByField,
  useGetAllPatientsQuery,
  useGetMyPatientsQuery,
} from '@fdha/graphql-api-admin';

export const useGetPatientsList = (
  shouldGetAllPatients: boolean,
  skipRequests: boolean,
  first: number,
  showInactivePatients: boolean,
  sort?: SortByField,
  filters?: FilterByField[]
): {
  nodes?: PatientUser[];
  pageInfo?: PageInfo;
  totalNumberFound?: number;
  loading: boolean;
  networkStatus: NetworkStatus;
  fetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<
      Exact<{
        first?: Maybe<number> | undefined;
        after?: Maybe<string> | undefined;
      }>
    >
  ) =>
    | Promise<ApolloQueryResult<GetAllPatientsQuery>>
    | Promise<ApolloQueryResult<GetMyPatientsQuery>>;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            first?: Maybe<number> | undefined;
            after?: Maybe<string> | undefined;
          }>
        >
      | undefined
  ) =>
    | Promise<ApolloQueryResult<GetAllPatientsQuery>>
    | Promise<ApolloQueryResult<GetMyPatientsQuery>>;
} => {
  const filterBy = filters ? { filterBy: filters } : undefined;
  const sortBy = sort ? { sortBy: [sort] } : undefined;

  // Get all patients (for admins only)
  const allPatientsResult = useGetAllPatientsQuery({
    variables: {
      first,
      filterBy,
      sortBy,
      showInactivePatients,
    },
    skip: !shouldGetAllPatients || skipRequests,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
  // Get only my patients (for any coach)
  const myPatientsResult = useGetMyPatientsQuery({
    variables: {
      first,
      filterBy,
      sortBy,
      showInactivePatients,
    },
    skip: shouldGetAllPatients || skipRequests,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
  if (shouldGetAllPatients) {
    const nodes = allPatientsResult.data?.allPatients.edges.map(
      (edge) => edge.node
    ) as PatientUser[];
    const pageInfo = allPatientsResult.data?.allPatients.pageInfo;
    const totalNumberFound =
      allPatientsResult.data?.allPatients.totalNumberFound;

    return {
      nodes,
      pageInfo,
      totalNumberFound,
      loading: allPatientsResult.loading,
      networkStatus: allPatientsResult.networkStatus,
      fetchMore: allPatientsResult.fetchMore,
      refetch: allPatientsResult.refetch,
    };
  } else {
    const nodes = myPatientsResult.data?.myPatients.edges.map(
      (edge) => edge.node
    ) as PatientUser[];
    const pageInfo = myPatientsResult.data?.myPatients.pageInfo;
    const totalNumberFound = myPatientsResult.data?.myPatients.totalNumberFound;

    return {
      nodes,
      pageInfo,
      totalNumberFound,
      loading: myPatientsResult.loading,
      networkStatus: myPatientsResult.networkStatus,
      fetchMore: myPatientsResult.fetchMore,
      refetch: myPatientsResult.refetch,
    };
  }
};
