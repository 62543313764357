import React, { FC, useCallback } from 'react';
import { Box, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useDrop } from 'react-dnd';
import { useReactiveVar } from '@apollo/client';
import update from 'immutability-helper';

import {
  addNewQuestionAt,
  questions as questionsState,
  replaceQuestions,
} from '../../states/questionBuilderState';
import { OptionProps, QuestionCardType } from '../../models/FormBuilderProps';

import EditQuestionCard from './EditQuestionCard/EditQuestionCard';
import PreviewQuestionCard from './PreviewQuestionCard/PreviewQuestionCard';

interface Props {
  item: QuestionCardType;
  index: number;
}

const DropContainer: FC<Props> = ({ item, index }) => {
  const theme = useTheme();

  const questions = useReactiveVar(questionsState);

  const [{ isOverBottom, canDropBottom }, bottomDrop] = useDrop({
    accept: ['optionCard'],
    drop: (item: OptionProps) => {
      addNewQuestionAt(item, index + 1);
    },
    collect: (monitor) => ({
      isOverBottom: monitor.isOver(),
      canDropBottom: monitor.canDrop(),
    }),
  });

  const [{ isOverTop, canDropTop }, topDrop] = useDrop({
    accept: ['optionCard'],
    drop: (item: OptionProps) => {
      addNewQuestionAt(item, index);
    },
    collect: (monitor) => ({
      isOverTop: monitor.isOver(),
      canDropTop: monitor.canDrop(),
    }),
  });

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = questions[dragIndex];
      const newQuestions = update(questions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      replaceQuestions(newQuestions);
    },
    [questions]
  );

  const dropValues = {
    bottomPadding: '',
    topPadding: '',
    topBackground: theme.palette.background.default,
  };

  if (isOverBottom && canDropBottom) {
    dropValues.bottomPadding = '60px';
    dropValues.topBackground = theme.palette.background.default;
  }

  if (isOverTop && canDropTop) {
    dropValues.topPadding = '84px';
    dropValues.topBackground = grey[200];
  }

  return (
    <Box display="flex" flexDirection="column" bgcolor={grey[200]}>
      {index === 0 && (
        <Box
          ref={topDrop}
          height="24px"
          paddingBottom={dropValues.topPadding}
          bgcolor={dropValues.topBackground}
        />
      )}
      <Box
        ref={bottomDrop}
        borderRadius="15px"
        paddingBottom={dropValues.bottomPadding}
        data-testid={`QUESTION_BOX_${item.type}`}
      >
        {item.isEditing ? (
          <EditQuestionCard
            key={item.id}
            index={index}
            item={item}
            moveCard={moveCard}
          />
        ) : (
          <PreviewQuestionCard
            key={item.id}
            index={index}
            item={item}
            moveCard={moveCard}
          />
        )}
      </Box>
    </Box>
  );
};

export default DropContainer;
