import React, { PropsWithChildren } from 'react';
import {
  FrequencyInput,
  MaskedTextField,
  Select,
  TimeInput,
  completeWithinLabelByValue,
  completeWithinOptionsByFrequency,
  frequencyLabelByValue,
} from '@fdha/web-ui-library';
import { Box, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { SurveyFrequency } from '@fdha/graphql-api-admin';

import { AssignSurveySchema } from './AssignSurvey';

const StartDate = () => {
  const {
    values: { date },
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormikContext<AssignSurveySchema>();

  const error = touched.date && errors.date;

  return (
    <MaskedTextField
      name="date"
      title="Start date"
      mask="date"
      placeholder="MM/DD/YYYY"
      value={date}
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!error}
      helperText={error}
    />
  );
};

const Time = () => {
  const {
    values: { time, dayPeriod },
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<AssignSurveySchema>();

  const error = touched.time && errors.time;

  return (
    <TimeInput
      time={time}
      dayPeriod={dayPeriod}
      error={error}
      onChange={setFieldValue}
      caption="This will be assigned in the patient's local time zone"
    />
  );
};

const FrequencyForm = () => {
  const {
    values: { completeWithinDays, frequency, weekDays },
    initialValues: { weekDays: initialWeekDays },
    setFieldValue,
  } = useFormikContext<AssignSurveySchema>();

  const frequencyOptions = Object.entries(SurveyFrequency).map(
    ([key, value]) => ({
      label: frequencyLabelByValue[value].label,
      value: key,
    })
  );

  return (
    <FrequencyInput
      completeWithinDays={completeWithinDays}
      frequency={frequency}
      frequencyOptions={frequencyOptions}
      weekDays={weekDays}
      initialWeekDays={initialWeekDays}
      setFieldValue={setFieldValue}
    />
  );
};

const CompleteWithin: FC<{ disabled?: boolean; title?: string }> = ({
  disabled,
  title,
}) => {
  const {
    values: { completeWithinDays, frequency },
    handleChange,
  } = useFormikContext<AssignSurveySchema>();

  const completeWithinOptions = frequency
    ? completeWithinOptionsByFrequency[frequency]
    : [];
  const selectOptions = completeWithinOptions.map((option) => ({
    label: completeWithinLabelByValue[option],
    value: option.valueOf(),
  }));

  return (
    <Select
      name="completeWithinDays"
      title={title || 'Close after'}
      value={completeWithinDays}
      onChange={handleChange}
      options={selectOptions}
      disabled={selectOptions.length <= 1 || disabled}
    />
  );
};

const EndsAt = () => {
  const {
    values: { ends, endsAt },
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useFormikContext<AssignSurveySchema>();

  const error = touched.endsAt && errors.endsAt;

  const options = [
    {
      value: 'never',
      label: 'Never',
    },
    {
      value: 'date',
      label: 'On date',
    },
  ];

  return (
    <Box>
      <Select
        name="ends"
        title="Ends"
        value={ends}
        onChange={(option) => setFieldValue('ends', option.target.value)}
        options={options}
      />
      {ends === 'date' && (
        <MaskedTextField
          data-testid="ENDS_ON_DATE"
          name="endsAt"
          mask="date"
          placeholder="MM/DD/YYYY"
          value={endsAt}
          onChange={handleChange}
          error={!!error}
          helperText={error}
          onBlur={handleBlur}
        />
      )}
    </Box>
  );
};

const SchedulesPreferences: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack spacing={2} marginTop={3}>
      {children}
    </Stack>
  );
};

export default Object.assign(SchedulesPreferences, {
  StartDate,
  Time,
  EndsAt,
  FrequencyForm,
  CompleteWithin,
});
