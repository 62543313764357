import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useChat } from '@fdha/common-hooks';

interface ContextProps {
  changeNotificationChannel: (notificationChannel?: string) => void;
}

const Context = createContext<ContextProps>({
  changeNotificationChannel: () => {},
});

export const ChatNotificationProvider = ({ ...props }) => {
  const { client: chatClient } = useChat();

  const navigate = useNavigate();

  const [notificationChannel, setNotificationChannel] = useState<
    string | undefined
  >(undefined);

  const changeNotificationChannel = useCallback((channelId?: string) => {
    setNotificationChannel(channelId);
  }, []);

  useEffect(() => {
    if (window.Notification && Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    let eventListener: { unsubscribe: () => void };

    if (chatClient != null) {
      eventListener = chatClient.on((event) => {
        const eventUserId = event.user?.id;
        const userId = chatClient.user?.id;

        const showUserNotification =
          eventUserId && userId && eventUserId !== userId;

        if (
          ['notification.message_new', 'message.new'].includes(event.type) &&
          notificationChannel !== event.channel_id &&
          showUserNotification
        ) {
          const userName = event.message?.user?.name || '';
          const message = event.message?.text || '';
          const attachmentsLength = event.message?.attachments?.length || 0;

          const notification = new Notification(`${userName} sent a message`, {
            body:
              message !== ''
                ? message
                : `${attachmentsLength} attachment${
                    attachmentsLength > 1 ? 's' : ''
                  }`,
          });
          notification.onclick = (e) => {
            e.preventDefault();
            navigate('/chat', {
              state: { channelId: event.channel_id },
            });
          };
        }
      });
    }

    return () => {
      eventListener?.unsubscribe();
    };
  }, [chatClient, navigate, notificationChannel, changeNotificationChannel]);

  return (
    <Context.Provider
      value={{
        changeNotificationChannel,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const useChatNotification = () => {
  const { changeNotificationChannel } = useContext(Context);
  return {
    changeNotificationChannel,
  };
};
