import React, { useState } from 'react';
import { formatDate } from '@fdha/web-ui-library';
import {
  Box,
  styled,
  Typography,
  useTheme,
  Card,
  CardActionArea,
} from '@mui/material';
import { parseISO } from 'date-fns';
import { NotesTemplate } from '@fdha/graphql-api-admin';

const Ellipsis = styled(Typography)(() => ({
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

interface Props {
  templates: NotesTemplate[];
  onSelect: (template: NotesTemplate) => void;
}

const Templates = ({ templates, onSelect }: Props) => {
  const theme = useTheme();
  const [selected, setSelected] = useState<string | null>(null);

  const handleTemplateClick = (template: NotesTemplate) => {
    setSelected(template.id);
    onSelect(template);
  };

  return (
    <Box display="flex" flexWrap="wrap" columnGap={2} rowGap={3}>
      {templates.map((template) => (
        <Card
          key={template.id}
          sx={{
            backgroundColor:
              template.id === selected ? theme.palette.secondary.selected : '',
            width: 268,
          }}
        >
          <CardActionArea
            onClick={handleTemplateClick.bind(null, template)}
            sx={{ padding: '16px 8px' }}
            data-testid="NOTES_TEMPLATE_CARD"
          >
            <Ellipsis
              variant="body1"
              gutterBottom={true}
              fontWeight="500"
              width="100%"
            >
              {template.name}
            </Ellipsis>
            <Ellipsis
              variant="caption"
              color={theme.palette.text.secondary}
            >{`last updated on ${formatDate(
              parseISO(template.updated_at || template.created_at)
            )}`}</Ellipsis>
          </CardActionArea>
        </Card>
      ))}
    </Box>
  );
};

export default Templates;
