import { Box } from '@mui/material';
import React from 'react';
import { AvatarProps } from 'stream-chat-react';

import Avatar from '../../Avatar/Avatar';

const MessageAvatar = (props: AvatarProps) => {
  const { image, name, size = 32 } = props;
  const sx = { flexBasis: size, height: size, width: size };

  return (
    <Box className="str-chat__avatar" sx={sx}>
      <Avatar
        data-testid="MESSAGE_AVATAR"
        name={name}
        picture={image}
        size={size}
      />
    </Box>
  );
};

export default MessageAvatar;
