import {
  Box,
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

interface LinearProgressProps extends MuiLinearProgressProps {
  value: number;
  withLabel?: boolean;
}

function LinearProgress({ withLabel, ...props }: LinearProgressProps) {
  const theme = useTheme();

  return (
    <Box
      alignItems="center"
      bgcolor={props.value >= 100 ? '#EBF2E8' : '#FFF'}
      border="1px solid"
      borderColor={theme.palette.divider}
      borderRadius={1}
      display="flex"
      flexBasis={173}
      paddingX={1.25}
      paddingY={0.75}
    >
      <Box width="100%" marginRight={1}>
        <MuiLinearProgress variant="determinate" {...props} />
      </Box>
      {withLabel && (
        <Box>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default LinearProgress;
