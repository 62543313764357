import React, { FC } from 'react';
import { TextField } from '@fdha/web-ui-library';

import { QuestionOptionProps } from './OptionsTabPanel';

const NumberOption: FC<QuestionOptionProps<string>> = ({
  values,
  errorState,
  handleChange,
}) => {
  return (
    <TextField
      name="placeholderText"
      title="Placeholder text"
      size="small"
      value={values.placeholderText || ''}
      error={!!errorState.placeholderText}
      helperText={errorState.placeholderText}
      onChange={(e) => handleChange('placeholderText', e.target.value)}
      data-testid="NUMBER_OPTIONS_PLACEHOLDER"
    />
  );
};

export default NumberOption;
