import React, { FC, PropsWithChildren } from 'react';
import {
  IconButton,
  Paper as MuiPaper,
  styled,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useDrag } from 'react-dnd';
import Icon from 'react-eva-icons';

import { OptionProps } from '../../../models/FormBuilderProps';
import { addNewQuestion } from '../../../states/questionBuilderState';

const Paper = styled(MuiPaper)(({ theme }) => ({
  height: '60px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px',
  border: '0.5px solid',
  borderColor: theme.palette.divider,
  marginBottom: '24px',
  cursor: 'pointer',
  boxShadow: theme.shadows[2],
}));

interface FieldOptionProps {
  item: OptionProps;
  testId: string;
}

const FieldOption: FC<PropsWithChildren<FieldOptionProps>> = ({
  item,
  testId,
  children,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: 'optionCard',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Paper ref={drag} sx={{ opacity: isDragging ? 0.4 : 1 }}>
      <Typography>{children}</Typography>
      <IconButton data-testid={testId} onClick={() => addNewQuestion(item)}>
        <Icon name="plus-circle-outline" size="large" fill={grey[600]} />
      </IconButton>
    </Paper>
  );
};

export default FieldOption;
