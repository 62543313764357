import { Form, Formik } from 'formik';
import React from 'react';
import { ApolloCache } from '@apollo/client';
import { ActivitySchema, activityValidationSchema } from '@fdha/web-ui-library';
import {
  Activity,
  Task,
  TaskInput,
  useAddTaskMutation,
  useUnscheduleTaskMutation,
  useUpdateTaskMutation,
} from '@fdha/graphql-api-admin';

import ActivityFormActions from '../Forms/ActivityFormActions';
import ActivityFormGeneral from '../Forms/ActivityFormGeneral';
import ActivityFormPreferences from '../Forms/ActivityFormPreferences';
import { resetSelectedActivity } from '../../../../../../states/activitiesState';
import { buildInitialPayload, getSchemaInitialValues } from '../Forms/schema';

interface AddOrEditTaskProps {
  data?: Task;
  patientId: string;
  onCanceled: () => void;
  onSaved: (data?: Partial<Activity>, error?: string) => void;
  onUnscheduled?: (error?: string) => void;
}

const AddOrEditTask: React.FC<AddOrEditTaskProps> = ({
  data,
  patientId,
  onCanceled,
  onSaved,
  onUnscheduled,
}) => {
  const isEditing = !!data;
  const titleLabel = isEditing ? 'Task Title' : undefined;

  const initialValues = getSchemaInitialValues(data);
  const [addTask] = useAddTaskMutation();
  const [updateTask] = useUpdateTaskMutation();
  const [unscheduleTask] = useUnscheduleTaskMutation();

  const updateCache = (cache: ApolloCache<any>) => {
    cache.evict({ fieldName: 'activitiesInRange' });
    cache.gc();
  };

  const buildTaskPayload = (values: ActivitySchema): TaskInput => {
    return {
      ...buildInitialPayload(values),
      ends: values.ends,
    };
  };

  const saveTask = async (payload: TaskInput) => {
    if (data) {
      return updateTask({
        variables: { id: data.id, props: payload },
        update(cache) {
          updateCache(cache);
        },
      });
    } else {
      return addTask({
        variables: { patientId: patientId, props: payload },
        update(cache) {
          updateCache(cache);
        },
      });
    }
  };

  const handleUnschedule = async () => {
    if (!data) {
      return;
    }
    const { errors } = await unscheduleTask({
      variables: { id: data.id },
      update(cache) {
        updateCache(cache);
      },
    });
    onUnscheduled && onUnscheduled(errors && errors[0].message);
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={activityValidationSchema()}
      onSubmit={async (values) => {
        const payload = buildTaskPayload(values);
        try {
          await saveTask(payload);
          resetSelectedActivity();
          onSaved(payload);
        } catch (error) {
          onSaved(undefined, error as string);
        }
      }}
    >
      {({ handleSubmit, isValid, isSubmitting }) => {
        return (
          <Form>
            <ActivityFormGeneral>
              <ActivityFormGeneral.Title label={titleLabel} />
              <ActivityFormGeneral.Details />
            </ActivityFormGeneral>
            <ActivityFormPreferences>
              <ActivityFormPreferences.Date />
              <ActivityFormPreferences.EndsAt />
              <ActivityFormPreferences.Time />
              <ActivityFormPreferences.Frequency />
              <ActivityFormPreferences.Reminder />
            </ActivityFormPreferences>
            <ActivityFormActions
              isEditing={isEditing}
              onCancel={onCanceled}
              onUnschedule={handleUnschedule}
              disableSave={!isValid || isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddOrEditTask;
