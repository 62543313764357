import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Accordion } from '@fdha/web-ui-library';
import { SiteStaffUser } from '@fdha/graphql-api-admin';

import ViewModeProfileInformation from './ViewModeProfileInformation';
import EditModeProfileInformation from './EditModeProfileInformation';

interface ProfileInformationProps {
  siteStaffUser?: SiteStaffUser | null;
}

export interface ProfileInformationViewOrEditProps
  extends ProfileInformationProps {
  handleEditMode: Dispatch<SetStateAction<boolean>>;
}

const ProfileInformation: FC<ProfileInformationProps> = ({ siteStaffUser }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Accordion
      title="Profile information"
      data-testid="PROFILE_INFO"
      sx={{ padding: 1 }}
    >
      {editMode ? (
        <EditModeProfileInformation
          siteStaffUser={siteStaffUser}
          handleEditMode={setEditMode}
        />
      ) : (
        <ViewModeProfileInformation
          siteStaffUser={siteStaffUser}
          handleEditMode={setEditMode}
        />
      )}
    </Accordion>
  );
};

export default ProfileInformation;
