import { Icon, HoverPopover, Typography } from '@fdha/web-ui-library';
import { Box, Card, Grid, Theme, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface AutomationStateCard {
  name: string;
  description: string;
  iconColor: string;
  iconName: string;
  borderColor: string;
}

const getAutomationStateCards = (theme: Theme) => [
  {
    name: 'Incomplete',
    description:
      'Incomplete automations are waiting for items to be completed in order to enter queue to trigger.',
    iconColor: theme.palette.warning.dark,
    iconName: 'flash-outline',
    borderColor: theme.palette.warning.main,
  },
  {
    name: 'Checking',
    description:
      'Changes were made to items that are tied to automations and the system is checking if it can queue them to trigger. Can take from seconds up to minutes.',
    iconColor: theme.palette.info.main,
    iconName: 'settings-2-outline',
    borderColor: theme.palette.info.main,
  },
  {
    name: 'Invalid',
    description:
      'The automation can’t be triggered because one or more items are incomplete and impeding it. Invalid automations disappear after a while.',
    iconColor: theme.palette.error.main,
    iconName: 'flash-outline',
    borderColor: theme.palette.error.main,
  },
  {
    name: 'Scheduled',
    description:
      'All criteria was met and automation is scheduled to be triggered on the projected date.',
    iconColor: theme.palette.info.main,
    iconName: 'flash-outline',
    borderColor: theme.palette.info.main,
  },
  {
    name: 'Triggered',
    description:
      'The automation was successfully triggered and notification will clear out after a while.',
    iconColor: theme.palette.success.main,
    iconName: 'flash-outline',
    borderColor: theme.palette.success.main,
  },
];

const renderAutomationStateCard = (
  automationStateCard: AutomationStateCard,
  theme: Theme
) => {
  return (
    <Card
      sx={{
        borderColor: automationStateCard.borderColor,
        padding: 1,
        height: '100%',
        width: '100%',
      }}
    >
      <Box display="flex" mb={1}>
        <Icon
          name={automationStateCard.iconName}
          size="medium"
          fill={automationStateCard.iconColor}
          showBorder
        />
        <Typography ml={1}>{automationStateCard.name}</Typography>
      </Box>
      <Typography variant="caption" color={theme.palette.text.secondary}>
        {automationStateCard.description}
      </Typography>
    </Card>
  );
};

const AutomationPopoverContent = () => {
  const theme = useTheme();

  return (
    <Box maxWidth="714px">
      <Typography variant="body2">
        Automations will enter queue to be triggered once all criteria are met.
        If an automation can’t be triggered for any reason, make sure to
        communicate with stakeholders so the patient onboarding can occur in
        time.
      </Typography>
      <Typography variant="body2" marginY={2}>
        Automations have 5 different states:
      </Typography>
      <Grid container direction="row" spacing={2}>
        {getAutomationStateCards(theme).map((status, index) => (
          <Grid item xs={6} key={index}>
            {renderAutomationStateCard(status, theme)}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const AutomationStatusInfo: FC = () => {
  const theme = useTheme();

  return (
    <HoverPopover content={<AutomationPopoverContent />}>
      <Icon
        name="alert-circle-outline"
        size="medium"
        fill={theme.palette.primary.main}
        sx={{ marginX: 1 }}
        showHover
      />
    </HoverPopover>
  );
};

export default AutomationStatusInfo;
