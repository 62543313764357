import React from 'react';
import { formatDate, HeadCell } from '@fdha/web-ui-library';
import { TableCell } from '@mui/material';
import { SurveyInstance, SurveyStatus } from '@fdha/graphql-api-admin';

import Chip from '../components/Chip/Chip';

export const LEGACY_SURVEY = '[Legacy Survey]';

export const getHeaderCells = (showSortable: boolean = true) => {
  const headCells: HeadCell<SurveyInstance>[] = [
    {
      id: 'name',
      label: 'Survey name',
      sortable: showSortable && true,
      searchable: showSortable && true,
    },
    {
      id: 'due_at',
      label: 'Due date',
      sortable: showSortable && true,
      searchable: false,
    },
    {
      id: 'status',
      label: 'Status',
      sortable: showSortable && false,
      searchable: false,
    },
    {
      id: 'completed_at',
      label: 'Completion date',
      sortable: showSortable && true,
      searchable: false,
    },
  ];

  return headCells;
};

const getChipColor = (status?: SurveyStatus) => {
  if (status === SurveyStatus.Done) {
    return 'success';
  } else if (status === SurveyStatus.Late) {
    return 'error';
  }
  return 'warning';
};

const getChipLabel = (statusName?: string) => {
  if (statusName === SurveyStatus.Late) {
    return SurveyStatus.Missing.toLowerCase();
  }
  return statusName?.toLowerCase();
};

export const renderTableCells = (row?: SurveyInstance) => {
  const date = new Date(Number(row?.due_at));
  const completedAt = row?.completed_at
    ? formatDate(new Date(Number(row.completed_at)))
    : '';

  return (
    <>
      <TableCell data-testid="SURVEY_INSTANCE_NAME_CELL">{row?.name}</TableCell>
      <TableCell data-testid="SURVEY_INSTANCE_DUE_DATE_CELL">
        {formatDate(date)}
      </TableCell>
      <TableCell>
        <Chip
          label={getChipLabel(row?.status)}
          color={getChipColor(row?.status)}
        />
      </TableCell>
      <TableCell data-testid="SURVEY_INSTANCE_COMPLETED_DATE_CELL">
        {completedAt}
      </TableCell>
    </>
  );
};

export const getSurveyName = (
  originalName: string,
  isStructured?: boolean | null
): string => {
  if (isStructured) {
    return originalName;
  }

  return `${LEGACY_SURVEY} ${originalName}`;
};
