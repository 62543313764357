import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Button, TextField, useSnackbar } from '@fdha/web-ui-library';
import { Form, Formik } from 'formik';
import {
  useUpdateProfileMutation,
  CoachUserWithPatients,
  GetProfileDocument,
} from '@fdha/graphql-api-admin';

interface DescriptionCardProps {
  user?: CoachUserWithPatients | null;
  handleIsEditing: () => void;
}

const EditDescription: FC<DescriptionCardProps> = ({
  user,
  handleIsEditing,
}) => {
  const { showSnackbar } = useSnackbar();

  const [updateProfile] = useUpdateProfileMutation();

  const initialValues = {
    description: user?.description,
  };

  const handleCancel = () => {
    handleIsEditing();
    showSnackbar({
      severity: 'warning',
      message: 'Changes not saved',
    });
  };

  const handleSubmit = async (values: { description?: string | null }) => {
    try {
      await updateProfile({
        variables: {
          props: {
            email: user?.email,
            phone_number: user?.phone_number,
            name: user?.name,
            birthdate: user?.birthdate,
            description: values.description,
          },
        },
        refetchQueries: [GetProfileDocument],
      });
      showSnackbar({
        severity: 'success',
        message: 'Description updated with success',
      });
      handleIsEditing();
    } catch (error: any) {
      console.log(error);
      showSnackbar({
        severity: 'error',
        message: 'Error to update description',
      });
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, isSubmitting }) => (
        <Form>
          <TextField
            name="description"
            value={values.description}
            onChange={handleChange}
            placeholder="Add description..."
            multiline
          />
          <Box display="flex" justifyContent="flex-end" marginTop={3}>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              color="secondary"
              autoFocus
              variant="contained"
              sx={{ paddingY: '8px', paddingX: '22px' }}
            >
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditDescription;
