import { KeyOfNestedLeaves } from '@fdha/web-ui-library/src/types/keyOfNestedLeaves';
import { useState } from 'react';
import { SortByField, SortByOrder } from '@fdha/graphql-api-admin';

type Field<T extends object> = KeyOfNestedLeaves<T>;
type Order = 'asc' | 'desc';

export function useSortBy<T extends object>(
  defaultField: Field<T>,
  defaultOrder: Order
): [SortByField, (field: Field<T>, order: Order) => void] {
  const [sortBy, setSortBy] = useState<SortByField>(
    asSortByField<T>(defaultField, defaultOrder)
  );

  const onChange = (field: Field<T>, order: Order) => {
    setSortBy(asSortByField<T>(field, order));
  };

  return [sortBy, onChange];
}

function asSortByField<T extends object>(
  field: Field<T>,
  order: Order
): SortByField {
  return {
    field: field.toString(),
    order: order === 'asc' ? SortByOrder.Asc : SortByOrder.Desc,
  };
}
