import {
  Button,
  CharactersCount,
  IconButton,
  TextField,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';
import {
  useCancelDeliveryMutation,
  useReactivateDeliveryMutation,
  ListDeliveriesDocument,
  GetDeliveryV2Document,
} from '@fdha/graphql-api-admin';
import { Box, Tooltip, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { parseBackendError } from '@fdha/common-utils';

interface DialogContentProps {
  deliveryId: string;
  type: 'cancel' | 'reactivate';
}

const initialValues = { comment: '' };

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .trim()
    .max(120, 'You have exceeded the character limit.'),
});

export const useDeliveryDialog = (subjectId: string) => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [cancelDelivery] = useCancelDeliveryMutation({
    refetchQueries: [ListDeliveriesDocument, GetDeliveryV2Document],
  });
  const [reactivateDelivery] = useReactivateDeliveryMutation({
    refetchQueries: [ListDeliveriesDocument, GetDeliveryV2Document],
  });

  const handleCancelDelivery = async (deliveryId: string, comment: string) => {
    try {
      await cancelDelivery({
        variables: {
          deliveryId,
          subjectId,
          isCanceledUpdateComment: comment || null,
        },
      });
      showSnackbar({
        message: 'The delivery has been successfully canceled',
        severity: 'success',
      });
    } catch (error: any) {
      const message = parseBackendError(error, 'Error to cancel delivery');
      showSnackbar({
        message,
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };

  const handleReactivateDelivery = async (
    deliveryId: string,
    comment: string
  ) => {
    try {
      await reactivateDelivery({
        variables: {
          deliveryId,
          subjectId,
          isCanceledUpdateComment: comment || null,
        },
      });
      showSnackbar({
        message:
          'Delivery reactivated! Please review this delivery in case it needs changes.',
        severity: 'success',
      });
    } catch (error: any) {
      const message = parseBackendError(error, 'Error to reactivate delivery');
      showSnackbar({
        message,
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };

  const DialogContent = ({ deliveryId, type }: DialogContentProps) => {
    const [textCopied, setTextCopied] = useState(false);
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={({ comment }) =>
          type === 'cancel'
            ? handleCancelDelivery(deliveryId, comment)
            : handleReactivateDelivery(deliveryId, comment)
        }
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ isValid, handleChange, values, isSubmitting }) => (
          <Form>
            <Typography mb={2}>
              This delivery will show as{' '}
              {type === 'cancel' ? 'canceled' : 'active'} to the patient. This
              action will send a notification to the Food Ops team.
            </Typography>
            <TextField
              title="Optional comment"
              name="comment"
              placeholder="Enter any additional information to support Food Ops"
              type="text"
              autoFocus
              multiline
              sx={{ marginBottom: 1 }}
              onChange={handleChange}
              value={values.comment}
              InputProps={{
                endAdornment: (
                  <>
                    {values.comment && (
                      <Tooltip
                        title={textCopied ? 'Copied!' : 'Copy text'}
                        arrow
                        onMouseLeave={() => setTextCopied(false)}
                      >
                        <Box>
                          <IconButton
                            iconProps={{
                              name: 'copy-outline',
                              size: 'large',
                            }}
                            onClick={() => {
                              setTextCopied(true);
                              navigator.clipboard.writeText(values.comment);
                            }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </>
                ),
              }}
            />
            <CharactersCount text={values.comment} maxCount={120} />
            <Box display="flex" justifyContent="flex-end" mt={3} mb={2}>
              <Button
                onClick={closeDialog}
                color="primary"
                sx={{ marginRight: 1 }}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={!isValid || isSubmitting}
                size="large"
              >
                {type === 'cancel' ? 'YES, CONTINUE' : 'REACTIVATE'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const openCancelDialog = (deliveryId: string) => {
    openDialog({
      title: 'Are you sure you want to cancel this delivery?',
      content: <DialogContent deliveryId={deliveryId} type="cancel" />,
    });
  };

  const openReactivateDialog = (deliveryId: string) => {
    openDialog({
      title: 'Are you sure you want to reactivate this delivery?',
      content: <DialogContent deliveryId={deliveryId} type="reactivate" />,
    });
  };

  return { openCancelDialog, openReactivateDialog };
};
