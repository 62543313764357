import { Box, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { Chip, Typography } from '..';

interface TagItem {
  id: string;
  name: string;
}
export interface DishInformationProps {
  name?: string;
  description: string;
  ingredients: string;
  photoUrl: string;
  nutritionalValuesUrl: string;
  tags?: TagItem[];
  v2?: boolean;
}

export const DishInformation: FC<DishInformationProps> = ({
  name,
  description,
  ingredients,
  photoUrl,
  nutritionalValuesUrl,
  tags,
  v2,
}) => {
  const { i18n } = useTranslation();

  const color = v2 ? 'success' : 'primary';

  const usesI18n = !isEmpty(Object.keys(i18n));
  return (
    <>
      <Box display="flex" justifyContent="center">
        <img
          src={photoUrl || '/images/empty_image.png'}
          alt={name || 'Dish'}
          style={{
            objectFit: 'cover',
            width: '95%',
            height: '280px',
            borderRadius: '16px',
          }}
        />
      </Box>
      <Stack spacing={3} px={3} pt={3}>
        {name && <Typography variant="h4">{name}</Typography>}
        <Typography variant="body1">{description}</Typography>
        <Typography
          i18nKey="meals:dishDetails.ingredients"
          i18nParams={{ ingredients }}
          variant="body1"
        >
          {usesI18n ? (
            `<strong>Ingredients:</strong> ${ingredients}`
          ) : (
            <>
              <strong>Ingredients:</strong> {ingredients}
            </>
          )}
        </Typography>
        {tags && (
          <Box>
            <Typography
              fontWeight="bold"
              variant="body1"
              i18nKey="temporary:meals.dishDetails.tags"
              marginBottom={1}
            >
              Tags
            </Typography>
            <Box display="flex" flexWrap="wrap" columnGap={1} rowGap={1}>
              {tags.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  variant="outlined"
                  color={color}
                  size="medium"
                />
              ))}
            </Box>
          </Box>
        )}
        <Typography
          fontWeight="bold"
          variant="body1"
          i18nKey="meals:dishDetails.nutritionalValues"
        >
          Nutritional Values:
        </Typography>
      </Stack>
      <img
        src={nutritionalValuesUrl}
        alt="Nutritional values"
        style={{
          objectFit: 'contain',
          maxWidth: '100%',
          marginBottom: '16px',
          padding: '0 24px',
        }}
      />
    </>
  );
};
