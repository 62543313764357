import React, { FC, useMemo } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Icon, LabelBox } from '@fdha/web-ui-library';

import { getTrialStatusChip } from '../../../../utils';

import { TrialViewOrEditProps } from './About';

const ViewModeAbout: FC<TrialViewOrEditProps> = ({ onEditMode, trial }) => {
  const defaultEmptyValue = 'Not assigned';

  const groups = useMemo(() => {
    return (
      trial?.groups
        ?.map((group) => group.name)
        .sort()
        .join(', ') || defaultEmptyValue
    );
  }, [trial?.groups]);

  const courses = useMemo(() => {
    return (
      trial?.courses
        ?.map((trial) => trial.title)
        .sort()
        .join(', ') || defaultEmptyValue
    );
  }, [trial?.courses]);

  return (
    <>
      <Box marginBottom={3} data-testid="VIEW_MODE_TRIALS_ABOUT">
        <Grid container columnSpacing={4} rowSpacing={0}>
          <LabelBox
            size={4}
            label="Protocol number"
            value={trial?.protocol_number}
          />
          <LabelBox
            size={4}
            label="Abbreviation"
            value={trial?.protocol_abbreviation}
          />
          <LabelBox
            size={4}
            label="Trial status"
            value={getTrialStatusChip(trial?.status)}
          />
          <LabelBox size={4} label="Groups" value={groups} />
          <LabelBox size={4} label="Courses" value={courses} />
        </Grid>
      </Box>
      <Box textAlign="end">
        <IconButton onClick={() => onEditMode(true)} data-testid="EDIT_BUTTON">
          <Icon name="edit-outline" size="medium" fill={grey[600]} />
        </IconButton>
      </Box>
    </>
  );
};

export default ViewModeAbout;
