import React from 'react';
import { Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';

import { questions as questionsState } from '../../states/questionBuilderState';

import DropContainer from './DropContainer';
import EmptyContainer from './EmptyContainer';

const QuestionsContainer = () => {
  const questions = useReactiveVar(questionsState);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="70%"
      marginLeft="24px"
      borderRadius="15px"
    >
      {questions.length ? (
        questions.map((item, index) => (
          <DropContainer item={item} index={index} key={item.id} />
        ))
      ) : (
        <EmptyContainer />
      )}
    </Box>
  );
};

export default QuestionsContainer;
