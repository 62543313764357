export const currentTreatmentList = [
  {
    label: 'Chemotherapy',
    value: 'chemotherapy',
  },
  {
    label: 'Diet',
    value: 'diet',
  },
  {
    label: 'Exercises',
    value: 'exercises',
  },
  {
    label: 'Immunotherapy',
    value: 'immunotherapy',
  },
  {
    label: 'Not on any treatment',
    value: 'noTreatment',
  },
  {
    label: 'Palliative Care',
    value: 'palliativeCare',
  },
  {
    label: 'Radiotherapy',
    value: 'radiotherapy',
  },
  {
    label: 'Surgery',
    value: 'surgery',
  },
  {
    label: 'Targeted Therapy (e.g. Pi3Ki, or HER)',
    value: 'targetedTherapy',
  },
];

export const getCurrentTreatmentsWithI18n = () => {
  return currentTreatmentList.map((treatment) => {
    return {
      ...treatment,
      i18nKey: `common:currentTreatments.${treatment.value}`,
    };
  });
};
