import { useReactiveVar } from '@apollo/client';
import { Select, TextField } from '@fdha/web-ui-library';
import { Box, SelectChangeEvent, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import {
  LogicAction,
  LogicCondition,
  SurveyQuestionType,
} from '@fdha/graphql-api-admin';

import { QuestionCardType } from '../../../../models/FormBuilderProps';
import { questions as questionsState } from '../../../../states/questionBuilderState';
import TabPanel from '../../../TabPanel/TabPanel';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

interface QuestionProps {
  label: string;
  value: string;
  index: number;
}

interface LogicTabPanelProps {
  hidden: boolean;
  values: QuestionCardType;
  handleChange: (
    event: React.ChangeEvent<any> | SelectChangeEvent<unknown>
  ) => void;
  setFieldValue: (field: string, value?: QuestionProps) => void;
}

const LogicTabPanel: FC<LogicTabPanelProps> = ({
  hidden,
  values,
  handleChange,
  setFieldValue,
}) => {
  const questions = useReactiveVar(questionsState);
  const conditionQuestions = questions
    .filter((question) => question.id !== values.id)
    .map((question, index) => {
      return {
        label: question.title,
        value: question.title,
        index: index,
        type: question.type,
      };
    });

  const ifOptions = [
    ...(values.conditionQuestion?.type === SurveyQuestionType.MultipleChoice
      ? [
          { label: 'contains', value: LogicCondition.Contains },
          { label: 'does not contain', value: LogicCondition.NotContains },
        ]
      : [
          { label: 'is equal to', value: LogicCondition.EqualTo },
          { label: 'is not equal to', value: LogicCondition.NotEqualTo },
        ]),
  ];

  const thenOptions = [
    { label: 'show', value: LogicAction.Show },
    { label: 'hide', value: LogicAction.Hide },
  ];

  const getConditionQuestion = (value: string) => {
    return conditionQuestions.find((question) => question.value === value);
  };

  return (
    <TabPanel hidden={hidden}>
      <FlexBox>
        <Typography
          variant="body1"
          sx={{ mr: 1, fontWeight: 'bold' }}
          data-testid="IF_LABEL"
        >
          IF
        </Typography>
        <Select
          name="conditionQuestion"
          value={values.conditionQuestion?.value ?? ''}
          defaultValue=""
          onChange={(event) => {
            const value = (event as SelectChangeEvent<string>).target.value;
            setFieldValue('conditionQuestion', getConditionQuestion(value));
          }}
          options={conditionQuestions}
          size="small"
          placeholder="Select a question"
          displayEmpty
          showEmptyValue
          fullWidth
        />
      </FlexBox>
      <FlexBox>
        <Select
          name="condition"
          value={values.condition ?? ''}
          defaultValue=""
          onChange={handleChange}
          options={ifOptions}
          size="small"
          placeholder="Select an option"
          displayEmpty
          showEmptyValue
          fullWidth
        />
        <TextField
          name="conditionAnswer"
          value={values.conditionAnswer}
          onChange={handleChange}
          placeholder="Enter an answer"
          size="small"
          sx={{ ml: 1, mb: '5px' }}
        />
      </FlexBox>
      <FlexBox>
        <Typography
          variant="body1"
          sx={{ mr: 1, fontWeight: 'bold' }}
          data-testid="THEN_LABEL"
        >
          THEN
        </Typography>
        <Select
          name="action"
          value={values.action ?? ''}
          defaultValue=""
          onChange={handleChange}
          options={thenOptions}
          size="small"
          placeholder="Select an option"
          displayEmpty
          showEmptyValue
          fullWidth
        />
        <Typography variant="body1" sx={{ width: '100%', ml: 1 }}>
          question {values.title}
        </Typography>
      </FlexBox>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        A question can only depend on another one that is above it in the list.
      </Typography>
    </TabPanel>
  );
};

export default LogicTabPanel;
