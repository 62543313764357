import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetSiteQuery } from '@fdha/graphql-api-admin';

import { BasePage } from '../../components';

import { AddOrEditSite } from './AddOrEditSite';

const EditSite = () => {
  const navigate = useNavigate();
  const { siteId } = useParams();

  const { data, loading } = useGetSiteQuery({
    variables: { id: siteId || '' },
  });

  return (
    <BasePage>
      <BasePage.BackButton />
      <BasePage.Wrapper>
        <BasePage.Title>Edit site</BasePage.Title>
        <AddOrEditSite
          data={data?.site ?? undefined}
          isLoading={loading}
          onCancel={() => navigate(-1)}
          onSuccess={(siteId) => navigate(`/trials/sites/${siteId}`)}
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default EditSite;
