import React, { useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { NetworkStatus, useApolloClient, useReactiveVar } from '@apollo/client';
import { Waypoint } from 'react-waypoint';
import { convertQuestionSpreadPropsToQuestionGroupedProps } from '@fdha/common-utils';
import {
  Loader,
  SplitMenu,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  FilterOp,
  NotesTemplate,
  useListNotesQuery,
  useListNotesTemplatesQuery,
} from '@fdha/graphql-api-admin';
import { useParams } from 'react-router-dom';

import {
  addDraftNote,
  addDraftNoteFromTemplate,
  editNotes,
  hasDraftNote,
  isDraftNote,
  notes,
  NotesProps,
} from '../../../../states/notesState';
import { useReadLocalStorage } from '../../../../hooks';

import NoteCard from './NoteCard';
import NoteCardPreview from './NoteCardPreview';
import ChartCard from './ChartCard';
import Templates from './Templates';

export const INITIAL_NOTES_PER_PAGE = 10;
const TEMPLATES_TO_FETCH = 10000;

const NotesTab = () => {
  const params = useParams();
  const apolloClient = useApolloClient();
  const draftNotes = useReactiveVar(notes);
  const editDraftNotes = useReactiveVar(editNotes);
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const storageNote = useReadLocalStorage('notes');

  const selectedTemplate = useRef<NotesTemplate | null>(null);

  const patientId = params?.patientId || '';
  const autoSavedNote = storageNote?.[patientId];

  useEffect(() => {
    return () => {
      apolloClient.cache.evict({
        fieldName: 'notes',
      });
    };
  }, [apolloClient.cache]);

  const { data: templatesData } = useListNotesTemplatesQuery({
    variables: {
      first: TEMPLATES_TO_FETCH,
      filterBy: {
        filterBy: [{ field: 'archived', op: FilterOp.Equal, value: 'false' }],
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data, error, networkStatus, fetchMore } = useListNotesQuery({
    variables: {
      first: INITIAL_NOTES_PER_PAGE,
      patientId: patientId,
    },
    notifyOnNetworkStatusChange: true,
  });
  const pageInfo = data?.notes.pageInfo;
  const hasMore = pageInfo?.hasNextPage;

  const nodes = data?.notes.edges.map((edge) => edge.node);

  const notesList = useMemo(() => {
    let newNotes: NotesProps[] = draftNotes.filter(
      (draftNote) => draftNote.patientId === patientId
    );

    if (nodes?.length) {
      newNotes = newNotes.concat(nodes as NotesProps[]);
    }

    if (autoSavedNote) {
      // Checking for notes in old format with spread props
      const isOldFormat = autoSavedNote.questions?.some(
        (question: any) =>
          'possibleAnswers' in question ||
          'maxLength' in question ||
          'placeholderText' in question
      );

      let questionsInNewFormat;
      if (isOldFormat) {
        questionsInNewFormat = autoSavedNote.questions.map((question: any) => ({
          ...convertQuestionSpreadPropsToQuestionGroupedProps(question),
          answer: question.answer,
        }));
      }

      const fixedAutoSavedNote = {
        ...autoSavedNote,
        questions: questionsInNewFormat || autoSavedNote.questions,
      };

      if (newNotes[fixedAutoSavedNote.index]?.id === fixedAutoSavedNote.id) {
        newNotes[fixedAutoSavedNote.index] = fixedAutoSavedNote;
      } else {
        return [fixedAutoSavedNote].concat(newNotes);
      }
    }

    return newNotes;

    // eslint-disable-next-line
  }, [nodes, draftNotes, editDraftNotes, autoSavedNote]);

  const loadMore = () => {
    if (hasMore) {
      fetchMore({
        variables: {
          first: INITIAL_NOTES_PER_PAGE,
          after: pageInfo?.endCursor,
          patientId: patientId,
        },
      });
    }
  };

  const handleCreateNew = () => {
    if (hasDraftNote(patientId)) {
      return showSnackbar({
        message: `There are notes that haven't been saved yet`,
        severity: 'error',
      });
    }

    addDraftNote(patientId);
  };

  const handleCreateFromTemplate = () => {
    if (hasDraftNote(patientId)) {
      return showSnackbar({
        message: `There are notes that haven't been saved yet`,
        severity: 'error',
      });
    }

    const templates =
      templatesData?.notesTemplates.edges.map((edge) => edge.node) ?? [];
    openDialog({
      title: 'Add From Template',
      content: (
        <Templates templates={templates} onSelect={handleSelectTemplate} />
      ),
      maxWidth: 'md',
      confirmButtonLabel: 'Add',
      cancelButtonLabel: 'Cancel',
      handleConfirm: handleAddFromTemplate,
    });
  };

  const handleSelectTemplate = (template: NotesTemplate) => {
    selectedTemplate.current = template;
  };

  const handleAddFromTemplate = async () => {
    if (selectedTemplate.current) {
      addDraftNoteFromTemplate(selectedTemplate.current, patientId);
    }
    closeDialog();
  };

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  if (networkStatus === NetworkStatus.loading || !data) {
    return <Loader />;
  }

  return (
    <Box data-testid="NOTES_CONTAINER">
      <Box display="flex" justifyContent="flex-end" marginBottom={5}>
        <SplitMenu
          label="Add"
          mainActionHandler={handleCreateNew}
          items={[
            {
              label: 'New',
              icon: 'plus-outline',
              handleClick: handleCreateNew,
            },
            {
              label: 'From Template',
              icon: 'file-add-outline',
              handleClick: handleCreateFromTemplate,
              testId: 'FROM_TEMPLATE_MENU_ITEM',
            },
          ]}
        />
      </Box>
      {notesList?.map((note, index) => {
        return (
          <Box key={note.id || 'draft'}>
            <Box marginBottom={3}>
              {isDraftNote(note.id) || autoSavedNote?.id === note.id ? (
                note.questions != null ? (
                  <ChartCard chart={note} patientId={patientId} index={index} />
                ) : (
                  <NoteCard note={note} patientId={patientId} index={index} />
                )
              ) : note.questions != null ? (
                <ChartCard chart={note} preview patientId={patientId} />
              ) : (
                <NoteCardPreview note={note} patientId={patientId} />
              )}
            </Box>
          </Box>
        );
      })}
      <Waypoint onEnter={loadMore} />
    </Box>
  );
};

export default NotesTab;
