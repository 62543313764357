import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  styled,
} from '@mui/material';
import React from 'react';

const CUSTOM_COLORS = ['infoLight', 'paper', 'disabled'] as const;
type CustomColor = (typeof CUSTOM_COLORS)[number];

interface ChipProps
  extends Pick<MuiChipProps, Exclude<keyof MuiChipProps, 'color'>> {
  color?: MuiChipProps['color'] | CustomColor;
}

const InfoLightChip = styled(MuiChip)(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  color: theme.palette.secondary.contrastText,
}));

const DisabledChip = styled(MuiChip)(({ theme }) => ({
  backgroundColor: theme.palette.action.disabledBackground,
  color: theme.palette.text.primary,
}));

const PaperChip = styled(MuiChip)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
}));

const Chip = ({ color, ...props }: ChipProps) => {
  const customColor = CUSTOM_COLORS.find((cColor) => cColor === color);

  if (!customColor) {
    return (
      <MuiChip
        color={color as MuiChipProps['color']}
        data-testid="CHIP"
        {...props}
      />
    );
  }

  switch (customColor) {
    case 'infoLight':
      return <InfoLightChip {...props} />;
    case 'paper':
      return <PaperChip {...props} />;
    case 'disabled':
      return <DisabledChip {...props} />;
  }
};

export default Chip;
