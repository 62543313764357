import React, { useMemo } from 'react';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { Icon, IconProps } from '../Icon/Icon';

export interface IconButtonProps extends MuiIconButtonProps {
  'data-testid'?: string;
  iconProps: Omit<IconProps, 'showSkeleton' | 'fill'>;
}

export const IconButton: React.FC<IconButtonProps> = ({
  'data-testid': dataTestId,
  iconProps,
  ...muiProps
}) => {
  const theme = useTheme();

  const iconFill = useMemo(() => {
    if (muiProps.disabled) {
      return theme.palette.action.disabled;
    }

    switch (muiProps.color) {
      case 'default': {
        return grey[600];
      }
      case 'inherit': {
        return 'inherit';
      }
      case 'primary':
      case 'secondary':
      case 'error':
      case 'info':
      case 'success':
      case 'warning': {
        return theme.palette[muiProps.color].main;
      }
      default: {
        return muiProps.color || grey[600];
      }
    }
  }, [muiProps.color, muiProps.disabled, theme.palette]);

  return (
    <MuiIconButton {...muiProps} data-testid={dataTestId}>
      <Icon {...iconProps} fill={iconFill} />
    </MuiIconButton>
  );
};
