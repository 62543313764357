import React, { useEffect } from 'react';
import {
  useChatContext,
  ChannelPreviewUIComponentProps,
} from 'stream-chat-react';

import { useChatNotification } from '../../../hooks';
import { ChannelType } from '../../../states/streamChannelTypeState';
import Avatar from '../../Avatar/Avatar';
import { ChatUser, getDisplaySubtitle } from '../../../utils';

import {
  ChatName,
  ChatSubtitle,
  LeftContainer,
  MessagePreview,
  MiddleContainer,
  RightContainer,
  SelectedPreviewItem,
  TimeStamp,
  UnreadBadge,
  UnselectedPreviewItem,
} from './styles';

interface PreviewProps extends ChannelPreviewUIComponentProps {
  customActiveChannel?: string;
}

const Preview: React.FC<PreviewProps> = ({
  channel,
  latestMessage,
  setActiveChannel,
  customActiveChannel,
}) => {
  const { channel: activeChannel, client } = useChatContext();
  const { changeNotificationChannel } = useChatNotification();

  const isActiveChannel = channel?.id === activeChannel?.id;
  const unreadCount = channel.countUnread();
  const isBroadcastChannel = channel.type === ChannelType.Broadcast;

  const members = Object.values(channel.state.members);
  const otherMembers = members.filter(({ user }) => user?.id !== client.userID);
  const chatUser = otherMembers[0]?.user as ChatUser;

  const displayAvatar = chatUser?.image;
  const displayName = isBroadcastChannel ? channel?.data?.name : chatUser?.name;
  const displaySubtitle = getDisplaySubtitle(
    chatUser?.userType,
    chatUser?.subjectId
  );
  const displayPresence = chatUser?.online;

  useEffect(() => {
    if (isActiveChannel) {
      changeNotificationChannel(activeChannel?.id);
    }

    return () => {
      changeNotificationChannel(undefined);
    };
  }, [isActiveChannel, changeNotificationChannel, activeChannel]);

  useEffect(() => {
    if (customActiveChannel && customActiveChannel === channel?.id) {
      setActiveChannel?.(channel);
    }
  }, [customActiveChannel, channel, setActiveChannel]);

  const getTimeStamp = () => {
    let lastHours = channel.state.last_message_at?.getHours();
    let lastMinutes: string | number | undefined =
      channel.state.last_message_at?.getMinutes();
    let half = 'AM';

    if (lastHours === undefined || lastMinutes === undefined) {
      return '';
    }

    if (lastHours > 12) {
      lastHours = lastHours - 12;
      half = 'PM';
    }

    if (lastHours === 0) lastHours = 12;
    if (lastHours === 12) half = 'PM';

    if (lastMinutes.toString().length === 1) {
      lastMinutes = `0${lastMinutes}`;
    }

    return `${lastHours}:${lastMinutes} ${half}`;
  };

  const PreviewItem = isActiveChannel
    ? SelectedPreviewItem
    : UnselectedPreviewItem;

  return (
    <PreviewItem
      onClick={() => {
        setActiveChannel?.(channel);
      }}
    >
      {channel.type === ChannelType.Messaging && (
        <LeftContainer>
          <Avatar
            picture={displayAvatar}
            name={displayName}
            presence={displayPresence ? 'online' : 'offline'}
          />
        </LeftContainer>
      )}

      <MiddleContainer>
        <ChatName
          variant="h6"
          sx={{
            color: isActiveChannel ? 'primary.contrastText' : 'text.primary',
          }}
        >
          {displayName}
        </ChatName>
        {!isBroadcastChannel && displaySubtitle && (
          <ChatSubtitle variant="caption">{displaySubtitle}</ChatSubtitle>
        )}
        <MessagePreview
          noWrap
          variant="body1"
          sx={{
            color: isActiveChannel ? 'primary.contrastText' : 'text.secondary',
          }}
        >
          {latestMessage || 'Send a message'}
        </MessagePreview>
      </MiddleContainer>
      <RightContainer>
        <TimeStamp
          noWrap
          variant="body2"
          sx={{
            color: isActiveChannel ? 'primary.contrastText' : 'text.secondary',
          }}
        >
          {getTimeStamp()}
        </TimeStamp>
        {!isActiveChannel && !!unreadCount && (
          <UnreadBadge badgeContent={unreadCount} color="error" />
        )}
      </RightContainer>
    </PreviewItem>
  );
};

export default Preview;
