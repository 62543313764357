import React from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@fdha/web-ui-library';

import BasePage from '../../components/BasePage/BasePage';

export const INITIAL_SURVEYS = 200;

const getTabs = () => [
  {
    value: 'survey',
    label: 'Survey',
    to: '/settings',
    href: '/settings',
    'data-testid': 'SURVEYS_TAB',
  },
  {
    value: 'notes',
    label: 'Notes Templates',
    to: '/settings/notes',
    href: '/settings/notes',
    'data-testid': 'NOTES_TAB',
  },
  {
    value: 'coaches',
    label: 'Coaches',
    to: '/settings/coaches',
    href: '/settings/coaches',
    'data-testid': 'COACHES_TAB',
  },
  {
    value: 'foodops',
    label: 'Food Ops Users',
    to: '/settings/foodops-users',
    href: '/settings/foodops-users',
    'data-testid': 'FOODOPS_TAB',
  },
  {
    value: 'csr',
    label: 'Customer Service',
    to: '/settings/csr',
    href: '/settings/csr',
    'data-testid': 'CUSTOMER_SERVICE_TAB',
  },
];

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = getTabs();
  const selectedTab =
    tabs.find((tab) => tab.href === location.pathname)?.value || 'survey';
  const displayCreateNewButton = ['survey', 'notes'].includes(selectedTab);

  const handleNavigate = () => {
    navigate(`/settings/${selectedTab}/create-${selectedTab}`);
  };

  return (
    <BasePage data-testid="SETTINGS_PAGE">
      <TabContext value={selectedTab}>
        <TabList
          orientation="horizontal"
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          {tabs.map((tab) => (
            <Tab
              value={tab.value}
              label={tab.label}
              component={Link}
              to={tab.to}
              key={tab.value}
              data-testid={tab['data-testid']}
            />
          ))}
        </TabList>
        <TabPanel
          sx={{ padding: 0 }}
          value={selectedTab}
          data-testid="SETTINGS_TAB_PANEL"
        >
          {displayCreateNewButton && (
            <Box marginTop={4} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                data-testid={`OPEN_CREATE_NEW_${selectedTab.toUpperCase()}`}
                size="large"
                startEvaIcon={{ name: 'plus-outline' }}
                sx={{ paddingX: 10 }}
                onClick={handleNavigate}
              >
                Create new
              </Button>
            </Box>
          )}
          <Box marginTop={displayCreateNewButton ? 3 : 4}>
            <Outlet />
          </Box>
        </TabPanel>
      </TabContext>
    </BasePage>
  );
};

export default Settings;
