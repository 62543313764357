import { Box, Paper, TableCell, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import {
  Button,
  useDialog,
  Table,
  HeadCell,
  SearchableTableHeader,
  maskPhone,
} from '@fdha/web-ui-library';
import Icon from 'react-eva-icons';
import { grey } from '@mui/material/colors';
import { FoodOpsUser, useListFoodOpsUsersQuery } from '@fdha/graphql-api-admin';

import Avatar from '../../../../components/Avatar/Avatar';
import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
} from '../../../../hooks';

import AddFoodOpsDialog from './AddFoodOpsDialog';

const headCells: HeadCell<FoodOpsUser>[] = [
  { id: 'name', label: 'Name', sortable: true, searchable: true },
  { id: 'email', label: 'Email', sortable: true, searchable: true },
  {
    id: 'phone_number',
    label: 'Phone number',
    sortable: true,
    searchable: true,
  },
];

const FoodOpsTab = () => {
  const { openDialog } = useDialog();

  const [filterBy, setFilterBy] = useFilterBy<FoodOpsUser>('name', '');
  const [sortBy, setSortBy] = useSortBy<FoodOpsUser>('name', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'foodops',
  });

  const { data, fetchMore, loading, networkStatus } = useListFoodOpsUsersQuery({
    variables: {
      first: rowsPerPage,
      filterBy: {
        filterBy: [filterByDebounced],
      },
      sortBy: {
        sortBy: [sortBy],
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const nodes = data?.foodopsUsers.edges.map(
    (edge) => edge.node
  ) as FoodOpsUser[];
  const pageInfo = data?.foodopsUsers.pageInfo;
  const totalNumberFound = data?.foodopsUsers.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const handleNewFoodOps = () => {
    openDialog({
      title: '',
      content: <AddFoodOpsDialog />,
    });
  };

  const renderRow = (row: FoodOpsUser) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="FOODOPS_NAME_CELL">
          <Avatar type="imageText" picture={row.picture} name={row.name} />
        </TableCell>
        <TableCell data-testid="EMAIL_CELL">{row.email}</TableCell>
        <TableCell data-testid="PHONE_CELL">
          {maskPhone(row.phone_number, false)}
        </TableCell>
        <TableCell padding="checkbox">
          <Link to={`/foodops/${row.id}`}>
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box display="flex" marginBottom={2} alignItems="center">
        <SearchableTableHeader<FoodOpsUser>
          headCells={headCells}
          defaultSearchField="name"
          onSearchChange={setFilterBy}
          disableMargin
        />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          startEvaIcon={{ name: 'plus-outline' }}
          sx={{ marginLeft: 2 }}
          onClick={handleNewFoodOps}
        >
          NEW
        </Button>
      </Box>
      <Paper data-testid="FOODOPS_TABLE">
        {nodes ? (
          <Table<FoodOpsUser>
            actions="right"
            initialOrderBy="name"
            headCells={headCells}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={changeRowsPerPage}
            renderRow={renderRow}
            rows={nodes}
            isLoading={loading}
            totalRowCount={totalNumberFound}
            withPagination
            onSortChange={setSortBy}
          />
        ) : null}
      </Paper>
    </>
  );
};

export default FoodOpsTab;
