import { Box, styled } from '@mui/material';
import React from 'react';
import { ProgressStatus } from '@fdha/graphql-api-admin';

import Chip from '../Chip/Chip';

const ChipContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0.5),
  display: 'inline-flex',
}));

interface ProgressStatusChipsProps {
  progressStatus?: ProgressStatus;
}

const ProgressStatusChips: React.FC<ProgressStatusChipsProps> = ({
  progressStatus,
}) => {
  const dataTestID = 'STATUS_CHIP';

  const noResults = (
    <Chip key="none" label="none" color="default" data-testid={dataTestID} />
  );

  if (!progressStatus) {
    return noResults;
  }

  const { done, late, open } = progressStatus;

  if (!done && !late && !open) {
    return noResults;
  }

  return (
    <>
      {done > 0 && (
        <ChipContainer>
          <Chip
            label={`${done} done`}
            color="success"
            data-testid={dataTestID}
          />
        </ChipContainer>
      )}
      {late > 0 && (
        <ChipContainer>
          <Chip
            label={`${late} missing`}
            color="error"
            sx={{ marginRight: '5px', marginBottom: '5px' }}
            data-testid={dataTestID}
          />
        </ChipContainer>
      )}
      {open > 0 && (
        <ChipContainer>
          <Chip
            label={`${open} open`}
            color="warning"
            sx={{ marginRight: '5px', marginBottom: '5px' }}
            data-testid={dataTestID}
          />
        </ChipContainer>
      )}
    </>
  );
};

export default ProgressStatusChips;
