import { i18n } from '../types/i18n';

export const isI18n = (value: unknown): value is i18n => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'key' in value &&
    'fallback' in value
  );
};
