import { useReactiveVar } from '@apollo/client';
import { Select } from '@fdha/web-ui-library';
import { Box, SelectChangeEvent } from '@mui/material';
import { throttle } from 'lodash';
import React from 'react';
import {
  ChannelSearch as StreamChannelSearch,
  ChannelSearchFunctionParams,
  ChannelSearchProps,
  useChatContext,
} from 'stream-chat-react';
import { useGetProfileQuery } from '@fdha/graphql-api-admin';

import {
  ChannelType,
  selectedStreamChannelType,
  setSelectedStreamChannelType,
} from '../../../states/streamChannelTypeState';

import SearchInput from './SearchInput';

const ChannelSearch: React.FC<ChannelSearchProps> = (props) => {
  const { client } = useChatContext();
  const channelType = useReactiveVar(selectedStreamChannelType);
  const { data } = useGetProfileQuery();

  const getChannels = async (
    text: string,
    params: ChannelSearchFunctionParams
  ) => {
    if (!text || !client.userID) {
      return;
    }

    const { setSearching, setResults, setResultsOpen } = params;
    setSearching(true);

    const channels = await client.queryChannels(
      {
        name: { $autocomplete: text },
        members: { $in: [client.userID] },
        type: channelType,
      },
      {},
      { limit: 5 }
    );

    setResults(channels);
    setResultsOpen(true);
    setSearching(false);
    return;
  };

  const getChannelsThrottled = throttle(getChannels, 200);

  const searchFunction = async (
    params: ChannelSearchFunctionParams,
    event: React.BaseSyntheticEvent
  ) => {
    event.preventDefault();

    const text = event.target.value;

    params.setQuery(text);
    getChannelsThrottled(text, params);
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as ChannelType;
    setSelectedStreamChannelType(value);
  };

  return (
    <Box data-testid="CHANNEL_SEARCH">
      <StreamChannelSearch
        searchFunction={searchFunction}
        SearchInput={SearchInput}
      />
      {data?.me.is_admin && (
        <Select
          size="small"
          name="channelType"
          value={channelType}
          onChange={handleChange}
          sx={{
            marginX: 3,
            marginBottom: '17px',
          }}
          options={[
            { value: ChannelType.Messaging, label: 'Conversations' },
            { value: ChannelType.Broadcast, label: 'Announcements' },
          ]}
        />
      )}
    </Box>
  );
};

export default ChannelSearch;
