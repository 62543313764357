import { useFormikContext } from 'formik';
import React, { FC, PropsWithChildren } from 'react';
import { Select, states, TextField } from '@fdha/web-ui-library';

export interface AddressProps {
  address: string;
  complement: string;
  city: string;
  state: string;
  zip: string;
}
interface FieldsProps {
  title?: string;
  placeholder?: string;
}

const Address: FC<FieldsProps> = ({ placeholder = 'Add address...' }) => {
  const {
    values: { address },
    errors,
    touched,
    handleChange,
  } = useFormikContext<AddressProps>();

  const errorState = touched.address && errors.address;

  return (
    <TextField
      name="address"
      value={address}
      onChange={handleChange}
      title="Address"
      placeholder={placeholder}
      error={!!errorState}
      helperText={errorState}
    />
  );
};

const Complement: FC<FieldsProps> = ({ placeholder = 'Add complement...' }) => {
  const {
    values: { complement },
    handleChange,
  } = useFormikContext<AddressProps>();

  return (
    <TextField
      name="complement"
      value={complement}
      onChange={handleChange}
      title="Complement"
      placeholder={placeholder}
    />
  );
};

const City: FC<FieldsProps> = ({ placeholder = 'Add city...' }) => {
  const {
    values: { city },
    errors,
    touched,
    handleChange,
  } = useFormikContext<AddressProps>();

  const errorState = touched.city && errors.city;

  return (
    <TextField
      name="city"
      value={city}
      onChange={handleChange}
      title="City"
      placeholder={placeholder}
      error={!!errorState}
      helperText={errorState}
    />
  );
};

const State: FC<FieldsProps> = ({
  title = 'State',
  placeholder = 'Choose state...',
}) => {
  const {
    values: { state },
    errors,
    touched,
    handleChange,
  } = useFormikContext<AddressProps>();

  const errorState = touched.state && errors.state;

  return (
    <Select
      name="state"
      title={title}
      titleVariant="body1"
      onChange={handleChange}
      value={state}
      options={states}
      placeholder={placeholder}
      displayEmpty
      error={!!errorState}
      helperText={errorState}
    />
  );
};

const Zip: FC<FieldsProps> = ({ placeholder = 'Add zip code...' }) => {
  const {
    values: { zip },
    errors,
    touched,
    handleChange,
  } = useFormikContext<AddressProps>();

  const hasZipError = !!zip && !!errors.zip;
  const errorState = touched.zip && errors.zip;

  return (
    <TextField
      name="zip"
      value={zip?.replace(/^[a-zA-Z]+$/, '')}
      onChange={handleChange}
      title="Zip/Postal Code"
      placeholder={placeholder}
      error={hasZipError || !!errorState}
      helperText={(hasZipError && errors.zip) || errorState}
    />
  );
};

const AddressFormPreferences: FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

export default Object.assign(AddressFormPreferences, {
  Address,
  Complement,
  City,
  State,
  Zip,
});
