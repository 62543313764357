import { Stack, useTheme } from '@mui/material';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

const ModeratorLabel = () => {
  const theme = useTheme();

  return (
    <Stack spacing={0.25} direction="row">
      <Icon name="shield" fill={theme.palette.success.light} size="medium" />
      <Typography variant="caption" color={theme.palette.success.light}>
        MODERATOR
      </Typography>
    </Stack>
  );
};

export default ModeratorLabel;
