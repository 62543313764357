import React from 'react';
import { Box, Paper, Stack, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  Table,
  HeadCell,
  SearchableTableHeader,
  Avatar,
  Icon,
} from '@fdha/web-ui-library';
import { Trial, useListTrialsQuery } from '@fdha/graphql-api-admin';
import { Link } from 'react-router-dom';

import {
  useDebouncedValue,
  useFilterBy,
  useSortBy,
  useTable,
} from '../../../../hooks';
import { getTrialStatusChip } from '../../../../utils';

const NUMBER_OF_TRIALS = 10000;

const headCells: HeadCell<Trial>[] = [
  {
    id: 'protocol_number',
    label: 'Protocol Number',
    sortable: true,
    searchable: true,
  },
  {
    id: 'protocol_abbreviation',
    label: 'Abbreviation',
    sortable: true,
    searchable: true,
  },
  {
    id: 'status',
    label: 'Trial status',
    sortable: false,
    searchable: false,
  },
];

const renderRow = (row: Trial) => {
  return (
    <TableRow hover key={row.id} data-testid="TABLE_ROW">
      <TableCell data-testid="PROTOCOL_NUMBER_CELL">
        <Avatar
          type="iconText"
          name={row.protocol_number || ''}
          icon="inbox-outline"
        />
      </TableCell>
      <TableCell data-testid="ABBREVIATION_CELL">
        {row.protocol_abbreviation}
      </TableCell>
      <TableCell data-testid="TRIAL_STATUS_CELL">
        {getTrialStatusChip(row.status)}
      </TableCell>
      <TableCell padding="checkbox">
        <Link to={`trial/${row.id}`}>
          <Icon
            name="arrow-ios-forward-outline"
            size="large"
            fill={grey[600]}
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

const TrialsTab = () => {
  const { page, rowsPerPage } = useTable({
    key: 'trials',
  });

  const [filterBy, setFilterBy] = useFilterBy<Trial>('protocol_number', '');
  const [sortBy, setSortBy] = useSortBy<any>('protocol_number', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const { data, loading } = useListTrialsQuery({
    variables: {
      first: NUMBER_OF_TRIALS,
      filterBy: {
        filterBy: [filterByDebounced],
      },
      sortBy: {
        sortBy: [sortBy],
      },
    },
  });

  const nodes = data?.trials || [];

  const emptyState = filterByDebounced.value
    ? 'No results found'
    : 'No trials yet';

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} marginBottom={2}>
        <Box flex={1}>
          <SearchableTableHeader<Trial>
            headCells={headCells}
            defaultSearchField="protocol_number"
            onSearchChange={setFilterBy}
            disableMargin
          />
        </Box>
      </Stack>
      <Paper>
        <Table<Trial>
          actions="right"
          initialOrderBy="protocol_number"
          headCells={headCells}
          page={page}
          emptyState={emptyState}
          rowsPerPage={rowsPerPage}
          rows={nodes}
          isLoading={loading}
          renderRow={renderRow}
          onSortChange={setSortBy}
        />
      </Paper>
    </>
  );
};

export default TrialsTab;
