import { DateQuestionInitialState } from '@fdha/graphql-api-admin';
import { RadioGroup } from '@fdha/web-ui-library';
import React from 'react';

import {
  dateQuestionInitialStateLabelByValue,
  dateQuestionInitialStateHelperTextByValue,
} from '../../../../utils';

import { QuestionOptionProps } from './OptionsTabPanel';

export const options = Object.entries(DateQuestionInitialState)
  .map(([_, value]) => ({
    label: dateQuestionInitialStateLabelByValue[value],
    value: value,
    helperText: dateQuestionInitialStateHelperTextByValue[value],
  }))
  .reverse();

export const DateOption: React.FC<QuestionOptionProps<string>> = ({
  values,
  handleChange,
}) => {
  return (
    <RadioGroup
      row
      type="default"
      name="customProps.initialState"
      title="Date field initial state"
      options={options}
      onChange={(value) => handleChange('customProps.initialState', value)}
      value={values.customProps?.initialState || ''}
    />
  );
};
