import React, { FC } from 'react';
import {
  Button,
  HeadCell,
  Icon,
  RTEContentRender,
  Table,
  formatDate,
  RecipeAdditionalInformation,
  Loader,
} from '@fdha/web-ui-library';
import {
  Box,
  Grid,
  IconButton,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Switch,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  GroupRecipeDetails,
  useGetGroupRecipeQuery,
} from '@fdha/graphql-api-admin';

const headCells: HeadCell<GroupRecipeDetails>[] = [
  {
    id: 'coverImage',
    label: 'Recipe Image',
  },
  {
    id: 'name',
    label: 'Recipe Name',
  },
  {
    id: 'createdAt',
    label: 'Created on',
  },
  {
    id: 'publishedAt',
    label: 'Last modified on',
  },
  {
    id: 'visibleToGroup',
    label: 'Visible to group',
  },
  {
    id: 'patientLink',
    label: '',
  },
  {
    id: 'editionUrl',
    label: '',
  },
];

interface RecipeDetailsProps {
  recipeId: string;
  groupId: string;
  handleBack: () => void;
  onChangeGroupVisibility: (isVisible: boolean, id: string) => Promise<void>;
  onCopyLink: (recipeLink: string, recipeName: string) => void;
}

const RecipeDetails: FC<RecipeDetailsProps> = ({
  recipeId,
  groupId,
  handleBack,
  onChangeGroupVisibility,
  onCopyLink,
}) => {
  const { data, loading } = useGetGroupRecipeQuery({
    variables: {
      recipeId,
      groupId,
    },
  });

  const recipe = data?.groupRecipe;

  const Paper = styled(MuiPaper)(({ theme }) => ({
    padding: theme.spacing(4),
    width: '100%',
    boxShadow: theme.shadows[2],
  }));

  const Divider = styled(MuiDivider)(() => ({
    width: '100%',
    marginTop: 8,
    marginBottom: 16,
  }));

  const renderRow = (row: GroupRecipeDetails) => {
    return (
      <TableRow hover key={row.name} data-testid="TABLE_ROW">
        <TableCell>
          <IconButton data-testid="RECIPE_BACK_BUTTON" onClick={handleBack}>
            <Icon size="large" name="arrow-ios-back-outline" fill={grey[600]} />
          </IconButton>
        </TableCell>
        <TableCell data-testid="RECIPE_IMAGE_CELL">
          <img
            src={row.coverImage || ''}
            alt="Recipe cover"
            width="180px"
            height="67px"
            style={{ objectFit: 'cover' }}
          />
        </TableCell>
        <TableCell data-testid="RECIPE_NAME_CELL">{row?.name}</TableCell>
        <TableCell data-testid="RECIPE_CREATED_AT_CELL">
          {formatDate(new Date(row.createdAt))}
        </TableCell>
        <TableCell data-testid="RECIPE_MODIFIED_ON_CELL">
          {formatDate(new Date(row.publishedAt))}
        </TableCell>
        <TableCell data-testid="RECIPE_VISIBLE_TO_GROUP_CELL">
          <Box textAlign="center">
            <Switch
              checked={row.visibleToGroup}
              color="secondary"
              onChange={() =>
                onChangeGroupVisibility(row.visibleToGroup, row.id)
              }
            />
          </Box>
        </TableCell>
        <TableCell data-testid="RECIPE_COPY_LINK_CELL">
          <Button
            variant="contained"
            color="secondary"
            startEvaIcon={{
              name: 'copy-outline',
            }}
            onClick={() => onCopyLink(row.patientLink, row.name)}
          >
            Copy link
          </Button>
        </TableCell>
        <TableCell data-testid="RECIPE_GO_TO_CONTENTFUL_CELL">
          <Button
            variant="contained"
            color="secondary"
            startEvaIcon={{
              name: 'external-link-outline',
            }}
            onClick={() => window.open(row.editionUrl, '_blank')}
          >
            Go to contentful
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  if (loading || !recipe) {
    return <Loader />;
  }

  const description =
    recipe?.recipeDescription && JSON.parse(recipe.recipeDescription);
  const ingredients = recipe?.ingredients && JSON.parse(recipe.ingredients);
  const instructions = recipe?.instructions && JSON.parse(recipe.instructions);

  return (
    <>
      <MuiPaper>
        <Table<GroupRecipeDetails>
          headCells={headCells}
          actions="left"
          renderRow={renderRow}
          rows={[recipe]}
        />
      </MuiPaper>
      <Grid container spacing={3} sx={{ marginTop: 3 }}>
        <Grid item xs={4}>
          <Paper sx={{ alignSelf: 'flex-start' }}>
            <Typography variant="h6">Description</Typography>
            <Divider />
            <RTEContentRender richTextDocument={description} />
            <RecipeAdditionalInformation
              type={recipe.recipeType}
              servings={recipe.servings}
            />
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper>
            <Typography variant="h6">Details</Typography>
            <Divider />
            <Typography variant="h5">Ingredients</Typography>
            <RTEContentRender richTextDocument={ingredients} />
            <Typography variant="h5" pt={3}>
              Instructions
            </Typography>
            <RTEContentRender richTextDocument={instructions} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default RecipeDetails;
