import React, { FC } from 'react';
import { Box, Link, Skeleton } from '@mui/material';

import { Button, Typography } from '..';
import RestrictedImage from '../../images/Community_Restricted.png';

interface RestrictedUserProps {
  reason: string;
  guidelinesLink: string;
  onLeave: () => void;
  loading?: boolean;
}

export const RestrictedUser: FC<RestrictedUserProps> = ({
  reason,
  guidelinesLink,
  onLeave,
  loading,
}) => {
  return (
    <>
      <Box textAlign="center" pb={3}>
        <img
          src={RestrictedImage}
          alt="Community Restricted"
          data-testid="RESTRICTED_IMG"
          width="96px"
          height="96px"
        />
      </Box>
      <Typography variant="h6">
        You have been restricted from Faeth Community by a moderator.
      </Typography>
      <Box py={3}>
        <Typography fontWeight="bold">Restriction reason:</Typography>
        {loading ? (
          <Skeleton variant="rectangular" height={120} />
        ) : (
          <Typography>"{reason}"</Typography>
        )}
      </Box>
      <Typography variant="body2">
        You may want to read our{' '}
        <b>
          <Link href={guidelinesLink} target="_blank">
            Community Guidelines
          </Link>
        </b>{' '}
        again. If you consider this an unfair decision, feel free to reach out
        to us at{' '}
        <Link href="mailto:community@faeththerapeutics.com" target="_blank">
          community@faeththerapeutics.com
        </Link>
        .
      </Typography>
      <Box mt={3} textAlign="end">
        <Button variant="contained" onClick={onLeave}>
          Go To Home
        </Button>
      </Box>
    </>
  );
};
