import { Box } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

interface TabPanelProps {
  hidden: boolean;
}

const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({
  hidden,
  children,
}) => {
  return (
    <div role="tabpanel" hidden={hidden}>
      {!hidden && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
