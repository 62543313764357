import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { parseISO } from 'date-fns';
import { useLocation, useParams } from 'react-router-dom';
import {
  ActivitiesView,
  DayRange,
  useDialog,
  usePrevious,
} from '@fdha/web-ui-library';
import { NetworkStatus, useApolloClient } from '@apollo/client';
import {
  Task,
  useGetActivitiesInRangeQuery,
  useUpdateTaskCompletedStateMutation,
} from '@fdha/graphql-api-admin';

import {
  useGetUserType,
  useLocalStorage,
  useReadLocalStorage,
} from '../../../../hooks';
import {
  resetSelectedActivity,
  setSelectedActivity,
  updateSelectedActivity,
} from '../../../../states';

import AddActivities from './AddActivity/AddActivities';
import ActivityDetails from './ActivityDetails/ActivityDetails';

const ActivitiesTab = () => {
  const location = useLocation();
  const client = useApolloClient();
  const { openDialog, closeDialog } = useDialog();

  const query = new URLSearchParams(window.location.search);
  const dateFromUrl = query.get('startDate');
  const activityDetailsIdFromUrl = query.get('activityDetailsId');
  const params = useParams();
  const patientId = params.patientId ?? '';

  const initialDateToScroll = dateFromUrl ? parseISO(dateFromUrl) : new Date();

  const activityPreferences = useReadLocalStorage('activityPreferences', false);
  const previousActivityPreferences = usePrevious(activityPreferences);
  const { saveToLocalStorage } = useLocalStorage('activityPreferences', false);

  const [selectedRange, setSelectedRange] = useState<DayRange>();
  const [dateToScroll, setDateToScroll] = useState<Date | undefined>(
    initialDateToScroll
  );
  const previousDate = usePrevious(dateToScroll?.toISOString());

  const { isCsr } = useGetUserType();

  const { data, error, networkStatus } = useGetActivitiesInRangeQuery({
    variables: {
      patientId,
      from: selectedRange?.from.toISOString() || '',
      to: selectedRange?.to.toISOString() || '',
    },
    notifyOnNetworkStatusChange: true,
  });
  const [updateTaskCompletedState] = useUpdateTaskCompletedStateMutation();

  useEffect(() => {
    return () => {
      resetSelectedActivity();
    };
  }, []);

  useEffect(() => {
    return () => {
      client.cache.evict({
        fieldName: 'activities',
      });
    };
  }, [client.cache]);

  const onAdd = () => {
    openDialog({
      title: '',
      content: (
        <AddActivities patientId={patientId} closeDialog={closeDialog} />
      ),
    });
  };

  const onUpdateTask = (data: Task, completed: boolean) => {
    updateTaskCompletedState({
      variables: { instanceId: data.instanceId!, completed: completed },
      update(cache) {
        cache.modify({
          id: cache.identify(data),
          fields: {
            completed() {
              return completed;
            },
          },
        });
      },
    });
  };

  if (error) {
    console.log(JSON.stringify(error, null, 2));
    return null;
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        flex: '1 0 auto',
        flexBasis: 0,
        marginBottom: 17,
        overflow: 'hidden',
      }}
    >
      <ActivitiesView
        pathname={location.pathname}
        activityPreferences={activityPreferences}
        previousActivityPreferences={previousActivityPreferences}
        dateFromUrl={dateFromUrl}
        loading={networkStatus === NetworkStatus.loading || !data}
        activityDetailsIdFromUrl={activityDetailsIdFromUrl || ''}
        selectedRange={selectedRange}
        previousDate={previousDate}
        dateToScroll={dateToScroll?.toISOString()}
        activitiesInRange={data?.activitiesInRange}
        onAdd={onAdd}
        onUpdateTask={onUpdateTask}
        setSelectedRange={setSelectedRange}
        saveToLocalStorage={saveToLocalStorage}
        setDateToScroll={setDateToScroll}
        handleSelectActivity={setSelectedActivity}
        updateSelectedActivity={updateSelectedActivity}
        readOnly={isCsr}
      />
      <ActivityDetails />
    </Paper>
  );
};

export default ActivitiesTab;
