import { PhysicalActivityLevel, UserGender } from '@fdha/graphql-api-admin';
import { SelectOption } from '@fdha/web-ui-library';

const PROFILE_ATTRIBUTES = {
  AGE: 'years',
  WEIGHT: 'kg',
  HEIGHT: 'cm',
};

type ProfileAttribute = keyof typeof PROFILE_ATTRIBUTES;

export const formatProfileAttribute = (
  attribute: ProfileAttribute,
  value?: string | number
): string => {
  if (!value) {
    return '';
  }
  return `${value} ${PROFILE_ATTRIBUTES[attribute]}`;
};

export const genderOptions: SelectOption[] = [
  {
    label: 'Male',
    value: UserGender.Male,
  },
  {
    label: 'Female',
    value: UserGender.Female,
  },
];

export const physicalActivityLevelOptions: SelectOption[] = [
  {
    label: 'Sedentary (little to no exercise)',
    value: PhysicalActivityLevel.Sedentary,
  },
  {
    label: 'Slightly active (light exercise/sports)',
    value: PhysicalActivityLevel.SlightlyActive,
  },
  {
    label: 'Moderately active (3-5 days/week)',
    value: PhysicalActivityLevel.Moderately,
  },
  {
    label: 'Very active (6-7 days/week)',
    value: PhysicalActivityLevel.VeryActive,
  },
];
