import React from 'react';

export interface VideoProps {
  url: string;
  poster?: string;
  style?: React.CSSProperties;
}

export const Video: React.FC<VideoProps> = ({ url, poster, style }) => {
  return (
    <video
      key={url}
      width="100%"
      height="auto"
      controls
      preload="auto"
      controlsList="nodownload"
      poster={poster}
      style={style}
    >
      <source src={url} />
    </video>
  );
};
