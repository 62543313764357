import React, { FC } from 'react';
import { Paper, Box, useTheme, Button } from '@mui/material';

import { Avatar } from '../Avatar/Avatar';
import { Icon } from '../Icon/Icon';
import { TextFieldV2 } from '../V2Wrapper';
import { Typography } from '../Typography/Typography';

export interface PostCardProps {
  userName?: string;
  picture?: string | null;
  handleWriteButton: () => void;
  handlePhotoButton: () => void;
}

const PostCard: FC<PostCardProps> = ({
  userName,
  picture,
  handleWriteButton,
  handlePhotoButton,
}) => {
  const theme = useTheme();

  return (
    <Paper sx={{ p: 2, alignSelf: 'center', width: '100%' }}>
      <Box flexDirection="row" display="flex">
        <Avatar
          name={userName}
          type="imageOnly"
          textPosition="left"
          picture={picture}
          testId="USER_AVATAR"
          sx={{ alignSelf: 'flex-start' }}
        />
        <Box pl={1} width="100%">
          <TextFieldV2
            placeholder="Write something..."
            disabled
            onClick={handleWriteButton}
            fullWidth
            sx={{ input: { cursor: 'pointer' } }}
          />
          <Button
            startIcon={
              <Icon
                name="image-outline"
                size="medium"
                fill={theme.palette.grey['900']}
              />
            }
            sx={{
              textTransform: 'none',
              mt: 2,
            }}
            onClick={handlePhotoButton}
          >
            <Typography
              variant="subtitle1"
              color={theme.palette.text.secondary}
            >
              Photo
            </Typography>
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default PostCard;
