import {
  CommunityUser,
  useGetCommunityUserQuery,
  useUnbanCommunityUserMutation,
} from '@fdha/graphql-api-admin';
import { useDialog, useSnackbar } from '@fdha/web-ui-library';
import { DocumentNode } from 'graphql';
import { useEffect, useState } from 'react';

import { RestrictCommunityUserDialog } from '../components';

export const useCommunity = (refetchQueries?: DocumentNode[]) => {
  const [isBanned, setIsBanned] = useState<boolean | undefined>(false);
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const { data } = useGetCommunityUserQuery();

  const [unbanCommunityUser] = useUnbanCommunityUserMutation({
    refetchQueries,
  });

  const onRestrictButton = (profile: CommunityUser) => {
    openDialog({
      title: 'Restrict user from community',
      content: (
        <RestrictCommunityUserDialog
          profile={profile}
          refetchQueries={refetchQueries}
        />
      ),
    });
  };

  const onliftRestrictionButton = (profile: CommunityUser) => {
    openDialog({
      title: 'Lift restriction',
      content:
        'Are you sure you want to lift this user’s restriction? They will be able to engage in the community again.',
      confirmButtonLabel: 'Lift restriction',
      cancelButtonLabel: 'Cancel',
      handleConfirm: () => handleLiftUserRestriction(profile),
    });
  };

  const handleLiftUserRestriction = async (profile: CommunityUser) => {
    try {
      if (profile.userId) {
        await unbanCommunityUser({ variables: { id: profile.userId } });
        showSnackbar({
          severity: 'success',
          message: 'User successfully unrestricted',
        });
      }
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Unable to unrestict user',
      });
    }
    closeDialog();
  };

  useEffect(() => {
    if (data?.getCommunityUser?.isBanned !== isBanned) {
      setIsBanned(data?.getCommunityUser?.isBanned);
    }
  }, [data, isBanned]);

  return { onliftRestrictionButton, onRestrictButton, isBanned };
};
