import React, { Dispatch, SetStateAction } from 'react';
import { Box, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import {
  useSnackbar,
  formatUTCDate,
  addDayToDateString,
  Button,
  Select,
  TextField,
  addDateValidation,
  cancerTypes,
  otherMedicalConditionsList,
  currentTreatmentList,
  caregiverList,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';
import {
  PatientUser,
  useUpdatePatientUserMutation,
} from '@fdha/graphql-api-admin';

import AddressFormPreferences from '../../../../../components/AddressFormPreferences/AddressFormPreferences';

interface MedicalInfo {
  cancerType?: string | null;
  cancerDetails?: string | null;
  diagDate?: string;
  dateDetails?: string | null;
  currentTreatment?: string[] | null;
  otherMedicalConditions?: string[] | null;
  oncologistName?: string | null;
  facilityName?: string | null;
  facilityCity?: string | null;
  state?: string | null;
  primaryCaregiver?: string | null;
  primaryCaregiverName?: string | null;
  primaryCaregiverDesc?: string | null;
  primaryCheerleader?: string | null;
  primaryCheerleaderName?: string | null;
  primaryCheerleaderDesc?: string | null;
  motivation?: string | null;
  medication?: string | null;
}

const EditModeMedicalInformation = ({
  patient,
  getCancerType,
  handleEditMode,
}: {
  patient?: PatientUser | null;
  getCancerType: (type?: string | null) => string;
  handleEditMode: Dispatch<SetStateAction<boolean>>;
}) => {
  const [updatePatientUser] = useUpdatePatientUserMutation();
  const { showSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    diagDate: addDateValidation('monthYear', 'Please insert a valid date'),
  });

  const initialValues: MedicalInfo = {
    cancerType: patient?.cancer_type ?? undefined,
    cancerDetails: patient?.diag_details,
    diagDate: formatUTCDate(patient?.diag_date, 'monthYear'),
    currentTreatment: patient?.current_treatment,
    otherMedicalConditions: patient?.other_medical_conditions,
    oncologistName: patient?.oncologist_name,
    facilityName: patient?.facility?.name,
    facilityCity: patient?.facility?.city,
    state: patient?.facility?.state,
    primaryCaregiver: patient?.caregiver?.primary_caregiver,
    primaryCaregiverName: patient?.caregiver?.name,
    primaryCaregiverDesc: patient?.caregiver?.primary_caregiver_desc,
    primaryCheerleader: patient?.cheerleader?.primary_cheerleader,
    primaryCheerleaderName: patient?.cheerleader?.name,
    primaryCheerleaderDesc: patient?.cheerleader?.primary_cheerleader_desc,
    motivation: patient?.motivation,
    medication: patient?.medication,
  };

  const handleSave = async (values: MedicalInfo) => {
    try {
      await updatePatientUser({
        variables: {
          id: patient?.id || '',
          props: {
            cancer_type:
              values.cancerType === 'other' ? undefined : values.cancerType,
            diag_details: values.cancerDetails,
            diag_date: addDayToDateString(values.diagDate),
            current_treatment: values.currentTreatment,
            other_medical_conditions: values.otherMedicalConditions,
            oncologist_name: values.oncologistName,
            facility: {
              name: values.facilityName,
              city: values.facilityCity,
              state: values.state,
            },
            caregiver: {
              primary_caregiver: values.primaryCaregiver,
              primary_caregiver_desc:
                values.primaryCaregiver === 'other'
                  ? values.primaryCaregiverDesc
                  : null,
              name: values.primaryCaregiverName,
            },
            cheerleader: {
              primary_cheerleader: values.primaryCheerleader,
              primary_cheerleader_desc:
                values.primaryCheerleader === 'other'
                  ? values.primaryCheerleaderDesc
                  : null,
              name: values.primaryCheerleaderName,
            },
            motivation: values.motivation,
            medication: values.medication,
          },
        },
      });
      handleEditMode(false);
      showSnackbar({
        severity: 'success',
        message: 'Changes saved!',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update patient data',
      });
    }
  };

  const handleCancel = () => {
    handleEditMode(false);
    showSnackbar({
      severity: 'warning',
      message: 'Changes not saved',
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, isSubmitting, errors }) => {
        return (
          <Form>
            <Box marginBottom={3} data-testid="MEDICAL_EDIT_MODE">
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={4}>
                  <Select
                    title="Cancer Diagnosis"
                    value={values.cancerType}
                    options={cancerTypes}
                    name="cancerType"
                    onChange={handleChange}
                    titleVariant="body1"
                    displayEmpty
                    renderValue={(_) => getCancerType(values.cancerType)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Additional Information"
                    value={values.cancerDetails}
                    name="cancerDetails"
                    placeholder="Type here..."
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Diagnosis Date"
                    value={values.diagDate}
                    name="diagDate"
                    placeholder="MM/YYYY"
                    onChange={handleChange}
                    error={!!errors.diagDate}
                    helperText={errors.diagDate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    name="currentTreatment"
                    title="Current Treatment"
                    options={currentTreatmentList}
                    value={values.currentTreatment ?? []}
                    multiple={true}
                    onChange={handleChange}
                    selectType="checkbox"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    name="otherMedicalConditions"
                    title="Other Medical Conditions"
                    options={otherMedicalConditionsList}
                    value={values.otherMedicalConditions ?? []}
                    multiple={true}
                    onChange={handleChange}
                    selectType="checkbox"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Oncologist Name"
                    value={values.oncologistName}
                    name="oncologistName"
                    placeholder="Type here..."
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Treatment Facility Name"
                    value={values.facilityName}
                    name="facilityName"
                    placeholder="Type here..."
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Treatment Facility City"
                    value={values.facilityCity}
                    name="facilityCity"
                    placeholder="Type here..."
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AddressFormPreferences.State title="Treatment Facility State" />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Medication"
                    value={values.medication}
                    name="medication"
                    placeholder="Type here..."
                    onChange={handleChange}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    title="Primary Caregiver"
                    name="primaryCaregiver"
                    value={values.primaryCaregiver}
                    options={caregiverList}
                    onChange={handleChange}
                    showEmptyValue={true}
                  />
                </Grid>
                {values.primaryCaregiver === 'other' && (
                  <Grid item xs={4}>
                    <TextField
                      title="Primary Caregiver: Other"
                      value={values.primaryCaregiverDesc}
                      name="primaryCaregiverDesc"
                      placeholder="Type here..."
                      onChange={handleChange}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    title="Primary Caregiver Name"
                    value={values.primaryCaregiverName}
                    name="primaryCaregiverName"
                    placeholder="Type here..."
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    title="Primary Cheerleader"
                    name="primaryCheerleader"
                    value={values.primaryCheerleader}
                    options={caregiverList}
                    onChange={handleChange}
                    showEmptyValue={true}
                  />
                </Grid>
                {values.primaryCheerleader === 'other' && (
                  <Grid item xs={4}>
                    <TextField
                      title="Primary Cheerleader: Other"
                      value={values.primaryCheerleaderDesc}
                      name="primaryCheerleaderDesc"
                      placeholder="Type here..."
                      onChange={handleChange}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField
                    title="Primary Cheerleader Name"
                    value={values.primaryCheerleaderName}
                    name="primaryCheerleaderName"
                    placeholder="Type here..."
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Motivation"
                    value={values.motivation}
                    name="motivation"
                    placeholder="Type here..."
                    onChange={handleChange}
                    multiline={true}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box textAlign="end">
              <Button
                onClick={handleCancel}
                sx={{ padding: '8px 34px' }}
                data-testid="CANCEL_EDITION_BUTTON"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: '8px 34px' }}
                type="submit"
                data-testid="SAVE_EDITION_BUTTON"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditModeMedicalInformation;
