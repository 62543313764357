import { Box } from '@mui/material';
import {
  Button,
  convertMaskToE164,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { Form, Formik } from 'formik';
import {
  ListFoodOpsUsersDocument,
  useAddFoodOpsUserMutation,
} from '@fdha/graphql-api-admin';

import AddCoachFormPreferences, {
  UserPreferences,
  validationSchema,
} from '../AddUserFormPreferences';

const AddFoodOpsDialog = () => {
  const { showSnackbar } = useSnackbar();
  const { closeDialog } = useDialog();

  const [addFoodOpsUser] = useAddFoodOpsUserMutation();

  const initialValues: UserPreferences = {
    name: '',
    email: '',
    phoneNumber: '',
  };

  const handleSubmit = async (values: UserPreferences) => {
    try {
      const user = {
        name: values.name,
        email: values.email,
        phone_number: convertMaskToE164(values.phoneNumber),
      };

      await addFoodOpsUser({
        variables: { newUser: user },
        refetchQueries: [ListFoodOpsUsersDocument],
      });
      showSnackbar({
        severity: 'success',
        message: 'Food Ops user added with success',
      });
      closeDialog();
    } catch (error: any) {
      showSnackbar({ severity: 'error', message: error?.message });
      throw error;
    }
  };

  const onCancel = () => {
    showSnackbar({
      message: 'Changes not saved',
      severity: 'warning',
    });
    closeDialog();
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <AddCoachFormPreferences>
            <AddCoachFormPreferences.Name />
            <AddCoachFormPreferences.Email />
            <AddCoachFormPreferences.PhoneNumber />
          </AddCoachFormPreferences>
          <Box display="flex" justifyContent="flex-end" marginTop={3}>
            <Button color="primary" onClick={onCancel} size="large">
              CANCEL
            </Button>
            <Button
              disabled={!isValid || isSubmitting}
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
            >
              SAVE
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddFoodOpsDialog;
