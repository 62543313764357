import { WeekdaysOnDietDeliveries } from '@fdha/common-utils';
import { Box, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface ProjectedWeekDeliveriesProps {
  values: WeekdaysOnDietDeliveries;
  isSelected: boolean;
}

const ProjectedWeekDeliveries: FC<ProjectedWeekDeliveriesProps> = ({
  values,
  isSelected,
}) => {
  const theme = useTheme();

  return (
    <>
      {Object.values(values).map(
        (value, index) =>
          !!value.length && (
            <Box
              key={index}
              paddingX={1.5}
              paddingY={0.25}
              borderRadius="5px"
              sx={{
                backgroundColor: isSelected
                  ? theme.palette.info.main
                  : theme.palette.info.lightBg,
              }}
            >
              <Typography
                variant="caption"
                color={
                  isSelected
                    ? theme.palette.background.paper
                    : theme.palette.info.main
                }
              >
                {index === 0 ? 'TUE' : 'FRI'}
              </Typography>
            </Box>
          )
      )}
    </>
  );
};

export default ProjectedWeekDeliveries;
