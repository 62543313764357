import React, { FC, useMemo } from 'react';
import {
  Link,
  MenuOptionSelection,
  MenuOptions,
  MenuSection,
  TagFilter,
  Typography,
  pluralize,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  GetMenuOptionSelectionsV2Document,
  PatientMealSelectionInputV2,
  PatientOptionSelectionV2,
  useUpdatePatientMealSelectionMutation,
} from '@fdha/graphql-api-admin';
import {
  getAllMenuOptionSelections,
  getQuantitySelected,
  isFull,
} from '@fdha/common-utils';
import { useFormik } from 'formik';
import { useMenuOptionFilter } from '@fdha/common-hooks';
import { Box, Paper, useTheme } from '@mui/material';

import EditButtons from './EditButtons';

interface EditDeliveryProps {
  foodProgramUserId: string;
  deliveryMenuId: string;
  initialValues: MenuOptions;
  entreeDishesAmount: number;
  snackAmount: number;
  hasSides: boolean;
  onDishClicked: (id: string, name: string) => void;
  openPreview: () => void;
}

const EditDelivery: FC<EditDeliveryProps> = ({
  foodProgramUserId,
  deliveryMenuId,
  initialValues,
  entreeDishesAmount,
  snackAmount,
  hasSides,
  onDishClicked,
  openPreview,
}) => {
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const theme = useTheme();

  const allMenuOptionSelections = useMemo(
    () => getAllMenuOptionSelections(initialValues),
    [initialValues]
  );

  const {
    filterOptions,
    appliedFilters,
    draftResults,
    results,
    handleApply,
    handleCancel,
    handleClearAll,
    handleDraftChange,
  } = useMenuOptionFilter(allMenuOptionSelections);

  const [updatePatientMealSelection] = useUpdatePatientMealSelectionMutation({
    refetchQueries: [GetMenuOptionSelectionsV2Document],
  });

  const menuOptionSelectionToMealSelectionInput = (
    menuOptionSelection: MenuOptionSelection
  ): PatientOptionSelectionV2 => {
    return {
      menuOptionId: menuOptionSelection.menuOptionId,
      quantity: menuOptionSelection.quantity,
    };
  };

  const buildPatientMealSelectionInputPayload = (
    values: MenuOptions
  ): PatientMealSelectionInputV2 => {
    const patientMealSelectionInput: PatientOptionSelectionV2[] = [];
    Object.values(values).forEach((value) => {
      const dishes = value as MenuOptionSelection[];
      const selection = dishes
        .map(menuOptionSelectionToMealSelectionInput)
        .filter((selection) => selection.quantity > 0);
      patientMealSelectionInput.push(...selection);
    });

    return {
      foodProgramUserId,
      deliveryMenuId,
      patientOptionSelections: patientMealSelectionInput,
    };
  };

  const onSubmit = async (values: MenuOptions) => {
    const patientMealSelection = buildPatientMealSelectionInputPayload(values);
    try {
      await updatePatientMealSelection({
        variables: {
          props: patientMealSelection,
        },
      });

      showSnackbar({
        message: 'Changes successfully saved.',
        severity: 'success',
      });
    } catch (error) {
      console.error(error);
      showSnackbar({
        message: 'Unable to save meal selections. Please try again',
        severity: 'error',
      });
    } finally {
      openPreview();
    }
  };

  const onCancel = () => {
    openDialog({
      title: 'Discard changes?',
      content: 'All changes made will be lost.',
      confirmButtonLabel: 'Discard changes',
      cancelButtonLabel: 'Back to editing',
      handleConfirm: async () => {
        openPreview();
        closeDialog();
      },
      handleCancel: () => {
        closeDialog();
      },
    });
  };

  const { values, isSubmitting, setFieldValue, handleSubmit } = useFormik({
    initialValues: initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit,
  });

  const isQuantityIncorrect = useMemo(() => {
    return (
      !isFull(values.entreeDishes, entreeDishesAmount) ||
      !isFull(values.snacks, snackAmount)
    );
  }, [values, entreeDishesAmount, snackAmount]);

  const quantitySelected = useMemo(
    () => ({
      entreeDishes: getQuantitySelected(values.entreeDishes),
      snacks: getQuantitySelected(values.snacks),
    }),
    [values.entreeDishes, values.snacks]
  );

  const filteredMenuIds = useMemo(
    () => results.map((menuOption) => menuOption.menuOptionId),
    [results]
  );

  const filteredEntreeDishes = useMemo(() => {
    return values.entreeDishes.filter((menuOption) =>
      filteredMenuIds.includes(menuOption.menuOptionId)
    );
  }, [filteredMenuIds, values.entreeDishes]);

  const filteredSnacks = useMemo(() => {
    return values.snacks.filter((menuOption) =>
      filteredMenuIds.includes(menuOption.menuOptionId)
    );
  }, [filteredMenuIds, values.snacks]);

  const filterButtonConfirmLabel = useMemo(() => {
    const resultCount = !!draftResults.length ? draftResults.length : null;

    return `Show ${resultCount ?? 'All'} ${pluralize(
      resultCount || 0,
      'meal',
      undefined,
      true
    )}`;
  }, [draftResults.length]);

  const filteredResultsLabel = useMemo(() => {
    return appliedFilters.length
      ? `Showing ${pluralize(results.length, 'result')}`
      : null;
  }, [appliedFilters, results.length]);

  const openDiscrepancyDialog = () =>
    openDialog({
      title: 'Planned volume discrepancy',
      content:
        'The current meal selection differs from what is specified in the Food Program Information. Saving these changes will modify the expected volume of meals delivered to the patient. Please review before proceeding.',
      confirmButtonLabel: 'Save selection',
      cancelButtonLabel: 'Back to editing',
      handleConfirm: async () => {
        handleSubmit();
        closeDialog();
      },
    });

  const handleSubmitButton = () => {
    if (isQuantityIncorrect) {
      openDiscrepancyDialog();
    } else {
      handleSubmit();
    }
  };

  const renderFilter = () => {
    return (
      <Paper
        variant="elevation"
        elevation={2}
        sx={{
          paddingX: 3,
          paddingY: 2,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
        }}
      >
        <TagFilter
          options={filterOptions}
          appliedValue={appliedFilters}
          confirmButtonLabel={filterButtonConfirmLabel}
          onDraftChange={handleDraftChange}
          onApply={handleApply}
          onCancel={handleCancel}
        />
        {!!appliedFilters.length && (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {filteredResultsLabel}
            </Typography>
            <Link component="button" onClick={handleClearAll}>
              Clear filters
            </Link>
          </Box>
        )}
      </Paper>
    );
  };

  return (
    <>
      {!!filterOptions.length && renderFilter()}
      <Paper>
        <Box m={3} display="flex" rowGap={1.5} flexDirection="column">
          <MenuSection
            name="entreeDishes"
            amount={entreeDishesAmount}
            hasSides={hasSides}
            allOptions={values.entreeDishes}
            filteredOptions={filteredEntreeDishes}
            setFieldValue={setFieldValue}
            onDishClicked={onDishClicked}
          />
          {snackAmount > 0 && (
            <MenuSection
              name="snacks"
              amount={snackAmount}
              hasSides={hasSides}
              allOptions={values.snacks}
              filteredOptions={filteredSnacks}
              setFieldValue={setFieldValue}
              onDishClicked={onDishClicked}
            />
          )}
        </Box>
      </Paper>
      <EditButtons
        entreeDishesAmount={quantitySelected.entreeDishes}
        snackAmount={quantitySelected.snacks}
        disabled={isSubmitting}
        showDiscrepancyAlert={isQuantityIncorrect}
        onCancel={onCancel}
        onSubmit={handleSubmitButton}
      />
    </>
  );
};

export default EditDelivery;
