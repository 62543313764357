import React, { Dispatch, SetStateAction, useState } from 'react';
import { Accordion } from '@fdha/web-ui-library';
import { FoodOpsUser } from '@fdha/graphql-api-admin';

import ViewModeProfileInformation from './ViewModeProfileInformation';
import EditModeProfileInformation from './EditModeProfileInformation';

export interface ProfileInformationProps {
  foodopsUser?: FoodOpsUser | null;
  handleEditMode: Dispatch<SetStateAction<boolean>>;
}

const ProfileInformation = (props: { foodopsUser?: FoodOpsUser | null }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <>
      <Accordion
        title="Profile information"
        data-testid="PROFILE_INFO"
        sx={{ padding: 1 }}
      >
        {editMode ? (
          <EditModeProfileInformation
            foodopsUser={props.foodopsUser}
            handleEditMode={setEditMode}
          />
        ) : (
          <ViewModeProfileInformation
            foodopsUser={props.foodopsUser}
            handleEditMode={setEditMode}
          />
        )}
      </Accordion>
    </>
  );
};

export default ProfileInformation;
