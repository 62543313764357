import {
  Box,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React from 'react';
import Icon from 'react-eva-icons';
import { TextField } from '@fdha/web-ui-library';

interface FormikContext {
  steps: { title: string }[];
}

interface GoalStepInputProps {
  disabled: boolean;
  title: string;
  onChangeTitle: (newTitle: string) => void;
  onBlur: (currentTitle?: string) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const GoalStepTextInput: React.FC<GoalStepInputProps> = ({
  disabled,
  onBlur,
  onChangeTitle,
  onKeyDown,
  title,
}) => {
  const theme = useTheme();

  return (
    <TextField
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box sx={{ '& .eva-hover': { display: 'flex' } }}>
              <Icon
                name="square-outline"
                fill={theme.palette.text.secondary}
                width={18}
                height={18}
              />
            </Box>
          </InputAdornment>
        ),
      }}
      inputProps={{ maxLength: 100 }}
      onBlur={(event) => onBlur(event.target.value)}
      onChange={(event) => onChangeTitle(event.target.value)}
      onKeyDown={onKeyDown}
      placeholder="Type here to add a step"
      value={title}
    />
  );
};

interface GoalStepsProps {
  disabled?: boolean;
}

const GoalSteps: React.FC<GoalStepsProps> = ({ disabled }) => {
  const {
    values: { steps },
    handleChange,
  } = useFormikContext<FormikContext>();

  const addOrDeleteStep = (
    title: string = '',
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    if (!title && index !== steps.length - 1) {
      arrayHelpers.remove(index);
    } else if (title && index === steps.length - 1) {
      arrayHelpers.push({ title: '' });
    }
  };

  return (
    <Box marginTop={2}>
      <Typography variant="body1" marginBottom="6px" fontWeight="500">
        Steps
      </Typography>
      <FieldArray
        name="steps"
        render={(arrayHelpers) => (
          <Stack spacing={1}>
            {steps.map((step, index) => (
              <GoalStepTextInput
                disabled={!!disabled}
                key={index}
                title={step.title}
                onChangeTitle={handleChange(`steps[${index}].title`)}
                onBlur={(title?: string) =>
                  addOrDeleteStep(title, index, arrayHelpers)
                }
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    addOrDeleteStep(
                      (event.target as HTMLInputElement).value,
                      index,
                      arrayHelpers
                    );
                  }
                }}
              />
            ))}
          </Stack>
        )}
      />
    </Box>
  );
};

export default GoalSteps;
