import { useSnackbar } from '@fdha/web-ui-library';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import { Activity, ActivityKind } from '@fdha/graphql-api-admin';

import AddOrEditAppt from './AddAppt/AddOrEditAppt';
import AddOrEditGoal from './AddGoal/AddOrEditGoal';
import AddOrEditTask from './AddTask/AddOrEditTask';

interface AddActivitiesProps {
  patientId: string;
  closeDialog: () => void;
}

const options = [
  { label: 'GOAL', value: ActivityKind.Goal },
  { label: 'TASK', value: ActivityKind.Task },
  { label: 'APPT', value: ActivityKind.Appointment },
];

const getComponentByKind = (kind: ActivityKind) => {
  return kind === ActivityKind.Goal
    ? AddOrEditGoal
    : kind === ActivityKind.Task
    ? AddOrEditTask
    : AddOrEditAppt;
};

const AddActivities: React.FC<AddActivitiesProps> = ({
  patientId,
  closeDialog,
}) => {
  const [selectedKind, setSelectedKind] = useState<ActivityKind>(
    ActivityKind.Goal
  );
  const { showSnackbar } = useSnackbar();

  const handleSelectedKind = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    newKind: ActivityKind
  ) => {
    if (newKind !== null) {
      setSelectedKind(newKind);
    }
  };

  const onCanceled = () => {
    closeDialog();
    showSnackbar({ message: 'Changes not saved', severity: 'warning' });
  };

  const onSaved = (data?: Partial<Activity>, error?: string) => {
    closeDialog();
    if (error) {
      showSnackbar({
        message: `Error to add activity`,
        severity: 'error',
      });
    } else {
      showSnackbar({ message: 'Changes Saved', severity: 'success' });
    }
  };

  const Component = getComponentByKind(selectedKind);

  return (
    <Box paddingBottom={1}>
      <ToggleButtonGroup
        color="primary"
        exclusive
        fullWidth
        value={selectedKind}
        onChange={handleSelectedKind}
      >
        {options.map((option) => (
          <ToggleButton value={option.value}>{option.label}</ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Box marginTop={3}>
        <Component
          patientId={patientId}
          onCanceled={onCanceled}
          onSaved={onSaved}
        />
      </Box>
    </Box>
  );
};

export default AddActivities;
