import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  LoginPage,
  ResetPasswordPage,
  ForgotPasswordPage,
  ChangePasswordPage,
  SMSChallengeCodePage,
} from '@fdha/web-auth';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<LoginPage />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/sms-challenge" element={<SMSChallengeCodePage />} />
    </Routes>
  );
};

export default PublicRoutes;
