import React, { useMemo, useState } from 'react';
import { Box, DialogContentText, Grid } from '@mui/material';
import {
  Accordion,
  Loader,
  getFoodProgramStatus,
  getWeekDays,
  shouldShowCustomField,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  AutomationRequirementType,
  FoodProgramStatus,
  FoodProgramUserInputV2,
  GetFoodProgramUserDocument,
  GetPatientUserDocument,
  ListDeliveriesDocument,
  PatientUser,
  useCreateFoodProgramUserMutation,
  useGetCustomFieldsValidationQuery,
  useGetFoodProgramUserQuery,
  useListDietPlansQuery,
  useUpdateFoodProgramUserMutation,
  useUpdatePatientUserMutation,
} from '@fdha/graphql-api-admin';
import { getConcatWeekdaysOnDiet } from '@fdha/common-utils';

import { perDayOptions } from '../../../../../utils/foodProgramData';
import {
  AutomationDetails,
  AutomationStatusInfo,
  EditableCardAutocomplete,
  EditableCardSelect,
  EditableCardWeekDays,
} from '../../../../../components';
import { useAutomation } from '../../../../../hooks';
import { getAutomationItemsPerSection } from '../../../../../utils';

const eligibleForHomeCookedOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const qtdPlaceholder = 'Select a quantity';

interface Props {
  patientUser?: PatientUser | null;
  isCsr?: boolean;
}

const NUMBER_OF_RESULTS = 10000;

const FoodProgramInformation = ({ patientUser, isCsr }: Props) => {
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();

  const patientId = patientUser?.id || '';
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const {
    automationItems,
    automationItemsPerAutomationRequirement,
    automationRequirementStatusPerRequirementType,
    isLoading: loadingAutomationItems,
    isAutomationTriggered,
    onAutomationRequirementChange,
  } = useAutomation(patientId);

  const { data: foodProgramData, loading } = useGetFoodProgramUserQuery({
    variables: {
      patientId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: dietPlansData, loading: loadingDietPlans } =
    useListDietPlansQuery({
      variables: { first: NUMBER_OF_RESULTS },
      fetchPolicy: 'cache-and-network',
    });

  const { data: customFieldsData, loading: loadingCustomFields } =
    useGetCustomFieldsValidationQuery({
      variables: { trialId: patientUser?.trial?.id || '' },
    });

  const [updateFoodProgram, { loading: loadingUpdate }] =
    useUpdateFoodProgramUserMutation({
      refetchQueries: [ListDeliveriesDocument],
    });
  const [createFoodProgram] = useCreateFoodProgramUserMutation({
    refetchQueries: [GetFoodProgramUserDocument],
  });
  const [updatePatientUser, { loading: loadingUpdatePatient }] =
    useUpdatePatientUserMutation({
      awaitRefetchQueries: true,
      refetchQueries: [GetPatientUserDocument],
    });

  const showSachets = useMemo(
    () =>
      shouldShowCustomField(
        'sachets',
        customFieldsData?.customFieldsValidation,
        loadingCustomFields
      ),
    [customFieldsData?.customFieldsValidation, loadingCustomFields]
  );

  const dietPlansOptions = useMemo(
    () =>
      dietPlansData?.dietPlansV2.edges.map((e) => ({
        label: e.node.name,
        id: e.node.id,
      })) ?? [],
    [dietPlansData?.dietPlansV2.edges]
  );

  const statusOnTrialOptions = useMemo(
    () =>
      Object.values(FoodProgramStatus).map((type) => ({
        label: getFoodProgramStatus[type],
        value: type,
      })),
    []
  );

  const foodProgramInfoAutomationItems =
    getAutomationItemsPerSection(automationItems, 'foodProgramInformation') ||
    [];
  const foodProgramUser = foodProgramData?.foodProgramUserByPatientIdV2;
  const firstUserDeliveryMenu = foodProgramUser?.userDeliveryMenus?.[0];
  const isArchived = foodProgramUser?.status === FoodProgramStatus.Archived;
  const canEdit = !isArchived && !isCsr;
  const isSachetAutomationTriggered = isAutomationTriggered(
    AutomationRequirementType.Sachets
  );

  const weekdaysOnDiet = foodProgramUser?.userDeliveryMenus?.length
    ? getConcatWeekdaysOnDiet(foodProgramUser?.userDeliveryMenus)
    : [];

  const foodProgramUserStatus = useMemo(
    () => foodProgramUser?.status || FoodProgramStatus.Active,
    [foodProgramUser?.status]
  );

  const dependenciesStatus = useMemo(
    () => ({
      fields: [{ name: 'Diet Plan', isSet: !!foodProgramUser?.dietPlan }],
      customMessage:
        'This item can only be edited once the diet plan is established',
    }),
    [foodProgramUser?.dietPlan]
  );

  const dietPlanOption = useMemo(() => {
    return dietPlansOptions.find((e) => e.id === foodProgramUser?.dietPlan.id);
  }, [dietPlansOptions, foodProgramUser?.dietPlan.id]);

  const snacksAndSidesOptions = useMemo(() => perDayOptions(9), []);
  const selectedSnacksAndSidesOption = snacksAndSidesOptions.find(
    (opt) => opt.value === firstUserDeliveryMenu?.snacksPerDay?.toString()
  );

  const sachetsPerDayOptions = useMemo(() => perDayOptions(10), []);
  const selectedPerDayOption = sachetsPerDayOptions.find(
    (opt) => opt.value === firstUserDeliveryMenu?.sachetsPerDay?.toString()
  );

  const mealsPerDayOptions = useMemo(() => perDayOptions(6, false), []);
  const selectedMealsPerDayOption = mealsPerDayOptions.find(
    (opt) => opt.value === firstUserDeliveryMenu?.mealsPerDay?.toString()
  );

  const shouldIncludeMealSelectionAlert = (input: FoodProgramUserInputV2) => {
    const inputProps = Object.keys(input);
    const mealsSelectionsRelatedFields = [
      'dietPlanId',
      'weekdaysOnDiet',
      'mealsPerDay',
      'snacksPerDay',
    ];

    return mealsSelectionsRelatedFields.some((m) => inputProps.includes(m));
  };

  const handleSave = async (
    input: Partial<FoodProgramUserInputV2>,
    automationRequirementType?: AutomationRequirementType
  ) => {
    const createPayload = {
      status: FoodProgramStatus.Active,
      dietPlanId: input.dietPlanId,
    };

    try {
      if (foodProgramUser?.id) {
        await updateFoodProgram({
          variables: {
            foodProgramUserId: foodProgramUser.id,
            input,
          },
        });
      } else {
        await createFoodProgram({
          variables: {
            patientId,
            input: createPayload,
          },
        });
      }

      if (automationRequirementType) {
        onAutomationRequirementChange(automationRequirementType);
      }

      let message = 'Changes saved!';

      if (shouldIncludeMealSelectionAlert(input)) {
        message +=
          ' Review open deliveries, which may be impacted by this change.';
      }

      showSnackbar({
        severity: 'success',
        message,
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update patient data',
      });
    }
  };

  const handleSaveStatus = async (status: FoodProgramStatus) => {
    const handleConfirm = () => handleSave({ status });

    if (status === FoodProgramStatus.Archived) {
      openDialog({
        title: `Are you sure you want to archive ${patientUser?.name}?`,
        content: (
          <DialogContentText>
            You cannot undo this action. You will still be able to access their
            data in the app, but they will no longer receive meals.
          </DialogContentText>
        ),
        confirmButtonLabel: 'Archive',
        cancelButtonLabel: 'Cancel',
        handleConfirm: async () => {
          handleConfirm();
          closeDialog();
        },
      });
    } else {
      handleConfirm();
    }
  };

  const handleSaveEligibleForHomeCooked = async (value: boolean) => {
    try {
      await updatePatientUser({
        variables: {
          id: patientId,
          props: {
            eligibleForHomeCooked: value,
          },
        },
      });

      showSnackbar({
        severity: 'success',
        message: 'Changes saved!',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update patient data',
      });
    }
  };

  const isLoading = loading || loadingDietPlans || loadingAutomationItems;

  return (
    <Accordion
      title="Food Program information"
      data-testId="FOOD_PROGRAM_INFO"
      sx={{ px: 1 }}
      rightAdornment={
        <Box display="flex" alignItems="center">
          {!!foodProgramInfoAutomationItems.length && !isAccordionExpanded && (
            <AutomationDetails
              automationItems={foodProgramInfoAutomationItems}
              showCount={false}
            />
          )}
          <AutomationStatusInfo />
        </Box>
      }
      onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={2}>
            <EditableCardSelect
              title="Status on trial"
              initialValue={foodProgramUserStatus}
              label={getFoodProgramStatus[foodProgramUserStatus]}
              canEdit={canEdit}
              isLoading={loadingUpdate}
              onSave={(value) => handleSaveStatus(value as FoodProgramStatus)}
              breakpoints={{ xs: 6 }}
              selectProps={{
                options: statusOnTrialOptions,
              }}
              dependenciesStatus={dependenciesStatus}
            />
            <EditableCardSelect
              title="Eligible for home cooked"
              initialValue={patientUser?.eligibleForHomeCooked ? 'yes' : 'no'}
              label={patientUser?.eligibleForHomeCooked ? 'Yes' : 'No'}
              canEdit={!isCsr}
              breakpoints={{ xs: 6 }}
              isLoading={loadingUpdatePatient}
              selectProps={{
                options: eligibleForHomeCookedOptions,
              }}
              onSave={(value) =>
                handleSaveEligibleForHomeCooked(value === 'yes')
              }
            />
            <EditableCardAutocomplete
              title="Diet plan"
              initialValue={dietPlanOption}
              required
              label={dietPlanOption?.label}
              canEdit={canEdit}
              autocompleteProps={{ options: dietPlansOptions }}
              onSave={(value) =>
                handleSave(
                  { dietPlanId: value?.id },
                  AutomationRequirementType.DietPlan
                )
              }
              isLoading={loadingUpdate}
              breakpoints={{ xs: 6 }}
              automationItems={automationItemsPerAutomationRequirement.get(
                AutomationRequirementType.DietPlan
              )}
              automationRequirementStatus={automationRequirementStatusPerRequirementType.get(
                AutomationRequirementType.DietPlan
              )}
            />
            <EditableCardWeekDays
              title="Days on diet"
              label={getWeekDays(weekdaysOnDiet)}
              initialValue={weekdaysOnDiet}
              canEdit={canEdit}
              breakpoints={{ xs: 6 }}
              dependenciesStatus={dependenciesStatus}
              isLoading={loadingUpdate}
              onSave={(value) =>
                handleSave(
                  {
                    weekdaysOnDiet: value,
                  },
                  AutomationRequirementType.DaysOnDiet
                )
              }
              automationItems={automationItemsPerAutomationRequirement.get(
                AutomationRequirementType.DaysOnDiet
              )}
              automationRequirementStatus={automationRequirementStatusPerRequirementType.get(
                AutomationRequirementType.DaysOnDiet
              )}
            />
            <EditableCardSelect
              title="Meals"
              initialValue={selectedMealsPerDayOption?.value}
              label={selectedMealsPerDayOption?.label}
              canEdit={canEdit}
              isLoading={loadingUpdate}
              onSave={(value) =>
                handleSave({
                  mealsPerDay: Number(value),
                })
              }
              breakpoints={{ xs: 6 }}
              selectProps={{
                placeholder: qtdPlaceholder,
                options: perDayOptions(6, false),
              }}
              dependenciesStatus={dependenciesStatus}
            />
            <EditableCardSelect
              title="Snacks and sides"
              initialValue={selectedSnacksAndSidesOption?.value}
              label={selectedSnacksAndSidesOption?.label}
              canEdit={canEdit}
              isLoading={loadingUpdate}
              onSave={(value) =>
                handleSave(
                  {
                    snacksPerDay: Number(value),
                  },
                  AutomationRequirementType.SnacksAndSides
                )
              }
              breakpoints={{ xs: 6 }}
              selectProps={{
                placeholder: qtdPlaceholder,
                options: perDayOptions(9),
              }}
              dependenciesStatus={dependenciesStatus}
              automationItems={automationItemsPerAutomationRequirement.get(
                AutomationRequirementType.SnacksAndSides
              )}
              automationRequirementStatus={automationRequirementStatusPerRequirementType.get(
                AutomationRequirementType.SnacksAndSides
              )}
            />
            {showSachets && (
              <EditableCardSelect
                title="Sachets"
                initialValue={selectedPerDayOption?.value}
                label={selectedPerDayOption?.label}
                selectProps={{
                  placeholder: qtdPlaceholder,
                  options: sachetsPerDayOptions,
                }}
                canEdit={canEdit}
                dependenciesStatus={dependenciesStatus}
                isLoading={loadingUpdate}
                onSave={(value) =>
                  handleSave(
                    { sachetsPerDay: Number(value) },
                    AutomationRequirementType.Sachets
                  )
                }
                breakpoints={{ xs: 6 }}
                automationItems={automationItemsPerAutomationRequirement.get(
                  AutomationRequirementType.Sachets
                )}
                automationRequirementStatus={automationRequirementStatusPerRequirementType.get(
                  AutomationRequirementType.Sachets
                )}
                showInfoCard={isSachetAutomationTriggered}
              />
            )}
          </Grid>
        </>
      )}
    </Accordion>
  );
};

export default FoodProgramInformation;
