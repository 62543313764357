import { KeyOfNestedLeaves } from '@fdha/web-ui-library/src/types/keyOfNestedLeaves';
import { useState } from 'react';
import { FilterByField, FilterOp } from '@fdha/graphql-api-admin';

type Field<T extends object> = KeyOfNestedLeaves<T>;

export function useFilterBy<T extends object>(
  field: Field<T>,
  value: string
): [FilterByField, (field: Field<T>, value: string) => void] {
  const [filterBy, setFilterBy] = useState<FilterByField>(
    asFilterByField<T>(field, value)
  );

  const onChange = (field: Field<T>, value: string) => {
    setFilterBy(asFilterByField<T>(field, value));
  };

  return [filterBy, onChange];
}

function asFilterByField<T extends object>(
  field: Field<T>,
  value: string
): FilterByField {
  return {
    field: field.toString(),
    op: FilterOp.Includes,
    value,
  };
}
