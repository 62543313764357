import { useEffect, useState } from 'react';
import { useGetProfileQuery, UserType } from '@fdha/graphql-api-admin';

export const useGetUserType = () => {
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>();
  const [isCsr, setIsCsr] = useState<boolean | undefined>();

  const { data, loading: isLoading } = useGetProfileQuery();

  useEffect(() => {
    const isCsrData = data?.me.type === UserType.CustomerService;
    if (data?.me.is_admin !== isAdmin) {
      setIsAdmin(data?.me.is_admin);
    }
    if (isCsrData !== isCsr) {
      setIsCsr(isCsrData);
    }
  }, [data, isAdmin, isCsr]);

  return { isAdmin, isCsr, isLoading };
};
