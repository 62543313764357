import React, { FC } from 'react';
import { Box, Checkbox, Radio } from '@mui/material';
import { TextField } from '@fdha/web-ui-library';
import { FormBuilderQuestionType } from '@fdha/graphql-api-admin';

import { OptionsErrorState } from '../../../../models/FormBuilderProps';

interface RadioOptionsProps {
  index: number;
  option: string;
  type: FormBuilderQuestionType;
  errorState: OptionsErrorState;
  handleChange: (event: React.ChangeEvent<any>) => void;
}

const EditChoiceField: FC<RadioOptionsProps> = ({
  index,
  option,
  type,
  errorState,
  handleChange,
}) => {
  const getChoiceOption = () => {
    const style = { paddingLeft: 0, paddingBottom: 0 };

    switch (type) {
      case FormBuilderQuestionType.MultipleChoice:
        return <Checkbox disabled sx={style} />;
      case FormBuilderQuestionType.SingleChoice:
      case FormBuilderQuestionType.Binary:
        return <Radio disabled sx={style} />;
    }
  };

  const touchedError = errorState.touched && errorState.touched[index];
  const error = errorState.errors && errorState.errors[index];

  return (
    <Box display="flex" flexDirection="row" alignContent="center" width="100%">
      {getChoiceOption()}
      <TextField
        name={`possibleAnswers[${index}]`}
        size="small"
        placeholder="Enter an answer choice"
        value={option}
        error={!!error && touchedError}
        helperText={touchedError && error}
        onChange={handleChange}
        sx={{ width: '100%' }}
      />
    </Box>
  );
};

export default EditChoiceField;
