"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContentfulMealSnackDish = exports.getContentfulMealDish = exports.getContentfulCourse = void 0;
var courses = [
    {
        name: 'Course Automation 0',
        id: '2nOTRqXcQrVFxOaKS3knYM',
    },
    {
        name: 'Course Automation DH and Caretaker',
        id: '2TDUe5kc34RkWhs49Gcb5b',
    },
];
var mealDishes = [
    {
        name: 'Meal Dish Automation',
        id: '1jP4TNHeHaBSRaJRJmXG25',
    },
];
var snackDishes = [
    {
        name: 'Snack Dish Automation',
        id: '53NwR1bXkCXAjdppfb1yXc',
    },
];
var getContentfulCourse = function (courseName) {
    var courseIdx = courses.findIndex(function (obj) { return obj.name === courseName; });
    return courses[courseIdx];
};
exports.getContentfulCourse = getContentfulCourse;
var getContentfulMealDish = function (dishName) {
    return mealDishes.find(function (obj) { return obj.name === dishName; });
};
exports.getContentfulMealDish = getContentfulMealDish;
var getContentfulMealSnackDish = function (dishName) {
    return snackDishes.find(function (obj) { return obj.name === dishName; });
};
exports.getContentfulMealSnackDish = getContentfulMealSnackDish;
