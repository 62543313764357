import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

export const LoadingState = () => {
  return (
    <Box pb={3}>
      <Skeleton variant="rectangular" width="100%" height={280} />
      <Box px={3} pt={1.5}>
        <Stack spacing={3} pb={1.5}>
          <Skeleton variant="rectangular" width="100%" height={27} />
          <Skeleton variant="rectangular" width="100%" height={27} />
          <Skeleton variant="rectangular" width="100%" height={27} />
        </Stack>
      </Box>
    </Box>
  );
};
