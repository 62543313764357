import React, { PropsWithChildren } from 'react';
import { Button } from '@fdha/web-ui-library';
import { Box, Collapse, Divider, Typography, useTheme } from '@mui/material';

interface InfoDrawerProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
}

const InfoDrawer: React.FC<PropsWithChildren<InfoDrawerProps>> = ({
  isOpen,
  title,
  onClose,
  children,
}) => {
  const theme = useTheme();

  return (
    <Collapse
      orientation="horizontal"
      in={isOpen}
      sx={{
        borderLeft: `1px solid ${theme.palette.divider}`,
      }}
      timeout={700}
      data-testid="INFO_DRAWER_COLLAPSE"
    >
      <Box minWidth="300px" display="flex" flexDirection="column" height="100%">
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX={3}
            minHeight="71px"
          >
            <Typography variant="h5">{title}</Typography>
            <Button
              variant="text"
              size="large"
              startEvaIcon={{
                name: 'close-outline',
                fill: theme.palette.primary.main,
              }}
              onClick={onClose}
              data-testid="INFO_DRAWER_CLOSE_BUTTON"
            >
              CLOSE
            </Button>
          </Box>
          <Divider variant="fullWidth" />
        </Box>
        <Box flexGrow={1} overflow="auto">
          {children}
        </Box>
      </Box>
    </Collapse>
  );
};

export default InfoDrawer;
