import {
  durationLabelByValue,
  frequencyLabelByValue,
  locationLabelByValue,
  reminderLabelByValue,
  Select,
  FrequencyInput,
  TimeInput,
  DatePicker,
} from '@fdha/web-ui-library';
import { Box, Paper, styled, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { PropsWithChildren } from 'react';
import {
  ActivityFrequency,
  ActivityReminder,
  AppointmentDuration,
  AppointmentLocation,
  WeekDay,
} from '@fdha/graphql-api-admin';

interface ActivityPreferences {
  date: Date | null;
  time: string;
  ends: 'never' | 'date';
  ends_at?: Date;
  dayPeriod: 'am' | 'pm';
  duration: AppointmentDuration;
  frequency: ActivityFrequency;
  reminder: ActivityReminder;
  weekDays?: WeekDay[];
  location: AppointmentLocation;
}

const ContentPaper = styled(Paper)(() => ({
  padding: '16px',
  margin: '32px 0',
  flexWrap: 'wrap',
}));

const FormItem = styled(Box)(() => ({
  marginTop: '16px',
}));

const Date = () => {
  const {
    values: { date, frequency },
    errors,
    setFieldValue,
  } = useFormikContext<ActivityPreferences>();

  const label = frequency === ActivityFrequency.Once ? 'Date' : 'Start Date';

  return (
    <FormItem>
      <DatePicker
        title={label}
        value={date}
        error={!!errors.date}
        helperText={errors.date}
        onChange={(value) => {
          setFieldValue('date', value);
        }}
      />
    </FormItem>
  );
};

const Time = () => {
  const {
    values: { time, dayPeriod },
    errors,
    setFieldValue,
  } = useFormikContext<ActivityPreferences>();

  return (
    <FormItem>
      <TimeInput
        time={time}
        dayPeriod={dayPeriod}
        error={errors.time}
        onChange={setFieldValue}
        caption="This will be assigned in the coach's local time zone"
      />
    </FormItem>
  );
};

const Duration = () => {
  const {
    values: { duration },
    setFieldValue,
  } = useFormikContext<ActivityPreferences>();

  const durationOptions = Object.entries(AppointmentDuration).map(
    ([key, value]) => ({
      label: durationLabelByValue[value].label,
      value: key,
    })
  );

  return (
    <FormItem>
      <Select
        options={durationOptions}
        title="Duration"
        value={duration}
        onChange={(option) => setFieldValue('duration', option.target.value)}
      />
    </FormItem>
  );
};

const Frequency = () => {
  const {
    values: { frequency, weekDays },
    initialValues: { weekDays: initialWeekDays },
    setFieldValue,
  } = useFormikContext<ActivityPreferences>();

  const frequencyOptions = Object.entries(ActivityFrequency).map(
    ([key, value]) => ({
      label: frequencyLabelByValue[value].label,
      value: key,
    })
  );

  return (
    <FormItem>
      <FrequencyInput
        frequency={frequency}
        weekDays={weekDays}
        initialWeekDays={initialWeekDays}
        frequencyOptions={frequencyOptions}
        setFieldValue={setFieldValue}
      />
    </FormItem>
  );
};

const Location = () => {
  const {
    values: { location },
    setFieldValue,
  } = useFormikContext<ActivityPreferences>();

  const locationOptions = Object.entries(AppointmentLocation).map(
    ([key, value]) => ({
      label: locationLabelByValue[value].label,
      value: key,
    })
  );

  return (
    <FormItem>
      <Select
        options={locationOptions}
        title="Location"
        value={location}
        onChange={(option) => setFieldValue('location', option.target.value)}
      />
    </FormItem>
  );
};

const Reminder = () => {
  const {
    values: { reminder },
    setFieldValue,
  } = useFormikContext<ActivityPreferences>();

  const reminderOptions = Object.entries(ActivityReminder).map(
    ([key, value]) => ({
      label: reminderLabelByValue[value].label,
      value: key,
    })
  );

  return (
    <FormItem>
      <Select
        options={reminderOptions}
        title="Reminder"
        value={reminder}
        onChange={(option) => setFieldValue('reminder', option.target.value)}
      />
    </FormItem>
  );
};

const EndsAt = () => {
  const {
    values: { ends, ends_at },
    setFieldValue,
    errors,
  } = useFormikContext<ActivityPreferences>();

  const options = [
    {
      value: 'never',
      label: 'Never',
    },
    {
      value: 'date',
      label: 'On date',
    },
  ];

  return (
    <FormItem>
      <Select
        options={options}
        title="Ends"
        value={ends}
        onChange={(option) => {
          if (option.target.value === 'never') {
            setFieldValue('ends_at', undefined);
          }
          setFieldValue('ends', option.target.value);
        }}
      />
      {ends === 'date' && (
        <DatePicker
          value={ends_at}
          onChange={(value) => {
            setFieldValue('ends_at', value);
          }}
          error={!!errors.ends_at}
          helperText={errors.ends_at}
          sx={{ mt: 2 }}
        />
      )}
    </FormItem>
  );
};

const ActivityFormPreferences: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ContentPaper>
      <Typography variant="h6">Preferences</Typography>
      {children}
    </ContentPaper>
  );
};

export default Object.assign(ActivityFormPreferences, {
  Date,
  Time,
  Duration,
  Frequency,
  Location,
  Reminder,
  EndsAt,
});
