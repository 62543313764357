import React from 'react';
import { Box } from '@mui/material';
import { BasePage, Loader, RestrictedUser } from '@fdha/web-ui-library';
import { useGetCommunityUserQuery } from '@fdha/graphql-api-admin';
import { useNavigate } from 'react-router';
import fdhaConfig from '@fdha/aws-config-admin';

import CommunityWrapper from './CommunityWrapper';

const RestrictedPage = () => {
  const { data, loading } = useGetCommunityUserQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });
  const navigate = useNavigate();

  const reason = data?.getCommunityUser?.restrictionReason ?? '';

  if (loading) return <Loader />;

  return (
    <BasePage data-testid="COMMUNITY_PANEL">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        flexDirection="column"
      >
        <CommunityWrapper spacing={3}>
          <RestrictedUser
            reason={reason}
            guidelinesLink={`https://${fdhaConfig.cloudfront}/FDHACommunityGuidelines.html`}
            onLeave={() => navigate('/')}
          />
        </CommunityWrapper>
      </Box>
    </BasePage>
  );
};

export default RestrictedPage;
