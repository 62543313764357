export const listeners: Map<string, Array<(item?: any) => void>> = new Map();

export function addItemListener<T>(key: string, cb: (item?: T) => void) {
  const itemListeners = listeners.get(key) || [];
  listeners.set(key, [...itemListeners, cb]);
}

export function removeItemListener<T>(key: string, cb: (item?: T) => void) {
  const itemListeners = listeners.get(key) || [];
  const index = itemListeners.indexOf(cb);
  if (index > -1) {
    listeners.set(key, [
      ...itemListeners.slice(0, index),
      ...itemListeners.slice(index + 1),
    ]);
  }
}
