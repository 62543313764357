import React, { useEffect } from 'react';
import { Box, Paper as MuiPaper, styled, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';
import { Button, useSnackbar } from '@fdha/web-ui-library';
import { useReactiveVar } from '@apollo/client';
import {
  convertQuestionGroupedPropsToQuestionSpreadProps,
  convertQuestionSpreadPropsToQuestionSplittedProps,
  parseBackendError,
} from '@fdha/common-utils';
import {
  ListNotesTemplatesDocument,
  NotesTemplate,
  useAddNotesTemplateMutation,
  useUpdateNotesTemplateMutation,
} from '@fdha/graphql-api-admin';

import BasePage from '../../../../components/BasePage/BasePage';
import FormBuilder from '../../../../components/FormBuilder/FormBuilder';
import {
  questions as questionsState,
  replaceQuestions,
  setQuestionInitialValue,
} from '../../../../states/questionBuilderState';
import { validate } from '../../../../utils/createValidation';

import { INITIAL_NOTES_TEMPLATES } from './NotesTab';

const Paper = styled(MuiPaper)(({ theme }) => ({
  width: '100%',
  height: '98px',
  padding: theme.spacing(3),
  marginBottom: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '0.5px solid',
  borderColor: theme.palette.divider,
}));

const ContainedButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  padding: '8px 24px',
}));

interface StateProps {
  notesTemplate: NotesTemplate;
}

const CreateNotes = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();

  const questions = useReactiveVar(questionsState);

  const [addNotesTemplate] = useAddNotesTemplateMutation();
  const [updateNotesTemplate] = useUpdateNotesTemplateMutation();

  const state = location.state as StateProps;
  const notesTemplate = state?.notesTemplate;

  useEffect(() => {
    if (notesTemplate) {
      replaceQuestions(
        notesTemplate.questions.map((question) => ({
          ...convertQuestionGroupedPropsToQuestionSpreadProps(question),
          name: question.title,
        }))
      );
    } else {
      setQuestionInitialValue();
    }
  }, [notesTemplate]);

  const handleGoBack = () => {
    navigate(-1);
    setQuestionInitialValue();
  };

  const showErrorSnackbar = (message: string) => {
    showSnackbar({ message, severity: 'error' });
  };

  const getRefetchQueries = () => [
    {
      query: ListNotesTemplatesDocument,
      variables: { first: INITIAL_NOTES_TEMPLATES },
    },
  ];

  const handleSubmit = async (values: { name: string }) => {
    const newTemplate = {
      ...values,
      questions: questions.map((question) =>
        omit(convertQuestionSpreadPropsToQuestionSplittedProps(question), [
          'id',
        ])
      ),
    };

    try {
      if (notesTemplate) {
        await updateNotesTemplate({
          variables: { id: notesTemplate.id, template: newTemplate },
          refetchQueries: getRefetchQueries(),
        });
      } else {
        await addNotesTemplate({
          variables: { template: newTemplate },
          refetchQueries: getRefetchQueries(),
        });
      }
      handleGoBack();
    } catch (error) {
      console.error(error);
      const message = parseBackendError(
        error,
        `Error to ${notesTemplate ? 'update' : 'create new'} template`
      );

      showErrorSnackbar(message);
    }
  };

  return (
    <BasePage>
      <Formik
        initialValues={{ name: notesTemplate?.name || '' }}
        validate={(values) => validate(values, questions, showErrorSnackbar)}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, isSubmitting }) => {
          const errorState = touched.name && errors.name;

          return (
            <Form>
              <Paper>
                <TextField
                  name="name"
                  placeholder="Notes template name"
                  value={values.name}
                  onChange={handleChange}
                  error={!!errorState}
                  helperText={errorState}
                  data-testid="INPUT_NOTES_TEMPLATE_NAME"
                />
                <Box>
                  <Button
                    onClick={handleGoBack}
                    data-testid="CANCEL_BUTTON_CREATE_NOTES_TEMPLATE"
                  >
                    Cancel
                  </Button>
                  <ContainedButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                    data-testid="SAVE_BUTTON_CREATE_NOTES_TEMPLATE"
                  >
                    Save
                  </ContainedButton>
                </Box>
              </Paper>
            </Form>
          );
        }}
      </Formik>
      <FormBuilder />
    </BasePage>
  );
};

export default CreateNotes;
