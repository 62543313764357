import { Box, CardActionArea } from '@mui/material';
import React, { FC } from 'react';

import { Typography } from '../Typography/Typography';
import { MealCard } from '../MealCard/MealCard';
import { MenuOptionSelection } from '../../utils';

export interface SelectedMenuSectionProps {
  mealType: string;
  options?: MenuOptionSelection[];
  onClick?: (dishName: string, dishId: string) => void;
  hideMealType?: boolean;
}

export const SelectedMenuSection: FC<SelectedMenuSectionProps> = ({
  mealType,
  options,
  onClick,
  hideMealType = false,
}) => {
  const i18nKeySection =
    mealType === 'Snacks and Sides'
      ? 'meals:deliveryPreview.selection.snacksSides'
      : mealType === 'Snacks'
      ? 'meals:deliveryPreview.selection.snacks'
      : 'meals:deliveryPreview.selection.entrees';

  return (
    <Box mt={1.5}>
      <Typography variant="h6" i18nKey={i18nKeySection}>
        Selection for <strong>{mealType}</strong>
      </Typography>
      {options?.map((opt) => (
        <CardActionArea
          key={opt.dishId}
          onClick={() => onClick && onClick(opt.dishId, opt.name)}
          disabled={!onClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 1.5,
          }}
        >
          <MealCard
            name={opt.name}
            tags={!hideMealType ? opt.mealType : undefined}
            photoUrl={opt.photoUrl}
            imageSize="60px"
          />
          <Typography>{opt.quantity}x</Typography>
        </CardActionArea>
      ))}
    </Box>
  );
};
