import { FormikErrors } from 'formik';

import { QuestionCardType } from '../models/FormBuilderProps';

const validateConditionalLogic = (
  questions: QuestionCardType[],
  errors: FormikErrors<{ name: string; questions: string }>,
  showErrorSnackbar: (error: string) => void
) => {
  questions.forEach((question, index) => {
    let hasLogicValue = false;
    let hasMissingLogicValue = false;
    const logic = {
      conditionQuestion: question.conditionQuestion?.value,
      condition: question.condition,
      conditionAnswer: question.conditionAnswer,
      action: question.action,
    };

    Object.entries(logic).forEach(([_, value]) => {
      if (value) {
        hasLogicValue = true;
      } else {
        hasMissingLogicValue = true;
      }
    });

    const conditionQuestionIndex = question.conditionQuestion?.index;

    if (
      conditionQuestionIndex !== undefined &&
      conditionQuestionIndex !== null &&
      conditionQuestionIndex >= index
    ) {
      errors.questions =
        'Make sure all questions with conditional logic depend on the one above it in the list';
      showErrorSnackbar(errors.questions);
    } else if (hasLogicValue && hasMissingLogicValue) {
      errors.questions = 'There are incomplete conditional logic fields';
      showErrorSnackbar(errors.questions);
    }
  });
};

export const validate = (
  values: { name: string },
  questions: QuestionCardType[],
  showErrorSnackbar: (error: string) => void
) => {
  let errors: FormikErrors<{ name: string; questions: string }> = {};
  const editingQuestion = questions.find((question) => question.isEditing);

  if (!values.name.trim()) {
    errors.name = 'This field is required';
  } else if (!questions.length) {
    errors.questions = 'It is required to create at least one question';
    showErrorSnackbar(errors.questions);
  } else if (editingQuestion) {
    errors.questions = "There are questions that haven't been saved yet";
    showErrorSnackbar(errors.questions);
  }

  validateConditionalLogic(questions, errors, showErrorSnackbar);

  return errors;
};
