import { useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';

import { Typography } from '../Typography/Typography';

type TimeAgoProps = {
  date: string;
  textOverrideFn?(originalText: string): string;
};

const TimeAgo: React.FC<TimeAgoProps> = ({ date, textOverrideFn }) => {
  const theme = useTheme();

  const getTimeAgo = () => {
    const offset = moment().utcOffset();
    const timeAgo = moment.utc(date).utcOffset(offset);
    if (moment().diff(timeAgo, 'minutes') < 1) {
      return 'A few seconds ago';
    } else if (moment().diff(timeAgo, 'hours') < 1) {
      const minutes = moment().diff(timeAgo, 'minutes');
      return `${minutes}min ago`;
    } else if (moment().diff(timeAgo, 'hours') < 24) {
      const hours = moment().diff(timeAgo, 'hours');
      return `${hours}h ago`;
    } else if (timeAgo.isSame(moment().subtract(1, 'day'), 'day')) {
      return `Yesterday at ${timeAgo.format('hh:mm A')}`;
    } else if (timeAgo.isSame(moment(), 'year')) {
      return timeAgo.format('MMMM D [at] hh:mm A');
    } else {
      return timeAgo.format('MMMM D, YYYY');
    }
  };

  const originalText = getTimeAgo();

  const finalText = textOverrideFn
    ? textOverrideFn(originalText)
    : originalText;

  return (
    <Typography variant="body2" color={theme.palette.text.secondary}>
      {finalText}
    </Typography>
  );
};

export default TimeAgo;
