import React, { FC } from 'react';
import { Paper, Box, useTheme, Typography } from '@mui/material';
import { Button, Icon } from '@fdha/web-ui-library';
import {
  CommunityUser,
  GetCommunityUserDocument,
} from '@fdha/graphql-api-admin';

import { useCommunity } from '../../hooks';

interface CommunityProfileCardProps {
  profile: CommunityUser;
}

const CommunityProfileCard: FC<CommunityProfileCardProps> = ({ profile }) => {
  const theme = useTheme();
  const { onliftRestrictionButton, onRestrictButton } = useCommunity([
    GetCommunityUserDocument,
  ]);

  const isRestricted = profile.isBanned;

  const iconName = isRestricted ? 'slash-outline' : 'checkmark-outline';
  const buttonIconName = isRestricted ? 'person-done-outline' : 'slash-outline';
  const labelColor = isRestricted
    ? theme.palette.error.main
    : theme.palette.success.dark;

  return (
    <Paper
      sx={{ boxShadow: theme.shadows[2], mt: 3 }}
      data-testid="COMMUNITY_PROFILE_CARD"
    >
      <Box
        padding={2}
        bgcolor={theme.palette.secondary.light}
        borderRadius="15px 15px 0px 0px"
        borderBottom={`0.5px solid ${theme.palette.divider}`}
      >
        <Typography variant="h6">Community</Typography>
        <Box display="flex" flexDirection="row">
          <Icon name={iconName} size="medium" fill={labelColor} />
          <Typography
            variant="subtitle2"
            color={labelColor}
            lineHeight={1.2}
            ml={0.2}
          >
            {isRestricted ? 'RESTRICTED' : 'ACTIVE MEMBER'}
          </Typography>
        </Box>
      </Box>
      <Box padding={2}>
        <Button
          variant="outlined"
          startIcon={
            <Icon
              name={buttonIconName}
              size="medium"
              fill={theme.palette.primary.main}
            />
          }
          onClick={() => {
            isRestricted
              ? onliftRestrictionButton(profile)
              : onRestrictButton(profile);
          }}
        >
          {isRestricted ? 'LIFT RESTRICTION' : 'RESTRICT USER FROM COMMUNITY'}
        </Button>
      </Box>
    </Paper>
  );
};

export default CommunityProfileCard;
