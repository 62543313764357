import React, { Dispatch, SetStateAction, useState } from 'react';
import { Accordion } from '@fdha/web-ui-library';
import { PatientUser } from '@fdha/graphql-api-admin';

import ViewModeAccountInformation from './ViewModeAccountInformation';
import EditModeAccountInformation from './EditModeAccountInformation';

interface AccountInformationProps {
  patientUser?: PatientUser | null;
  isCsr?: boolean;
}

export interface ViewEditAccountInformationProps
  extends AccountInformationProps {
  handleEditMode: Dispatch<SetStateAction<boolean>>;
}

const AccountInformation = (props: AccountInformationProps) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Accordion
      title="Account information"
      data-testid="ACCOUNT_INFO"
      sx={{ px: 1 }}
    >
      {editMode ? (
        <EditModeAccountInformation
          patientUser={props.patientUser}
          isCsr={props.isCsr}
          handleEditMode={setEditMode}
        />
      ) : (
        <ViewModeAccountInformation
          patientUser={props.patientUser}
          isCsr={props.isCsr}
          handleEditMode={setEditMode}
        />
      )}
    </Accordion>
  );
};

export default AccountInformation;
