import { useGetDishQuery } from '@fdha/graphql-api-admin';
import {
  DishInformation,
  EmptyStateCard,
  LoadingState,
} from '@fdha/web-ui-library';
import { Box } from '@mui/material';
import React, { FC } from 'react';

interface DishDetailsProps {
  dishId: string;
}

const DishDetails: FC<DishDetailsProps> = ({ dishId }) => {
  const { data, loading } = useGetDishQuery({
    variables: { id: dishId },
    fetchPolicy: 'cache-and-network',
  });

  const dish = data?.dish;

  const tags = dish?.tags || [];

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Box>
      {dish ? (
        <DishInformation
          name={dish.name}
          description={dish.description}
          ingredients={dish.ingredients}
          photoUrl={dish.photoUrl}
          nutritionalValuesUrl={dish.nutritionalValuesUrl}
          tags={tags}
        />
      ) : (
        <EmptyStateCard message="This dish doesn't exist." />
      )}
    </Box>
  );
};

export default DishDetails;
