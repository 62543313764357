import { getToken } from '@fdha/common-utils';
import Axios from 'axios';
import fdhaConfig from '@fdha/aws-config-admin';

const apiKey = fdhaConfig.api.apiKey;

export const doGet = async (url: string, authenticated: boolean = false) => {
  const headers: HeadersInit = {
    'x-api-key': apiKey,
  };

  if (authenticated) {
    const token = await getToken();
    headers.Authorization = 'Bearer ' + token;
  }

  try {
    const response = await Axios.get(url, {
      headers,
    });

    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const doPost = async (
  url: string,
  data?: any,
  authenticated: boolean = false
) => {
  const headers: HeadersInit = {
    'x-api-key': apiKey,
  };

  if (authenticated) {
    const token = await getToken();
    headers.Authorization = 'Bearer ' + token;
  }

  try {
    const response = await Axios.post(url, data, {
      headers,
    });

    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const doPut = async (
  url: string,
  data?: any,
  authenticated: boolean = false
) => {
  const headers: HeadersInit = data.type
    ? {
        'x-api-key': apiKey,
        'Content-type': data.type,
      }
    : {
        'x-api-key': apiKey,
      };

  if (authenticated) {
    const token = await getToken();
    headers.Authorization = 'Bearer ' + token;
  }

  try {
    const response = await Axios.put(url, data, {
      headers,
    });

    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
