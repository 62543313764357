import { UserType } from '@fdha/graphql-api-admin';

export const userTypeLabelByValue: Record<UserType, string> = {
  [UserType.Caretaker]: 'Caretaker',
  [UserType.CustomerService]: 'Customer Service',
  [UserType.ClinicalTrialPatient]: 'CT Patient',
  [UserType.Coach]: 'Coach',
  [UserType.Patient]: 'DH Patient',
  [UserType.Foodops]: 'Food Ops',
  [UserType.SiteStaff]: 'Site Staff',
};
