import { makeVar } from '@apollo/client';
import { Activity } from '@fdha/graphql-api-admin';

const selectedActivity = makeVar<Activity | null>(null);

const updateSelectedActivity = (activity: Partial<Activity>) => {
  const currentValue = selectedActivity();
  if (currentValue) {
    selectedActivity({ ...currentValue, ...activity });
  }
};
const setSelectedActivity = (activity?: Activity) => {
  selectedActivity(activity);
};
const resetSelectedActivity = () => {
  selectedActivity(null);
};

export {
  selectedActivity,
  setSelectedActivity,
  updateSelectedActivity,
  resetSelectedActivity,
};
