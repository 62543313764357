import React, { FC } from 'react';
import { Loader } from '@fdha/web-ui-library';
import { Box, Stack, Typography } from '@mui/material';
import { useListProjectedDeliveryMenusQuery } from '@fdha/graphql-api-admin';

import { WeekMenuCard } from '../../../../../components';

interface WeekMenuProps {
  patientId?: string;
}

const WeekMenus: FC<WeekMenuProps> = ({ patientId = '' }) => {
  const { data, loading } = useListProjectedDeliveryMenusQuery({
    variables: {
      patientId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !patientId,
  });

  const projectedDeliveryMenus = data?.projectedDeliveryMenus;

  const renderEmptyState = () => (
    <Box display="flex" justifyContent="center">
      <Typography variant="body2">No week menus available.</Typography>
    </Box>
  );

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : !projectedDeliveryMenus?.length ? (
        renderEmptyState()
      ) : (
        <Stack spacing={2}>
          {projectedDeliveryMenus?.map((projectedDeliveryMenu) => (
            <WeekMenuCard
              key={projectedDeliveryMenu.id}
              projectedDeliveryMenu={projectedDeliveryMenu}
              patientId={patientId}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default WeekMenus;
