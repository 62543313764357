import React, { FC, useMemo } from 'react';
import { FormBuilderQuestionType } from '@fdha/graphql-api-admin';

import { QuestionCardType } from '../../../models/FormBuilderProps';
import { QuestionField } from '../../index';

export interface PreviewQuestionContentProps {
  item: QuestionCardType;
  index: number;
}

const PreviewQuestionContent: FC<PreviewQuestionContentProps> = ({
  item,
  index,
}) => {
  const possibleAnswers = useMemo(
    () =>
      item.possibleAnswers?.map((possibleAnswer) => ({
        label: possibleAnswer,
        value: possibleAnswer,
      })) ?? [],
    [item.possibleAnswers]
  );

  const renderQuestion = () => {
    const questionNumber = index + 1;
    const commonProps = {
      number: questionNumber,
      title: item.title,
      helperText: item.helpText,
      dataTestIdPrefix: 'FORM_BUILDER',
    };

    switch (item.type) {
      case FormBuilderQuestionType.OpenText:
      case FormBuilderQuestionType.Number:
        return (
          <QuestionField
            type="openText"
            textFieldProps={{
              value: '',
              placeholder: item.placeholderText || '',
            }}
            {...commonProps}
          />
        );
      case FormBuilderQuestionType.MultipleChoice: {
        return (
          <QuestionField
            type="multipleChoice"
            checkboxGroupProps={{ value: {}, options: possibleAnswers }}
            {...commonProps}
          />
        );
      }
      case FormBuilderQuestionType.SingleChoice:
      case FormBuilderQuestionType.Binary: {
        return (
          <QuestionField
            type="singleChoice"
            radioGroupProps={{ value: '', options: possibleAnswers }}
            {...commonProps}
          />
        );
      }
      case FormBuilderQuestionType.Scale:
        return (
          <QuestionField
            type="scale"
            scaleProps={{ value: '', options: possibleAnswers }}
            {...commonProps}
          />
        );
      case FormBuilderQuestionType.Date:
        return (
          <QuestionField
            type="date"
            datePickerProps={{ value: null }}
            {...commonProps}
          />
        );
    }
  };

  return <>{renderQuestion()}</>;
};

export default PreviewQuestionContent;
