import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  AutocompleteProps,
  requiredMessage,
} from '@fdha/web-ui-library';

import { EditableCard, EditableCardProps } from './EditableCard';

type AutocompleteValue = AutocompleteProps<false, false>['value'];

interface EditableCardAutocompleteSchema {
  option: AutocompleteValue | null;
}

interface EditableCardAutocompleteProps extends EditableCardProps {
  initialValue: AutocompleteValue;
  required?: boolean;
  onSave: (value: AutocompleteValue) => Promise<void>;
  autocompleteProps: Omit<
    AutocompleteProps<false, false>,
    'value' | 'onChange'
  >;
}

const validationSchema = Yup.object().shape({
  option: Yup.object().nullable().required(requiredMessage),
});

export const EditableCardAutocomplete: React.FC<
  EditableCardAutocompleteProps
> = ({
  initialValue,
  required,
  onSave,
  autocompleteProps,
  ...editableCardProps
}) => {
  const titleId = editableCardProps.title.toUpperCase().replaceAll(' ', '_');

  const initialValues: EditableCardAutocompleteSchema = {
    option: initialValue,
  };

  const handleSubmit = async (values: EditableCardAutocompleteSchema) => {
    await onSave(values.option);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={required ? validationSchema : undefined}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <EditableCard {...editableCardProps}>
          <Autocomplete
            placeholder={`${editableCardProps.title} Name`}
            data-testid={`${titleId}_AUTOCOMPLETE`}
            error={!!errors.option}
            helperText={errors.option}
            {...autocompleteProps}
            value={values.option}
            onChange={(_, newValue) => {
              setFieldValue('option', newValue);
            }}
          />
        </EditableCard>
      )}
    </Formik>
  );
};

export default EditableCardAutocomplete;
