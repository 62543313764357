import React from 'react';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import {
  Button,
  useDialog,
  useSnackbar,
  convertMaskToE164,
} from '@fdha/web-ui-library';
import {
  ListCoachUsersDocument,
  useAddCoachUserMutation,
  UserType,
} from '@fdha/graphql-api-admin';

import AddCoachFormPreferences, {
  UserPreferences,
  validationSchema,
} from '../AddUserFormPreferences';

const AddCustomerServiceDialog = () => {
  const { showSnackbar } = useSnackbar();
  const { closeDialog } = useDialog();

  const [addCoachUser] = useAddCoachUserMutation();

  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
  };

  const handleSubmit = async (values: UserPreferences) => {
    try {
      const user = {
        name: values.name,
        email: values.email,
        phone_number: convertMaskToE164(values.phoneNumber),
        type: UserType.CustomerService,
      };

      await addCoachUser({
        variables: { newUser: user },
        refetchQueries: [ListCoachUsersDocument],
      });
      showSnackbar({
        severity: 'success',
        message: 'Customer Service Representative added with success',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error while adding Customer Service Representative',
      });
    } finally {
      closeDialog();
    }
  };

  const onCancel = () => {
    showSnackbar({
      message: 'Changes not saved',
      severity: 'warning',
    });
    closeDialog();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <AddCoachFormPreferences>
            <AddCoachFormPreferences.Name />
            <AddCoachFormPreferences.Email />
            <AddCoachFormPreferences.PhoneNumber />
          </AddCoachFormPreferences>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={onCancel} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!isValid || isSubmitting}
              sx={{ paddingY: 1, paddingX: '22px' }}
            >
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddCustomerServiceDialog;
