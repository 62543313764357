export const mainGoalList = [
  {
    label: '1-on-1 support from a Coach',
    value: 'support',
    i18nKey: 'profile:medicalInformation.input.goal.options.support',
  },
  {
    label: 'Learn about my condition',
    value: 'learn',
    i18nKey: 'profile:medicalInformation.input.goal.options.learn',
  },
  {
    label: 'Connect with other patients',
    value: 'connect',
    i18nKey: 'profile:medicalInformation.input.goal.options.connect',
  },
  {
    label: 'Track my health routine',
    value: 'track',
    i18nKey: 'profile:medicalInformation.input.goal.options.track',
  },
];
