import React from 'react';
import Icon from 'react-eva-icons';
import { Table, Loader } from '@fdha/web-ui-library';
import { getContentfulEnv } from '@fdha/common-e2e';
import { grey } from '@mui/material/colors';
import { Paper, TableCell, TableRow, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import {
  AssignType,
  DetailedLearningModule,
  UserType,
  useGetLearningModuleQuery,
  useGetPatientUserQuery,
} from '@fdha/graphql-api-admin';

import {
  getHeaderCells,
  renderTableCells,
} from '../../../../utils/learningData';
import { useGetUserType } from '../../../../hooks';

import LearningModule from './LearningModule/LearningModule';

const LearningPreview = () => {
  const { learningId, patientId } = useParams();
  const contentfulEnv = getContentfulEnv();
  const { data, loading } = useGetLearningModuleQuery({
    variables: {
      learningModuleId: learningId || '',
      patientId: patientId || '',
      ...(contentfulEnv && { contentfulEnv }),
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: patientData, loading: loadingPatient } = useGetPatientUserQuery(
    {
      variables: {
        id: patientId || '',
      },
    }
  );

  const { isCsr } = useGetUserType();

  if (loading || loadingPatient) {
    return <Loader />;
  }

  if (!data?.learningModule) {
    return (
      <Typography>{`No learning module found with ID ${learningId}`}</Typography>
    );
  }

  const renderTableRow = (row: DetailedLearningModule) => {
    const isAutoAssigned =
      row?.assignType === AssignType.DhAndCaretaker &&
      [UserType.Caretaker, UserType.Patient].includes(
        patientData?.patientUser?.type as UserType
      );

    return (
      <TableRow>
        <TableCell>
          <Link to="../learning" data-testid="LEARNING_PREVIEW_BUTTON">
            <Icon name="arrow-ios-back-outline" fill={grey[600]} size="large" />
          </Link>
        </TableCell>
        {renderTableCells(row, patientId, isCsr, isAutoAssigned)}
      </TableRow>
    );
  };

  return (
    <>
      <Paper sx={{ marginBottom: 3 }} data-testid="LEARNING_PREVIEW_TABLE">
        <Table<DetailedLearningModule>
          actions="left"
          headCells={getHeaderCells<DetailedLearningModule>()}
          renderRow={renderTableRow}
          rows={[data.learningModule]}
        />
      </Paper>
      <LearningModule data={data.learningModule} />
    </>
  );
};

export default LearningPreview;
