import { Box, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import {
  ChannelListMessengerProps,
  LoadingIndicator,
  useChatContext,
} from 'stream-chat-react';

import Avatar from '../../Avatar/Avatar';
import ChannelSearch from '../ChannelSearch/ChannelSearch';

import StyledChannelListWrapper from './styles';

const List: React.FC<PropsWithChildren<ChannelListMessengerProps>> = (
  props
) => {
  const { client } = useChatContext();
  const { image, name } = client.user || {};
  const { children, error, loading } = props;

  let content: React.ReactNode;
  if (error) {
    content = 'Error loading conversations, please try again momentarily.';
  } else if (loading) {
    content = <LoadingIndicator />;
  } else {
    content = children;
  }

  return (
    <StyledChannelListWrapper data-testid="LEFT_NAV">
      <Box
        alignItems="center"
        display="flex"
        paddingY="6px"
        marginLeft="24px"
        marginRight="22px"
      >
        <Avatar
          data-testid="CHANNEL_LIST_HEADER_AVATAR"
          picture={image}
          name={name}
          size={40}
        />
        <Typography noWrap variant="h5" sx={{ marginLeft: 2 }}>
          {name}
        </Typography>
      </Box>
      <ChannelSearch />
      <Box height="100%" overflow="auto" paddingLeft="24px" paddingRight="22px">
        {content}
      </Box>
    </StyledChannelListWrapper>
  );
};

export default React.memo(List);
