import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  Button,
  TextField,
  useSnackbar,
  formatDate,
  addPhoneNumberValidation,
  requiredMessage,
  MaskedTextField,
  convertMaskToE164,
  maskPhone,
} from '@fdha/web-ui-library';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import { parseISO } from 'date-fns';
import * as Yup from 'yup';
import { useUpdateFoodOpsUserMutation } from '@fdha/graphql-api-admin';

import { ProfileInformationProps } from './ProfileInformation';

interface Information {
  name?: string;
  email?: string;
  phoneNumber?: string;
}

const EditModeProfileInformation = (props: ProfileInformationProps) => {
  const { showSnackbar } = useSnackbar();

  const [updateFoodOpsUser] = useUpdateFoodOpsUserMutation();

  const handleCancel = () => {
    props.handleEditMode(false);
    showSnackbar({
      severity: 'warning',
      message: 'Changes not saved',
    });
  };

  const handleSave = async (values: Information) => {
    try {
      await updateFoodOpsUser({
        variables: {
          id: props.foodopsUser?.id || '',
          userProps: {
            name: values.name,
            email: values.email,
            phone_number: convertMaskToE164(values.phoneNumber),
          },
        },
      });
      props.handleEditMode(false);
      showSnackbar({
        severity: 'success',
        message: 'Changes saved!',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update food ops user data',
      });
    }
  };

  const initialValues: Information = {
    name: props.foodopsUser?.name,
    email: props.foodopsUser?.email,
    phoneNumber: maskPhone(props.foodopsUser?.phone_number),
  };

  const handleErrors = (
    errors: FormikErrors<Information>,
    touched: FormikTouched<Information>
  ) => {
    return {
      name: touched.name && errors.name,
      email: touched.email && errors.email,
      phoneNumber: touched.phoneNumber && errors.phoneNumber,
    };
  };

  const foodopsValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required(requiredMessage),
    email: Yup.string().trim().email().required(requiredMessage),
    phoneNumber: addPhoneNumberValidation().required(requiredMessage),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={foodopsValidationSchema}
      onSubmit={handleSave}
    >
      {({ values, handleChange, isSubmitting, errors, touched }) => {
        const errorState = handleErrors(errors, touched);

        return (
          <Form>
            <Box marginBottom={3}>
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={4}>
                  <TextField
                    title="Name"
                    value={values.name}
                    name="name"
                    onChange={handleChange}
                    error={!!errorState.name}
                    helperText={errorState.name}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Email"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    error={!!errorState.email}
                    helperText={errorState.email}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MaskedTextField
                    title="Phone number"
                    mask="phoneNumber"
                    placeholder="000-000-0000"
                    value={values.phoneNumber}
                    name="phoneNumber"
                    onChange={handleChange}
                    error={!!errorState.phoneNumber}
                    helperText={errorState.phoneNumber}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Joined on"
                    value={formatDate(parseISO(props.foodopsUser?.created_at))}
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            <Box textAlign="end">
              <Button
                onClick={handleCancel}
                sx={{ padding: '8px 34px' }}
                data-testid="CANCEL_EDITION_BUTTON"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: '8px 34px' }}
                type="submit"
                data-testid="SAVE_EDITION_BUTTON"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditModeProfileInformation;
