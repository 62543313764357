import { Box, Stack, TextField, useTheme } from '@mui/material';
import Linkify from 'linkify-react';
import React, { FC } from 'react';

import { handleClickProfile, Comment, timeAgoOverrideFn } from '../../utils';
import { Avatar } from '../Avatar/Avatar';
import { Button } from '../Button/Button';
import { IconMenu } from '../Menu/IconMenu';
import ModeratorLabel from '../ModeratorLabel/ModeratorLabel';
import TimeAgo from '../TimeAgo/TimeAgo';
import RestrictedLabel from '../RestrictedLabel/RestrictedLabel';
import { Typography } from '../Typography/Typography';

import { CommunityUser } from './Post';

export interface CommentCardProps {
  comment: Comment;
  isEditing: boolean;
  editError?: string;
  editText: string;
  isMyComment: boolean;
  isModerator: boolean;
  showModeratorLabel: boolean;
  editOnChange(e: React.ChangeEvent<any>): void;
  editOnCancel(): void;
  editOnSave(): void;
  onRestrict?: (user: CommunityUser) => void;
  onLiftRestriction?: (user: CommunityUser) => void;
  onEdit(comment: Comment): void;
  onDelete(comment: Comment): void | Promise<void>;
  onRemove?: (comment: Comment) => void | Promise<void>;
  onClickProfile?: (url: string) => void;
  v2?: boolean;
}

export const CommentCard: FC<CommentCardProps> = ({
  comment,
  isMyComment,
  isModerator,
  showModeratorLabel,
  isEditing,
  editError,
  editText,
  v2,
  onEdit,
  onDelete,
  onRemove,
  onRestrict,
  onLiftRestriction,
  editOnChange,
  editOnCancel,
  editOnSave,
  onClickProfile,
}) => {
  const theme = useTheme();
  const cursor = isModerator ? 'pointer' : 'default';
  const isBanned = comment.user.isBanned;

  /************ V2 updates *********************/

  const cancelButtonLabel = v2 ? 'Cancel' : 'CANCEL';
  const saveChangesButtonLabel = v2 ? 'Save Changes' : 'SAVE CHANGES';
  const buttonColor = v2 ? undefined : 'secondary';

  /*********************************************/

  const editMenuOption = {
    label: 'Edit',
    icon: 'edit-outline',
    handleClick: () => onEdit(comment),
  };
  const deleteMenuOption = {
    label: 'Delete',
    icon: 'trash-2-outline',
    handleClick: () => onDelete(comment),
  };
  const removeMenuOption = {
    label: 'Remove Comment',
    icon: 'trash-2-outline',
    handleClick: () => {
      if (onRemove) onRemove(comment);
    },
  };
  const restrictMenuOption = {
    label: 'Restrict user from community',
    testId: 'RESTRICT_USER_MENU_OPTION',
    icon: 'slash-outline',
    handleClick: () => {
      if (onRestrict) onRestrict(comment.user);
    },
  };
  const liftRestrictionMenuOption = {
    label: 'Lift restriction',
    testId: 'LIFT_RESTRICTION_MENU_OPTION',
    icon: 'person-done-outline',
    handleClick: () => {
      if (onLiftRestriction) onLiftRestriction(comment.user);
    },
  };

  const restrictionMenuOption = comment.user.isBanned
    ? liftRestrictionMenuOption
    : restrictMenuOption;

  const getUserMenuOptions = () =>
    isMyComment
      ? [editMenuOption, deleteMenuOption]
      : isModerator
      ? [removeMenuOption, restrictionMenuOption]
      : [];

  const handleClick = () => {
    if (isModerator) {
      handleClickProfile(
        comment.user.type,
        comment.user.userId,
        isMyComment,
        onClickProfile
      );
    }
  };

  const getCommentView = () => {
    return (
      <>
        <Box
          p={1.5}
          sx={{
            backgroundColor: theme.palette.action.background,
            borderRadius: '15px',
            borderTopLeftRadius: 0,
          }}
        >
          <Stack
            spacing={1}
            direction="row"
            onClick={handleClick}
            sx={{ cursor }}
          >
            <Typography fontWeight="medium">{comment.user.name}</Typography>
            {showModeratorLabel && !isBanned && <ModeratorLabel />}
            {isBanned && isModerator && <RestrictedLabel />}
          </Stack>
          <Linkify options={{ target: '_blank' }}>
            <Typography sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
              {comment.text}
            </Typography>
          </Linkify>
          <TimeAgo
            date={comment.time}
            textOverrideFn={timeAgoOverrideFn(comment, comment.isRemoved)}
          />
        </Box>
        {(isMyComment || isModerator) && (
          <IconMenu items={getUserMenuOptions()} />
        )}
      </>
    );
  };

  const getCommentEdit = () => {
    return (
      <Stack direction="column" spacing={2}>
        <TextField
          onChange={editOnChange}
          name="text"
          value={editText}
          error={!!editError}
          helperText={editError}
        />
        <Stack direction="row" spacing={2}>
          <Button onClick={editOnCancel}>{cancelButtonLabel}</Button>
          <Button variant="contained" color={buttonColor} onClick={editOnSave}>
            {saveChangesButtonLabel}
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack direction="row" spacing={1}>
      <Avatar
        name={comment.user.name}
        type="imageOnly"
        textPosition="left"
        picture={comment.user.picture}
        testId="USER_AVATAR"
        sx={{ alignSelf: 'flex-start', cursor }}
        onClick={handleClick}
      />
      {isEditing ? getCommentEdit() : getCommentView()}
    </Stack>
  );
};
