import { Badge, Button, styled, Typography } from '@mui/material';

export const LeftContainer = styled('div')(() => ({
  marginRight: '8px',
}));

export const MiddleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  margin: 0,
  height: '100%',
  marginBottom: 0.5,
  marginLeft: '8px',
  marginRight: '8px',
  overflow: 'hidden',
  width: '100%',
}));

export const RightContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'flex-end',
  height: '100%',
}));

export const MessagePreview = styled(Typography)(() => ({
  maxWidth: '100%',
  maxHeight: '30%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  p: {
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const TimeStamp = styled(Typography)(() => ({
  margin: '0',
}));

export const ChatName = styled(Typography)(() => ({
  fontWeight: 500,
  margin: '0',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const ChatSubtitle = styled(Typography)(() => ({
  marginBottom: '8px',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const UnreadBadge = styled(Badge)(() => ({
  marginRight: 8,
  height: 20,
  span: { top: '50%' },
}));

export const UnselectedPreviewItem = styled(Button)(() => ({
  height: 112,
  marginBottom: 16,
  borderRadius: 15,
  border: 'solid 1px rgba(0, 0, 0, .23)',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  textTransform: 'none',
  width: '100%',
  ':hover': { backgroundColor: 'transparent' },
}));

export const SelectedPreviewItem = styled(UnselectedPreviewItem)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    ':hover': { backgroundColor: theme.palette.primary.light },
  })
);
