import { useCallback, useMemo } from 'react';
import { parseISO } from 'date-fns';
import {
  AutomationItem,
  AutomationItemStatus,
  AutomationRequirementStatus,
  AutomationItemType,
  AutomationRequirementType,
  useGetAutomationItemsQuery,
} from '@fdha/graphql-api-admin';
import { useFeatureFlag } from '@fdha/common-hooks';
import { useApolloClient } from '@apollo/client';

import { getMostPriorityAutomationStatus } from '../utils';

export type AutomationItemsPerRequirementType = Map<
  AutomationRequirementType,
  AutomationItem[]
>;

export type AutomationRequirementStatusPerRequirementType = Map<
  AutomationRequirementType,
  AutomationRequirementStatus
>;

const getAutomationItemsPerRequirementType = (
  automationItems?: AutomationItem[]
): AutomationItemsPerRequirementType => {
  const automationItemsPerRequirementType = new Map<
    AutomationRequirementType,
    AutomationItem[]
  >();

  automationItems?.forEach((automationItem) => {
    automationItem.requirements.forEach((requirement) => {
      const automationItemsForRequirementType =
        automationItemsPerRequirementType.get(requirement.type) ?? [];

      automationItemsForRequirementType.push(automationItem);

      automationItemsPerRequirementType.set(
        requirement.type,
        automationItemsForRequirementType
      );
    });
  });

  return automationItemsPerRequirementType;
};

const getAutomationRequirementStatusPerRequirementType = (
  automationItems?: AutomationItem[]
): AutomationRequirementStatusPerRequirementType => {
  const automationRequirementStatusPerRequirementType = new Map<
    AutomationRequirementType,
    AutomationRequirementStatus
  >();

  automationItems?.forEach((automationItem) => {
    automationItem.requirements.forEach((requirement) => {
      const currentAutomationRequirementStatus =
        automationRequirementStatusPerRequirementType.get(requirement.type);

      if (!currentAutomationRequirementStatus) {
        automationRequirementStatusPerRequirementType.set(
          requirement.type,
          requirement.status
        );
      } else {
        const newPriority = getMostPriorityAutomationStatus([
          currentAutomationRequirementStatus,
          requirement.status,
        ]);

        if (newPriority !== currentAutomationRequirementStatus) {
          automationRequirementStatusPerRequirementType.set(
            requirement.type,
            requirement.status
          );
        }
      }
    });
  });

  return automationRequirementStatusPerRequirementType;
};

export const useAutomation = (patientId: string) => {
  const { cache } = useApolloClient();

  const {
    data: automationItemsData,
    loading: loadingAutomationItems,
    refetch,
  } = useGetAutomationItemsQuery({
    variables: { patientId },
    fetchPolicy: 'network-only',
  });

  const { isFeatureEnabled, isLoading: loadingFF } = useFeatureFlag();
  const showFrozenFoodAutomation = isFeatureEnabled(
    'show_frozen_food_automation'
  );

  const automationItemsDataFiltered = useMemo(
    () =>
      automationItemsData?.automationItems.filter((automationItem) => {
        if (
          automationItem.type === AutomationItemType.FrozenFood &&
          !showFrozenFoodAutomation
        ) {
          return false;
        }

        return true;
      }),
    [automationItemsData, showFrozenFoodAutomation]
  );

  const itemsPerRequirement = useMemo(
    () => getAutomationItemsPerRequirementType(automationItemsDataFiltered),
    [automationItemsDataFiltered]
  );

  const automationRequirementStatusPerRequirementType = useMemo(
    () =>
      getAutomationRequirementStatusPerRequirementType(
        automationItemsDataFiltered
      ),
    [automationItemsDataFiltered]
  );

  const isAutomationTriggered = useCallback(
    (requirementType: AutomationRequirementType) => {
      const automationItems = itemsPerRequirement?.get(requirementType) || [];

      return automationItems.every(
        (automationItem) =>
          automationItem.status === AutomationItemStatus.Triggered
      );
    },
    [itemsPerRequirement]
  );

  const handleAutomationRequirementChange = useCallback(
    (requirementType: AutomationRequirementType) => {
      const automationItemsToUpdate = itemsPerRequirement.get(requirementType);

      automationItemsToUpdate?.forEach((automationItem) => {
        if (automationItem.status === AutomationItemStatus.Triggered) return;

        // Very edge case, but coach can have the automation expired while page is open
        // In this case, we don't want to change the automation to checking, but instead refetch it
        if (
          automationItem.expirationDate &&
          parseISO(automationItem.expirationDate) < new Date()
        ) {
          refetch();
          return;
        }

        const requirement = automationItem.requirements.find(
          (req) => req.type === requirementType
        );

        cache.modify({
          id: cache.identify(automationItem),
          fields: {
            status() {
              return AutomationItemStatus.Checking;
            },
          },
        });

        if (requirement) {
          cache.modify({
            id: cache.identify(requirement),
            fields: {
              status() {
                return AutomationRequirementStatus.Checking;
              },
            },
          });
        }
      });
    },
    [cache, itemsPerRequirement, refetch]
  );

  const isLoading = loadingAutomationItems || loadingFF;

  return {
    automationItems: automationItemsDataFiltered || [],
    automationItemsPerAutomationRequirement: itemsPerRequirement,
    isLoading,
    automationRequirementStatusPerRequirementType,
    isAutomationTriggered,
    onAutomationRequirementChange: handleAutomationRequirementChange,
  };
};
