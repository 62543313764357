import { WeekdaysOnDietDeliveries } from '@fdha/common-utils';
import { Stack, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

import DeliverySchedule from './DeliverySchedule';

interface SameDeliveryScheduleProps {
  result: WeekdaysOnDietDeliveries;
}

const SameDeliverySchedule: FC<SameDeliveryScheduleProps> = ({ result }) => {
  const theme = useTheme();

  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        Given this patient's food program information, both prioritization
        algorithms produce the same delivery schedule, which is why no options
        are presented. This schedule provides the freshest meals with the fewest
        deliveries.
      </Typography>
      <Typography
        variant="body2"
        color={theme.palette.text.secondary}
        fontWeight="bold"
      >
        Current delivery settings:
      </Typography>
      <DeliverySchedule values={result} />
    </Stack>
  );
};

export default SameDeliverySchedule;
