import {
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import React, { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import {
  Button,
  useDialog,
  useSnackbar,
  formatDate,
  Table,
  INITIAL_ROWS_PER_PAGE,
  RowsPerPageOptions,
  SearchableTableHeader,
  formatUTCDate,
  completeWithinLabelByValue,
  SplitMenu,
} from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import {
  ListSurveyInstancesDocument,
  ListSurveySchedulesDocument,
  SurveyFrequency,
  useGetPatientUserQuery,
  useListSurveySchedulesQuery,
  useUnassignSurveyMutation,
  WeekDay,
} from '@fdha/graphql-api-admin';
import { parseBackendError } from '@fdha/common-utils';

import { AssignSurvey, BasePage, Chip } from '../../../../../components';
import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
  useGetUserType,
} from '../../../../../hooks';
import {
  getFrequency,
  headCells,
  mapScheduleNode,
  SurveyScheduleProps,
} from '../../../../../utils';

const Schedules = () => {
  const params = useParams();
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [showLegacy, setShowLegacy] = useState(false);

  const [filterBy, setFilterBy] = useFilterBy<SurveyScheduleProps>('name', '');
  const [sortBy, setSortBy] = useSortBy<SurveyScheduleProps>('name', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const patientId = params.patientId || '';

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'schedules',
  });

  const [unassignSurvey] = useUnassignSurveyMutation();

  const { data: patientData } = useGetPatientUserQuery({
    variables: {
      id: patientId,
    },
  });

  const { isCsr } = useGetUserType();

  const patientName = patientData?.patientUser?.name;

  const { data, error, fetchMore, loading, networkStatus, refetch } =
    useListSurveySchedulesQuery({
      variables: {
        patientId: patientId,
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
        showLegacy,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const nodes = data?.surveySchedules.edges.map((edge) =>
    mapScheduleNode(edge.node)
  );
  const pageInfo = data?.surveySchedules.pageInfo;
  const totalNumberFound = data?.surveySchedules.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          patientId: patientId,
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const onRowsPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
    changeRowsPerPage(rowsPerPage);
  };

  const getRefetchQueries = () => [
    ListSurveySchedulesDocument,
    {
      query: ListSurveyInstancesDocument,
      variables: { patientId: patientId, first: INITIAL_ROWS_PER_PAGE },
    },
  ];

  const handleUnassignSurvey = (row: SurveyScheduleProps) => {
    const firstName = patientName?.split(' ')[0];

    openDialog({
      title: 'Are you sure you want to unassign this survey?',
      content: (
        <Typography variant="body1">
          {firstName} will no longer receive this.
        </Typography>
      ),
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Unassign',
      handleConfirm: () => handleConfirmUnassignSurvey(row),
    });
  };

  const handleConfirmUnassignSurvey = async (row: SurveyScheduleProps) => {
    try {
      await unassignSurvey({
        variables: {
          patientId: patientId,
          surveyId: row.id,
        },
        refetchQueries: getRefetchQueries(),
      });
    } catch (error) {
      const message = parseBackendError(error, 'Error to unassign survey');

      showSnackbar({
        message,
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };

  const openAssignSurvey = (row: SurveyScheduleProps) => {
    const weekDays = (
      row.frequency &&
      [
        SurveyFrequency.Biweekly,
        SurveyFrequency.Triweekly,
        SurveyFrequency.Weekly,
      ].includes(row.frequency)
        ? row.weekDays
        : [formatUTCDate(row.starts_at, 'weekDays')]
    ) as WeekDay[];

    openDialog({
      content: (
        <AssignSurvey
          mode="toPatient"
          patientData={{ id: patientId, name: patientName || '' }}
          surveyData={{
            id: row.id,
            name: row.name,
            isAssigned: row.isAssigned,
            startsAt: row.starts_at,
            endsAt: row.ends_at,
            frequency: row.frequency,
            completeWithinDays: row.complete_within_days,
            weekDays,
          }}
          onSuccess={refetch}
          onFinish={closeDialog}
        />
      ),
    });
  };

  const getChip = (label: string) => {
    return <Chip label={label} />;
  };

  const getButton = (row: SurveyScheduleProps) => {
    if (row.isAssigned) {
      return (
        <SplitMenu
          label="Edit"
          startIcon="edit-outline"
          items={[
            {
              label: 'Unassign',
              icon: 'close-outline',
              handleClick: () => handleUnassignSurvey(row),
              testId: 'UNASSIGN_MENU_ITEM',
            },
          ]}
          mainActionHandler={() => openAssignSurvey(row)}
        />
      );
    }

    return (
      <Button
        data-testid="ASSIGN_BUTTON"
        color="secondary"
        variant="contained"
        startEvaIcon={{ name: 'plus-outline' }}
        onClick={() => openAssignSurvey(row)}
      >
        Assign
      </Button>
    );
  };

  const renderRow = (row: SurveyScheduleProps) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="SURVEY_SCHEDULE_NAME">{row.name}</TableCell>
        <TableCell>
          {row.starts_at
            ? formatDate(parseISO(row.starts_at))
            : getChip('unassigned')}
        </TableCell>
        <TableCell>
          {getFrequency(row.starts_at, row.frequency, row.weekDays) ||
            getChip('none')}
        </TableCell>
        <TableCell>
          {row.complete_within_days
            ? completeWithinLabelByValue[row.complete_within_days]
            : getChip('none')}
        </TableCell>
        <TableCell>
          {row.ends_at
            ? formatUTCDate(row.ends_at)
            : row.isAssigned
            ? getChip('never')
            : getChip('unassigned')}
        </TableCell>
        {!isCsr && <TableCell>{getButton(row)}</TableCell>}
        <TableCell>
          <Link to={row.id} data-testid="SURVEY_SCHEDULE_DETAILS_BUTTON">
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
              data-testid="SCHEDULE_BUTTON"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <BasePage data-testid="SCHEDULES_CONTAINER">
      <BasePage.BackButton to={`/patient/${patientId}/surveys`} />
      <Typography variant="h5" sx={{ marginBottom: '24px' }}>
        Survey schedules for {patientName}
      </Typography>
      <Stack direction="row" alignItems="baseline" spacing={3}>
        <SearchableTableHeader<SurveyScheduleProps>
          headCells={headCells}
          defaultSearchField="name"
          onSearchChange={setFilterBy}
        />
        <FormControlLabel
          componentsProps={{ typography: { noWrap: true } }}
          control={
            <Switch
              checked={showLegacy}
              onChange={(event) => setShowLegacy(event.target.checked)}
            />
          }
          label="Show legacy surveys"
        />
      </Stack>
      <Paper data-testid="SCHEDULES_TABLE">
        {nodes ? (
          <Table<SurveyScheduleProps>
            actions={!isCsr ? 'right' : undefined}
            headCells={headCells}
            initialOrderBy="name"
            isLoading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            renderRow={renderRow}
            totalRowCount={totalNumberFound}
            rows={nodes}
            withPagination
            onSortChange={setSortBy}
          />
        ) : null}
      </Paper>
    </BasePage>
  );
};

export default Schedules;
