import { Box, Fab, styled, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from 'react-eva-icons';
import { Channel } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import { useChat } from '@fdha/common-hooks';
import '@stream-io/stream-chat-css';

import { useChatNotification } from '../../hooks';
import ChannelContainer from '../Chat/ChannelContainer/ChannelContainer';

export const StyledChannelWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '125px',
  right: '50px',
  borderRadius: '15px',
  boxShadow: theme.shadows[3],
  '.str-chat-channel': {
    maxHeight: '75vh',
    maxWidth: '380px',
  },
  '.str-chat.messaging, .str-chat.commerce': {
    backgroundColor: 'transparent',
  },
}));

export const FabButton = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  position: 'fixed',
  bottom: '50px',
  right: '50px',
  ':hover': {
    backgroundColor: theme.palette.info.dark,
  },
}));

export interface ChatWidgetProps {
  channelId: string;
}

const ChatWidget: React.FC<ChatWidgetProps> = ({ channelId }) => {
  const theme = useTheme();
  const { client: chatClient } = useChat();
  const { changeNotificationChannel } = useChatNotification();

  const [showChat, setShowChat] = useState(false);
  const [channel, setChannel] = useState<Channel | undefined>();

  const iconName = showChat ? 'close-outline' : 'message-circle-outline';
  const initChat = useCallback(async () => {
    if (!channelId || !chatClient) {
      return;
    }
    const patientChannel = chatClient.channel('messaging', channelId);
    await patientChannel.watch();
    setChannel(patientChannel);
  }, [channelId, chatClient]);

  useEffect(() => {
    if (!showChat && channel) {
      setChannel(undefined);
    } else if (showChat) {
      changeNotificationChannel(channel?.id);
      initChat();
    }

    return () => {
      changeNotificationChannel(undefined);
    };
  }, [showChat, channel, initChat, changeNotificationChannel]);

  if (!chatClient) {
    return null;
  }

  const handleFabClick = () => {
    setShowChat(!showChat);
  };

  return (
    <Chat client={chatClient}>
      {showChat && (
        <StyledChannelWrapper>
          <ChannelContainer channel={channel} isWidget={true} />
        </StyledChannelWrapper>
      )}
      <FabButton data-testid="CHAT_BUTTON" onClick={handleFabClick}>
        <Box height={24} key={iconName}>
          <Icon
            name={iconName}
            size="large"
            fill={theme.palette.primary.contrastText}
          />
        </Box>
      </FabButton>
    </Chat>
  );
};

export default ChatWidget;
