import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import Icon from 'react-eva-icons';
import { grey } from '@mui/material/colors';
import { formatUTCDate, LabelBox, maskPhone } from '@fdha/web-ui-library';

import {
  formatProfileAttribute,
  genderOptions,
  physicalActivityLevelOptions,
} from '../../../../../utils';

import { ViewEditAccountInformationProps } from './AccountInformation';

const ViewModeAccountInformation = (props: ViewEditAccountInformationProps) => {
  return (
    <>
      <Box marginBottom={3} data-testid="ACCOUNT_SHOW_MODE">
        <Grid container columnSpacing={3} rowSpacing={0}>
          <LabelBox size={8} label="Name" value={props.patientUser?.name} />
          <LabelBox size={4} label="Email" value={props.patientUser?.email} />
          {!props.isCsr && (
            <>
              <LabelBox
                size={4}
                label="Birth date"
                value={formatUTCDate(props.patientUser?.birthdate)}
              />
              <LabelBox
                size={4}
                label="Weight"
                value={formatProfileAttribute(
                  'WEIGHT',
                  props.patientUser?.weight
                )}
              />
              <LabelBox
                size={4}
                label="Height"
                value={formatProfileAttribute(
                  'HEIGHT',
                  props.patientUser?.height
                )}
              />
              <LabelBox
                size={4}
                label="Assigned sex at birth"
                value={
                  genderOptions.find(
                    (val) => val.value === props.patientUser?.gender
                  )?.label
                }
              />
              <LabelBox
                size={4}
                label="Physical Activity Level"
                value={
                  physicalActivityLevelOptions.find(
                    (val) =>
                      val.value === props.patientUser?.physical_activity_level
                  )?.label
                }
              />
            </>
          )}
          <LabelBox
            size={8}
            label="Address"
            value={props.patientUser?.address?.formatted}
          />
          <LabelBox
            size={4}
            label="Apt, Suite, etc"
            value={props.patientUser?.address?.complement}
          />
          <LabelBox
            size={4}
            label="Mobile phone number"
            value={maskPhone(props.patientUser?.phone_number)}
          />
          {!props.isCsr && (
            <>
              <Grid item xs={8} />
              <LabelBox
                size={4}
                label="Caregiver name"
                value={props.patientUser?.caregiver?.name}
              />
              <LabelBox
                size={4}
                label="Caregiver email"
                value={props.patientUser?.caregiver?.primary_caregiver_email}
              />
            </>
          )}
        </Grid>
      </Box>
      <Box textAlign="end">
        <IconButton
          onClick={() => props.handleEditMode(true)}
          data-testid="EDIT_BUTTON"
        >
          <Icon name="edit-outline" width={24} height={24} fill={grey[600]} />
        </IconButton>
      </Box>
    </>
  );
};

export default ViewModeAccountInformation;
