import { parseISO } from 'date-fns';
import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { formatDate } from '@fdha/web-ui-library';

import { NotesProps } from '../../../../states/notesState';
interface PreviewHeaderProps {
  note: NotesProps;
}

const PreviewHeader = ({ note }: PreviewHeaderProps) => {
  const theme = useTheme();

  return (
    <>
      <Typography variant="h6">{note.name}</Typography>
      {note.created_at ? (
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
        >
          created on {formatDate(parseISO(note.created_at))}
        </Typography>
      ) : null}
    </>
  );
};

export default PreviewHeader;
