import React, { FC, useMemo } from 'react';
import * as Yup from 'yup';
import { Box, Grid } from '@mui/material';
import {
  Autocomplete,
  Button,
  Select,
  TextField,
  useSnackbar,
  Option,
  Loader,
} from '@fdha/web-ui-library';
import {
  TrialStatus,
  useListCoursesQuery,
  useListGroupsQuery,
  useUpdateTrialMutation,
} from '@fdha/graphql-api-admin';
import { useFormik } from 'formik';

import {
  getError as getErrorHelper,
  trialStatusLabelByValue,
} from '../../../../utils';

import { TrialViewOrEditProps } from './About';

interface TrialSchema {
  protocolNumber?: string | null;
  protocolAbbreviation?: string | null;
  status?: TrialStatus | null;
  description?: string | null;
  courses?: Option[];
  groups?: Option[];
}
const NUMBER_OF_RESULTS = 10000;
const validationSchema = Yup.object().shape({
  groups: Yup.array().min(1, 'This field must have at least 1 item'),
});

const EditModeAbout: FC<TrialViewOrEditProps> = ({ trial, onEditMode }) => {
  const { showSnackbar } = useSnackbar();

  const [updateTrial] = useUpdateTrialMutation();
  const { data: coursesData, loading: loadingCourses } = useListCoursesQuery({
    variables: { first: NUMBER_OF_RESULTS },
    fetchPolicy: 'cache-and-network',
  });

  const coursesOptions = useMemo(
    () =>
      coursesData?.courses.edges.map((e) => ({
        label: e.node.title,
        id: e.node.id,
      })) ?? [],
    [coursesData?.courses.edges]
  );

  const { data: groupsList, loading: loadingGroups } = useListGroupsQuery({
    variables: { first: NUMBER_OF_RESULTS },
    fetchPolicy: 'cache-and-network',
  });

  const groupsOptions = useMemo(
    () =>
      groupsList?.groups.edges.map((e) => ({
        label: e.node.name,
        id: e.node.id,
      })) ?? [],
    [groupsList?.groups.edges]
  );

  const trialStatusOptions = Object.values(TrialStatus).map((value) => ({
    label: trialStatusLabelByValue[value],
    value,
  }));

  const initialValues: TrialSchema = {
    protocolNumber: trial?.protocol_number,
    protocolAbbreviation: trial?.protocol_abbreviation,
    status: trial?.status,
    courses:
      coursesOptions.filter((opt) =>
        trial?.courses?.some((course) => course.id === opt.id)
      ) || [],
    groups:
      groupsOptions.filter((opt) =>
        trial?.groups?.some((group) => group.id === opt.id)
      ) || [],
  };

  const onSubmit = async (values: TrialSchema) => {
    try {
      await updateTrial({
        variables: {
          trialId: trial?.id || '',
          props: {
            status: values.status,
            courses: values.courses?.map((course) => course.id),
            groups: values.groups?.map((group) => group.id),
          },
        },
      });
      onEditMode(false);
      showSnackbar({
        severity: 'success',
        message: 'Changes saved!',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update trial data',
      });
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const getError = (name: string) => {
    const errorMessage = getErrorHelper<TrialSchema>(name, errors, touched);
    if (!errorMessage) return '';

    return errorMessage;
  };

  if (loadingGroups || loadingCourses) {
    return <Loader wrapperSx={{ paddingY: 4 }} />;
  }

  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={5}>
        <Grid item xs={4}>
          <TextField
            disabled
            title="Protocol number"
            name="protocol_number"
            value={values.protocolNumber}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled
            title="Abbreviation"
            name="abbreviation"
            value={values.protocolAbbreviation}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            title="Trial status"
            name="status"
            options={trialStatusOptions}
            value={values.status}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            title="Groups"
            placeholder="Select a group"
            options={groupsOptions}
            loading={loadingGroups}
            defaultValue={[]}
            value={values.groups}
            onChange={(_event, value) => {
              setFieldValue('groups', value);
            }}
            error={!!getError('groups')}
            helperText={getError('groups')}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            title="Courses"
            placeholder="Select a course"
            options={coursesOptions}
            loading={loadingCourses}
            defaultValue={[]}
            value={values.courses}
            onChange={(_event, value) => {
              setFieldValue('courses', value);
            }}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" mt={6}>
        <Button color="primary" size="large" onClick={() => onEditMode(false)}>
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default EditModeAbout;
