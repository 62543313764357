import React, { FC } from 'react';
import { Accordion, Button } from '@fdha/web-ui-library';
import { Box } from '@mui/material';
import { useGetDomoUrlQuery } from '@fdha/graphql-api-admin';

export interface DashboardProps {
  patientId: string;
}

const DATA_VISUALIZATION_ID = '1';

const Dashboard: FC<DashboardProps> = ({ patientId }) => {
  const { data } = useGetDomoUrlQuery({
    variables: {
      patientId,
      domoURLId: DATA_VISUALIZATION_ID,
    },
  });

  const pFilters = [
    {
      column: 'PATIENT_ID_FILTER',
      operand: 'IN',
      values: [patientId],
    },
  ];

  const domoBaseUrl = data?.domoURL?.dashboard || '';
  const domoBaseEmbedUrl = data?.domoURL?.embed;

  const finalEmbedUrl = `${domoBaseEmbedUrl}?pfilters=${encodeURIComponent(
    JSON.stringify(pFilters)
  )}`;

  const finalUrl = `${domoBaseUrl}?pfilters=${encodeURIComponent(
    JSON.stringify(pFilters)
  )}`;

  return (
    <Accordion
      title="Dashboard"
      data-testid="DASHBOARD_ACCORDION"
      sx={{ px: 1 }}
    >
      <Box display="flex" flexDirection="column">
        <iframe
          title="Dashboard"
          data-testid="DASHBOARD_IFRAME"
          src={finalEmbedUrl}
          height="720px"
          width="100%"
          style={{ border: 'none', borderRadius: 8 }}
        />
        <Button
          sx={{ alignSelf: 'flex-end', marginTop: 2 }}
          href={finalUrl}
          target="_blank"
        >
          OPEN IN DOMO
        </Button>
      </Box>
    </Accordion>
  );
};

export default Dashboard;
