import React, { FC } from 'react';
import { TextField } from '@fdha/web-ui-library';
import { Box } from '@mui/material';

import { ErrorStateProps } from '../../../../models/FormBuilderProps';

interface ScaleFieldProps {
  maxScale?: number;
  errorState: ErrorStateProps;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ScaleField: FC<ScaleFieldProps> = ({
  maxScale,
  errorState,
  handleChange,
}) => {
  return (
    <Box mt={3} mb={2}>
      <TextField
        type="number"
        onlyNumbers
        name="maxScale"
        placeholder="Enter the maximum scale value"
        value={maxScale}
        onChange={handleChange}
        inputProps={{ min: 3, max: 10 }}
        error={!!errorState.maxScale}
        helperText={errorState.maxScale}
      />
    </Box>
  );
};

export default ScaleField;
