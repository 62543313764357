import { omit } from 'lodash';
import React from 'react';

import { Avatar } from '../Avatar/Avatar';

import { Select, SelectProps } from './Select';

export interface UserSelectOption {
  label: string;
  value: string | number;
  picture?: string;
}

export type UserSelectProps = Omit<SelectProps, 'options'> & {
  options: UserSelectOption[];
  downloadPicture?: (picture: string) => Promise<string | undefined>;
};

export const UserSelect: React.FC<UserSelectProps> = (props) => {
  const renderOption = (
    value: string | number | readonly string[] | undefined
  ) => {
    const option = props.options.find((o) => o.value === value);

    return (
      <Avatar
        type="imageText"
        name={option?.label}
        picture={option?.picture}
        downloadPicture={props.downloadPicture}
      />
    );
  };

  const selectProps = omit(props, 'downloadPicture');

  return <Select {...selectProps} customRender={renderOption} />;
};
