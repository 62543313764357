import React, { FC } from 'react';
import {
  DeliverySettings,
  FoodProgramStatus,
  FoodProgramUserV2,
  UserDeliveryMenu,
  useUpdateFoodProgramUserMutation,
  WeekDay,
} from '@fdha/graphql-api-admin';
import { RadioGroup, RadioOption, useSnackbar } from '@fdha/web-ui-library';
import { Stack, Typography } from '@mui/material';
import { getWeekdaysOnDietDeliveries } from '@fdha/common-utils';

import ProjectedWeekDeliveries from './ProjectedWeekDeliveries';
import DeliverySchedule from './DeliverySchedule';

const deliveryConfigs: RadioOption[] = [
  {
    label: 'Prioritize freshness',
    value: DeliverySettings.Freshest,
    helperText:
      'This algorithm prioritizes a schedule that delivers the freshest meals, which often results in deliveries on both Tuesday and Friday.',
  },
  {
    label: 'Prioritize fewer delivery days',
    value: DeliverySettings.Fewest,
    helperText:
      'This algorithm prioritizes a schedule with the fewest deliveries each week at acceptable levels of freshness, which may result in a single delivery per week.',
  },
];

interface DifferentDeliverySchedulesProps {
  foodProgramUserId: string;
  subjectId: string;
  userDeliveryMenus: UserDeliveryMenu[];
  selectedSetting: DeliverySettings;
  weekdays: WeekDay[];
}

const DifferentDeliverySchedules: FC<DifferentDeliverySchedulesProps> = ({
  foodProgramUserId,
  subjectId,
  userDeliveryMenus,
  selectedSetting,
  weekdays,
}) => {
  const { showSnackbar } = useSnackbar();

  const [updateFoodProgram] = useUpdateFoodProgramUserMutation();

  const handleUpdateDeliverySettings = async (value: string) => {
    const deliverySettingEnumValue = value as DeliverySettings;

    const updateFoodProgramResponse: FoodProgramUserV2 = {
      __typename: 'FoodProgramUserV2',
      id: foodProgramUserId,
      status: FoodProgramStatus.Active,
      deliverySettings: deliverySettingEnumValue,
      patient: {
        __typename: 'FoodProgramPatient',
        id: '',
        name: '',
        subject_id: subjectId,
      },
      dietPlan: {
        __typename: 'FoodProgramDietPlan',
        id: '',
        name: '',
      },
      userDeliveryMenus,
      plannedPauses: [],
    };

    try {
      await updateFoodProgram({
        variables: {
          foodProgramUserId,
          input: {
            deliverySettings: deliverySettingEnumValue,
          },
        },
        optimisticResponse: {
          updateFoodProgramUserV2: updateFoodProgramResponse,
        },
      });
      showSnackbar({
        title: 'Changes Saved!',
        message: 'New settings will apply to Weekly Menus and new deliveries.',
        severity: 'success',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update deliveries settings',
      });
    }
  };

  return (
    <Stack spacing={3}>
      <Typography variant="body1">
        These preferences are based around a subject’s diet days so even if
        freshness is chosen, subject may receive only 1 delivery per week.
      </Typography>
      <RadioGroup
        type="border"
        options={deliveryConfigs}
        onChange={handleUpdateDeliverySettings}
        value={selectedSetting}
        renderContent={(option) => {
          const setting = option.value as DeliverySettings;
          const result = getWeekdaysOnDietDeliveries(weekdays, setting);

          return (
            <Stack spacing={2} pb={2}>
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2">
                  Projected week deliveries:
                </Typography>
                <ProjectedWeekDeliveries
                  values={result}
                  isSelected={setting === selectedSetting}
                />
              </Stack>
              <DeliverySchedule values={result} />
            </Stack>
          );
        }}
      />
    </Stack>
  );
};

export default DifferentDeliverySchedules;
