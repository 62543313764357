import React, { FC, useMemo, useState } from 'react';
import {
  useGetMenuOptionSelectionsV2Query,
  useGetSelectedMenuOptionsV2Query,
  DeliveryStatus as DeliveryStatusEnum,
  DeliveryStatus,
  useGetDeliveryV2Query,
} from '@fdha/graphql-api-admin';
import { Loader, useDialog } from '@fdha/web-ui-library';
import { useLocation } from 'react-router-dom';
import { groupDishes } from '@fdha/common-utils';

import DeliveryPreview from './DeliveryPreview/DeliveryPreview';
import EditDelivery from './EditDelivery/EditDelivery';
import DishDetails from './DishDetails/DishDetails';

enum PaperType {
  Preview = 'preview',
  Edit = 'edit',
}

interface DeliveryProps {
  patientId: string;
  subjectId?: string;
  foodProgramUserId?: string;
  deliveryMenuId?: string;
  deliveryId?: string;
  status?: DeliveryStatus | null;
  openChangesDate?: string;
  isCanceled?: boolean;
  isCanceledUpdateComment?: string | null;
  showWeekMenu?: boolean;
}

const Delivery: FC<DeliveryProps> = ({
  patientId,
  subjectId = '',
  foodProgramUserId = '',
  deliveryMenuId = '',
  deliveryId = '',
  status,
  openChangesDate,
  isCanceled = false,
  isCanceledUpdateComment,
  showWeekMenu = false,
}) => {
  const { openDialog } = useDialog();

  const location = useLocation();

  const initialMode = location.state?.editMode
    ? PaperType.Edit
    : PaperType.Preview;

  const [type, setType] = useState(initialMode);

  const {
    data: dataMenuOptionSelections,
    loading: loadingMenuOptionSelections,
  } = useGetMenuOptionSelectionsV2Query({
    variables: {
      dietPlanDeliveryMenuId: deliveryMenuId,
      patientId,
    },
    fetchPolicy: 'cache-and-network',
    skip:
      !deliveryMenuId ||
      (!showWeekMenu && status === DeliveryStatusEnum.Closed),
  });

  const { data: deliveryData, loading: loadingDelivery } =
    useGetDeliveryV2Query({
      variables: { deliveryId },
      fetchPolicy: 'cache-and-network',
      skip: !deliveryId,
    });

  const menuOptionSelections = useMemo(
    () => dataMenuOptionSelections?.getMenuOptionSelectionsV2,
    [dataMenuOptionSelections?.getMenuOptionSelectionsV2]
  );

  const { data: dataSelectedMenuOptions, loading: loadingSelectedMenuOptions } =
    useGetSelectedMenuOptionsV2Query({
      variables: { deliveryId },
      fetchPolicy: 'cache-and-network',
      skip: !deliveryId || status === DeliveryStatusEnum.Open,
    });

  const selectedMenuOptions = useMemo(
    () => dataSelectedMenuOptions?.selectedMenuOptionsV2,
    [dataSelectedMenuOptions?.selectedMenuOptionsV2]
  );

  const amount = useMemo(
    () => ({
      entreeDishes: menuOptionSelections?.amountPerDay || 0,
      snacks: menuOptionSelections?.snackAmount || 0,
    }),
    [menuOptionSelections]
  );

  const initialValues = useMemo(() => {
    const dishes = groupDishes(menuOptionSelections);

    const values = {
      entreeDishes: dishes?.entreeDishes || [],
      snacks: dishes?.snacks || [],
    };

    return values;
  }, [menuOptionSelections]);

  const shipmentOrder = deliveryData?.deliveryV2?.order;

  const onDishClicked = (dishId: string, dishName: string) =>
    openDialog({
      title: dishName,
      content: <DishDetails dishId={dishId} />,
      showCloseButton: true,
      hidePadding: true,
      hideTopPadding: true,
      maxWidth: 'md',
    });
  const isLoading =
    loadingMenuOptionSelections ||
    loadingSelectedMenuOptions ||
    loadingDelivery;

  if (isLoading) {
    return <Loader />;
  }

  return type === 'preview' ? (
    <DeliveryPreview
      subjectId={subjectId}
      deliveryId={deliveryId}
      status={status}
      openChangesDate={openChangesDate}
      isCanceled={isCanceled}
      isCanceledUpdateComment={isCanceledUpdateComment}
      menuOptionSelections={menuOptionSelections}
      shipmentOrder={shipmentOrder}
      selectedMenuOptions={selectedMenuOptions}
      entreeDishesAmount={amount.entreeDishes}
      snackAmount={amount.snacks}
      showWeekMenu={showWeekMenu}
      deliveryDate={deliveryData?.deliveryV2?.deliveryDate}
      onDishClicked={onDishClicked}
      openEdit={() => setType(PaperType.Edit)}
    />
  ) : (
    <EditDelivery
      foodProgramUserId={foodProgramUserId}
      deliveryMenuId={deliveryMenuId}
      initialValues={initialValues}
      entreeDishesAmount={amount.entreeDishes}
      snackAmount={amount.snacks}
      hasSides={!!menuOptionSelections?.sides.length}
      onDishClicked={onDishClicked}
      openPreview={() => setType(PaperType.Preview)}
    />
  );
};

export default Delivery;
