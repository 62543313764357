import React, { FC } from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import {
  Button,
  CharactersCount,
  PostAuthor,
  TextField,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { DocumentNode } from 'graphql';
import {
  CommunityUser,
  useBanCommunityUserMutation,
  UserType,
} from '@fdha/graphql-api-admin';

interface RestrictUserDialogProps {
  profile: CommunityUser;
  refetchQueries?: DocumentNode[];
}

const validationSchema = Yup.object().shape({
  text: Yup.string()
    .trim()
    .max(140, 'You have exceeded the character limit.')
    .required('required'),
});

interface RestrictionDialogSchema {
  text: string;
}

const getUserTypeLink = (userType?: UserType | null) => {
  switch (userType) {
    case UserType.CustomerService:
    case UserType.Coach:
      return 'coach';
    case UserType.Caretaker:
    case UserType.ClinicalTrialPatient:
    case UserType.Patient:
      return 'patient';
  }
};

const RestrictCommunityUserDialog: FC<RestrictUserDialogProps> = ({
  profile,
  refetchQueries,
}) => {
  const initialValues: RestrictionDialogSchema = { text: '' };
  const { closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();

  const [banCommunityUser] = useBanCommunityUserMutation({
    refetchQueries,
  });

  const handleSubmit = async (values: RestrictionDialogSchema) => {
    const { text } = values;

    try {
      if (profile.userId) {
        await banCommunityUser({
          variables: {
            id: profile.userId,
            reason: text.trim(),
          },
        });
        showSnackbar({
          severity: 'success',
          message: 'User successfully restricted',
        });
      }
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Unable to restict user',
      });
    }

    closeDialog();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid, handleChange, values, isSubmitting, errors }) => (
        <Form>
          <Typography>
            Restricted users can’t access community related content, they can
            still use other app features though.
          </Typography>
          <Box my={2}>
            <PostAuthor
              role={profile.role}
              name={profile.name}
              picture={profile.picture ?? undefined}
              subtitle={
                <Link
                  to={`/${getUserTypeLink(profile.type)}/${profile.userId}`}
                  style={{ textDecoration: 'none' }}
                  onClick={closeDialog}
                >
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Go to user profile
                  </Typography>
                </Link>
              }
            />
          </Box>
          <Typography variant="h6">Note for user</Typography>
          <Box mt={1}>
            <TextField
              name="text"
              placeholder="Let the user know why they are being restricted."
              onChange={handleChange}
              value={values.text}
              error={!!errors.text && errors.text !== 'required'}
              helperText={errors.text !== 'required' ? errors.text : undefined}
              multiline
              maxRows={3}
            />
            <CharactersCount text={values.text} maxCount={140} />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={3} mb={2}>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!isValid || isSubmitting}
              size="large"
            >
              RESTRICT USER
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default RestrictCommunityUserDialog;
