import React from 'react';
import { Channel, ChannelProps } from 'stream-chat-react';

import ChannelInner from '../ChannelInner';
import ChatDetails from '../../ChatDetails/ChatDetails';
import MessageInput from '../MessageInput/MessageInput';

import MessageAvatar from './MessageAvatar';
import StyledChannelWrapper from './styles';

export type ChannelContainerProps = Pick<ChannelProps, 'channel'> & {
  isWidget: boolean;
};

const ChannelContainer: React.FC<ChannelContainerProps> = (props) => {
  return (
    <StyledChannelWrapper data-testid="CHAT_GRID">
      <Channel Avatar={MessageAvatar} Input={MessageInput} {...props}>
        <ChannelInner isWidget={props.isWidget} />
        <ChatDetails />
      </Channel>
    </StyledChannelWrapper>
  );
};

export default ChannelContainer;
