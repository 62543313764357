"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCoachUserAPI = updateCoachUserAPI;
exports.getProfileAdminAPI = getProfileAdminAPI;
exports.getProfileAPI = getProfileAPI;
exports.updateProfilePatientAPI = updateProfilePatientAPI;
exports.getProfilePatientAPI = getProfilePatientAPI;
exports.assignSurveyToUsersAPI = assignSurveyToUsersAPI;
exports.unassignSurveyAPI = unassignSurveyAPI;
exports.associateCoachAPI = associateCoachAPI;
exports.assignBHBSurveyToPatientAPI = assignBHBSurveyToPatientAPI;
exports.unassignBHBSurveyToPatientAPI = unassignBHBSurveyToPatientAPI;
exports.addSiteStaffUserAPI = addSiteStaffUserAPI;
exports.setSiteStaffUserPasswordAPI = setSiteStaffUserPasswordAPI;
exports.updateSiteStaffUserAPI = updateSiteStaffUserAPI;
exports.addSiteAPI = addSiteAPI;
exports.deactivateAccountAPI = deactivateAccountAPI;
exports.addSubjectAPI = addSubjectAPI;
exports.updatePatientTrialInfoAPI = updatePatientTrialInfoAPI;
exports.updateSiteAPI = updateSiteAPI;
exports.saveSubjectDraftAPI = saveSubjectDraftAPI;
exports.addGroupAPI = addGroupAPI;
exports.setGroupsToUserAPI = setGroupsToUserAPI;
exports.answerSurveyAPI = answerSurveyAPI;
exports.listSurveyInstancesAPI = listSurveyInstancesAPI;
exports.deleteGroupsAPI = deleteGroupsAPI;
exports.addNotesTemplateAPI = addNotesTemplateAPI;
exports.updatePatientAccountInformationAPI = updatePatientAccountInformationAPI;
exports.answerDataSurveyBhbAPI = answerDataSurveyBhbAPI;
exports.getPatientBhbAnswersAPI = getPatientBhbAnswersAPI;
exports.createFoodProgramUserAPI = createFoodProgramUserAPI;
exports.addCtPresignupAPI = addCtPresignupAPI;
exports.getPatientsBySiteAPI = getPatientsBySiteAPI;
exports.getPatientsByTrialAPI = getPatientsByTrialAPI;
exports.getFoodProgramUserAPI = getFoodProgramUserAPI;
exports.enableWebFeatureFlag = enableWebFeatureFlag;
exports.publishContentfulEntryAPI = publishContentfulEntryAPI;
exports.addMenuAPI = addMenuAPI;
exports.addDietPlanAPI = addDietPlanAPI;
exports.listDietPlansAPI = listDietPlansAPI;
exports.addFoodOpsUserAPI = addFoodOpsUserAPI;
exports.setFoodOpsUserPasswordAPI = setFoodOpsUserPasswordAPI;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
var helper_1 = require("./helper");
var queries = __importStar(require("./queries"));
function updateCoachUserAPI(coachId, props) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        id: coachId.startsWith('coa_') ? coachId : 'coa_' + coachId,
                        props: props,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.UpdateCoachUserDocument,
                            variables: variables,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_1 = _a.sent();
                    console.error(JSON.stringify(error_1));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function getProfileAdminAPI(username, password) {
    return __awaiter(this, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: username,
                            password: password,
                            query: queries.GetProfileDocumentAdmin,
                            variables: {},
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error(JSON.stringify(error_2));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function getProfileAPI(username, password) {
    return __awaiter(this, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: username,
                            password: password,
                            query: queries.GetProfileDocumentPatient,
                            variables: {},
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    console.error(JSON.stringify(error_3));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function updateProfilePatientAPI(userId, props) {
    return __awaiter(this, void 0, void 0, function () {
        var error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.UpdatePatientUserDocument,
                            variables: { id: userId, props: props },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    console.error(JSON.stringify(error_4));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function getProfilePatientAPI(username, password) {
    return __awaiter(this, void 0, void 0, function () {
        var error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.requestPatient)({
                            email: username,
                            password: password,
                            query: queries.GetProfilePatientDocument,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_5 = _a.sent();
                    console.error(JSON.stringify(error_5));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function assignSurveyToUsersAPI(groupIds, schedule, surveyId, userIds) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        groupIds: groupIds,
                        schedule: schedule,
                        surveyId: surveyId,
                        userIds: userIds,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.AssignSurveyToUsersDocument,
                            variables: variables,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_6 = _a.sent();
                    console.error(JSON.stringify(error_6));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function unassignSurveyAPI(patientId, surveyId) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        patientId: patientId,
                        surveyId: surveyId,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.UnassignSurveyDocument,
                            variables: variables,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_7 = _a.sent();
                    console.error(JSON.stringify(error_7));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function associateCoachAPI(coachId_1) {
    return __awaiter(this, arguments, void 0, function (coachId, is_main, patientId) {
        var variables, error_8;
        if (is_main === void 0) { is_main = true; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        coachId: coachId,
                        is_main: is_main,
                        patientId: patientId,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.AssociateCoachDocument,
                            variables: variables,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_8 = _a.sent();
                    console.error(JSON.stringify(error_8));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function assignBHBSurveyToPatientAPI(patientId, schedule) {
    return __awaiter(this, void 0, void 0, function () {
        var error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.AssignBHBSurveyToPatientDocument,
                            variables: { patientId: patientId, schedule: schedule },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_9 = _a.sent();
                    console.error(JSON.stringify(error_9));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function unassignBHBSurveyToPatientAPI(patientId) {
    return __awaiter(this, void 0, void 0, function () {
        var error_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.UnassignBHBSurveyToPatientDocument,
                            variables: { patientId: patientId },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_10 = _a.sent();
                    console.error(JSON.stringify(error_10));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function addSiteStaffUserAPI(newUser) {
    return __awaiter(this, void 0, void 0, function () {
        var error_11;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.AddSiteStaffUserDocument,
                            variables: { newUser: newUser },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_11 = _a.sent();
                    console.error(JSON.stringify(error_11));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function setSiteStaffUserPasswordAPI(username, password) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_12;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        username: username,
                        password: password,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.SetSiteStaffUserPasswordDocument,
                            variables: variables,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_12 = _a.sent();
                    console.error(JSON.stringify(error_12));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function updateSiteStaffUserAPI(id, props) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_13;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        id: id,
                        props: props,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.UpdateSiteStaffUserDocument,
                            variables: variables,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_13 = _a.sent();
                    console.error(JSON.stringify(error_13));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function addSiteAPI(site) {
    return __awaiter(this, void 0, void 0, function () {
        var error_14;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.AddSiteDocument,
                            variables: { site: site },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_14 = _a.sent();
                    console.error(JSON.stringify(error_14));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function deactivateAccountAPI(id) {
    return __awaiter(this, void 0, void 0, function () {
        var error_15;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.DeactivateAccountDocument,
                            variables: { id: id },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_15 = _a.sent();
                    console.error(JSON.stringify(error_15));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function addSubjectAPI(staff, input) {
    return __awaiter(this, void 0, void 0, function () {
        var error_16;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.staffRequest)({
                            email: staff.email,
                            password: staff.password,
                            query: queries.AddSubjectDocument,
                            variables: { input: input },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_16 = _a.sent();
                    console.error(JSON.stringify(error_16));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function updatePatientTrialInfoAPI(patientId, patientTrialInfoProps) {
    return __awaiter(this, void 0, void 0, function () {
        var error_17;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.UpdatePatientTrialInfoDocument,
                            variables: { patientId: patientId, patientTrialInfoProps: patientTrialInfoProps },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_17 = _a.sent();
                    console.error(JSON.stringify(error_17));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function updateSiteAPI(siteId, input) {
    return __awaiter(this, void 0, void 0, function () {
        var error_18;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.UpdateSiteDocument,
                            variables: { siteId: siteId, input: input },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_18 = _a.sent();
                    console.error(JSON.stringify(error_18));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function saveSubjectDraftAPI(staff, input) {
    return __awaiter(this, void 0, void 0, function () {
        var error_19;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.staffRequest)({
                            email: staff.email,
                            password: staff.password,
                            query: queries.SaveSubjectDraftDocument,
                            variables: { input: input },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_19 = _a.sent();
                    console.error(JSON.stringify(error_19));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function addGroupAPI(groupName) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_20;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = { newGroup: { name: groupName } };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.AddGroupDocument,
                            variables: variables,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_20 = _a.sent();
                    console.error(JSON.stringify(error_20));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function setGroupsToUserAPI(groupIdsToAdd, groupIdsToRemove, userId) {
    return __awaiter(this, void 0, void 0, function () {
        var error_21;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.SetGroupsToUserDocument,
                            variables: { groupIdsToAdd: groupIdsToAdd, groupIdsToRemove: groupIdsToRemove, userId: userId },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_21 = _a.sent();
                    console.error(JSON.stringify(error_21));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function answerSurveyAPI(email, password, instanceId, answers) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_22;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        instanceId: instanceId,
                        answers: answers,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.requestPatient)({
                            email: email,
                            password: password,
                            query: queries.AnswerSurveyDocument,
                            variables: variables,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_22 = _a.sent();
                    console.error(JSON.stringify(error_22));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function listSurveyInstancesAPI(patientId, surveyName) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_23;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        patientId: patientId,
                        first: 10,
                        filterBy: {
                            filterBy: [{ field: 'name', op: 'includes', value: surveyName }],
                        },
                        sortBy: { sortBy: [{ field: 'name', order: 'asc' }] },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.ListSurveyInstancesDocument,
                            variables: variables,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_23 = _a.sent();
                    console.error(JSON.stringify(error_23));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function deleteGroupsAPI(groupIds) {
    return __awaiter(this, void 0, void 0, function () {
        var error_24;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.DeleteGroupsDocument,
                            variables: { ids: groupIds },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_24 = _a.sent();
                    console.error(JSON.stringify(error_24));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function addNotesTemplateAPI(template) {
    return __awaiter(this, void 0, void 0, function () {
        var error_25;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.AddNotesTemplateDocument,
                            variables: { template: template },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_25 = _a.sent();
                    console.error(JSON.stringify(error_25));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function updatePatientAccountInformationAPI(email, password, props) {
    return __awaiter(this, void 0, void 0, function () {
        var error_26;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.requestPatient)({
                            email: email,
                            password: password,
                            query: queries.UpdatePatientInformationDocument,
                            variables: { props: props },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_26 = _a.sent();
                    console.error(JSON.stringify(error_26));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function answerDataSurveyBhbAPI(email, password, bhbData) {
    return __awaiter(this, void 0, void 0, function () {
        var error_27;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.requestPatient)({
                            email: email,
                            password: password,
                            query: queries.AnswerDataSurveyBhbDocument,
                            variables: { bhbData: bhbData },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_27 = _a.sent();
                    console.error(JSON.stringify(error_27));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function getPatientBhbAnswersAPI(patientId) {
    return __awaiter(this, void 0, void 0, function () {
        var error_28;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.GetPatientBhbAnswersDocument,
                            variables: { patientId: patientId },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_28 = _a.sent();
                    console.error(JSON.stringify(error_28));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function createFoodProgramUserAPI(patientId, input) {
    return __awaiter(this, void 0, void 0, function () {
        var error_29;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.CreateFoodProgramUserDocument,
                            variables: { patientId: patientId, input: input },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_29 = _a.sent();
                    console.error(JSON.stringify(error_29));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function addCtPresignupAPI(input) {
    return __awaiter(this, void 0, void 0, function () {
        var error_30;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.AddCtPresignupDocument,
                            variables: { input: input },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_30 = _a.sent();
                    console.error(JSON.stringify(error_30));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function getPatientsBySiteAPI(siteId, filterBy) {
    return __awaiter(this, void 0, void 0, function () {
        var input, error_31;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    input = {
                        siteId: siteId,
                        first: 10,
                        filterBy: filterBy,
                        sortBy: { sortBy: [{ field: 'name', order: 'asc' }] },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.GetPatientsBySiteDocument,
                            variables: input,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_31 = _a.sent();
                    console.error(JSON.stringify(error_31));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function getPatientsByTrialAPI(trialId, filterBy) {
    return __awaiter(this, void 0, void 0, function () {
        var input, error_32;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    input = {
                        trialId: trialId,
                        first: 10,
                        filterBy: filterBy,
                        sortBy: { sortBy: [{ field: 'name', order: 'asc' }] },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.GetPatientsByTrialDocument,
                            variables: input,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_32 = _a.sent();
                    console.error(JSON.stringify(error_32));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function getFoodProgramUserAPI(patientId) {
    return __awaiter(this, void 0, void 0, function () {
        var error_33;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.GetFoodProgramUserDocument,
                            variables: { patientId: patientId },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_33 = _a.sent();
                    console.error(JSON.stringify(error_33));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function enableWebFeatureFlag(name) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_34;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        name: name,
                        type: graphql_api_admin_1.FeatureFlagType.Web,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.EnableFeatureFlagDocument,
                            variables: variables,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_34 = _a.sent();
                    console.error(JSON.stringify(error_34));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function publishContentfulEntryAPI(entryId) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_35;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        entryId: entryId,
                        contentfulEnv: 'automation',
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.PublishContentfulEntryDocument,
                            variables: variables,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_35 = _a.sent();
                    console.error(JSON.stringify(error_35));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function addMenuAPI(email, password, payload) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_36;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = { input: payload };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.foodOpsRequest)({
                            email: email,
                            password: password,
                            query: queries.AddMenuDocument,
                            variables: variables,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_36 = _a.sent();
                    console.error(JSON.stringify(error_36));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function addDietPlanAPI(email, password, payload) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_37;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = { input: payload };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.foodOpsRequest)({
                            email: email,
                            password: password,
                            query: queries.AddDietPlanDocument,
                            variables: variables,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_37 = _a.sent();
                    console.error(JSON.stringify(error_37));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function listDietPlansAPI(email, password) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_38;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        first: 10000,
                        sortBy: { sortBy: [{ field: 'updated_at', order: 'desc' }] },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.foodOpsRequest)({
                            email: email,
                            password: password,
                            query: queries.ListDietPlansV2Document,
                            variables: variables,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    error_38 = _a.sent();
                    console.error(JSON.stringify(error_38));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
function addFoodOpsUserAPI(newUser) {
    return __awaiter(this, void 0, void 0, function () {
        var error_39;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.AddFoodOpsUserDocument,
                            variables: { newUser: newUser },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_39 = _a.sent();
                    console.error(JSON.stringify(error_39));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function setFoodOpsUserPasswordAPI(username, password) {
    return __awaiter(this, void 0, void 0, function () {
        var variables, error_40;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        username: username,
                        password: password,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, helper_1.request)({
                            email: Cypress.env('adminUser'),
                            password: Cypress.env('adminPassword'),
                            query: queries.SetFoodOpsUserPasswordDocument,
                            variables: variables,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_40 = _a.sent();
                    console.error(JSON.stringify(error_40));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
