import { useMemo } from 'react';
import { useGetProjectedDeliveryMenuQuery } from '@fdha/graphql-api-admin';
import { BasePage, Loader, Typography } from '@fdha/web-ui-library';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { TabValues } from '../tabs/Medical/deliveryInformation/DeliveryInformation';
import { weekdayLabelByValue } from '../../../utils';
import { WeekMenuDeliveryInformation } from '../../../components';

import Delivery from './Delivery';

const WeekMenuDelivery = () => {
  const navigate = useNavigate();
  const params = useParams();

  const patientId = params.patientId || '';
  const deliveryMenuId = params.deliveryMenuId || '';

  const { data, loading } = useGetProjectedDeliveryMenuQuery({
    variables: {
      deliveryMenuId,
      patientId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const projectedDeliveryMenu = useMemo(
    () => data?.projectedDeliveryMenu,
    [data?.projectedDeliveryMenu]
  );

  const { id, delivery, weekOfCycle, deliveryDayOfWeek } =
    projectedDeliveryMenu || {};

  if (loading) {
    return <Loader />;
  }

  const Header = () => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {deliveryDayOfWeek && (
          <Typography variant="h5">
            Week {weekOfCycle} - {weekdayLabelByValue[deliveryDayOfWeek]}
          </Typography>
        )}

        <WeekMenuDeliveryInformation
          deliveryStatus={delivery?.status}
          openChangesDate={delivery?.openChanges}
          opensOnDate={delivery?.opensOn}
          deliveryDate={delivery?.deliveryDate}
        />
      </Box>
    );
  };

  return (
    <BasePage>
      <BasePage.BackButton
        handleClick={() =>
          navigate(`/patient/${patientId}`, {
            state: { initialTab: TabValues.WEEK_MENUS },
            replace: true,
          })
        }
      />
      <BasePage.Wrapper spacing={3}>
        <Header />
        <Delivery
          patientId={patientId}
          foodProgramUserId={delivery?.foodProgramUserId}
          deliveryMenuId={id}
          status={delivery?.status}
          openChangesDate={delivery?.openChanges}
          isCanceled={delivery?.isCanceled}
          showWeekMenu
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default WeekMenuDelivery;
