import { NetworkStatus } from '@apollo/client';
import { Box, IconButton, Paper, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect } from 'react';
import Icon from 'react-eva-icons';
import {
  Button,
  formatUTCDate,
  useDialog,
  Table,
  HeadCell,
  SearchableTableHeader,
} from '@fdha/web-ui-library';
import {
  CtPresignupInfoV2,
  useListCtPresignupsQuery,
} from '@fdha/graphql-api-admin';

import Chip from '../../../../components/Chip/Chip';
import {
  useDebouncedValue,
  useFilterBy,
  useSortBy,
  useTable,
} from '../../../../hooks';

import AddOrEditPreSignUp from './AddOrEditPreSignUp';

const headCells: HeadCell<CtPresignupInfoV2>[] = [
  { id: 'email', label: 'Email', sortable: true, searchable: true },
  { id: 'subject_id', label: 'Subject ID', sortable: true, searchable: true },
  {
    id: 'study_start',
    label: 'Study Start',
    sortable: true,
    searchable: false,
  },
  {
    id: 'associated_site',
    label: 'Associated Site',
    sortable: true,
    searchable: true,
  },
  {
    id: 'group:name',
    label: 'Group',
    sortable: true,
    searchable: true,
  },
  {
    id: 'course:id',
    label: 'Automatically Assigned Course',
    sortable: false,
    searchable: false,
  },
];

const PreSignUpTab = () => {
  const { openDialog, closeDialog } = useDialog();
  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'preSignUp',
  });

  const [filterBy, setFilterBy] = useFilterBy<CtPresignupInfoV2>('email', '');
  const [sortBy, setSortBy] = useSortBy<CtPresignupInfoV2>('email', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const { data, loading, fetchMore, networkStatus } = useListCtPresignupsQuery({
    variables: {
      first: rowsPerPage,
      sortBy: { sortBy: [sortBy] },
      filterBy: {
        filterBy: [filterByDebounced],
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const nodes = data?.ctPresignupsV2.edges.map((edge) => edge.node) || [];
  const totalNumberFound = data?.ctPresignupsV2.totalNumberFound;
  const pageInfo = data?.ctPresignupsV2.pageInfo;

  const emptyState = filterByDebounced.value
    ? 'No results found'
    : 'No pre sign-ups yet';

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onAddOrEdit = (data?: CtPresignupInfoV2) => {
    openDialog({
      title: '',
      content: <AddOrEditPreSignUp data={data} onFinish={closeDialog} />,
    });
  };

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const renderRow = (row: CtPresignupInfoV2) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="EMAIL_CELL">{row.email}</TableCell>
        <TableCell data-testid="SUBJECT_ID_CELL">{row.subject_id}</TableCell>
        <TableCell data-testid="STUDY_START_CELL">
          {formatUTCDate(row.study_start)}
        </TableCell>
        <TableCell data-testid="ASSOCIATED_SITE_CELL">
          {row.associated_site}
        </TableCell>
        <TableCell data-testid="GROUP_CELL">
          {row.group?.name || <Chip label="none" />}
        </TableCell>
        <TableCell data-testid="COURSE_CELL">
          {row.course?.title || <Chip label="none" />}
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={() => onAddOrEdit(row)}>
            <Icon name="edit-outline" size="medium" fill={grey[600]} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box
        display="flex"
        marginBottom={2}
        alignItems="center"
        data-testid="CT_PRESIGNUP_CONTAINER"
      >
        <Box flex="1 1 auto">
          <SearchableTableHeader<CtPresignupInfoV2>
            headCells={headCells}
            defaultSearchField="email"
            onSearchChange={setFilterBy}
            disableMargin
          />
        </Box>
        <Box marginLeft={2}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startEvaIcon={{ name: 'plus-outline' }}
            onClick={() => onAddOrEdit()}
            data-testid="NEW_CT_PRESIGNUP_BUTTON"
          >
            NEW
          </Button>
        </Box>
      </Box>
      <Paper>
        <Table<CtPresignupInfoV2>
          actions="right"
          initialOrderBy="email"
          headCells={headCells}
          page={page}
          onPageChange={onPageChange}
          renderRow={renderRow}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={changeRowsPerPage}
          rows={nodes}
          isLoading={loading}
          totalRowCount={totalNumberFound}
          emptyState={emptyState}
          withPagination
          onSortChange={setSortBy}
        />
      </Paper>
    </>
  );
};

export default PreSignUpTab;
