import React from 'react';

import { Activity, ActivityKind, Appointment, Goal, Task } from '../../utils';

import AppointmentCard from './Cards/AppointmentCard';
import GoalCard from './Cards/GoalCard';
import TaskCard from './Cards/TaskCard';

interface TimelineItemProps {
  v2?: boolean;
  item: Activity;
  readOnly?: boolean;
  handleSelectActivity: (activity: Activity) => void;
  updateSelectedActivity: (activity: Partial<Activity>) => void;
  onUpdateTask: (data: Task, completed: boolean) => void;
}

export const TimelineItem: React.FC<TimelineItemProps> = ({
  v2,
  item,
  readOnly,
  handleSelectActivity,
  updateSelectedActivity,
  onUpdateTask,
}) => {
  switch (item.kind) {
    case ActivityKind.Task: {
      return (
        <TaskCard
          v2={v2}
          data={item as Task}
          readOnly={readOnly}
          handleSelectActivity={handleSelectActivity}
          updateSelectedActivity={updateSelectedActivity}
          onUpdateTask={onUpdateTask}
        />
      );
    }
    case ActivityKind.Goal: {
      return (
        <GoalCard
          v2={v2}
          data={item as Goal}
          handleSelectActivity={handleSelectActivity}
        />
      );
    }
    case ActivityKind.Appointment: {
      return (
        <AppointmentCard
          v2={v2}
          data={item as Appointment}
          handleSelectActivity={handleSelectActivity}
        />
      );
    }
  }
};
