import { InputAdornment, TextField, useTheme } from '@mui/material';
import React from 'react';
import Icon from 'react-eva-icons';
import { SearchInputProps } from 'stream-chat-react';

const SearchInput = (props: SearchInputProps) => {
  const theme = useTheme();
  const { channelSearchParams, inputRef, onSearch, query, searchFunction } =
    props;

  return (
    <TextField
      onChange={(event: React.BaseSyntheticEvent) =>
        searchFunction
          ? searchFunction(channelSearchParams, event)
          : onSearch(event)
      }
      placeholder="Search"
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{ marginTop: '6px', marginRight: '14px' }}
          >
            <Icon
              name="search-outline"
              fill={theme.palette.action.disabled}
              size="large"
            />
          </InputAdornment>
        ),
      }}
      inputRef={inputRef}
      type="text"
      value={query}
      sx={{
        width: '100%',
      }}
      data-testid="CHANNEL_SEARCH_INPUT"
    />
  );
};

export default SearchInput;
