import React, { useMemo, useState } from 'react';
import { Chip, SelectChangeEvent, Stack } from '@mui/material';
import { Select, SelectProps, SelectOption } from '@fdha/web-ui-library';
import { debounce } from 'lodash';
import { useListGroupsQuery } from '@fdha/graphql-api-admin';

const NUMBER_OF_GROUPS = 10000;

interface Group {
  name: string;
  id: string;
}

type SelectMultipleGroupProps = {
  initialSelectedGroups: string[];
  onCloseSelector?: (
    addedGroupIds: string[],
    removedGroupIds: string[]
  ) => void;
  onRemoveChips?: (removedGroupIds: string[]) => void;
  showChips?: boolean;
} & Omit<SelectProps, 'name' | 'value' | 'options'>;

const SelectMultipleGroup: React.FC<SelectMultipleGroupProps> = (props) => {
  const {
    showChips,
    initialSelectedGroups,
    onRemoveChips,
    onCloseSelector,
    ...muiProps
  } = props;

  const { data: groupsData } = useListGroupsQuery({
    variables: { first: NUMBER_OF_GROUPS },
    fetchPolicy: 'cache-and-network',
  });
  const allGroups = groupsData?.groups?.edges.map((group) => group.node);
  const [selectedGroups, setGroups] = useState<string[]>(initialSelectedGroups);

  const removeGroupsFromChips = useMemo(
    () =>
      debounce(
        (removedGroups) => onRemoveChips && onRemoveChips(removedGroups),
        1500
      ),
    [onRemoveChips]
  );

  if (!allGroups) {
    return null;
  }

  const addGroup = async (newGroup: Group) => {
    setGroups((groups) => groups.concat(newGroup.name));
  };

  const removeGroupFromSelection = (removedGroup: Group) => {
    const groups = selectedGroups.filter(
      (groupName) => groupName !== removedGroup.name
    );
    setGroups(groups);
  };

  const removeGroupFromChip = async (removedGroup: Group) => {
    const groups = selectedGroups.filter(
      (groupName) => groupName !== removedGroup.name
    );
    setGroups(groups);

    if (onRemoveChips) {
      removeGroupsFromChips(getRemovedGroupIdsFromChips(groups));
    }
  };

  const handleChangeSelectValue = async (event: SelectChangeEvent<unknown>) => {
    const value = Object.assign(new Array<string>(), event.target.value);
    const groupsLength = selectedGroups.length || 0;

    if (value?.length > groupsLength) {
      const newGroupName = value[value.length - 1];
      const newGroup = allGroups.find((group) => group.name === newGroupName);

      if (newGroup) {
        await addGroup({ name: newGroup.name, id: newGroup.id });
      }
    } else {
      const removedGroupName = selectedGroups.find(
        (groupName) => !value.includes(groupName)
      );

      const removedGroup = allGroups.find(
        (group) => group.name === removedGroupName
      );

      if (removedGroup) {
        await removeGroupFromSelection({
          name: removedGroup.name,
          id: removedGroup.id,
        });
      }
    }
  };

  const getRemovedGroupIdsFromChips = (currentGroups: string[]) => {
    const removedGroupNames = initialSelectedGroups.filter(
      (initialGroupName) =>
        !currentGroups.some(
          (currentGroupName) => initialGroupName === currentGroupName
        )
    );
    return getGroupIdsFromNames(removedGroupNames);
  };

  const getGroupIdsFromNames = (groupNames: string[]) =>
    allGroups
      .filter((groupObj) =>
        groupNames.some((groupName) => groupObj.name === groupName)
      )
      .map((groupObj) => groupObj.id);

  const getNewGroupIds = () => {
    const newGroupNames = selectedGroups.filter(
      (groupName) =>
        !initialSelectedGroups.some(
          (initialGroup) => initialGroup === groupName
        )
    );
    return getGroupIdsFromNames(newGroupNames);
  };

  const getRemovedGroupIds = () => {
    const removedGroupNames = initialSelectedGroups.filter(
      (groupName) =>
        !selectedGroups.some(
          (removedGroupName) => groupName === removedGroupName
        )
    );
    return getGroupIdsFromNames(removedGroupNames);
  };

  const getGroupsOptions = (): SelectOption[] => {
    return (
      allGroups.map((group) => {
        return {
          label: group.name,
          value: group.name,
        };
      }) || []
    );
  };

  const getDefaultPlaceholder = () =>
    selectedGroups.length ? `${selectedGroups.length} selected` : 'None';

  const isSelectedGroup = (value: string): boolean =>
    !!selectedGroups.find((groupName) => groupName === value);

  const getChips = () =>
    allGroups
      .filter((group) => isSelectedGroup(group.name))
      .map((group) => (
        <Chip
          sx={{ mt: 1, ml: 1 }}
          data-testid="CHIP"
          color="secondary"
          label={group.name}
          key={group.id}
          onDelete={() => removeGroupFromChip(group)}
        />
      ));

  const onClose = () => {
    if (onCloseSelector) {
      onCloseSelector(getNewGroupIds(), getRemovedGroupIds());
    }
  };

  return (
    <>
      <Select
        color="secondary"
        name="groups"
        title="Groups"
        selectType="checkbox"
        multiple={true}
        options={getGroupsOptions()}
        value={selectedGroups}
        placeholder={getDefaultPlaceholder()}
        onChange={handleChangeSelectValue}
        onClose={onClose}
        displayEmpty={true}
        {...muiProps}
      />
      {showChips && (
        <Stack display="contents" direction="row">
          {getChips()}
        </Stack>
      )}
    </>
  );
};

export default SelectMultipleGroup;
