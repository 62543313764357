import React from 'react';
import { Box } from '@mui/material';
import { CollapsedWrapper, Loader } from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import {
  NotesTemplate,
  useListNotesTemplatesQuery,
} from '@fdha/graphql-api-admin';

import NotesTemplateCard from '../../../../components/NotesTemplateCard/NotesTemplateCard';

export const INITIAL_NOTES_TEMPLATES = 200;

const NotesTab = () => {
  const { data, error, networkStatus } = useListNotesTemplatesQuery({
    variables: {
      first: INITIAL_NOTES_TEMPLATES,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
  const notesTemplates = data?.notesTemplates.edges.map((edge) => edge.node);

  const activeTemplates = notesTemplates?.filter(
    (template) => !template.archived
  );
  const archivedTemplates = notesTemplates?.filter(
    (template) => template.archived
  );

  const renderTemplates = (
    title: string,
    isOpen: boolean,
    templatesList?: NotesTemplate[]
  ) => (
    <CollapsedWrapper title={title} initialOpenState={isOpen}>
      {templatesList?.map((template) => (
        <NotesTemplateCard notesTemplate={template} key={template.id} />
      ))}
    </CollapsedWrapper>
  );

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  if (networkStatus === NetworkStatus.loading || !data) {
    return <Loader />;
  }

  return (
    <Box data-testid="NOTES_TEMPLATES_CARDS">
      {renderTemplates('Active', true, activeTemplates)}
      {renderTemplates('Archived', false, archivedTemplates)}
    </Box>
  );
};

export default NotesTab;
