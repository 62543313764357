import React, { useState } from 'react';
import { Button, Select, SelectOption, useDialog } from '@fdha/web-ui-library';
import { Box, SelectChangeEvent } from '@mui/material';
import { SurveysExportType, SurveyType } from '@fdha/graphql-api-admin';

import { useRequestDownload } from '../../hooks';

type Scope = 'all' | 'own';

const OPTIONS: SelectOption[] = [
  { label: 'All Patients', value: 'all' },
  { label: 'Only My Patients', value: 'own' },
];

interface Props {
  type: 'dh' | 'ct';
}

const ExportScopeDialog = ({ type }: Props) => {
  const [scope, setScope] = useState<Scope>('all');
  const [submitting, setSubmitting] = useState(false);
  const { closeDialog } = useDialog();
  const requestDownload = useRequestDownload();

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as Scope;
    setScope(value);
  };

  const handleSave = async () => {
    setSubmitting(true);
    const exportType =
      type === 'dh'
        ? SurveysExportType.DhPatients
        : SurveysExportType.CtPatients;
    const onlyOwnPatients = scope === 'own';
    try {
      await requestDownload({
        type: exportType,
        onlyOwnPatients,
        surveyType: SurveyType.Normal,
      });
      await requestDownload({
        type: exportType,
        onlyOwnPatients,
        surveyType: SurveyType.Bhb,
      });
    } finally {
      setSubmitting(false);
      closeDialog();
    }
  };

  return (
    <Box>
      <Select options={OPTIONS} value={scope} onChange={handleChange} />
      <Box display="flex" justifyContent="flex-end" mt={4} columnGap={2}>
        <Button
          color="primary"
          size="large"
          onClick={closeDialog}
          data-testId="CANCEL_DIALOG_BUTTON"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          disabled={submitting}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ExportScopeDialog;
