import {
  EligibleForTrialStatus,
  SiteStaffRole,
  SubjectStatus,
  TrialStatus,
} from '@fdha/graphql-api-admin';
import { SelectOption } from '@fdha/web-ui-library';

import { Chip } from '../components';

import { trialEligibilityLabelByValue } from './labelByValueMapper';

export const siteStaffRoleLabelByValue: Record<SiteStaffRole, string> = {
  [SiteStaffRole.Crc]: 'Clinical Research Coordinator',
  [SiteStaffRole.Pi]: 'Principle Investigator',
  [SiteStaffRole.SubI]: 'Sub-Investigator',
  [SiteStaffRole.Nurse]: 'Study Nurse',
};

export const STATUS_OPTIONS: SelectOption[] = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const trialStatusLabelByValue: Record<TrialStatus, string> = {
  [TrialStatus.Completed]: 'Completed',
  [TrialStatus.Recruiting]: 'Active - Enrolling',
  [TrialStatus.NotYetRecruiting]: 'Active - Not yet enrolling',
  [TrialStatus.Terminated]: 'Terminated',
  [TrialStatus.ActiveNotRecruiting]: 'Active - Not enrolling',
};

export type SubjectStatusOptions = Exclude<SubjectStatus, SubjectStatus.Draft>;

export const subjectStatusLabelByValue: Record<SubjectStatusOptions, string> = {
  [SubjectStatus.Discontinued]: 'Discontinued',
  [SubjectStatus.Ongoing]: 'Ongoing',
  [SubjectStatus.Screening]: 'Screening',
  [SubjectStatus.StudyCompleted]: 'Study completed',
  [SubjectStatus.ScreenFailed]: 'Screen failed',
};

export const getStatusLabel = (isActive?: boolean) => {
  if (isActive) {
    return 'Active';
  } else {
    return 'Inactive';
  }
};

export const getStatus = (isActive: boolean) => {
  if (isActive) {
    return <Chip label="Active" color="success" />;
  } else {
    return <Chip label="Inactive" color="error" />;
  }
};

export const getTrialStatusChip = (status?: TrialStatus | null) => {
  switch (status) {
    case TrialStatus.Recruiting:
      return <Chip label="Active - Enrolling" color="success" />;
    case TrialStatus.NotYetRecruiting:
      return <Chip label="Active - Not yet enrolling" color="info" />;
    case TrialStatus.Terminated:
      return <Chip label="Terminated" color="error" />;
    case TrialStatus.Completed:
      return <Chip label="Completed" color="default" />;
    case TrialStatus.ActiveNotRecruiting:
      return <Chip label="Active - Not enrolling" color="info" />;
  }
};

export const getSubjectTrialStatusChip = (status?: SubjectStatus | null) => {
  switch (status) {
    case SubjectStatus.Ongoing:
      return <Chip label="Ongoing" color="success" />;
    case SubjectStatus.Discontinued:
      return <Chip label="Discontinued" color="error" />;
    case SubjectStatus.StudyCompleted:
      return <Chip label="Study completed" color="error" />;
    case SubjectStatus.Screening:
      return <Chip label="Screening" color="info" />;
    case SubjectStatus.ScreenFailed:
      return <Chip label="Screen failed" color="error" />;
  }
};

export const trialEligibilityOptions = Object.values(
  EligibleForTrialStatus
).map((type) => ({
  label: trialEligibilityLabelByValue[type],
  value: type,
}));
