import React, { FC, useCallback, useMemo } from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { startOfToday } from 'date-fns';
import { convertDateToLocalUtc, getEarliestMFSD } from '@fdha/common-utils';
import {
  Accordion,
  CustomText,
  formatUTCDate,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  AutomationRequirementType,
  EligibleForTrialStatus,
  GetPatientUserDocument,
  PatientUser,
  UpdatePatientTrialInfoInput,
  useUpdatePatientTrialInfoMutation,
} from '@fdha/graphql-api-admin';
import { Box, Grid, Paper } from '@mui/material';

import {
  AutomationDetails,
  AutomationStatusInfo,
  EditableCardDate,
  EditableCardSelect,
} from '../../../../../components';
import {
  getAutomationItemsPerSection,
  trialEligibilityLabelByValue,
  trialEligibilityOptions,
} from '../../../../../utils';
import { useAutomation } from '../../../../../hooks';

import ViewModeTrialInformation from './ViewModeTrialInformation';
import EditModeTrialInformation from './EditModeTrialInformation';

export interface TrialInformationProps {
  patientUser?: PatientUser | null;
  isCsr?: boolean;
  isAdmin?: boolean;
}

export interface ViewEditTrialInformationProps extends TrialInformationProps {
  shouldDisableEdit?: boolean;
  handleEditMode: Dispatch<SetStateAction<boolean>>;
  handleCancel?: () => void;
}

const TrialInformation: FC<TrialInformationProps> = ({
  patientUser,
  isAdmin,
  isCsr,
}) => {
  const { showSnackbar } = useSnackbar();

  const patientId = patientUser?.id || '';

  const {
    automationItems,
    automationItemsPerAutomationRequirement,
    automationRequirementStatusPerRequirementType,
    isLoading: loadingAutomationItems,
    onAutomationRequirementChange,
  } = useAutomation(patientId);

  const [editMode, setEditMode] = useState(false);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const getMFSDMinDate = useCallback(() => {
    const earliestMedicalFoodStartDate = getEarliestMFSD(startOfToday());
    return convertDateToLocalUtc(earliestMedicalFoodStartDate);
  }, []);

  const medicalFoodStartDateUTC = useMemo(
    () => formatUTCDate(patientUser?.medicalFoodStartDate),
    [patientUser?.medicalFoodStartDate]
  );

  const shouldDisableEdit = useMemo(() => {
    return !isAdmin && !patientUser?.site && !patientUser?.trial;
  }, [isAdmin, patientUser?.site, patientUser?.trial]);

  const dependenciesStatus = useMemo(
    () => ({
      fields: [
        { name: 'Trial', isSet: !!patientUser?.trial },
        { name: 'Site', isSet: !!patientUser?.site },
      ],
    }),
    [patientUser?.trial, patientUser?.site]
  );

  const [updatePatientTrialInfo, { loading }] =
    useUpdatePatientTrialInfoMutation({
      awaitRefetchQueries: true,
      refetchQueries: [GetPatientUserDocument],
    });

  const handleSave = async (
    patientTrialInfoProps: Partial<UpdatePatientTrialInfoInput>,
    automationRequirementType?: AutomationRequirementType
  ) => {
    try {
      await updatePatientTrialInfo({
        variables: {
          patientId: patientUser?.id || '',
          patientTrialInfoProps,
        },
      });
      if (automationRequirementType) {
        onAutomationRequirementChange(automationRequirementType);
      }
      showSnackbar({
        severity: 'success',
        message: 'Trial information has been submitted.',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error updating patient trial information',
      });
    }
  };

  const isLoading = loading || loadingAutomationItems;
  const trialInfoAutomationItems =
    getAutomationItemsPerSection(automationItems, 'trialInformation') || [];

  return (
    <Accordion
      title="Trial Information"
      data-testid="TRIAL_INFO"
      rightAdornment={
        <Box display="flex" alignItems="center">
          {!!trialInfoAutomationItems.length && !isAccordionExpanded && (
            <AutomationDetails
              automationItems={trialInfoAutomationItems}
              showCount={false}
            />
          )}
          <AutomationStatusInfo />
        </Box>
      }
      onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
    >
      <Grid container spacing={2} px={2} pb={2}>
        <Grid item xs={12}>
          <Paper sx={{ py: 2, px: 3 }}>
            {editMode && !isCsr ? (
              <EditModeTrialInformation
                patientUser={patientUser}
                isAdmin={isAdmin}
                handleEditMode={setEditMode}
              />
            ) : (
              <ViewModeTrialInformation
                shouldDisableEdit={shouldDisableEdit}
                patientUser={patientUser}
                isAdmin={isAdmin}
                isCsr={isCsr}
                handleEditMode={setEditMode}
              />
            )}
          </Paper>
        </Grid>
        <EditableCardDate
          title="Medical food start date"
          initialValue={
            medicalFoodStartDateUTC ? new Date(medicalFoodStartDateUTC) : null
          }
          datePickerProps={{
            minDate: getMFSDMinDate,
            disabledMode: 'selectable',
            slots: {
              actionBar: () => (
                <CustomText text="You can select any dates, past or future, but only the highlighted ones will successfully trigger automations." />
              ),
            },
          }}
          canEdit={!isCsr}
          breakpoints={{ xs: 6 }}
          onSave={(value) =>
            handleSave(
              { medicalFoodStartDate: value },
              AutomationRequirementType.MedicalFoodStartDate
            )
          }
          isLoading={isLoading}
          dependenciesStatus={dependenciesStatus}
          automationItems={automationItemsPerAutomationRequirement.get(
            AutomationRequirementType.MedicalFoodStartDate
          )}
          automationRequirementStatus={automationRequirementStatusPerRequirementType.get(
            AutomationRequirementType.MedicalFoodStartDate
          )}
        />
        <EditableCardSelect
          title="Trial Eligibility"
          initialValue={patientUser?.eligibleForTrial || ''}
          label={
            patientUser?.eligibleForTrial
              ? trialEligibilityLabelByValue[patientUser.eligibleForTrial]
              : undefined
          }
          canEdit={!isCsr}
          isLoading={isLoading}
          breakpoints={{ xs: 6 }}
          dependenciesStatus={dependenciesStatus}
          selectProps={{
            options: trialEligibilityOptions,
          }}
          onSave={(value) =>
            handleSave(
              { eligibleForTrial: value as EligibleForTrialStatus },
              AutomationRequirementType.EligibilityInTrial
            )
          }
          automationItems={automationItemsPerAutomationRequirement.get(
            AutomationRequirementType.EligibilityInTrial
          )}
          automationRequirementStatus={automationRequirementStatusPerRequirementType.get(
            AutomationRequirementType.EligibilityInTrial
          )}
        />
      </Grid>
    </Accordion>
  );
};

export default TrialInformation;
