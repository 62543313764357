import React, { useEffect } from 'react';
import { NetworkStatus, useApolloClient } from '@apollo/client';
import { Paper, Stack, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import {
  Button,
  Table,
  RowsPerPageOptions,
  SearchableTableHeader,
} from '@fdha/web-ui-library';
import {
  SurveyInstance,
  useListSurveyInstancesQuery,
} from '@fdha/graphql-api-admin';

import {
  getHeaderCells,
  getSurveyName,
  renderTableCells,
} from '../../../../utils';
import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
  useGetUserType,
} from '../../../../hooks';

const SurveysTab = () => {
  const params = useParams();
  const apolloClient = useApolloClient();
  const [filterBy, setFilterBy] = useFilterBy<SurveyInstance>('name', '');
  const [sortBy, setSortBy] = useSortBy<SurveyInstance>('name', 'asc');

  const { isCsr } = useGetUserType();

  const filterByDebounced = useDebouncedValue(filterBy);

  const patientId = params.patientId || '';

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'surveys',
  });

  useEffect(() => {
    return () => {
      apolloClient.cache.evict({
        fieldName: 'surveyInstances',
      });
      apolloClient.cache.evict({
        fieldName: 'surveySchedules',
      });
    };
  }, [apolloClient.cache]);

  const headCells = getHeaderCells();

  const { data, error, fetchMore, loading, networkStatus } =
    useListSurveyInstancesQuery({
      variables: {
        patientId: patientId,
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const nodes = data?.surveyInstances.edges.map((edge) => {
    return {
      ...edge.node,
      name: getSurveyName(edge.node.name, edge.node.isStructured),
    };
  });
  const pageInfo = data?.surveyInstances.pageInfo;
  const totalNumberFound = data?.surveyInstances.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          patientId: patientId,
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const onRowsPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
    changeRowsPerPage(rowsPerPage);
  };

  const renderRow = (row: SurveyInstance) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        {renderTableCells(row)}
        {!isCsr && (
          <TableCell>
            <Link to={row.id} data-testid="SURVEY_INSTANCE_DETAILS_BUTTON">
              <Icon
                name="arrow-ios-forward-outline"
                fill={grey[600]}
                size="large"
                data-testid="SURVEYS_BUTTON"
              />
            </Link>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <>
      <Stack spacing={2} direction="row" alignItems="baseline">
        <SearchableTableHeader<SurveyInstance>
          headCells={headCells}
          defaultSearchField="name"
          onSearchChange={setFilterBy}
        />
        <Button
          variant="contained"
          color="secondary"
          endEvaIcon={{ name: 'arrow-ios-forward-outline' }}
          sx={{
            height: '42px',
            padding: '8px 32px',
          }}
          component={Link}
          to="schedules"
          data-testid="SURVEY_SCHEDULES_BUTTON"
        >
          Schedules
        </Button>
      </Stack>
      <Paper data-testid="SURVEYS_TABLE">
        {nodes ? (
          <Table<SurveyInstance>
            actions={!isCsr ? 'right' : undefined}
            headCells={headCells}
            isLoading={loading}
            initialOrderBy="due_at"
            totalRowCount={totalNumberFound}
            renderRow={renderRow}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            rows={nodes}
            withPagination
            onSortChange={setSortBy}
          />
        ) : null}
      </Paper>
    </>
  );
};

export default SurveysTab;
