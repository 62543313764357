export const caregiverList = [
  {
    label: 'Spouse',
    value: 'spouse',
  },
  {
    label: 'Child',
    value: 'child',
  },
  {
    label: 'Other Relative',
    value: 'otherRelative',
  },
  {
    label: 'Home Healthcare Worker',
    value: 'homeHealthcareWorker',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
export const getCaregiversWithI18n = () => {
  return caregiverList.map((caregiver) => {
    return {
      ...caregiver,
      i18nKey: `common:caregivers.${caregiver.value}`,
    };
  });
};
