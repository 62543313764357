import React, { FC } from 'react';
import { DeliveryStatus } from '@fdha/graphql-api-admin';
import { formatUTCDate, Icon, Typography } from '@fdha/web-ui-library';
import { Stack, useTheme } from '@mui/material';

interface WeekMenuDeliveryInformationProps {
  deliveryStatus?: DeliveryStatus | null;
  openChangesDate?: string;
  opensOnDate?: string | null;
  deliveryDate?: string;
}

interface DeliveryInfoProps {
  icon: string;
  iconColor: string;
  message: React.ReactNode;
}

const WeekMenuDeliveryInformation: FC<WeekMenuDeliveryInformationProps> = ({
  deliveryStatus,
  openChangesDate,
  opensOnDate,
  deliveryDate,
}) => {
  const theme = useTheme();

  const DeliveryInfo: React.FC<DeliveryInfoProps> = ({
    icon,
    iconColor,
    message,
  }) => (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Icon name={icon} size="medium" fill={iconColor} />
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {message}
      </Typography>
    </Stack>
  );

  return deliveryStatus === DeliveryStatus.Open ? (
    <DeliveryInfo
      icon="unlock-outline"
      iconColor={theme.palette.success.dark}
      message={
        <>
          Open until <b>{formatUTCDate(openChangesDate, 'monthDay')}</b> at 9 AM
          ET - Delivery on <b>{formatUTCDate(deliveryDate, 'monthDay')}</b>
        </>
      }
    />
  ) : (
    <DeliveryInfo
      icon="calendar-outline"
      iconColor={theme.palette.text.secondary}
      message={
        <>
          Order opens on{' '}
          <b>{opensOnDate ? formatUTCDate(opensOnDate, 'monthDay') : ''}</b> -
          Delivery on <b>{formatUTCDate(deliveryDate, 'monthDay')}</b>
        </>
      }
    />
  );
};

export default WeekMenuDeliveryInformation;
