import React, { FC } from 'react';
import { ProjectedDeliveryMenu } from '@fdha/graphql-api-admin';
import { Typography } from '@fdha/web-ui-library';
import { Card, CardActionArea, Stack, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';

import { weekdayLabelByValue } from '../../utils';

import WeekMenuDeliveryInformation from './WeekMenuDeliveryInformation';

interface WeekMenuCardProps {
  projectedDeliveryMenu: ProjectedDeliveryMenu;
  patientId: string;
}

const WeekMenuCard: FC<WeekMenuCardProps> = ({
  projectedDeliveryMenu,
  patientId,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { id, weekOfCycle, deliveryDayOfWeek, delivery } =
    projectedDeliveryMenu;

  return (
    <Card sx={{ boxShadow: theme.shadows[2] }}>
      <Stack
        padding={1.5}
        spacing={0.5}
        sx={{ backgroundColor: theme.palette.primary.background }}
      >
        <Typography>
          Week {weekOfCycle} - {weekdayLabelByValue[deliveryDayOfWeek]}
        </Typography>
        <WeekMenuDeliveryInformation
          deliveryStatus={delivery.status}
          openChangesDate={delivery.openChanges}
          opensOnDate={delivery.opensOn}
          deliveryDate={delivery.deliveryDate}
        />
      </Stack>
      <CardActionArea
        onClick={() => navigate(`/patient/${patientId}/deliveryMenu/${id}`)}
        sx={{ padding: 1 }}
      >
        <Typography variant="body2" color={theme.palette.info.main}>
          See more details
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default WeekMenuCard;
