import React from 'react';
import { Typography, Box, Link } from '@mui/material';
import { CoachInfo, useDialog } from '@fdha/web-ui-library';
import { Coach, useGetProfileQuery } from '@fdha/graphql-api-admin';

import AssignCoachDialog from '../AssignCoachDialog/AssignCoachDialog';
import { useGetUserType } from '../../hooks';

interface ManageCoachInfoProps {
  mainCoach?: Coach | null;
  backupCoach?: Coach | null;
  patientId: string;
  patientName: string;
}

function ManageCoachInfo(props: ManageCoachInfoProps) {
  const { isAdmin } = useGetUserType();
  const { data } = useGetProfileQuery();
  const { openDialog } = useDialog();

  const mainCoach = { id: props.mainCoach?.id, name: props.mainCoach?.name };
  const backupCoach = {
    id: props.backupCoach?.id,
    name: props.backupCoach?.name,
  };

  const openManageCoachDialog = () => {
    openDialog({
      title: `Manage ${props.patientName}'s coaches`,
      content: (
        <AssignCoachDialog
          patientId={props.patientId}
          mainCoach={props.mainCoach}
          backupCoach={props.backupCoach}
        />
      ),
    });
  };

  return (
    <Box marginTop="16px" data-testid="MANAGE_COACH_INFO">
      <CoachInfo
        mainCoach={mainCoach}
        backupCoach={backupCoach}
        meId={data?.me.id}
      />
      {isAdmin && (
        <Link component="button" onClick={openManageCoachDialog}>
          <Typography variant="body1">Click to manage</Typography>
        </Link>
      )}
    </Box>
  );
}

export default ManageCoachInfo;
