import React from 'react';
import {
  Box,
  Paper,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';
import { HeadCell, Table } from '@fdha/web-ui-library';
import { useGetDocumentCountsQuery } from '@fdha/graphql-api-admin';

import { useGetUserType } from '../../../../hooks';

interface TableFolder {
  type: string;
  count?: number;
}

const getHeadCells = () => {
  const headCells: HeadCell<TableFolder>[] = [
    { id: 'type', label: 'Folder Name' },
    {
      id: 'count',
      label: 'Files Count',
    },
  ];

  return headCells;
};

const DocumentsTab = () => {
  const { patientId } = useParams();
  const theme = useTheme();
  const { data, loading } = useGetDocumentCountsQuery({
    variables: {
      patientId: patientId ?? '',
    },
    fetchPolicy: 'cache-and-network',
  });
  const headCells = getHeadCells();

  const { isCsr } = useGetUserType();

  const rows = [
    {
      type: 'Coaches',
      count: data?.documentCounts?.coach,
    },
    ...(!isCsr
      ? [
          {
            type: 'Patient',
            count: data?.documentCounts?.patient,
          },
        ]
      : []),
  ];

  const renderRow = (row: TableFolder) => {
    const isPlural = row.count && row.count > 1;
    return (
      <TableRow hover key={row.type} data-testid="FOLDER_TABLE_ROW">
        <TableCell data-testid="FOLDER_ICON_CELL">
          <Icon
            name="folder"
            fill={theme.palette.text.secondary}
            size="large"
          />
        </TableCell>
        <TableCell data-testid="FOLDER_NAME_CELL">{`${row.type} Uploads`}</TableCell>
        <TableCell data-testid="FOLDER_COUNT_CELL">{`${row.count} file${
          isPlural ? 's' : ''
        }`}</TableCell>

        <TableCell padding="checkbox">
          <Link to={row.type.toLowerCase()} data-testid="FOLDER_BUTTON">
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box data-testid="DOCUMENTS_CONTAINER">
      <Typography variant="h5" marginBottom={3}>
        Folders
      </Typography>
      <Paper data-testid="FOLDER_TABLE">
        <Table<TableFolder>
          actions="both"
          headCells={headCells}
          renderRow={renderRow}
          rows={rows}
          isLoading={loading}
        />
      </Paper>
    </Box>
  );
};

export default DocumentsTab;
