import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  Button,
  TextField,
  useSnackbar,
  formatUTCDate,
  Select,
  maskPhone,
  DecimalTextField,
  AddressInputFields,
  AddressFields,
  heightValidation,
  weightValidation,
  emailValidation,
} from '@fdha/web-ui-library';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import {
  PhysicalActivityLevel,
  UserGender,
  useUpdatePatientUserMutation,
} from '@fdha/graphql-api-admin';

import {
  genderOptions,
  physicalActivityLevelOptions,
} from '../../../../../utils';

import { ViewEditAccountInformationProps } from './AccountInformation';

interface Information {
  name: string;
  weight: string;
  height: string;
  gender: string | null;
  physicalActivity: string | null;
  address: AddressInputFields;
  complement: string;
  caregiverName?: string | null;
  caregiverEmail?: string | null;
}

const EditModeAccountInformation = (props: ViewEditAccountInformationProps) => {
  const { showSnackbar } = useSnackbar();

  const [updatePatientUser] = useUpdatePatientUserMutation();

  const handleCancel = () => {
    props.handleEditMode(false);
    showSnackbar({
      severity: 'warning',
      message: 'Changes not saved',
    });
  };

  const handleSave = async (values: Information) => {
    try {
      await updatePatientUser({
        variables: {
          id: props.patientUser?.id || '',
          props: {
            name: values.name,
            weight: +values.weight,
            height: +values.height,
            gender: values.gender as UserGender,
            physical_activity_level:
              values.physicalActivity as PhysicalActivityLevel,
            caregiver: {
              name: values.caregiverName,
              primary_caregiver_email: values.caregiverEmail,
            },
            address: {
              formatted: values.address.formatted,
              country: values.address.country,
              complement: values.complement,
              region: values.address.region,
              street_address: values.address.streetAddress,
              postal_code: values.address.postalCode,
              locality: values.address.locality,
            },
          },
        },
      });
      props.handleEditMode(false);
      showSnackbar({
        severity: 'success',
        message: 'Changes saved!',
      });
    } catch (error: any) {
      console.error(error.message);
      showSnackbar({
        severity: 'error',
        message: 'Error to update patient data',
      });
    }
  };

  const initialValues: Information = {
    name: props.patientUser?.name ?? '',
    weight: props.patientUser?.weight.toString() ?? '0.0',
    height: props.patientUser?.height.toString() ?? '0.0',
    gender: props.patientUser?.gender ?? '',
    physicalActivity: props.patientUser?.physical_activity_level ?? '',
    address: {
      formatted: props.patientUser?.address?.formatted ?? '',
      country: props.patientUser?.address?.country ?? '',
      region: props.patientUser?.address?.region ?? '',
      streetAddress: props.patientUser?.address?.street_address ?? '',
      postalCode: props.patientUser?.address?.postal_code ?? '',
      locality: props.patientUser?.address?.locality ?? '',
    },
    complement: props.patientUser?.address?.complement ?? '',
    caregiverName: props.patientUser?.caregiver?.name ?? '',
    caregiverEmail: props.patientUser?.caregiver?.primary_caregiver_email ?? '',
  };

  const requiredMessage = 'Required fields cannot be empty';

  const defaultValidation = Yup.string().trim().required(requiredMessage);

  const validationSchema = Yup.object().shape({
    name: defaultValidation,
    weight: weightValidation.required(requiredMessage),
    height: heightValidation.required(requiredMessage),
    gender: defaultValidation,
    physicalActivity: defaultValidation,
    address: Yup.object().test(
      'address',
      requiredMessage,
      (address) => !!address.formatted
    ),
    caregiverEmail: emailValidation,
  });

  const handleErrors = (
    errors: FormikErrors<Information>,
    touched: FormikTouched<Information>
  ) => {
    return {
      name: touched.name && errors.name,
      weight: touched.weight && errors.weight,
      height: touched.height && errors.height,
      gender: touched.gender && errors.gender,
      physicalActivity: touched.physicalActivity && errors.physicalActivity,
      address: touched.address && errors.address,
      complement: touched.complement && errors.complement,
      caregiverName: touched.caregiverName && errors.caregiverName,
      caregiverEmail: touched.caregiverEmail && errors.caregiverEmail,
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        isSubmitting,
        errors,
        touched,
      }) => {
        const errorState = handleErrors(errors, touched);

        return (
          <Form>
            <Box marginBottom={3} data-testid="ACCOUNT_EDIT_MODE">
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={8}>
                  <TextField
                    title="Name"
                    value={values.name}
                    name="name"
                    onChange={handleChange}
                    error={!!errorState.name}
                    helperText={errorState.name}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Email"
                    value={props.patientUser?.email}
                    disabled
                  />
                </Grid>
                {!props.isCsr && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        title="Birth date"
                        value={formatUTCDate(props.patientUser?.birthdate)}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DecimalTextField
                        title="Weight"
                        value={values.weight}
                        name="weight"
                        placeholder="70,0 kg"
                        handleChange={(value) => setFieldValue('weight', value)}
                        error={!!errorState.weight}
                        helperText={errorState.weight}
                        numberOfDecimals={1}
                        maxLength={5}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DecimalTextField
                        title="Height"
                        value={values.height}
                        name="height"
                        placeholder="175 cm"
                        handleChange={(value) => setFieldValue('height', value)}
                        error={!!errorState.height}
                        helperText={errorState.height}
                        numberOfDecimals={1}
                        maxLength={5}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Select
                        title="Sex"
                        value={values.gender}
                        options={genderOptions}
                        name="gender"
                        onChange={handleChange}
                        titleVariant="subtitle2"
                        displayEmpty
                        error={!!errorState.gender}
                        helperText={errorState.gender}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        title="Physical activity level"
                        value={values.physicalActivity}
                        options={physicalActivityLevelOptions}
                        name="physicalActivity"
                        onChange={handleChange}
                        titleVariant="subtitle2"
                        displayEmpty
                        error={!!errorState.physicalActivity}
                        helperText={errorState.physicalActivity}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <AddressFields
                    address={values.address}
                    complement={values.complement}
                    onChangeAddress={(address) => {
                      setFieldValue('address', address);
                    }}
                    onChangeComplement={(complement) => {
                      setFieldValue('complement', complement);
                    }}
                    addressError={errorState.address as string}
                    complementError={errorState.complement as string}
                    isRequired
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Mobile phone number"
                    value={maskPhone(props.patientUser?.phone_number)}
                    disabled
                  />
                </Grid>
                {!props.isCsr && (
                  <>
                    <Grid item xs={8} />
                    <Grid item xs={4}>
                      <TextField
                        title="Caregiver name"
                        value={values.caregiverName}
                        name="caregiverName"
                        onChange={handleChange}
                        error={!!errorState.caregiverName}
                        helperText={errorState.caregiverName}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        title="Caregiver email"
                        value={values.caregiverEmail}
                        name="caregiverEmail"
                        onChange={handleChange}
                        error={!!errorState.caregiverEmail}
                        helperText={errorState.caregiverEmail}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Box textAlign="end">
              <Button
                onClick={handleCancel}
                sx={{ padding: '8px 34px' }}
                data-testid="CANCEL_EDITION_BUTTON"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: '8px 34px' }}
                type="submit"
                data-testid="SAVE_EDITION_BUTTON"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditModeAccountInformation;
