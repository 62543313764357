import { Box, Typography } from '@mui/material';
import React from 'react';
import { useChatContext, useChannelStateContext } from 'stream-chat-react';
import { Button, ConditionalWrapper } from '@fdha/web-ui-library';
import { useNavigate } from 'react-router-dom';

import Avatar from '../Avatar/Avatar';
import { ChannelType } from '../../states/streamChannelTypeState';
import { ChatUser, getDisplaySubtitle } from '../../utils';

import HeaderMenu from './HeaderMenu/HeaderMenu';

interface ChannelHeaderProps {
  isWidget: boolean;
}

const ChannelHeader: React.FC<ChannelHeaderProps> = ({ isWidget }) => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();

  const navigate = useNavigate();

  const isBroadcastChannel = channel.type === ChannelType.Broadcast;
  const members = Object.values(channel.state.members);
  const otherMembers = members.filter(({ user }) => user?.id !== client.userID);
  const chatUser = otherMembers[0]?.user as ChatUser;

  const displayAvatar = chatUser?.image;
  const displayName =
    channel.type === ChannelType.Broadcast
      ? channel?.data?.name
      : chatUser?.name;
  const displaySubtitle = getDisplaySubtitle(
    chatUser?.userType,
    chatUser?.subjectId
  );
  const displayPresence = chatUser?.online;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
      minHeight="72px"
      paddingLeft={2}
      paddingRight={3}
      paddingY={2}
      borderBottom="solid 1px rgba(0, 0, 0, .12)"
      data-testid="CHANNEL_HEADER"
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        {channel.type === ChannelType.Messaging ? (
          <ConditionalWrapper
            showWrapper={!isWidget}
            wrapper={(children) => (
              <Button
                onClick={() => navigate(`/patient/${chatUser?.id}`)}
                size="large"
              >
                {children}
              </Button>
            )}
          >
            <Box marginRight={3} minWidth="40px">
              <Avatar
                picture={displayAvatar}
                name={displayName}
                presence={displayPresence ? 'online' : 'offline'}
                size={40}
              />
            </Box>
            <Box>
              <Typography variant="h6">{displayName}</Typography>
              {!isBroadcastChannel && displaySubtitle && (
                <Typography variant="body1" textTransform="none">
                  {displaySubtitle}
                </Typography>
              )}
            </Box>
          </ConditionalWrapper>
        ) : (
          <Box marginLeft={3}>
            <Typography variant="h6">{displayName}</Typography>
          </Box>
        )}
      </Box>
      {channel.type === ChannelType.Messaging && (
        <Box lineHeight="1em" display="flex">
          {!isWidget && <HeaderMenu />}
        </Box>
      )}
    </Box>
  );
};

export default ChannelHeader;
