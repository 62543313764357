import { Box, Tab, Tabs as MuiTabs } from '@mui/material';
import React from 'react';

import { ErrorStateProps } from '../../../../models/FormBuilderProps';

interface TabsProps {
  tabs: string[];
  selectedTab: number;
  errorState?: ErrorStateProps;
  handleChangeTab: (event: React.SyntheticEvent, newTab: number) => void;
}

function Tabs({ tabs, selectedTab, handleChangeTab, errorState }: TabsProps) {
  const getError = (tab: string) => {
    const editTabHasError =
      errorState?.title ||
      (errorState?.possibleAnswers.errors &&
        errorState?.possibleAnswers.touched);
    const optionsTabHasError =
      errorState?.placeholderText ||
      errorState?.maxLength ||
      errorState?.errorMessage;

    if (
      (editTabHasError && tab === 'Edit') ||
      (optionsTabHasError && tab === 'Options')
    ) {
      return { color: 'error.main', opacity: 1 };
    }
  };

  return (
    <Box borderBottom={1} borderColor="divider" marginBottom="12px">
      <MuiTabs value={selectedTab} onChange={handleChangeTab}>
        {tabs.map((tab, index) => (
          <Tab
            label={tab}
            key={index}
            data-testid={`${tab.toUpperCase()}_TAB`}
            sx={getError(tab)}
          />
        ))}
      </MuiTabs>
    </Box>
  );
}

export default Tabs;
