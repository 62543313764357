import React, { FC } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { formatUTCDate, Icon, LabelBox } from '@fdha/web-ui-library';

import { getStatusLabel, siteStaffRoleLabelByValue } from '../../../utils';

import { ProfileInformationViewOrEditProps } from './ProfileInformation';

const ViewModeProfileInformation: FC<ProfileInformationViewOrEditProps> = ({
  handleEditMode,
  siteStaffUser,
}) => {
  return (
    <>
      <Box marginBottom={3} data-testid="PROFILE_SHOW_MODE">
        <Grid container columnSpacing={3} rowSpacing={0}>
          <LabelBox size={8} label="Name" value={siteStaffUser?.name} />
          <LabelBox size={4} label="Email" value={siteStaffUser?.email} />
          <LabelBox
            size={4}
            label="Associated site"
            value={siteStaffUser?.site.name}
          />
          <LabelBox
            size={4}
            label="Staff role"
            value={
              siteStaffUser?.role &&
              siteStaffRoleLabelByValue[siteStaffUser.role]
            }
          />
          <LabelBox
            size={4}
            label="Site staff status"
            value={getStatusLabel(siteStaffUser?.is_active)}
          />
          <LabelBox
            size={4}
            label="Joined on"
            value={formatUTCDate(siteStaffUser?.created_at)}
          />
        </Grid>
      </Box>
      <Box textAlign="end">
        <IconButton
          onClick={() => handleEditMode(true)}
          data-testid="EDIT_BUTTON"
        >
          <Icon name="edit-outline" size="medium" fill={grey[600]} />
        </IconButton>
      </Box>
    </>
  );
};

export default ViewModeProfileInformation;
