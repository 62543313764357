import { Box, useTheme } from '@mui/material';
import React from 'react';

interface MainContainerProps {
  children: React.ReactNode;
  activeTabId: string;
}

function MainContainer({ activeTabId, children }: MainContainerProps) {
  const theme = useTheme();
  return (
    <Box
      aria-labelledby={activeTabId}
      bgcolor={theme.palette.background.default}
      borderRadius="15px 0px 0px 15px"
      id={`tabpanel-${activeTabId}`}
      role="tabpanel"
      width="100%"
      overflow="auto"
    >
      {children}
    </Box>
  );
}

export default MainContainer;
