import React, { useEffect, useState } from 'react';
import { NetworkStatus, useApolloClient } from '@apollo/client';
import {
  Box,
  FormControlLabel,
  Paper,
  Switch,
  TableCell,
  TableRow,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import {
  Button,
  Loader,
  useDialog,
  formatDate,
  getAge,
  Table,
  HeadCell,
  SearchableTableHeader,
  cancerTypes,
} from '@fdha/web-ui-library';
import { parseISO } from 'date-fns';
import Icon from 'react-eva-icons';
import {
  FilterOp,
  PatientUser,
  SurveysExportType,
  SurveyType,
} from '@fdha/graphql-api-admin';

import AssignCoachDialog from '../../../../components/AssignCoachDialog/AssignCoachDialog';
import Avatar from '../../../../components/Avatar/Avatar';
import Chip from '../../../../components/Chip/Chip';
import LinearProgress from '../../../../components/LinearProgress/LinearProgress';
import ProgressStatusChips from '../../../../components/ProgressStatusChips/ProgressStatusChips';
import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useGetPatientsList,
  useTable,
  useRequestDownload,
  useGetUserType,
} from '../../../../hooks';
import ExportScopeDialog from '../../../../components/ExportScopeDialog/ExportScopeDialog';

const getHeadCells = (isAdmin: boolean, isCsr?: boolean) => {
  const headCells: HeadCell<PatientUser>[] = [
    { id: 'name', label: 'Patient name', sortable: true, searchable: true },
    { id: 'created_at', label: 'Joined on', sortable: true, searchable: false },
    { id: 'birthdate', label: 'Age', sortable: true, searchable: false },
    ...Object.assign(
      !isCsr
        ? [
            {
              id: 'cancer_type',
              label: 'Cancer type',
              sortable: true,
              searchable: true,
            },
          ]
        : []
    ),
    {
      id: 'progress:survey',
      label: 'Survey status',
      sortable: false,
      searchable: false,
    },
    {
      id: 'progress:goal',
      label: 'Goal status',
      sortable: false,
      searchable: false,
    },
    {
      id: 'progress:task',
      label: 'Task status',
      sortable: false,
      searchable: false,
    },
    ...Object.assign(
      isAdmin
        ? [
            {
              id: 'coach:name',
              label: 'Coach',
              sortable: true,
              searchable: true,
            },
          ]
        : []
    ),
  ];

  return headCells;
};

const DHPatientsTab = () => {
  const { openDialog } = useDialog();
  const { isAdmin, isCsr } = useGetUserType();
  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'dhPatients',
  });
  const requestDownload = useRequestDownload();

  const [onlyMyPatients, setOnlyMyPatients] = useState(true);

  useEffect(() => {
    setOnlyMyPatients(!isAdmin);
  }, [isAdmin]);

  const client = useApolloClient();

  useEffect(() => {
    return () => {
      /* Clearing admin patients data from cache in order to not mix CT and DH */
      client.cache.evict({
        fieldName: 'allPatients',
      });

      /* Clearing coach patients data from cache in order to always get updated
       * data when table mounts. So we avoid a lot of refetch when data related
       * to this table changes
       */
      client.cache.evict({
        fieldName: 'myPatients',
      });
    };
  }, [client.cache]);

  const [queryFilterBy, setQueryFilterBy] = useFilterBy<PatientUser>(
    'name',
    ''
  );
  const [sortBy, setSortBy] = useSortBy<PatientUser>('name', 'asc');

  const queryFilterByDebounced = useDebouncedValue(queryFilterBy);
  const isNotCtFilter = { field: 'is_ct', op: FilterOp.Equal, value: 'false' };
  const filters = [isNotCtFilter, queryFilterByDebounced];

  const {
    nodes,
    pageInfo,
    totalNumberFound,
    fetchMore,
    loading,
    networkStatus,
  } = useGetPatientsList(
    !onlyMyPatients,
    isAdmin === undefined,
    rowsPerPage,
    true,
    sortBy,
    filters
  );

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const headCells = getHeadCells(!!isAdmin, isCsr);

  const handleAssignCoach = (row: PatientUser) => {
    openDialog({
      title: `Assign coach to ${row.name} `,
      content: <AssignCoachDialog patientId={row.id} />,
    });
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyMyPatients(event.target.checked);
  };

  const handleExportData = async () => {
    if (isAdmin) {
      openDialog({
        title: 'Export data for:',
        content: <ExportScopeDialog type="dh" />,
      });
    } else {
      await requestDownload({
        type: SurveysExportType.DhPatients,
        surveyType: SurveyType.Normal,
      });
      await requestDownload({
        type: SurveysExportType.DhPatients,
        surveyType: SurveyType.Bhb,
      });
    }
  };

  const renderRow = (row: PatientUser) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="PATIENT_NAME_CELL">
          <Avatar type="imageText" name={row.name} picture={row.picture} />
        </TableCell>
        <TableCell data-testid="JOINED_ON_CELL">
          {row.created_at && formatDate(parseISO(row.created_at))}
        </TableCell>
        <TableCell data-testid="AGE_CELL">{getAge(row.birthdate)}</TableCell>
        {!isCsr && (
          <TableCell data-testid="CANCER_TYPE_CELL">
            {
              cancerTypes.find(
                (cancerType) => cancerType.value === row.cancer_type
              )?.label
            }
          </TableCell>
        )}
        <TableCell data-testid="SURVEY_CELL">
          <ProgressStatusChips progressStatus={row.progress?.survey} />
        </TableCell>
        <TableCell data-testid="GOAL_CELL">
          {row.progress?.goal != null ? (
            <LinearProgress value={row.progress.goal * 100} withLabel={true} />
          ) : (
            <Chip
              key="none"
              label="none"
              color="default"
              data-testid="STATUS_CHIP"
            />
          )}
        </TableCell>
        <TableCell data-testid="TASK_CELL">
          <ProgressStatusChips progressStatus={row.progress?.task} />
        </TableCell>
        {isAdmin && (
          <TableCell data-testid="PATIENT_COACH_CELL">
            {row.coach ? (
              row.coach?.name
            ) : (
              <Button
                variant="contained"
                color="secondary"
                startEvaIcon={{ name: 'plus-outline' }}
                onClick={() => handleAssignCoach(row)}
              >
                Assign
              </Button>
            )}
          </TableCell>
        )}
        <TableCell padding="checkbox">
          <Link
            to={`/patient/${row.id}`}
            state={{ backRoute: `/dh` }}
            data-testid="PATIENT_PROFILE_BUTTON"
          >
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  if (isAdmin === undefined) {
    return <Loader />;
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="baseline"
        columnGap={3}
      >
        <SearchableTableHeader<PatientUser>
          headCells={headCells}
          defaultSearchField="name"
          onSearchChange={setQueryFilterBy}
        />
        {isAdmin && (
          <FormControlLabel
            componentsProps={{ typography: { noWrap: true } }}
            control={
              <Switch
                checked={onlyMyPatients}
                color="secondary"
                onChange={handleSwitch}
              />
            }
            label="Only my patients"
            sx={{ marginRight: 0 }}
          />
        )}
        {!isCsr && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            startEvaIcon={{ name: 'download-outline' }}
            data-testid="EXPORT_BUTTON"
            onClick={handleExportData}
          >
            Export
          </Button>
        )}
      </Box>
      <Paper data-testid="DH_PATIENTS_TABLE">
        {nodes && (
          <Table<PatientUser>
            actions="right"
            initialOrderBy="name"
            headCells={headCells}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={changeRowsPerPage}
            renderRow={renderRow}
            rows={nodes}
            isLoading={loading}
            totalRowCount={totalNumberFound}
            withPagination
            onSortChange={setSortBy}
          />
        )}
      </Paper>
    </>
  );
};

export default DHPatientsTab;
