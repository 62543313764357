import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { BasePage, Button } from '@fdha/web-ui-library';
import { BoxProps } from '@mui/material';

interface BasePageProps extends BoxProps {
  ['data-testId']?: string;
}

type BackButtonProps = { to?: string } | { handleClick?: () => void };

const AdminBasePage: React.FC<BasePageProps> = (props) => {
  return <BasePage {...props}>{props.children}</BasePage>;
};

const BackButton: React.FC<BackButtonProps> = (props) => {
  const location = useLocation();

  if ('handleClick' in props) {
    return (
      <BasePage.BackButton handleClick={props.handleClick} component={Button} />
    );
  }
  const state = location.state;
  const navigatePath = state?.backRoute ?? '..';

  const toPath = 'to' in props ? props.to : navigatePath;
  return <BasePage.BackButton to={toPath} component={Link} />;
};

const Title: React.FC<PropsWithChildren> = ({ children }) => {
  return <BasePage.Title>{children}</BasePage.Title>;
};

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return <BasePage.Wrapper>{children}</BasePage.Wrapper>;
};

export default Object.assign(AdminBasePage, { BackButton, Title, Wrapper });
