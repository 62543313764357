import React, { FC, useEffect } from 'react';
import { Box, IconButton, Paper, styled } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Icon from 'react-eva-icons';
import { grey } from '@mui/material/colors';
import { Form, Formik, FormikErrors } from 'formik';
import { Button, useDialog, useSnackbar } from '@fdha/web-ui-library';
import {
  GetFlaggedNoteDocument,
  ListNotesDocument,
  useAddNoteMutation,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from '@fdha/graphql-api-admin';

import {
  NotesProps,
  removeDraftNote,
  removeEditNote,
} from '../../../../states/notesState';
import { useShouldAutoSave, useLocalStorage } from '../../../../hooks';
import { modules } from '../../../../utils';

import Card from './Card';

interface NoteCardProps {
  note: NotesProps;
  patientId: string;
  index: number;
}

const Editor = styled(ReactQuill)(() => ({
  '& > div, & .ql-container.ql-snow': {
    border: 'none',
  },
  '& > div, & .ql-toolbar.ql-snow': {
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
  },
}));

const NoteCard: FC<NoteCardProps> = ({ note, patientId, index }) => {
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const { shouldAutoSave } = useShouldAutoSave();
  const { saveToLocalStorage, removeFromLocalStorage } =
    useLocalStorage('notes');

  const [addNote] = useAddNoteMutation();
  const [updateNote] = useUpdateNoteMutation();
  const [deleteNote] = useDeleteNoteMutation();

  const initialValues = {
    name: note.name,
    content: note.content,
  };

  let formValues = initialValues;

  useEffect(() => {
    if (shouldAutoSave) {
      const data = {
        [patientId]: {
          index,
          ...note,
          ...formValues,
        },
      };
      saveToLocalStorage(data);
    }
  }, [shouldAutoSave, formValues, index, note, patientId, saveToLocalStorage]);

  const handleSubmit = async (values: { name: string; content: string }) => {
    try {
      if (note.id) {
        await updateNote({
          variables: { id: note.id, note: values },
          refetchQueries: note.flagged
            ? [ListNotesDocument, GetFlaggedNoteDocument]
            : [ListNotesDocument],
        });
        removeEditNote(note);
      } else {
        await addNote({
          variables: { note: values, patientId },
          refetchQueries: [ListNotesDocument],
        });
        removeDraftNote(note);
      }
      removeFromLocalStorage(patientId);
      showSuccessSnackbar('Note saved!');
    } catch (error) {
      console.error(error);
      showErrorSnackbar('Error editing/saving note');
    }
  };

  const handleCancel = () => {
    if (note.id) {
      removeEditNote(note);
    } else {
      removeDraftNote(note);
    }
    removeFromLocalStorage(patientId);
    showSnackbar({ message: 'Changes not saved', severity: 'warning' });
  };

  const handleDelete = async () => {
    if (note.id) {
      openDialog({
        title:
          'Are you sure you want to delete this note? You will not be able to recover it.',
        content: null,
        confirmButtonLabel: 'Delete',
        cancelButtonLabel: 'Keep',
        handleConfirm: async () => {
          try {
            removeEditNote(note);
            await deleteNote({
              variables: { id: note.id },
              refetchQueries: note.flagged
                ? [ListNotesDocument, GetFlaggedNoteDocument]
                : [ListNotesDocument],
            });
            removeFromLocalStorage(patientId);
            showSuccessSnackbar('Note deleted');
          } catch (error) {
            showErrorSnackbar('Error deleting note');
          } finally {
            closeDialog();
          }
        },
      });
    } else {
      removeDraftNote(note);
      removeFromLocalStorage(patientId);
    }
  };

  const showErrorSnackbar = (message: string) => {
    showSnackbar({ message: message, severity: 'error' });
  };

  const showSuccessSnackbar = (message: string) => {
    showSnackbar({ message: message, severity: 'success' });
  };

  const validate = (values: { name: string; content: string }) => {
    let errors: FormikErrors<{ name: string }> = {};

    if (!values.name.trim()) {
      errors.name = 'Note title is required';
      showErrorSnackbar(errors.name);
    }

    return errors;
  };

  return (
    <Paper>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, isSubmitting }) => {
          formValues = values;

          return (
            <Form data-testid={`NOTE_CARD_FORM_${note.name}`}>
              <Card note={note} isEditing data-testid="NOTE_CARD_FORM">
                <Editor
                  modules={modules}
                  value={values.content}
                  onChange={handleChange('content')}
                  preserveWhitespace
                />
              </Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
                data-testid={'EDIT_CARD'}
              >
                <IconButton onClick={handleDelete}>
                  <Icon name="trash-2-outline" size="large" fill={grey[600]} />
                </IconButton>
                <Box>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default NoteCard;
