import { useSnackbar } from '@fdha/web-ui-library';
import { format } from 'date-fns';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useRequestSurveysExportMutation,
  useGetSurveyExportStatusQuery,
  SurveysExportOptions,
  SurveysExportType,
  SurveyType,
} from '@fdha/graphql-api-admin';

import downloadFile from '../utils/downloadFile';

interface DownloadContext {
  requestDownload: (opts: SurveysExportOptions) => Promise<void>;
}

const Context = createContext<DownloadContext>({
  requestDownload: async () => {},
});

export const useSurveyExportDownload = () => {
  const [pollingId, setPollingId] = useState<string | undefined>();
  const optionsRef = useRef<SurveysExportOptions>();
  const [generateSurveys] = useRequestSurveysExportMutation();
  const { data, startPolling, stopPolling } = useGetSurveyExportStatusQuery({
    skip: !pollingId,
    variables: { id: pollingId ?? '' },
  });
  const snackbar = useSnackbar();

  let downloadPromiseResolve = useRef<(value: unknown) => void>();
  let downloadPromiseReject = useRef<(value: unknown) => void>();

  const requestDownload = async (opts: SurveysExportOptions) => {
    optionsRef.current = opts;

    const downloadPromise = new Promise((resolve, reject) => {
      downloadPromiseResolve.current = resolve;
      downloadPromiseReject.current = reject;
    });

    try {
      const response = await generateSurveys({
        variables: { opts },
      });
      setPollingId(response.data?.requestSurveysExport);

      const typeLabel =
        opts.type === SurveysExportType.Group
          ? opts.groupName
          : opts.type === SurveysExportType.CtPatients
          ? 'Clinical Trial Patients'
          : 'Digital Health Patients';
      snackbar.showSnackbar({
        title: 'Loading',
        message: `Exporting data for ${typeLabel}`,
        variant: 'loading',
      });
    } catch (error) {
      if (downloadPromiseReject.current) {
        downloadPromiseReject.current(error);
      }
    }

    await downloadPromise;
  };

  const buildFileName = () => {
    const date = format(new Date(), 'yyyyMMdd');

    if (optionsRef.current?.surveyType === SurveyType.Bhb) {
      return `SERABELISIB_DATA_BHB_${date}`;
    }

    const type = optionsRef.current?.type;
    const groupName = optionsRef.current?.groupName;
    const typeLabel =
      type === SurveysExportType.Group
        ? groupName
        : type === SurveysExportType.CtPatients
        ? 'ctpatient'
        : 'dhpatient';
    return `faeth_${typeLabel}_surveydata_${date}`;
  };

  useEffect(() => {
    if (pollingId != null) {
      startPolling(1000);
      return () => stopPolling();
    }
  }, [pollingId, startPolling, stopPolling]);

  useEffect(() => {
    const downloadFileHandler = async (downloadUrl: string) => {
      try {
        await downloadFile(downloadUrl, buildFileName());
        if (downloadPromiseResolve.current) {
          downloadPromiseResolve.current(null);
        }
      } catch (error) {
        if (downloadPromiseReject.current) {
          downloadPromiseReject.current(error);
        }
      }
    };

    const isReady = data?.surveyExportStatus.is_ready;
    const downloadUrl = data?.surveyExportStatus.download_url;

    if (isReady && downloadUrl != null) {
      setPollingId(undefined);
      downloadFileHandler(downloadUrl);

      snackbar.closeSnackbar();
    }
  }, [data?.surveyExportStatus, snackbar]);

  return [requestDownload];
};

export const SurveyDownloadProvider: FC<PropsWithChildren> = ({ children }) => {
  const DownloadProvider = Context.Provider;

  const [requestDownload] = useSurveyExportDownload();

  return (
    <DownloadProvider value={{ requestDownload }}>{children}</DownloadProvider>
  );
};

export const useRequestDownload = () => {
  const { requestDownload } = useContext(Context);

  return requestDownload;
};
