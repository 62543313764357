import React, { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';
import { FieldArray } from 'formik';
import { FormBuilderQuestionType } from '@fdha/graphql-api-admin';

import { OptionsErrorState } from '../../../../models/FormBuilderProps';

import EditChoiceField from './EditChoiceField';

interface RadioOptionsProps {
  options?: string[] | null;
  type: FormBuilderQuestionType;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorState: OptionsErrorState;
}

const ChoiceFieldArray: FC<RadioOptionsProps> = ({
  options,
  type,
  handleChange,
  errorState,
}) => {
  const newOptions = options || [''];
  const optionsSize = newOptions.length;

  const getIcon = (
    name: string,
    testId: string,
    handleClick: React.MouseEventHandler<HTMLButtonElement>
  ) => {
    return (
      type !== FormBuilderQuestionType.Binary && (
        <IconButton
          sx={{ paddingRight: 0, paddingBottom: 0 }}
          onClick={handleClick}
          data-testid={testId}
        >
          <Icon name={name} fill={grey[600]} />
        </IconButton>
      )
    );
  };

  return (
    <FieldArray name="possibleAnswers" validateOnChange={false}>
      {({ insert, remove }) => (
        <>
          {newOptions.map((option: string, index: number) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                key={index}
                marginBottom={2}
              >
                <EditChoiceField
                  type={type}
                  option={option}
                  index={index}
                  handleChange={handleChange}
                  errorState={errorState}
                />
                {getIcon(
                  'plus-circle-outline',
                  `ADD_${type.toUpperCase()}_CHOICE_${index}`,
                  () => insert(index + 1, '')
                )}
                {optionsSize !== 1 &&
                  getIcon(
                    'minus-circle-outline',
                    `REMOVE_${type.toUpperCase()}_CHOICE_${index}`,
                    () => remove(index)
                  )}
              </Box>
            );
          })}
        </>
      )}
    </FieldArray>
  );
};

export default ChoiceFieldArray;
