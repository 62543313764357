import { Stack, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import React, { Ref } from 'react';

import { Button, Icon, TextField } from '..';
import {
  commentInitialValues,
  CommentSchema,
  commentValidationSchema,
} from '../..';

export interface CommentBoxProps {
  onSendComment: (text: string) => Promise<void>;
  isPostRemoved?: boolean;
  focusOnInput?: boolean;
  inputRef: Ref<HTMLDivElement>;
  v2?: boolean;
}

const CommentBox: React.FC<CommentBoxProps> = ({
  onSendComment,
  isPostRemoved,
  focusOnInput,
  inputRef,
  v2,
}) => {
  const theme = useTheme();

  /************ V2 updates *********************/

  const buttonHeight = v2 ? '40px' : '56px';
  const commentPlaceholder = v2 ? 'Write a Comment' : 'Add a comment';

  /*********************************************/

  const handleSendComment = async (values: CommentSchema) => {
    const { text } = values;
    if (!text) {
      return;
    }

    resetForm({ values: { text: '' } });

    await onSendComment(text);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    resetForm,
    errors,
  } = useFormik({
    initialValues: commentInitialValues,
    validationSchema: commentValidationSchema,
    onSubmit: handleSendComment,
  });

  return (
    <Stack direction="row" spacing={1}>
      <TextField
        disabled={isPostRemoved}
        placeholder={commentPlaceholder}
        autoFocus={focusOnInput}
        inputRef={inputRef}
        name="text"
        onChange={handleChange}
        value={values.text}
        error={!!errors.text}
        helperText={errors.text}
        v2={v2}
      />
      <Button
        variant="contained"
        size="small"
        sx={{
          borderRadius: '10px',
          height: buttonHeight,
        }}
        onClick={() => handleSubmit()}
        disabled={isSubmitting || isPostRemoved}
      >
        <Icon
          name="paper-plane-outline"
          fill={theme.palette.primary.contrastText}
          size="medium"
        />
      </Button>
    </Stack>
  );
};

export default CommentBox;
