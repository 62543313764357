import React, { Dispatch, SetStateAction } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';
import {
  caregiverList,
  currentTreatmentList,
  formatUTCDate,
  LabelBox,
  otherMedicalConditionsList,
  SelectOption,
} from '@fdha/web-ui-library';
import { PatientUser } from '@fdha/graphql-api-admin';

export const getLabelByValue = (value: string, options: SelectOption[]) => {
  const option = options.find((o) => o.value === value);
  return option?.label ?? '';
};

const ViewModeMedicalInformation = ({
  patient,
  getCancerType,
  handleEditMode,
}: {
  patient?: PatientUser | null;
  getCancerType: (type?: string | null) => string;
  handleEditMode: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Box marginBottom={3} data-testid="MEDICAL_SHOW_MODE">
        <Grid container columnSpacing={3} rowSpacing={0}>
          <LabelBox
            size={4}
            label="Cancer Diagnosis"
            value={getCancerType(patient?.cancer_type)}
          />
          <LabelBox
            size={4}
            label="Additional Information"
            value={patient?.diag_details}
          />
          <LabelBox
            size={4}
            label="Diagnosis date"
            value={formatUTCDate(patient?.diag_date, 'monthYear')}
          />
          <LabelBox
            size={4}
            label="Current Treatment"
            value={patient?.current_treatment
              ?.map((treatment) =>
                getLabelByValue(treatment, currentTreatmentList)
              )
              .join('; ')}
          />
          <LabelBox
            size={4}
            label="Other Medical Conditions"
            value={patient?.other_medical_conditions
              ?.map((condition) =>
                getLabelByValue(condition, otherMedicalConditionsList)
              )
              .join('; ')}
          />
          <LabelBox
            size={4}
            label="Oncologist Name"
            value={patient?.oncologist_name}
          />
          <LabelBox
            size={4}
            label="Treatment Facility Name"
            value={patient?.facility?.name}
          />
          <LabelBox
            size={4}
            label="Treatment Facility City"
            value={patient?.facility?.city}
          />
          <LabelBox
            size={4}
            label="Treatment Facility State"
            value={patient?.facility?.state}
          />
          <LabelBox size={4} label="Medication" value={patient?.medication} />
          <LabelBox
            size={4}
            label="Primary Caregiver"
            value={
              patient?.caregiver?.primary_caregiver &&
              getLabelByValue(
                patient?.caregiver?.primary_caregiver,
                caregiverList
              )
            }
          />
          {patient?.caregiver?.primary_caregiver === 'other' && (
            <LabelBox
              size={4}
              label="Primary Caregiver: Other"
              value={patient?.caregiver?.primary_caregiver_desc}
            />
          )}
          <LabelBox
            size={4}
            label="Primary Caregiver Name"
            value={patient?.caregiver?.name}
          />
          <LabelBox
            size={4}
            label="Primary Cheerleader"
            value={
              patient?.cheerleader?.primary_cheerleader &&
              getLabelByValue(
                patient?.cheerleader?.primary_cheerleader,
                caregiverList
              )
            }
          />
          {patient?.cheerleader?.primary_cheerleader === 'other' && (
            <LabelBox
              size={4}
              label="Primary Caregiver: Other"
              value={patient?.cheerleader?.primary_cheerleader_desc}
            />
          )}
          <LabelBox
            size={4}
            label="Primary Cheerleader Name"
            value={patient?.cheerleader?.name}
          />
          <LabelBox size={4} label="Motivation" value={patient?.motivation} />
        </Grid>
      </Box>
      <Box textAlign="end">
        <IconButton
          onClick={() => handleEditMode(true)}
          data-testid="EDIT_BUTTON"
        >
          <Icon name="edit-outline" width={24} height={24} fill={grey[600]} />
        </IconButton>
      </Box>
    </>
  );
};

export default ViewModeMedicalInformation;
