import { Button, RowsPerPageOptions, Table } from '@fdha/web-ui-library';
import { Box, Paper, Stack, TableCell, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { NetworkStatus } from '@apollo/client';
import { BhbData, useGetPatientBhbAnswersQuery } from '@fdha/graphql-api-admin';

import { Chip } from '../../../../components';
import { useGetUserType, useSortBy, useTable } from '../../../../hooks';

import {
  BhbDataSurvey,
  getDataChipColor,
  getDataHeaderCells,
  getDate,
  getUTCDate,
} from './utils';

const Data = () => {
  const params = useParams();
  const patientId = params.patientId || '';
  const { isCsr } = useGetUserType();
  const headCells = getDataHeaderCells(isCsr);

  const [sortBy, setSortBy] = useSortBy<BhbDataSurvey>('due_at', 'desc');
  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'bhbData',
  });

  const { data, error, fetchMore, loading, networkStatus } =
    useGetPatientBhbAnswersQuery({
      variables: {
        patientId: patientId,
        first: rowsPerPage,
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });
  const nodes =
    data?.getPatientBhbAnswers?.edges.map((edge) => edge.node) || [];
  const pageInfo = data?.getPatientBhbAnswers?.pageInfo;
  const totalNumberFound = data?.getPatientBhbAnswers?.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          patientId: patientId,
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const onRowsPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
    changeRowsPerPage(rowsPerPage);
  };

  const renderRow = (row: BhbData) => {
    const date = getUTCDate(row.due_at);
    const measuredTime = getDate(row.measured_at, row.timezone);

    return (
      <TableRow hover key={row.id}>
        <TableCell data-testid="TABLE_CELL_DATA_TYPE">
          SER-ISD1-001 - BHB
        </TableCell>
        <TableCell data-testid="TABLE_CELL_DUE_TIME">{date}</TableCell>
        {!isCsr && (
          <>
            <TableCell data-testid="TABLE_CELL_MEASURED_TIME">
              {measuredTime}
            </TableCell>
            <TableCell data-testid="TABLE_CELL_BHB_VALUE">
              {row.bhb_value != null ? `${row.bhb_value} mmol/L` : '-'}
            </TableCell>
            <TableCell data-testid="TABLE_CELL_BHB_SITUATION">
              {row.situation || '-'}
            </TableCell>
          </>
        )}
        <TableCell data-testid="TABLE_CELL_BHB_STATUS">
          <Chip
            label={row.status.toLowerCase()}
            color={getDataChipColor(row.status)}
          />
        </TableCell>
      </TableRow>
    );
  };
  return (
    <Stack spacing={3}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          size="large"
          endEvaIcon={{ name: 'arrow-ios-forward-outline' }}
          component={Link}
          to="schedules"
          data-testid="DATA_SCHEDULES"
        >
          Schedules
        </Button>
      </Box>

      <Paper data-testid="BHB_TABLE">
        {
          <Table<BhbDataSurvey>
            headCells={headCells}
            initialOrderBy="due_at"
            renderRow={renderRow}
            page={page}
            rowsPerPage={rowsPerPage}
            rows={nodes}
            withPagination
            isLoading={loading}
            totalRowCount={totalNumberFound}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            onSortChange={setSortBy}
          />
        }
      </Paper>
    </Stack>
  );
};

export default Data;
