import { FC, useMemo } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import {
  Button,
  TextField,
  useSnackbar,
  requiredMessage,
  formatUTCDate,
  Select,
} from '@fdha/web-ui-library';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import {
  SiteStaffRole,
  SiteStatus,
  UpdateSiteStaffUserInput,
  useDeactivateAccountMutation,
  useListSitesQuery,
  useReactivateAccountMutation,
  useUpdateSiteStaffUserMutation,
} from '@fdha/graphql-api-admin';

import { STATUS_OPTIONS, siteStaffRoleLabelByValue } from '../../../utils';

import { ProfileInformationViewOrEditProps } from './ProfileInformation';

interface EditSiteStaffSchema {
  name: string;
  email: string;
  associatedSite: string;
  role: string;
  status: SiteStatus;
}

const siteStaffValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required(requiredMessage),
  email: Yup.string().trim().email().required(requiredMessage),
  associatedSite: Yup.string().trim().required(requiredMessage),
  role: Yup.string().trim().required(requiredMessage),
  status: Yup.string().trim().required(requiredMessage),
});

const NUMBER_OF_SITES = 10000;

const EditModeProfileInformation: FC<ProfileInformationViewOrEditProps> = ({
  siteStaffUser,
  handleEditMode,
}) => {
  const { showSnackbar } = useSnackbar();

  const [updateSiteStaffUser] = useUpdateSiteStaffUserMutation();
  const [deactivateUser] = useDeactivateAccountMutation();
  const [reactivateUser] = useReactivateAccountMutation();

  const { data: sitesData } = useListSitesQuery({
    variables: { first: NUMBER_OF_SITES },
    fetchPolicy: 'cache-and-network',
  });

  const siteOptions = useMemo(
    () =>
      sitesData?.sites.edges.map(({ node }) => ({
        label: node.name,
        value: node.id,
      })) || [],
    [sitesData]
  );

  const handleCancel = () => {
    handleEditMode(false);
    showSnackbar({
      severity: 'warning',
      message: 'Changes not saved',
    });
  };

  const roleOptions = useMemo(
    () =>
      Object.values(SiteStaffRole).map((type) => ({
        label: siteStaffRoleLabelByValue[type],
        value: type,
      })),
    []
  );

  const changeUserStatus = async (status: string) => {
    if (status === initialValues.status) {
      return;
    }

    const payload = {
      variables: {
        id: siteStaffUser?.id || '',
      },
    };
    if (status === 'active') {
      await reactivateUser(payload);
    } else {
      await deactivateUser(payload);
    }
  };

  const getSiteStaffUserPayload = (
    values: EditSiteStaffSchema
  ): UpdateSiteStaffUserInput => {
    return {
      name: values.name,
      email: values.email,
      role: values.role as SiteStaffRole,
      siteId: values.associatedSite,
    };
  };

  const handleSave = async (values: EditSiteStaffSchema) => {
    try {
      await changeUserStatus(values.status);
      await updateSiteStaffUser({
        variables: {
          id: siteStaffUser?.id || '',
          props: getSiteStaffUserPayload(values),
        },
      });
      handleEditMode(false);
      showSnackbar({
        severity: 'success',
        message: 'Changes saved!',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update site staff user data',
      });
    }
  };

  const initialValues: EditSiteStaffSchema = {
    name: siteStaffUser?.name || '',
    email: siteStaffUser?.email || '',
    associatedSite: siteStaffUser?.site.id || '',
    role: siteStaffUser?.role || '',
    status: siteStaffUser?.is_active ? SiteStatus.Active : SiteStatus.Inactive,
  };

  const handleErrors = (
    errors: FormikErrors<EditSiteStaffSchema>,
    touched: FormikTouched<EditSiteStaffSchema>
  ) => {
    return {
      name: touched.name && errors.name,
      email: touched.email && errors.email,
      associatedSite: touched.associatedSite && errors.associatedSite,
      role: touched.role && errors.role,
      status: touched.status && errors.status,
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={siteStaffValidationSchema}
      onSubmit={handleSave}
    >
      {({
        values,
        isSubmitting,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => {
        const errorState = handleErrors(errors, touched);

        return (
          <Form>
            <Box marginBottom={3}>
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={8}>
                  <TextField
                    title="Name"
                    name="name"
                    value={values.name}
                    error={!!errorState.name}
                    helperText={errorState.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Email"
                    name="email"
                    value={values.email}
                    error={!!errorState.email}
                    helperText={errorState.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    displayEmpty
                    title="Associated site"
                    name="associatedSite"
                    placeholder="Select a site"
                    value={values.associatedSite}
                    options={siteOptions}
                    error={!!errorState.associatedSite}
                    helperText={errorState.associatedSite}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    title="Staff role"
                    name="role"
                    displayEmpty
                    value={values.role}
                    error={!!errorState.role}
                    helperText={errorState.role}
                    options={roleOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    title="Site staff status"
                    name="status"
                    value={values.status}
                    error={!!errorState.status}
                    helperText={errorState.status}
                    options={STATUS_OPTIONS}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    title="Joined on"
                    disabled
                    value={formatUTCDate(siteStaffUser?.created_at)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <Button
                onClick={handleCancel}
                size="large"
                data-testid="CANCEL_EDITION_BUTTON"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                data-testid="SAVE_EDITION_BUTTON"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditModeProfileInformation;
