import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useFeatureFlag } from '@fdha/common-hooks';
import { Loader } from '@fdha/web-ui-library';

import BasePage from '../../components/BasePage/BasePage';

const Trials = () => {
  const location = useLocation();

  const { isFeatureEnabled, isLoading: loading } = useFeatureFlag();
  const showSiteFeatures = isFeatureEnabled('show_site_features');

  const tabs = useMemo(
    () => [
      { value: 'PreSignUp', label: 'Pre Sign-up', to: './', href: '/trials' },
      ...(showSiteFeatures
        ? [
            {
              value: 'Trials',
              label: 'Trials',
              to: 'trials-list',
              href: '/trials/trials-list',
            },
            {
              value: 'Sites',
              label: 'Sites',
              to: 'sites',
              href: '/trials/sites',
            },
            {
              value: 'SiteStaffUsers',
              label: 'Site Staff Users',
              to: 'site-staff-users',
              href: '/trials/site-staff-users',
            },
          ]
        : []),
    ],
    [showSiteFeatures]
  );

  const tab = useMemo(
    () => tabs.find((tab) => tab.href === location.pathname)?.value,
    [location.pathname, tabs]
  );
  const selectedTab = useMemo(() => tab || 'PreSignUp', [tab]);
  const showTab = useMemo(() => !!tab, [tab]);

  if (loading) {
    return <Loader />;
  }

  return (
    <BasePage>
      {showTab ? (
        <TabContext value={selectedTab}>
          <Box borderBottom={1} borderColor="divider">
            <TabList orientation="horizontal" value={selectedTab}>
              {tabs.map((tab, index) => (
                <Tab
                  data-testid={`TRIALS_TAB_${tab.value.toUpperCase()}`}
                  key={index}
                  label={tab.label}
                  value={tab.value}
                  component={Link}
                  to={tab.to}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              padding: 0,
              paddingTop: 4,
            }}
            value={selectedTab}
          >
            <Outlet />
          </TabPanel>
        </TabContext>
      ) : (
        <Outlet />
      )}
    </BasePage>
  );
};

export default Trials;
