import { useEffect, useState } from 'react';

export function useShouldAutoSave() {
  const [shouldAutoSave, setShouldAutoSave] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldAutoSave(true);
    }, 10000);

    return () => {
      setShouldAutoSave(false);
      clearTimeout(timer);
    };
  }, [shouldAutoSave]);

  return {
    shouldAutoSave,
  };
}
