import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import React from 'react';
import { useFeatureFlag } from '@fdha/common-hooks';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { HomeHeader, Loader } from '@fdha/web-ui-library';
import { displayProtectedImage } from '@fdha/web-auth';
import { useGetProfileQuery } from '@fdha/graphql-api-admin';

import BasePage from '../../components/BasePage/BasePage';
import { useGetUserType } from '../../hooks';

const getTabs = (isDhEnabled: boolean, isStreamChatEnabled: boolean) => [
  ...(isStreamChatEnabled
    ? [
        {
          value: 'inbox',
          label: 'Inbox',
          to: '/',
          href: '/',
          'data-testid': 'INBOX_TAB',
        },
      ]
    : []),
  ...(isDhEnabled
    ? [
        {
          value: 'dhPatients',
          label: 'Digital Health Patients',
          to: '/dh',
          href: '/dh',
          'data-testid': 'DH_PATIENTS_TAB',
        },
      ]
    : []),
  {
    value: 'ctPatients',
    label: 'Clinical Trials Patients',
    to: '/ct',
    href: '/ct',
    'data-testid': 'CT_PATIENTS_TAB',
  },
  {
    value: 'groups',
    label: 'Groups',
    to: '/groups',
    href: '/groups',
    'data-testid': 'GROUPS_TAB',
  },
];

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isCsr } = useGetUserType();
  const { data, loading } = useGetProfileQuery();

  const { isFeatureEnabled, isLoading: loadingFeatureFlag } = useFeatureFlag();
  const isDhEnabled = isFeatureEnabled('show_dh');
  const isStreamChatEnabled = isFeatureEnabled('show_stream_chat');

  const tabs = getTabs(isDhEnabled, isStreamChatEnabled);

  const selectedTab =
    tabs.find((tab) => tab.href === location.pathname)?.value || tabs[0].value;

  if (loading || loadingFeatureFlag) {
    return <Loader />;
  }

  const coachName = data?.me.name;
  const picture = data?.me.picture;

  const handleNavigate = () => {
    navigate('profile');
  };

  return (
    <BasePage data-testid="HOME_PANEL">
      <HomeHeader
        userName={coachName}
        picture={picture}
        downloadPicture={displayProtectedImage}
        handleNavigate={handleNavigate}
        showUserType={!isCsr}
      />
      <TabContext value={selectedTab}>
        <TabList
          orientation="horizontal"
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              value={tab.value}
              label={tab.label}
              component={Link}
              to={tab.to}
              key={tab.value}
              data-testid={tab['data-testid']}
            />
          ))}
        </TabList>
        <TabPanel sx={{ padding: 0, paddingTop: 4 }} value={selectedTab}>
          <Outlet />
        </TabPanel>
      </TabContext>
    </BasePage>
  );
};

export default Home;
