import { useSnackbar } from '@fdha/web-ui-library';
import { Autocomplete, Button, Option } from '@fdha/web-ui-library';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import {
  UserType,
  useListUsersAvailableForGroupAssignQuery,
  useSetGroupsToUserMutation,
} from '@fdha/graphql-api-admin';

import { userTypeLabelByValue } from '../../../../utils';

const NUMBER_OF_USERS = 10000;

interface Props {
  groupId: string;
  onAdded?: () => void;
  onFinish?: () => void;
}

interface Schema {
  participant: Option | null;
}

const initialValues: Schema = { participant: null };

const validationSchema = Yup.object().shape({
  participant: Yup.object().required(),
});

const AddParticipant: React.FC<Props> = ({ groupId, onAdded, onFinish }) => {
  const { showSnackbar } = useSnackbar();

  const [addUserToGroups] = useSetGroupsToUserMutation();

  const { data, loading } = useListUsersAvailableForGroupAssignQuery({
    variables: { groupId, first: NUMBER_OF_USERS },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const options = useMemo(
    () =>
      data?.usersAvailableForGroupAssign.edges.map(({ node }) => {
        const subjectIdSuffix =
          node.type === UserType.ClinicalTrialPatient && node.subject_id
            ? ` (${node.subject_id})`
            : '';
        return {
          label: `${node.name}${subjectIdSuffix}`,
          secondaryLabel: userTypeLabelByValue[node.type],
          id: node.id,
        };
      }) ?? [],
    [data?.usersAvailableForGroupAssign.edges]
  );

  const onSubmit = async (values: Schema) => {
    try {
      await addUserToGroups({
        variables: {
          userId: values.participant?.id ?? '',
          groupIdsToAdd: [groupId],
        },
      });
      showSnackbar({
        message: 'User added with success',
        severity: 'success',
      });
      onAdded && onAdded();
    } catch {
      showSnackbar({
        message: 'Error to add user to group',
        severity: 'error',
      });
    } finally {
      onFinish && onFinish();
    }
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, setFieldValue, values }) => {
        return (
          <Form>
            <Autocomplete
              title="Add Participants"
              loading={loading}
              options={options}
              value={values.participant}
              placeholder="Search by name or subject ID"
              onChange={(_e, option) => {
                setFieldValue('participant', option);
              }}
            />
            <Box marginTop={3} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                size="large"
                onClick={onFinish}
                data-testid="CANCEL_DIALOG_BUTTON"
              >
                CANCEL
              </Button>
              <Button
                disabled={!isValid || isSubmitting}
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                data-testid="SAVE_DIALOG_BUTTON"
              >
                SAVE
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddParticipant;
