import { makeVar } from '@apollo/client';

enum ChannelType {
  Broadcast = 'broadcast',
  Messaging = 'messaging',
}

const selectedStreamChannelType = makeVar<ChannelType>(ChannelType.Messaging);

const setSelectedStreamChannelType = (channelType: ChannelType) =>
  selectedStreamChannelType(channelType);

export { ChannelType, selectedStreamChannelType, setSelectedStreamChannelType };
