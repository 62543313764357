import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDialog } from '@fdha/web-ui-library';
import {
  Activity,
  ActivityKind,
  Appointment,
  Goal,
  Task,
} from '@fdha/graphql-api-admin';

import { selectedActivity, setSelectedActivity } from '../../../../../states';
import EditActivities from '../AddActivity/EditActivities';
import { InfoDrawer } from '../../../../../components';
import { useGetUserType } from '../../../../../hooks';

import TaskDetails from './TaskDetails/TaskDetails';
import AppointmentDetails from './AppointmentDetails/AppointmentDetails';
import GoalDetails from './GoalDetails/GoalDetails';

const ActivityDetails = () => {
  const { openDialog, closeDialog } = useDialog();
  const params = useParams();
  const patientId = params.patientId ?? '';
  const { isCsr } = useGetUserType();

  const activity = useReactiveVar(selectedActivity);
  const isCreatedByCoach = activity?.creatorId !== patientId;

  const onEdit = (activity: Activity) => {
    openDialog({
      title: '',
      content: (
        <EditActivities
          data={activity}
          closeDialog={closeDialog}
          patientId={patientId}
        />
      ),
    });
  };

  const getContent = () => {
    switch (activity?.kind) {
      case ActivityKind.Task:
        return (
          <TaskDetails
            data={activity as Task}
            isCreatedByCoach={isCreatedByCoach}
            onEdit={() => onEdit(activity)}
            isCsr={isCsr}
          />
        );
      case ActivityKind.Appointment:
        return (
          <AppointmentDetails
            data={activity as Appointment}
            isCreatedByCoach={isCreatedByCoach}
            onEdit={() => onEdit(activity)}
            isCsr={isCsr}
          />
        );
      case ActivityKind.Goal:
        return (
          <GoalDetails
            data={activity as Goal}
            isCreatedByCoach={isCreatedByCoach}
            onEdit={() => onEdit(activity)}
            isCsr={isCsr}
          />
        );
    }
  };

  return (
    <InfoDrawer
      title="Details"
      isOpen={!!activity}
      onClose={() => setSelectedActivity()}
    >
      <Box paddingX={3} paddingY={2} maxWidth="375px">
        {getContent()}
      </Box>
    </InfoDrawer>
  );
};

export default ActivityDetails;
