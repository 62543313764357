import React from 'react';
import { useNavigate } from 'react-router';

import { BasePage } from '../../components';

import { AddOrEditSite } from './AddOrEditSite';

const AddSite = () => {
  const navigate = useNavigate();

  return (
    <BasePage>
      <BasePage.BackButton />
      <BasePage.Wrapper>
        <BasePage.Title>Create new site</BasePage.Title>
        <AddOrEditSite
          onCancel={() => navigate(-1)}
          onSuccess={(siteId) => navigate(`/trials/sites/${siteId}`)}
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default AddSite;
