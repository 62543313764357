import React, { useMemo } from 'react';
import { Paper as MuiPaper, styled, Typography } from '@mui/material';
import { FormBuilderQuestionType } from '@fdha/graphql-api-admin';

import FieldOption from './FieldOption';

const options: {
  type: FormBuilderQuestionType;
  testId: string;
  name: string;
}[] = [
  {
    type: FormBuilderQuestionType.MultipleChoice,
    testId: 'ADD_MULTIPLE_CHOICE_QUESTION',
    name: 'Multiple choice',
  },
  {
    type: FormBuilderQuestionType.Number,
    testId: 'ADD_NUMBER_QUESTION',
    name: 'Number input',
  },
  {
    type: FormBuilderQuestionType.OpenText,
    testId: 'ADD_OPEN_TEXT_QUESTION',
    name: 'Open text input',
  },
  {
    type: FormBuilderQuestionType.Scale,
    testId: 'ADD_SCALE_QUESTION',
    name: 'Scale',
  },
  {
    type: FormBuilderQuestionType.SingleChoice,
    testId: 'ADD_SINGLE_CHOICE_QUESTION',
    name: 'Single choice',
  },
  {
    type: FormBuilderQuestionType.Binary,
    testId: 'ADD_BINARY_QUESTION',
    name: 'Binary responses',
  },
  {
    type: FormBuilderQuestionType.Date,
    testId: 'ADD_DATE_QUESTION',
    name: 'Date picker',
  },
];

const Paper = styled(MuiPaper)(({ theme }) => ({
  width: '30%',
  maxHeight: '677px',
  padding: theme.spacing(4),
  marginTop: '24px',
  border: '0.5px solid',
  borderColor: theme.palette.divider,
}));

interface ToolbarProps {
  notAllowedTypes?: FormBuilderQuestionType[];
}

const Toolbar: React.FC<ToolbarProps> = ({ notAllowedTypes }) => {
  const filteredOptions = useMemo(
    () => options.filter((option) => !notAllowedTypes?.includes(option.type)),
    [notAllowedTypes]
  );

  return (
    <Paper>
      <Typography fontWeight="500" marginBottom="16px" variant="h6">
        Add fields
      </Typography>
      {filteredOptions.map((item, index) => (
        <FieldOption item={item} key={index} testId={item.testId}>
          {item.name}
        </FieldOption>
      ))}
    </Paper>
  );
};

export default Toolbar;
