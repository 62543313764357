import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Channel,
  ChannelFilters,
  ChannelOptions,
  ChannelSort,
} from 'stream-chat';
import { Chat } from 'stream-chat-react';
import '@stream-io/stream-chat-css';
import { Loader } from '@fdha/web-ui-library';
import { useReactiveVar } from '@apollo/client';
import { useChat, useIsMounted } from '@fdha/common-hooks';

import ChannelContainer from '../../components/Chat/ChannelContainer/ChannelContainer';
import ChannelListContainer from '../../components/Chat/ChannelListContainer/ChannelListContainer';
import BasePage from '../../components/BasePage/BasePage';
import {
  ChannelType,
  selectedStreamChannelType,
  setSelectedStreamChannelType,
} from '../../states/streamChannelTypeState';

import { customStyles, StyledChatWrapper } from './styles';

const filters = (
  userId: string,
  channelType?: ChannelType
): ChannelFilters => ({
  type: channelType,
  members: { $in: [userId] },
});
const options: ChannelOptions = {
  state: true,
  watch: true,
  presence: true,
  limit: 8,
};
const sort: ChannelSort = {
  last_message_at: -1,
  updated_at: -1,
  cid: 1,
};

interface Props {
  streamUserId: string;
  channelId?: string;
  channelType: ChannelType;
}

interface StateProps {
  channelId: string;
}

export const ChatInner: React.FC<Props> = ({
  streamUserId,
  channelId,
  channelType,
}) => {
  return (
    <>
      <ChannelListContainer
        filters={filters(streamUserId, channelType)}
        options={options}
        sort={sort}
        customActiveChannel={channelId}
      />
      <ChannelContainer isWidget={false} />
    </>
  );
};

const ChatPage = () => {
  const { client: chatClient, streamUserId } = useChat();

  const checkIsMounted = useIsMounted();

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as StateProps;

  const [customChannel, setCustomChannel] = useState<Channel | undefined>();
  const [loadingCustomChannel, setLoadingCustomChannel] = useState(false);

  const channelTypeSelected = useReactiveVar(selectedStreamChannelType);

  useEffect(() => {
    // Reset custom channel when channel type changes
    setCustomChannel(undefined);
  }, [channelTypeSelected]);

  if (!chatClient || !streamUserId || loadingCustomChannel) {
    return <Loader />;
  }

  const queryChannel = async () => {
    const [channel] = await chatClient.queryChannels({
      ...filters(streamUserId),
      id: state.channelId,
    });

    if (checkIsMounted()) {
      setCustomChannel(channel);

      if (channel?.type !== channelTypeSelected) {
        if (
          Object.values(ChannelType).includes(
            channel?.type as unknown as ChannelType
          )
        ) {
          setSelectedStreamChannelType(channel?.type as unknown as ChannelType);
        }
      }

      setLoadingCustomChannel(false);

      // Clear state to avoid have the same channel selected on reload
      navigate(location.pathname, { replace: true });
    }
  };

  if (state?.channelId && customChannel?.id !== state?.channelId) {
    setLoadingCustomChannel(true);
    queryChannel();
  }

  const channelId =
    customChannel?.type === channelTypeSelected ? customChannel?.id : undefined;

  return (
    <BasePage>
      <StyledChatWrapper>
        <Chat client={chatClient} customStyles={customStyles}>
          <ChatInner
            streamUserId={streamUserId}
            channelId={channelId}
            channelType={channelTypeSelected}
          />
        </Chat>
      </StyledChatWrapper>
    </BasePage>
  );
};

export default ChatPage;
