import React, { FC, useContext } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FormBuilderQuestionType } from '@fdha/graphql-api-admin';

import {
  ErrorStateProps,
  QuestionCardType,
} from '../../../../models/FormBuilderProps';
import TabPanel from '../../../TabPanel/TabPanel';
import { FormBuilderContext } from '../../FormBuilder';

import TextOption from './TextOption';
import NumberOption from './NumberOption';
import { DateOption } from './DateOption';

export interface QuestionOptionProps<T> {
  values: QuestionCardType;
  handleChange: (fieldName: string, value: T) => void;
  errorState: ErrorStateProps;
}

interface OptionsTabPanelProps extends QuestionOptionProps<any> {
  hidden: boolean;
}

const OptionsTabPanel: FC<OptionsTabPanelProps> = ({
  hidden,
  values,
  errorState,
  handleChange,
}) => {
  const { hasRequiredOption } = useContext(FormBuilderContext);

  const getOption = () => {
    switch (values.type) {
      case FormBuilderQuestionType.OpenText:
        return (
          <TextOption
            values={values}
            errorState={errorState}
            handleChange={handleChange}
          />
        );
      case FormBuilderQuestionType.Number:
        return (
          <NumberOption
            values={values}
            errorState={errorState}
            handleChange={handleChange}
          />
        );
      case FormBuilderQuestionType.Date:
        return (
          <DateOption
            values={values}
            errorState={errorState}
            handleChange={handleChange}
          />
        );
    }
  };

  const renderOptions = () => {
    const option = getOption();

    if (!option && !hasRequiredOption) {
      return (
        <Typography paddingTop={1}>
          No options available for this question type.
        </Typography>
      );
    } else {
      return (
        <>
          {option}
          {hasRequiredOption && (
            <FormControlLabel
              control={
                <Checkbox
                  name="required"
                  checked={values.required}
                  color="primary"
                  onChange={(e) => handleChange('required', e.target.checked)}
                />
              }
              label="Required"
            />
          )}
        </>
      );
    }
  };

  return <TabPanel hidden={hidden}>{renderOptions()}</TabPanel>;
};

export default OptionsTabPanel;
