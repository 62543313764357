import React, { useEffect } from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  Button,
  HeadCell,
  IconMenu,
  MenuItem,
  SearchableTableHeader,
  Table,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  Box,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetGroupQuery,
  useGetProfileQuery,
  useListGroupParticipantsQuery,
  useRemoveUserFromGroupMutation,
  UserType,
  SurveysExportType,
  useGetGroupParticipantsCountQuery,
  SurveyType,
  GroupUser,
} from '@fdha/graphql-api-admin';

import { AssignSurvey } from '../../../../components';
import {
  useGetUserType,
  useRequestDownload,
  useFilterBy,
  useDebouncedValue,
  useSortBy,
  useTable,
} from '../../../../hooks';
import { userTypeLabelByValue } from '../../../../utils';

import AddParticipant from './AddParticipant';

const headCells: HeadCell<GroupUser>[] = [
  {
    id: 'name',
    label: 'Participant Name',
    sortable: true,
    searchable: true,
  },
  {
    id: 'subject_id',
    label: 'Subject ID',
    sortable: true,
    searchable: true,
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
    searchable: true,
  },
  {
    id: 'type',
    label: 'Type',
    sortable: false,
    searchable: false,
  },
];

const Participants = () => {
  const params = useParams();
  const navigate = useNavigate();
  const requestDownload = useRequestDownload();
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { isCsr } = useGetUserType();

  const groupId = params.groupId || '';

  const [queryFilterBy, setQueryFilterBy] = useFilterBy<GroupUser>('name', '');
  const queryFilterByDebounced = useDebouncedValue(queryFilterBy);

  const [sortBy, setSortBy] = useSortBy<GroupUser>('name', 'asc');
  const {
    page,
    setPage,
    rowsPerPage,
    changeRowsPerPage,
    loading: loadingTablePreferences,
  } = useTable({
    key: 'groupParticipants',
  });

  const [removeUserFromGroup] = useRemoveUserFromGroupMutation();

  const { data: currentUserData } = useGetProfileQuery();
  const { data: groupData, loading: loadingGroupData } = useGetGroupQuery({
    variables: { id: groupId },
  });

  const {
    data: participantsData,
    loading: loadingTableData,
    fetchMore,
    networkStatus,
    refetch: refetchParticipantsList,
  } = useListGroupParticipantsQuery({
    variables: {
      groupId: groupId,
      first: rowsPerPage,
      filterBy: {
        filterBy: [queryFilterByDebounced],
      },
      sortBy: {
        sortBy: [sortBy],
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: loadingTablePreferences,
  });

  const {
    data: participantsCountData,
    loading: loadingParticipantsCount,
    refetch: refetchParticipantsCount,
  } = useGetGroupParticipantsCountQuery({
    variables: { groupId },
    fetchPolicy: 'no-cache',
  });

  const groupParticipants =
    participantsData?.groupParticipants.edges.map((edge) => edge.node) || [];
  const totalNumberFound = participantsData?.groupParticipants.totalNumberFound;
  const pageInfo = participantsData?.groupParticipants.pageInfo;

  const group = groupData?.group;

  const emptyState = queryFilterByDebounced.value
    ? 'No results found'
    : 'No participant yet. Add one to start populating this group.';
  const isLoadingTable =
    loadingTableData || loadingTablePreferences || loadingGroupData;

  const hasPatientParticipant =
    participantsCountData?.caretaker.totalNumberFound ||
    participantsCountData?.ctPatient.totalNumberFound ||
    participantsCountData?.dhPatient.totalNumberFound;

  const isAssignEnabled = !loadingParticipantsCount && hasPatientParticipant;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const refetchQueries = () => {
    refetchParticipantsList();
    refetchParticipantsCount();
  };

  const onAddParticipant = () => {
    openDialog({
      title: '',
      content: (
        <AddParticipant
          groupId={groupId}
          onAdded={refetchQueries}
          onFinish={closeDialog}
        />
      ),
    });
  };

  const onAssignSurvey = () => {
    openDialog({
      title: '',
      content: (
        <AssignSurvey mode="toGroup" groupId={groupId} onFinish={closeDialog} />
      ),
    });
  };

  const onExportData = async (groupName: string) => {
    await requestDownload({
      type: SurveysExportType.Group,
      groupName,
      surveyType: SurveyType.Normal,
    });
    await requestDownload({
      type: SurveysExportType.Group,
      groupName,
      surveyType: SurveyType.Bhb,
    });
  };

  const onRemoveParticipant = async (userId: string, userName: string) => {
    openDialog({
      title: '',
      content: (
        <Typography variant="h6">
          {`Are you sure you want to remove ${userName} from ${group?.name}?`}
        </Typography>
      ),
      confirmButtonLabel: 'Remove',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        try {
          await removeUserFromGroup({
            variables: { userId: userId, groupId: groupId },
          });
          refetchQueries();
          showSnackbar({
            message: `Participant removed with success`,
            severity: 'success',
          });
        } catch {
          showSnackbar({
            message: `Error to remove group participant`,
            severity: 'error',
          });
        } finally {
          closeDialog();
        }
      },
    });
  };

  const onOpenProfile = (
    userId: string,
    type: UserType,
    isCurrentUser: boolean
  ) => {
    switch (type) {
      case UserType.Caretaker:
      case UserType.ClinicalTrialPatient:
      case UserType.Patient:
        navigate(`/patient/${userId}`, {
          state: { backRoute: `/group/${groupId}` },
        });
        break;
      case UserType.Coach:
        if (!isCurrentUser) {
          navigate(`/coach/${userId}`, {
            state: { backRoute: `/group/${groupId}` },
          });
        } else {
          navigate('/profile/', {
            state: { backRoute: `/group/${groupId}` },
          });
        }
        break;
      case UserType.Foodops:
        navigate(`/foodops/${userId}`, {
          state: { backRoute: `/group/${groupId}` },
        });
        break;
    }
  };

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const renderRow = (row: GroupUser) => {
    const isCurrentUser = row.id === currentUserData?.me.id;

    const menuOptions: MenuItem[] = [
      {
        label: 'Go to profile',
        icon: 'person-outline',
        handleClick: () => onOpenProfile(row.id, row.type, isCurrentUser),
        testId: 'GO_TO_PROFILE_OPTION',
      },
      ...(!isCurrentUser && !isCsr
        ? [
            {
              label: 'Remove',
              icon: 'trash-2-outline',
              handleClick: () => onRemoveParticipant(row.id, row.name),
              testId: 'REMOVE_OPTION',
            },
          ]
        : []),
    ];

    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="PARTICIPANT_NAME_CELL">{row.name}</TableCell>
        <TableCell data-testid="PARTICIPANT_SUBJECT_ID_CELL">
          {row.type === UserType.ClinicalTrialPatient && row.subject_id}
        </TableCell>
        <TableCell data-testid="PARTICIPANT_EMAIL_CELL">{row.email}</TableCell>
        <TableCell data-testid="PARTICIPANT_TYPE_CELL">
          {userTypeLabelByValue[row.type]}
        </TableCell>
        <TableCell data-testid="PARTICIPANT_ACTION_CELL" padding="checkbox">
          <IconMenu items={menuOptions} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" columnGap={3}>
        <SearchableTableHeader<GroupUser>
          headCells={headCells}
          defaultSearchField="name"
          onSearchChange={setQueryFilterBy}
          disableMargin
        />
        {!isCsr && (
          <Box display="flex" flexShrink={0} columnGap={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startEvaIcon={{ name: 'download-outline' }}
              onClick={() => onExportData(group?.name || '')}
              data-testid="EXPORT_BUTTON"
            >
              export
            </Button>
            <Tooltip
              title={
                !isAssignEnabled ? 'Add a patient to this group first' : ''
              }
            >
              <span>
                <Button
                  disabled={!isAssignEnabled}
                  variant="contained"
                  color="secondary"
                  size="large"
                  startEvaIcon={{ name: 'plus-outline' }}
                  onClick={onAssignSurvey}
                  data-testid="ASSIGN_SURVEY_BUTTON"
                >
                  assign survey
                </Button>
              </span>
            </Tooltip>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startEvaIcon={{ name: 'plus-outline' }}
              onClick={onAddParticipant}
              data-testid="ADD_BUTTON"
            >
              add
            </Button>
          </Box>
        )}
      </Box>
      <Paper data-testid="GROUP_PARTICIPANTS_TABLE" sx={{ marginTop: 2 }}>
        <Table<GroupUser>
          actions="right"
          initialOrderBy="name"
          headCells={headCells}
          renderRow={renderRow}
          rows={groupParticipants}
          emptyState={emptyState}
          isLoading={isLoadingTable}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={setSortBy}
          onPageChange={onPageChange}
          page={page}
          totalRowCount={totalNumberFound}
          withPagination
        />
      </Paper>
    </Box>
  );
};

export default Participants;
