import React from 'react';
import { NetworkStatus } from '@apollo/client';
import { Box } from '@mui/material';
import { CollapsedWrapper, Loader } from '@fdha/web-ui-library';
import { Survey, useListSurveysQuery } from '@fdha/graphql-api-admin';

import SurveyCard from '../../../../components/SurveyCard/SurveyCard';
import { getSurveyName } from '../../../../utils';

export const INITIAL_SURVEYS = 200;

const SurveyTab = () => {
  const { data, error, networkStatus } = useListSurveysQuery({
    variables: {
      first: INITIAL_SURVEYS,
    },
    notifyOnNetworkStatusChange: true,
  });

  const nodes = data?.surveys.edges.map((edge) => {
    return { ...edge.node, name: getSurveyName(edge.node.name) };
  });
  const draftsSurveys = nodes?.filter((survey) => !survey.published);
  const publishedSurveys = nodes?.filter(
    (survey) => survey.published && !survey.archived
  );
  const archivedSurveys = nodes?.filter((survey) => survey.archived);

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  if (networkStatus === NetworkStatus.loading || !data) {
    return <Loader data-testid="SURVEYS_LOADER" />;
  }

  const renderSurveys = (
    title: string,
    isOpen: boolean,
    surveysList?: Survey[]
  ) => (
    <CollapsedWrapper
      title={title}
      initialOpenState={isOpen}
      testId={title.toUpperCase() + '_SURVEYS_CONTAINER'}
    >
      {surveysList?.map((survey) => (
        <SurveyCard survey={survey} key={survey.id} />
      ))}
    </CollapsedWrapper>
  );

  return (
    <Box data-testid="SURVEYS_CONTAINER">
      {renderSurveys('Drafts', true, draftsSurveys)}
      {renderSurveys('Published', true, publishedSurveys)}
      {renderSurveys('Archived', false, archivedSurveys)}
    </Box>
  );
};

export default SurveyTab;
