import { WeekdaysOnDietDeliveries } from '@fdha/common-utils';
import { Divider, Stack } from '@mui/material';
import React, { FC } from 'react';

import DeliveryCard from './DeliveryCard';

interface DeliveryScheduleProps {
  values: WeekdaysOnDietDeliveries;
}

const DeliverySchedule: FC<DeliveryScheduleProps> = ({ values }) => {
  return (
    <>
      {Object.values(values).map(
        (value, index) =>
          !!value.length && (
            <>
              <Stack spacing={1} direction="row" key={index}>
                <DeliveryCard
                  title="Delivery"
                  type="deliveryDay"
                  label={index === 0 ? 'TUE' : 'FRI'}
                  isSelected
                />
                <DeliveryCard title="Diet days" type="dietDay" days={value} />
              </Stack>
              {index === 0 && !!values.secondDeliveryWeekdaysOnDiet.length && (
                <Divider />
              )}
            </>
          )
      )}
    </>
  );
};

export default DeliverySchedule;
