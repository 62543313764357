import React from 'react';
import { Stack, StackProps } from '@mui/material';

const WIDTH = '830px';

const CommunityWrapper: React.FC<StackProps> = (props) => {
  return (
    <Stack
      maxWidth={WIDTH}
      direction="column"
      width="100%"
      alignSelf="center"
      {...props}
    >
      {props.children}
    </Stack>
  );
};

export default CommunityWrapper;
