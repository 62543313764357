import React, { FC } from 'react';
import { useTheme } from '@mui/material';

import { Typography } from '../Typography/Typography';

export interface CharactersCountProps {
  text: string;
  maxCount: number;
}

const CharactersCount: FC<CharactersCountProps> = ({ text, maxCount }) => {
  const theme = useTheme();

  const textCount = text.length;
  const color =
    textCount > maxCount
      ? theme.palette.error.dark
      : theme.palette.text.secondary;

  return (
    <Typography variant="body2" color={color}>
      {textCount === 0
        ? `${maxCount} characters limit`
        : `${textCount}/${maxCount} characters`}
    </Typography>
  );
};

export default CharactersCount;
