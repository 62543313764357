import React from 'react';
import { Icon } from '@fdha/web-ui-library';
import { Box, Stack, Typography, useTheme } from '@mui/material';

const INFO = [
  {
    icon: 'car-outline',
    title: 'Active Deliveries',
    description:
      'Current open and closed deliveries for the patient. Active deliveries are generated from Week Menu selections.',
  },
  {
    icon: 'calendar-outline',
    title: 'Week Menus',
    description:
      "Week Menu selections are generated based on patient's last selection for that specific week (A, B or C). Changing Week Menu selections modify any upcoming deliveries for that week and Active Deliveries, if any.",
  },
];

const PopoverInformation = () => {
  const theme = useTheme();

  return (
    <Stack maxWidth="440px" spacing={2}>
      {INFO.map((item) => (
        <Box key={item.title}>
          <Stack direction="row" spacing={1} mb={0.5}>
            <Icon
              name={item.icon}
              size="medium"
              fill={theme.palette.primary.main}
            />
            <Typography variant="body2">{item.title}</Typography>
          </Stack>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {item.description}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default PopoverInformation;
