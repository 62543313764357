import { Box, useTheme } from '@mui/material';
import React from 'react';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

const RestrictedLabel = () => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center">
      <Icon
        name="slash-outline"
        size="medium"
        fill={theme.palette.error.light}
      />
      <Typography
        variant="subtitle2"
        color={theme.palette.error.light}
        lineHeight={1.2}
        ml={0.2}
      >
        RESTRICTED
      </Typography>
    </Box>
  );
};

export default RestrictedLabel;
