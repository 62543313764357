import * as Yup from 'yup';

import {
  ActivityFrequency,
  ActivityReminder,
  AppointmentDuration,
  AppointmentLocation,
  WeekDay,
} from './activityUtils';
import {
  addDayPeriodValidation,
  addEndsAtValidation,
  addTimeValidation,
  addWeekDaysValidation,
  requiredMessage,
  validDateMessage,
} from './validationSchema';

export interface ActivitySchema {
  title: string;
  description?: string;
  date: Date;
  time: string;
  ends: 'never' | 'date';
  ends_at?: Date;
  dayPeriod: 'am' | 'pm';
  frequency: ActivityFrequency;
  weekDays?: WeekDay[];
  reminder: ActivityReminder;
}

export const activityValidationSchema = (errors?: {
  required: string;
  date: string;
  time: string;
  future: string;
  afterStart: string;
}) =>
  Yup.object().shape({
    title: Yup.string().required(errors?.required ?? requiredMessage),
    description: Yup.string(),
    date: Yup.date()
      .nullable()
      .required(errors?.required ?? requiredMessage)
      .typeError(errors?.date ?? validDateMessage),
    time: addTimeValidation(errors?.time).required(
      errors?.required ?? requiredMessage
    ),
    dayPeriod: addDayPeriodValidation(),
    frequency: Yup.string().required(errors?.required ?? requiredMessage),
    weekDays: addWeekDaysValidation(),
    reminder: Yup.string(),
    ends_at: addEndsAtValidation(
      errors?.future && errors?.afterStart
        ? {
            future: errors.future,
            afterStart: errors.afterStart,
          }
        : undefined
    ),
  });

// Appointment

export interface ApptSchema extends ActivitySchema {
  duration: AppointmentDuration;
  location: AppointmentLocation;
  hasVideoCall: boolean;
  syncWithGoogle: boolean;
}

export const apptValidationSchema = (errors?: {
  required: string;
  date: string;
  time: string;
  future: string;
  afterStart: string;
}) =>
  activityValidationSchema(errors).concat(
    Yup.object().shape({
      location: Yup.string().oneOf([
        AppointmentLocation.Virtual,
        AppointmentLocation.InPerson,
      ]),
    })
  );

// Goal

export type GoalSchema = Pick<
  ActivitySchema,
  'date' | 'time' | 'description' | 'title' | 'reminder' | 'dayPeriod'
> & {
  steps: { title: string }[];
};

export const goalValidationSchema = (errors?: {
  required: string;
  date: string;
  time: string;
  future: string;
  afterStart: string;
}) =>
  activityValidationSchema(errors)
    .pick(['title', 'description', 'date', 'time', 'dayPeriod', 'reminder'])
    .concat(
      Yup.object({
        steps: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string(),
            })
          )
          .min(2, errors?.required ?? requiredMessage),
      })
    );
