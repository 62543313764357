import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import {
  Autocomplete,
  Button,
  TextField,
  useSnackbar,
  formatUTCDate,
  Loader,
  Option,
  Select,
  MaskedTextField,
  addDateValidation,
  WeekDaysPicker,
} from '@fdha/web-ui-library';
import { useFeatureFlag } from '@fdha/common-hooks';
import {
  useListCoursesQuery,
  useListGroupsQuery,
  WeekDay,
  CtPresignupInputV2,
  useListDietPlansQuery,
  CtPresignupInfoV2,
  useAddCtPresignupMutation,
  useUpdateCtPresignupMutation,
  useDeleteCtPresignupsMutation,
  ListCtPresignupsDocument,
} from '@fdha/graphql-api-admin';

import { perDayOptions } from '../../../../utils/foodProgramData';

const NUMBER_OF_RESULTS = 10000;

interface PreSignUpSchema {
  email: string;
  subjectId: string;
  studyStart: string;
  associatedSite: string;
  course: Option | null;
  group: Option | null;
  dietPlan: Option | null;
  weekdaysOnDiet: WeekDay[];
  snacksPerDay: string;
  sachetsPerDay: string;
}

const basicValidationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  subjectId: Yup.string().trim().required(),
  studyStart: addDateValidation(),
  associatedSite: Yup.string().trim().required(),
  course: Yup.object().nullable(),
  group: Yup.object().required(),
});

const foodProgramValidationSchema = Yup.object().shape({
  dietPlan: Yup.object().required(),
});

const getValidationSchema = (hasFoodProgram: boolean) => {
  if (hasFoodProgram) {
    return basicValidationSchema.concat(foodProgramValidationSchema);
  }
  return basicValidationSchema;
};

interface AddOrEditPreSignUpProps {
  data?: CtPresignupInfoV2;
  onFinish: () => void;
}

const AddOrEditPreSignUp: React.FC<AddOrEditPreSignUpProps> = ({
  data,
  onFinish,
}) => {
  const [addCtPresignUp] = useAddCtPresignupMutation();
  const [updateCtPresignUp] = useUpdateCtPresignupMutation();
  const [deleteCtPreSignup] = useDeleteCtPresignupsMutation();

  const { isFeatureEnabled, isLoading: loadingFeatureFlag } = useFeatureFlag();
  const showFoodOps = isFeatureEnabled('show_food_ops');

  const { showSnackbar } = useSnackbar();

  const { data: dietPlansData, loading: loadingDietPlans } =
    useListDietPlansQuery({
      variables: { first: NUMBER_OF_RESULTS },
      fetchPolicy: 'cache-and-network',
    });

  const dietPlansOptions = useMemo(
    () =>
      dietPlansData?.dietPlansV2.edges.map((e) => ({
        label: e.node.name,
        id: e.node.id,
      })) ?? [],
    [dietPlansData?.dietPlansV2.edges]
  );

  const { data: coursesData, loading: loadingCourses } = useListCoursesQuery({
    variables: { first: NUMBER_OF_RESULTS },
    fetchPolicy: 'cache-and-network',
  });

  const coursesOptions = useMemo(
    () =>
      coursesData?.courses.edges.map((e) => ({
        label: e.node.title,
        id: e.node.id,
      })) ?? [],
    [coursesData?.courses.edges]
  );

  const { data: groupsList, loading: loadingGroups } = useListGroupsQuery({
    variables: { first: NUMBER_OF_RESULTS },
    fetchPolicy: 'cache-and-network',
  });

  const groupsOptions = useMemo(
    () =>
      groupsList?.groups.edges.map((e) => ({
        label: e.node.name,
        id: e.node.id,
      })) ?? [],
    [groupsList?.groups.edges]
  );

  const initialValues: PreSignUpSchema = {
    email: data?.email || '',
    subjectId: data?.subject_id || '',
    studyStart: formatUTCDate(data?.study_start) || '',
    associatedSite: data?.associated_site || '',
    course: coursesOptions.find((opt) => opt.id === data?.course?.id) || null,
    group: groupsOptions.find((opt) => opt.id === data?.group?.id) || null,
    dietPlan:
      dietPlansOptions.find((opt) => opt.id === data?.dietPlanId) || null,
    weekdaysOnDiet: data?.weekdaysOnDiet || [],
    snacksPerDay: data?.snacksPerDay?.toString() || '',
    sachetsPerDay: data?.sachetsPerDay?.toString() || '',
  };

  const addOrEditPreSignUp = async (values: PreSignUpSchema) => {
    const payload: CtPresignupInputV2 = {
      // `values.courseId` is any empty string when no course
      // is selected, but in that case `courseId` must be null.
      courseId: values.course?.id || null,
      email: values.email,
      subjectId: values.subjectId,
      associatedSite: values.associatedSite,
      studyStart: values.studyStart,
      groupId: values.group?.id || '',
      dietPlanId: values.dietPlan?.id,
      weekdaysOnDiet: values.weekdaysOnDiet,
      snacksPerDay: values.snacksPerDay ? Number(values.snacksPerDay) : null,
      sachetsPerDay: values.sachetsPerDay ? Number(values.sachetsPerDay) : null,
    };

    if (data) {
      return updateCtPresignUp({
        variables: { id: data.id, props: payload },
        refetchQueries: [ListCtPresignupsDocument],
      });
    }

    return addCtPresignUp({
      variables: { input: payload },
      refetchQueries: [ListCtPresignupsDocument],
    });
  };

  const onDelete = async () => {
    if (!data) {
      return;
    }

    try {
      await deleteCtPreSignup({
        variables: { ids: data.id },
        refetchQueries: [ListCtPresignupsDocument],
      });
      showSnackbar({
        message: 'Pre Sign-up entry deleted with success',
        severity: 'success',
      });
    } catch {
      showSnackbar({
        message: 'Error to delete Pre Sign-up entry',
        severity: 'error',
      });
    } finally {
      onFinish();
    }
  };

  const onCancel = () => {
    showSnackbar({
      message: 'Changes not saved',
      severity: 'warning',
    });
    onFinish();
  };

  const isLoading =
    loadingFeatureFlag || loadingCourses || loadingDietPlans || loadingGroups;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={getValidationSchema(showFoodOps)}
      onSubmit={async (values) => {
        try {
          await addOrEditPreSignUp(values);
          showSnackbar({
            message: data
              ? 'Pre Sign-up entry updated with success'
              : 'Pre Sign-up entry added with success',
            severity: 'success',
          });
        } catch (error) {
          console.log(error);
          showSnackbar({
            message: data
              ? 'Error to update Pre Sign-up entry'
              : 'Error to add Pre Sign-up entry',
            severity: 'error',
          });
        } finally {
          onFinish();
        }
      }}
    >
      {({
        errors,
        isValid,
        isSubmitting,
        handleChange,
        setFieldValue,
        values,
      }) => {
        return (
          <Form>
            <Box display="flex" rowGap={2} flexDirection="column">
              <Typography variant="h4">Basic information</Typography>
              <TextField
                title="Email"
                name="email"
                placeholder="Add your email here..."
                type="email"
                onChange={handleChange}
                value={values.email}
              />
              <TextField
                title="Subject ID"
                name="subjectId"
                placeholder="Type your ID..."
                onChange={handleChange}
                value={values.subjectId}
              />
              <MaskedTextField
                title="Study Start"
                mask="date"
                name="studyStart"
                placeholder="MM/DD/YYYY"
                onChange={handleChange}
                error={!!errors.studyStart}
                helperText={errors.studyStart}
                value={values.studyStart}
              />
              <TextField
                title="Associated Site"
                name="associatedSite"
                placeholder="Hospital Name"
                onChange={handleChange}
                value={values.associatedSite}
              />
              <Autocomplete
                title="Group"
                options={groupsOptions}
                value={values.group}
                onChange={(_e, option) => {
                  setFieldValue('group', option);
                }}
                placeholder="Group Name"
              />
              <Autocomplete
                title="Automatically Assigned Course"
                options={coursesOptions}
                value={values.course}
                onChange={(_e, option) => {
                  setFieldValue('course', option);
                }}
                placeholder="Course Name"
              />
              {showFoodOps && (
                <>
                  <Typography variant="h4">Food Program</Typography>
                  <Autocomplete
                    title="Diet Plan"
                    options={dietPlansOptions}
                    value={values.dietPlan}
                    onChange={(_e, option) => {
                      setFieldValue('dietPlan', option);
                    }}
                    placeholder="Diet Plan Name"
                  />
                  <WeekDaysPicker
                    title="Days on Diet"
                    selectedDays={values.weekdaysOnDiet}
                    showSelectedDaysQtd
                    onPressDay={(days) => setFieldValue('weekdaysOnDiet', days)}
                  />
                  <Select
                    name="snacksPerDay"
                    title="How many snacks and sides per day?"
                    titleVariant="body1"
                    onChange={handleChange}
                    options={perDayOptions(9)}
                    value={values.snacksPerDay}
                    showEmptyValue
                    displayEmpty
                  />
                  <Select
                    name="sachetsPerDay"
                    title="How many sachets per day?"
                    titleVariant="body1"
                    onChange={handleChange}
                    options={perDayOptions(10)}
                    value={values.sachetsPerDay}
                    showEmptyValue
                    displayEmpty
                  />
                </>
              )}
            </Box>
            <Box display="flex" justifyContent="space-between" marginTop={5}>
              <Box>
                {data && (
                  <Button
                    color="error"
                    onClick={onDelete}
                    size="large"
                    variant="outlined"
                  >
                    DELETE
                  </Button>
                )}
              </Box>
              <Box>
                <Button
                  color="primary"
                  size="large"
                  onClick={onCancel}
                  data-testid="CANCEL_DIALOG_BUTTON"
                >
                  CANCEL
                </Button>
                <Button
                  disabled={!isValid || isSubmitting}
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  data-testid="SAVE_DIALOG_BUTTON"
                >
                  SAVE
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddOrEditPreSignUp;
