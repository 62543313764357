import { Box } from '@mui/material';
import React from 'react';
import { useSnackbar } from '@fdha/web-ui-library';
import {
  Activity,
  ActivityKind,
  Appointment,
  Goal,
  Task,
} from '@fdha/graphql-api-admin';

import {
  setSelectedActivity,
  updateSelectedActivity,
} from '../../../../../states/activitiesState';

import AddOrEditAppt from './AddAppt/AddOrEditAppt';
import AddOrEditGoal from './AddGoal/AddOrEditGoal';
import AddOrEditTask from './AddTask/AddOrEditTask';

interface EditActivitiesProps {
  patientId: string;
  data: Activity;
  closeDialog: () => void;
}

const EditActivities: React.FC<EditActivitiesProps> = ({
  patientId,
  data,
  closeDialog,
}) => {
  const { showSnackbar } = useSnackbar();

  const onCanceled = () => {
    closeDialog();
    showSnackbar({ message: 'Changes not saved', severity: 'warning' });
  };

  const onSaved = (data?: Partial<Activity>, error?: string) => {
    closeDialog();
    if (error) {
      showSnackbar({
        message: `Error to update activity`,
        severity: 'error',
      });
    } else {
      if (data) {
        updateSelectedActivity(data);
      }
      showSnackbar({ message: 'Activity updated', severity: 'success' });
    }
  };

  const onUnscheduled = (error?: string) => {
    closeDialog();
    if (error) {
      showSnackbar({
        message: `Error to unschedule activity: ${error}`,
        severity: 'error',
      });
    } else {
      setSelectedActivity();
      showSnackbar({ message: 'Activity unscheduled', severity: 'error' });
    }
  };

  return (
    <Box paddingBottom={1}>
      {data.kind === ActivityKind.Goal ? (
        <AddOrEditGoal
          patientId={patientId}
          data={data as Goal}
          onCanceled={onCanceled}
          onSaved={onSaved}
          onUnscheduled={onUnscheduled}
        />
      ) : data.kind === ActivityKind.Task ? (
        <AddOrEditTask
          patientId={patientId}
          data={data as Task}
          onCanceled={onCanceled}
          onSaved={onSaved}
          onUnscheduled={onUnscheduled}
        />
      ) : (
        <AddOrEditAppt
          patientId={patientId}
          data={data as Appointment}
          onCanceled={onCanceled}
          onSaved={onSaved}
          onUnscheduled={onUnscheduled}
        />
      )}
    </Box>
  );
};

export default EditActivities;
