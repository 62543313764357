import { FormikErrors, FormikTouched, getIn } from 'formik';

export function getError<T>(
  name: string,
  errors: FormikErrors<T>,
  touched: FormikTouched<T>
): string | null {
  const errorMsg = getIn(errors, name);
  const hasTouched = getIn(touched, name);

  return hasTouched && errorMsg ? errorMsg : null;
}
