export const otherMedicalConditionsList = [
  {
    label: 'Diabetes',
    value: 'diabetes',
    i18nKey:
      'profile:medicalInformation.input.medicalConditions.options.diabetes',
  },
  {
    label: 'Pre-diabetes',
    value: 'preDiabetes',
    i18nKey:
      'profile:medicalInformation.input.medicalConditions.options.preDiabetes',
  },
  {
    label: 'Hypertension',
    value: 'hypertension',
    i18nKey:
      'profile:medicalInformation.input.medicalConditions.options.hypertension',
  },
  {
    label: 'Stroke',
    value: 'stroke',
    i18nKey:
      'profile:medicalInformation.input.medicalConditions.options.stroke',
  },
  {
    label: 'Arthritis',
    value: 'arthritis',
    i18nKey:
      'profile:medicalInformation.input.medicalConditions.options.arthritis',
  },
  {
    label: 'Anxiety',
    value: 'anxiety',
    i18nKey:
      'profile:medicalInformation.input.medicalConditions.options.anxiety',
  },
  {
    label: 'Cardiovascular Disease',
    value: 'cardiovascularDisease',
    i18nKey:
      'profile:medicalInformation.input.medicalConditions.options.cardiovascularDisease',
  },
  {
    label: 'Hyperlipidemia',
    value: 'hyperlipidemia',
    i18nKey:
      'profile:medicalInformation.input.medicalConditions.options.hyperlipidemia',
  },
  {
    label: 'Other',
    value: 'other',
    i18nKey: 'profile:medicalInformation.input.medicalConditions.options.other',
  },
];
