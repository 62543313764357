import React, { FC, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { ButtonGroup } from '@mui/material';

import { Menu, MenuItem } from '../Menu/Menu';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

export interface SplitMenuProps {
  label: string;
  items: MenuItem[];
  startIcon?: string;
  endIcon?: string;
  mainActionHandler: () => void;
}

export const SplitMenu: FC<SplitMenuProps> = ({
  label,
  items,
  startIcon,
  endIcon = 'arrow-down',
  mainActionHandler,
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const open = !!anchorEl;

  const handleOpenMenu = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button
          data-testid="SPLIT_MENU_BUTTON"
          onClick={mainActionHandler}
          startEvaIcon={
            startIcon
              ? { name: startIcon, fill: theme.palette.primary.contrastText }
              : undefined
          }
        >
          {label}
        </Button>
        <Button onClick={handleOpenMenu} data-testid="OPEN_SPLIT_MENU_BUTTON">
          <Icon
            name={endIcon}
            fill={theme.palette.primary.contrastText}
            size="medium"
          />
        </Button>
      </ButtonGroup>
      <Menu
        items={items}
        handleClose={handleCloseMenu}
        anchorEl={anchorEl}
        open={open}
      />
    </Box>
  );
};
