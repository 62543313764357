import React, { useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import { Box, IconButton } from '@mui/material';

import { Typography } from '../Typography/Typography';
import { Popover } from '../Popover/Popover';
import { Button } from '../Button/Button';
import { Chip } from '../Chip/Chip';
import { i18n } from '../../types/i18n';
import { isI18n } from '../../utils';
import { Icon } from '../Icon/Icon';

export interface TagFilterItem {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface TagFilterOption {
  category: string;
  items: TagFilterItem[];
}

export interface TagFilterProps {
  anchorTitle?: string | i18n;
  containerTitle?: string | i18n;
  confirmButtonLabel?: string | i18n;
  clearButtonLabel?: string | i18n;
  options: TagFilterOption[];
  showCount?: boolean;
  showInlineSelections?: boolean;
  appliedValue: string[];
  onApply?: (value: string[]) => void;
  onDraftChange?: (value: string[]) => void;
  onCancel?: () => void;
  v2?: boolean;
}

export const TagFilter: React.FC<TagFilterProps> = ({
  anchorTitle,
  containerTitle,
  confirmButtonLabel: confirmButtonLabelProp,
  clearButtonLabel: clearButtonLabelProp,
  options,
  appliedValue,
  showCount = true,
  showInlineSelections = true,
  onDraftChange,
  onApply,
  onCancel,
  v2,
}) => {
  const [draftSelections, setDraftSelections] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isOpen = Boolean(anchorEl);

  const popoverId = useMemo(
    () => (isOpen ? 'tag-filter-popover' : undefined),
    [isOpen]
  );

  const containerTitleLabel = useMemo(() => {
    if (containerTitle) {
      return isI18n(containerTitle) ? containerTitle.fallback : containerTitle;
    }
    return 'Filters';
  }, [containerTitle]);
  const containerTitleI18n = useMemo(
    () => (isI18n(containerTitle) ? containerTitle : undefined),
    [containerTitle]
  );

  const anchorTitleLabel = useMemo(() => {
    const label = anchorTitle
      ? isI18n(anchorTitle)
        ? anchorTitle.fallback
        : anchorTitle
      : 'Filters';

    if (showCount && appliedValue.length > 0) {
      return `${label} (${appliedValue.length})`;
    }

    return label;
  }, [showCount, appliedValue.length, anchorTitle]);
  const anchorTitleI18n = useMemo(
    () => (isI18n(anchorTitle) ? anchorTitle : undefined),
    [anchorTitle]
  );

  const confirmButtonLabel = useMemo(() => {
    if (confirmButtonLabelProp) {
      return isI18n(confirmButtonLabelProp)
        ? confirmButtonLabelProp.fallback
        : confirmButtonLabelProp;
    }
    return 'Apply';
  }, [confirmButtonLabelProp]);
  const confirmButtonI18n = useMemo(
    () => (isI18n(confirmButtonLabelProp) ? confirmButtonLabelProp : undefined),
    [confirmButtonLabelProp]
  );

  const clearButtonLabel = useMemo(() => {
    if (clearButtonLabelProp) {
      return isI18n(clearButtonLabelProp)
        ? clearButtonLabelProp.fallback
        : clearButtonLabelProp;
    }
    return 'Clear all';
  }, [clearButtonLabelProp]);
  const clearButtonI18n = useMemo(
    () => (isI18n(clearButtonLabelProp) ? clearButtonLabelProp : undefined),
    [clearButtonLabelProp]
  );

  const appliedItems = useMemo(() => {
    if (!showInlineSelections) {
      return [];
    }

    return uniqBy(options.map((opt) => opt.items).flat(), 'value').filter(
      (item) => appliedValue.includes(item.value)
    );
  }, [appliedValue, options, showInlineSelections]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDraftSelections(appliedValue);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    handleClose();
    onCancel?.();
  };

  const handleClose = () => {
    setDraftSelections([]);
    setAnchorEl(null);
  };

  const handleToggle = (item: TagFilterItem, isSelected: boolean) => {
    // If is open changes should be applied to draft
    if (isOpen) {
      const newDraftSelections = isSelected
        ? draftSelections.filter((v) => v !== item.value)
        : [...draftSelections, item.value];
      setDraftSelections(newDraftSelections);

      onDraftChange?.(newDraftSelections);
    } else {
      // Otherwise, changes should be applied to the actual value
      onApply?.(
        isSelected
          ? appliedValue.filter((v) => v !== item.value)
          : [...appliedValue, item.value]
      );
    }
  };

  const handleConfirm = () => {
    onApply?.(draftSelections);
    handleClose();
  };

  const handleClearAll = () => {
    setDraftSelections([]);
    onApply?.([]);
  };

  /************ V2 updates *********************/

  const confirmButtonColor = v2 ? 'primary' : 'secondary';

  /*********************************************/

  const renderItem = (
    item: TagFilterItem,
    action: 'click' | 'delete',
    isSelected: boolean
  ) => {
    const selectedStyle = isSelected ? { border: '1px solid transparent' } : {};
    const variant = isSelected ? 'filled' : 'outlined';
    const color = v2 ? 'success' : 'primary';

    const toggleFn = () => handleToggle(item, isSelected);

    return (
      <Chip
        key={item.value}
        color={color}
        variant={variant}
        label={item.label}
        disabled={item.disabled}
        onClick={action === 'click' ? toggleFn : undefined}
        onDelete={action === 'delete' ? toggleFn : undefined}
        sx={{ ...selectedStyle }}
        v2={v2}
      />
    );
  };

  const renderAppliedItems = () => {
    return (
      <Box display="flex" flexWrap="wrap" columnGap={1} rowGap={1}>
        {appliedItems.map((item) => renderItem(item, 'delete', true))}
      </Box>
    );
  };

  const renderOption = (option: TagFilterOption) => {
    const { category, items } = option;

    return (
      <Box mb={1} key={option.category}>
        <Typography variant="body1" mb={1}>
          {category}
        </Typography>
        <Box display="flex" flexWrap="wrap" columnGap={1} rowGap={1}>
          {items.map((item) => {
            const isSelected = draftSelections.includes(item.value);
            return renderItem(item, 'click', isSelected);
          })}
        </Box>
      </Box>
    );
  };

  const renderFooter = () => {
    return (
      <Box display="flex" justifyContent="flex-end" columnGap={2}>
        <Button
          onClick={handleClearAll}
          variant="text"
          i18n={clearButtonI18n}
          disabled={!draftSelections?.length}
        >
          {clearButtonLabel}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color={confirmButtonColor}
          i18n={confirmButtonI18n}
        >
          {confirmButtonLabel}
        </Button>
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        rowGap={2}
        minWidth="320px"
        maxWidth="480px"
      >
        <Box display="flex" justifyContent="space-between">
          <Typography
            variant="h6"
            i18nKey={containerTitleI18n?.key}
            i18nComponents={containerTitleI18n?.components}
            i18nParams={containerTitleI18n?.params}
          >
            {containerTitleLabel}
          </Typography>
          <IconButton color="inherit" onClick={handleClose}>
            <Icon name="close-outline" fill="currentColor" size="large" />
          </IconButton>
        </Box>
        {options.map((option) => renderOption(option))}
        {renderFooter()}
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="row" columnGap={2} alignItems="center">
        <Button
          startEvaIcon={{ name: 'funnel-outline' }}
          onClick={handlePopoverOpen}
          variant="outlined"
          sx={{ flexShrink: 0 }}
          i18n={anchorTitleI18n}
        >
          {anchorTitleLabel}
        </Button>
        {!!appliedItems.length && renderAppliedItems()}
      </Box>
      <Popover
        id={popoverId}
        open={isOpen}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        PaperProps={{ variant: 'elevation' }}
        v2={v2}
      >
        {renderContent()}
      </Popover>
    </>
  );
};
