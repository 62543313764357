import React, { FC } from 'react';
import { RecipeType } from '@fdha/graphql-api-patient';
import { Box, Stack, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Icon, Typography } from '..';
import { pluralize } from '../../utils';

export interface RecipeAdditionalInformationProps {
  type?: RecipeType | null;
  servings?: number | null;
  v2?: boolean;
  i18nKeyType?: string;
}

export const RecipeAdditionalInformation: FC<
  RecipeAdditionalInformationProps
> = ({ type, servings, v2, i18nKeyType }) => {
  const theme = useTheme();

  /************ V2 updates *********************/

  const backgroundColor = v2
    ? theme.palette.background.bright5
    : theme.palette.grey[100];
  const borderRadius = v2 ? 0 : '10px';

  /*********************************************/

  const renderSection = (
    text: string,
    icon: string,
    i18nKey?: string,
    i18nParams?: { [key: string]: string | number }
  ) => {
    return (
      <Stack spacing={0.5} direction="row">
        <Icon name={icon} fill={theme.palette.info.dark} size="large" />
        <Typography i18nKey={i18nKey} i18nParams={i18nParams}>
          {text}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      {(type || servings) && (
        <Stack
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="56px"
          sx={{ backgroundColor, borderRadius }}
        >
          {type && renderSection(type, 'clipboard-outline', i18nKeyType)}
          {type && servings && (
            <Box
              sx={{ backgroundColor: grey[500] }}
              height="34px"
              width="1px"
            />
          )}
          {servings &&
            renderSection(
              `${pluralize(servings, 'serving')}`,
              'inbox-outline',
              'learning:recipe.serving',
              { count: servings }
            )}
        </Stack>
      )}
    </>
  );
};
