import { Box, styled } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { PropsWithChildren } from 'react';
import { TextField } from '@fdha/web-ui-library';

interface ActivityFormValues {
  title: string;
  description?: string;
}

const FormItem = styled(Box)(() => ({
  marginTop: '16px',
}));

interface TitleProps {
  label?: string;
}

const Title: React.FC<TitleProps> = ({ label = 'Title' }) => {
  const {
    handleChange,
    values: { title },
  } = useFormikContext<ActivityFormValues>();

  return (
    <FormItem>
      <TextField
        title={label}
        placeholder="Type here..."
        value={title}
        onChange={handleChange('title')}
        inputProps={{ maxLength: 100 }}
      />
    </FormItem>
  );
};

const Details = () => {
  const {
    handleChange,
    values: { description },
  } = useFormikContext<ActivityFormValues>();

  return (
    <FormItem>
      <TextField
        title="Details"
        placeholder="Type here..."
        multiline={true}
        value={description}
        onChange={handleChange('description')}
      />
    </FormItem>
  );
};

const ActivityFormGeneral: React.FC<PropsWithChildren> = ({ children }) => {
  return <Box>{children}</Box>;
};

export default Object.assign(ActivityFormGeneral, { Title, Details });
