import {
  formatHour,
  HeadCell,
  ScheduleCompleteWithin,
} from '@fdha/web-ui-library';
import {
  Survey,
  SurveyFrequency,
  SurveyQuestion,
  WeekDay,
} from '@fdha/graphql-api-admin';
import { parseISO } from 'date-fns';

import { getSurveyName } from './surveysData';

export interface SurveyScheduleProps {
  id: string;
  name: string;
  published: boolean;
  created_at: string;
  questions: SurveyQuestion[];
  starts_at?: string;
  frequency?: SurveyFrequency;
  weekDays?: WeekDay[] | null;
  complete_within_days?: number | null;
  isAssigned: boolean;
  ends_at?: string;
}

export interface ScheduleProps {
  date: string;
  time?: string;
  ends_at?: string;
  dayPeriod: 'am' | 'pm';
  frequency: SurveyFrequency;
  weekDays?: WeekDay[];
  complete_within_days: ScheduleCompleteWithin;
}

export const headCells: HeadCell<SurveyScheduleProps>[] = [
  { id: 'name', label: 'Survey name', sortable: true, searchable: true },
  { id: 'starts_at', label: 'Starts on', sortable: true, searchable: false },
  { id: 'frequency', label: 'Frequency', sortable: true, searchable: false },
  {
    id: 'complete_within_days',
    label: 'Complete Within',
    sortable: true,
    searchable: false,
  },
  { id: 'ends_at', label: 'Ends on', sortable: true, searchable: false },
  { id: 'isAssigned', label: '', sortable: false },
];

export const mapScheduleNode = (node: Survey) => {
  return {
    id: node.id,
    name: getSurveyName(node.name, node.isStructured),
    published: node.published,
    archived: node.archived,
    created_at: node.created_at,
    questions: node.questions,
    starts_at: node.schedule?.starts_at,
    frequency: node.schedule?.frequency,
    weekDays: node.schedule?.weekDays,
    complete_within_days: node.schedule?.complete_within_days,
    isAssigned: !!node.schedule,
    ends_at: node.schedule?.ends_at || undefined,
  };
};

export const getFrequency = (
  starts_at?: string,
  frequency?: SurveyFrequency,
  weekDays?: WeekDay[] | null
) => {
  let time = formatHour(starts_at);
  if (starts_at) {
    const parsedTime = parseISO(starts_at);
    parsedTime.setHours(parsedTime.getUTCHours());
    time = formatHour(parsedTime);
  }

  switch (frequency) {
    case SurveyFrequency.Once:
      return 'non-recurring';
    case SurveyFrequency.Weekly: {
      const days = weekDays?.join(', ').toLowerCase();
      return `weekly, ${days}, ${time}`;
    }
    case SurveyFrequency.Biweekly: {
      const days = weekDays?.join(', ').toLowerCase();
      return `biweekly, ${days}, ${time}`;
    }
    case SurveyFrequency.Triweekly: {
      const days = weekDays?.join(', ').toLowerCase();
      return `triweekly, ${days}, ${time}`;
    }
    case SurveyFrequency.Daily:
      return `daily, ${time}`;
    case SurveyFrequency.Monthly:
      return `monthly, ${time}`;
  }
};
