import { InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Reference, relayStylePagination } from '@apollo/client/utilities';
import { getToken } from '@fdha/common-utils';
import { createUploadLink } from 'apollo-upload-client';
import fdhaConfig from '@fdha/aws-config-admin';

const httpLink = createUploadLink({
  uri: fdhaConfig.api.adminApiBaseUrl,
  headers: {
    'x-api-key': fdhaConfig.api.apiKey,
    'apollo-require-preflight': true,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

export const apolloClientConfig = {
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Goal: {
        keyFields: ['instanceId'],
      },
      Task: {
        keyFields: ['instanceId'],
      },
      Appointment: {
        keyFields: ['instanceId'],
      },
      GoalStep: {
        keyFields: false,
      },
      SurveyQuestion: {
        keyFields: false,
      },
      NoteQuestion: {
        keyFields: false,
      },
      ChartQuestion: {
        keyFields: false,
      },
      AutomationItem: {
        keyFields: ['type', 'patientId'],
        fields: {
          patientId: {
            read(existing, options) {
              return options?.variables?.patientId ?? existing;
            },
          },
        },
      },
      AutomationRequirement: {
        keyFields: ['type', 'patientId'],
        fields: {
          patientId: {
            read(existing, options) {
              return options?.variables?.patientId ?? existing;
            },
          },
        },
      },
      Comment: {
        fields: {
          isPersisted: {
            read(existing) {
              if (existing == null) {
                return true;
              }

              return existing;
            },
          },
        },
      },
      PostWithComments: {
        keyFields: ['__typename'],
        fields: {
          comments: {
            merge(existing = [], incoming, { args, readField }) {
              if (!args?.before) {
                existing = [];
              }

              const incomingNoDups = incoming.filter(
                (incomingItem: Reference) => {
                  const incomingItemId = readField<string>('id', incomingItem);

                  return !existing.some((existingItem: Reference) => {
                    const existingItemId = readField<string>(
                      'id',
                      existingItem
                    );
                    return incomingItemId === existingItemId;
                  });
                }
              );

              return [...existing, ...incomingNoDups];
            },
            read(existing) {
              return existing;
            },
          },
        },
      },
      Post: {
        fields: {
          isPersisted: {
            read(existing) {
              if (existing == null) {
                return true;
              }

              return existing;
            },
          },
        },
      },
      PostList: {
        keyFields: ['__typename'],
        fields: {
          posts: {
            merge(existing, incoming, { readField }) {
              const incomingNoDups = incoming.filter(
                (incomingItem: Reference) => {
                  const incomingItemId = readField<string>('id', incomingItem);

                  return !existing?.some((existingItem: Reference) => {
                    const existingItemId = readField<string>(
                      'id',
                      existingItem
                    );
                    return incomingItemId === existingItemId;
                  });
                }
              );

              return existing ? [...existing, ...incomingNoDups] : incoming;
            },
            read(existing) {
              return existing;
            },
          },
        },
      },
      Query: {
        fields: {
          allPatients: relayStylePagination(),
          myPatients: relayStylePagination(),
          patientsBySite: relayStylePagination(),
          siteStaffBySite: relayStylePagination(),
          patientsByTrial: relayStylePagination(),
          surveySchedules: relayStylePagination(),
          surveys: relayStylePagination(),
          notes: relayStylePagination(),
          surveyInstances: relayStylePagination(),
          ctPresignupsV2: relayStylePagination(),
          coachUsers: relayStylePagination(),
          courses: relayStylePagination(),
          groups: relayStylePagination(),
          groupParticipants: relayStylePagination(),
          foodopsUsers: relayStylePagination(),
          siteStaffUsers: relayStylePagination(),
          sites: relayStylePagination(),
          patientsByCoach: relayStylePagination(),
          learningModules: relayStylePagination(),
          getPatientBhbAnswers: relayStylePagination(),
          documents: relayStylePagination(['ownerFilter']),
          groupRecipes: relayStylePagination(),
          notesTemplates: relayStylePagination(),
          channelAttachments: {
            keyArgs: false,
            merge(existing, incoming) {
              return existing
                ? {
                    next: incoming.next,
                    attachments: [
                      ...existing.attachments,
                      ...incoming.attachments,
                    ],
                  }
                : incoming;
            },
          },
          conferencesHistory: {
            keyArgs: false,
            merge(existing, incoming) {
              return existing
                ? {
                    next: incoming.next,
                    conferences: [
                      ...existing.conferences,
                      ...incoming.conferences,
                    ],
                  }
                : incoming;
            },
          },
          communityNotifications: {
            keyArgs: false,
            merge(existing, incoming, { readField }) {
              const incomingNoDups = incoming.notifications.filter(
                (incomingItem: Reference) => {
                  const incomingItemId = readField<string>('id', incomingItem);

                  return !existing?.notifications?.some(
                    (existingItem: Reference) => {
                      const existingItemId = readField<string>(
                        'id',
                        existingItem
                      );
                      return incomingItemId === existingItemId;
                    }
                  );
                }
              );

              return existing
                ? {
                    hasNextPage:
                      existing?.hasNextPage === null
                        ? null
                        : incoming.hasNextPage,
                    notifications: [
                      ...existing.notifications,
                      ...incomingNoDups,
                    ],
                  }
                : incoming;
            },
          },
        },
      },
    },
  }),
};
