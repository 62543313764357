import {
  ActivitySchema,
  apptValidationSchema,
  useSnackbar,
} from '@fdha/web-ui-library';
import { Form, Formik } from 'formik';
import React from 'react';
import { Box, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import Icon from 'react-eva-icons';
import { grey } from '@mui/material/colors';
import { ApolloCache } from '@apollo/client';
import { useFeatureFlag } from '@fdha/common-hooks';
import {
  Activity,
  Appointment,
  AppointmentDuration,
  AppointmentInput,
  AppointmentLocation,
  useAddAppointmentMutation,
  useGetProfileQuery,
  useUnscheduleAppointmentMutation,
  useUpdateAppointmentMutation,
} from '@fdha/graphql-api-admin';

import ActivityFormGeneral from '../Forms/ActivityFormGeneral';
import ActivityFormPreferences from '../Forms/ActivityFormPreferences';
import ActivityFormActions from '../Forms/ActivityFormActions';
import { resetSelectedActivity } from '../../../../../../states/activitiesState';
import { buildInitialPayload, getSchemaInitialValues } from '../Forms/schema';

interface ApptSchema extends ActivitySchema {
  duration: AppointmentDuration;
  location: AppointmentLocation;
  hasVideoCall: boolean;
  syncWithGoogle: boolean;
}

const buildApptPayload = (values: ApptSchema): AppointmentInput => {
  return {
    ...buildInitialPayload(values),
    ends: values.ends,
    duration: values.duration,
    location: values.location,
    hasVideoCall: values.hasVideoCall,
    syncWithGoogle: !!values.syncWithGoogle,
  };
};

interface AddOrEditApptProps {
  data?: Appointment;
  patientId: string;
  onCanceled: () => void;
  onSaved: (data?: Partial<Activity>, error?: string) => void;
  onUnscheduled?: (error?: string) => void;
}

const AddOrEditAppt: React.FC<AddOrEditApptProps> = ({
  data,
  patientId,
  onCanceled,
  onSaved,
  onUnscheduled,
}) => {
  const isEditing = !!data;
  const titleLabel = isEditing ? 'Appointment Title' : undefined;
  const [addAppointment] = useAddAppointmentMutation();
  const [updateAppointment] = useUpdateAppointmentMutation();
  const [unscheduleAppointment] = useUnscheduleAppointmentMutation();
  const { data: profileData, refetch } = useGetProfileQuery();
  const { showSnackbar } = useSnackbar();

  const { isFeatureEnabled } = useFeatureFlag();
  const showAddToGoogleCalendarButton = isFeatureEnabled(
    'show_add_to_google_calendar'
  );

  const initialValues: ApptSchema = {
    ...getSchemaInitialValues(data),
    duration: data?.duration ?? AppointmentDuration.FifteenMinutes,
    location: data?.location ?? AppointmentLocation.InPerson,
    hasVideoCall: !!data?.hasVideoCall,
    syncWithGoogle: !!data?.google_event_id,
  };

  const updateCache = (cache: ApolloCache<any>) => {
    cache.evict({ fieldName: 'activitiesInRange' });
    cache.gc();
  };

  const saveAppt = async (payload: AppointmentInput) => {
    if (data) {
      return updateAppointment({
        variables: {
          id: data.id,
          props: payload,
        },
        update(cache) {
          updateCache(cache);
        },
      });
    } else {
      return addAppointment({
        variables: { patientId: patientId, props: payload },
        update(cache) {
          updateCache(cache);
        },
      });
    }
  };

  const handleGoogleOAuthClick = () => {
    const newUrl = '/google-oauth';
    const newWindow = window.open(
      newUrl,
      'Google Authentication',
      'height=600,width=500'
    );

    if (newWindow?.focus) {
      newWindow.focus();

      window.addEventListener('locationchange', async () => {
        const query = new URLSearchParams(window.location.search);
        const googleOAuth = query.get('google_oauth_success');

        if (googleOAuth === '1') {
          showSnackbar({
            message: 'Google credentials saved with success!',
            severity: 'success',
          });
          refetch();
        } else if (googleOAuth === '0') {
          showSnackbar({
            message: 'Error to save Google Credentials',
            severity: 'error',
          });
        }
      });
    }
  };

  const handleUnschedule = async () => {
    if (!data) {
      return;
    }
    const { errors } = await unscheduleAppointment({
      variables: { id: data.id },
      update(cache) {
        updateCache(cache);
      },
    });
    onUnscheduled && onUnscheduled(errors && errors[0].message);
  };

  const userHasGoogleAuth = (): boolean => !!profileData?.me.gapi_refresh_token;

  const isCheckboxDisabled = (): boolean =>
    !userHasGoogleAuth() || !!data?.google_event_id;

  const displayGoogleCalendarSyncOption = (): boolean => {
    return showAddToGoogleCalendarButton;
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={apptValidationSchema()}
      onSubmit={async (values) => {
        const payload = buildApptPayload(values);

        try {
          await saveAppt(payload);
          resetSelectedActivity();
          onSaved(payload);
        } catch (error) {
          onSaved(undefined, error as string);
        }
      }}
    >
      {({ handleChange, isValid, values, isSubmitting }) => {
        return (
          <Form>
            <ActivityFormGeneral>
              <ActivityFormGeneral.Title label={titleLabel} />
              <ActivityFormGeneral.Details />
            </ActivityFormGeneral>
            <ActivityFormPreferences>
              <ActivityFormPreferences.Date />
              <ActivityFormPreferences.EndsAt />
              <ActivityFormPreferences.Time />
              <ActivityFormPreferences.Duration />
              <ActivityFormPreferences.Frequency />
              <ActivityFormPreferences.Location />
              <ActivityFormPreferences.Reminder />
            </ActivityFormPreferences>
            {displayGoogleCalendarSyncOption() && (
              <Box display="flex" justifyContent="end" marginBottom={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="syncWithGoogle"
                      onChange={handleChange}
                      disabled={isCheckboxDisabled()}
                      checked={values.syncWithGoogle}
                    />
                  }
                  label="Sync with my Google Calendar"
                  title={
                    !userHasGoogleAuth()
                      ? 'Click on the icon beside and sign in with your Google Account'
                      : ''
                  }
                />
                {!userHasGoogleAuth() && (
                  <IconButton
                    onClick={handleGoogleOAuthClick}
                    title="Open Google sign in"
                  >
                    <Icon
                      name="external-link-outline"
                      size="large"
                      fill={grey[600]}
                    />
                  </IconButton>
                )}
              </Box>
            )}
            <ActivityFormActions
              isEditing={isEditing}
              onCancel={onCanceled}
              onUnschedule={handleUnschedule}
              disableSave={!isValid || isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddOrEditAppt;
