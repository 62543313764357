import React, { useEffect } from 'react';
import { Box, Paper, Stack, TableCell, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import { NetworkStatus } from '@apollo/client';
import {
  Button,
  Table,
  HeadCell,
  SearchableTableHeader,
  formatUTCDate,
} from '@fdha/web-ui-library';
import {
  SiteStaffUser,
  useListSiteStaffUsersQuery,
} from '@fdha/graphql-api-admin';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { Avatar } from '../../../../components';
import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
} from '../../../../hooks';
import { getStatus } from '../../../../utils';

const headCells: HeadCell<SiteStaffUser>[] = [
  { id: 'name', label: 'Site staff name', sortable: true, searchable: true },
  { id: 'email', label: 'Email', sortable: true, searchable: true },
  {
    id: 'site:name',
    label: 'Associated site',
    sortable: true,
    searchable: true,
  },
  {
    id: 'created_at',
    label: 'Creation date',
    sortable: true,
    searchable: false,
  },
  {
    id: 'is_active',
    label: 'Site staff status',
    sortable: false,
    searchable: false,
  },
];

const renderRow = (row: SiteStaffUser) => {
  return (
    <TableRow hover key={row.id} data-testid="TABLE_ROW">
      <TableCell data-testid="NAME_CELL">
        <Avatar type="imageText" picture={row.picture} name={row.name} />
      </TableCell>
      <TableCell data-testid="EMAIL_CELL">{row.email}</TableCell>
      <TableCell data-testid="ASSOCIATED_SITE_CELL">{row.site.name}</TableCell>
      <TableCell data-testid="CREATION_DATE_CELL">
        {formatUTCDate(row.created_at)}
      </TableCell>
      <TableCell data-testid="STATUS_CELL">
        {getStatus(row.is_active)}
      </TableCell>
      <TableCell padding="checkbox">
        <Link
          to={`/trials/site-staff/${row.id}`}
          data-testid="SITE_STAFF_PROFILE_BUTTON"
        >
          <Icon
            name="arrow-ios-forward-outline"
            fill={grey[600]}
            size="large"
          />
        </Link>
      </TableCell>
    </TableRow>
  );
};

const SiteStaffTab = () => {
  const navigate = useNavigate();
  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'siteStaff',
  });

  const [filterBy, setFilterBy] = useFilterBy<SiteStaffUser>('name', '');
  const [sortBy, setSortBy] = useSortBy<SiteStaffUser>('name', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const { data, fetchMore, loading, networkStatus } =
    useListSiteStaffUsersQuery({
      variables: {
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });
  const nodes = data?.siteStaffUsers.edges.map((edge) => edge.node) || [];
  const pageInfo = data?.siteStaffUsers.pageInfo;
  const totalNumberFound = data?.siteStaffUsers.totalNumberFound;

  const emptyState = filterByDebounced.value
    ? 'No results found'
    : 'No site staff users yet';

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const handleNewSiteStaff = () => {
    navigate('/trials/create-site-staff-user');
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} mb={2}>
        <Box flex={1}>
          <SearchableTableHeader<SiteStaffUser>
            headCells={headCells}
            defaultSearchField="name"
            onSearchChange={setFilterBy}
            disableMargin
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          startEvaIcon={{ name: 'plus-outline' }}
          onClick={handleNewSiteStaff}
        >
          Create new user
        </Button>
      </Stack>
      <Paper>
        <Table<SiteStaffUser>
          actions="right"
          initialOrderBy="name"
          headCells={headCells}
          page={page}
          emptyState={emptyState}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={changeRowsPerPage}
          renderRow={renderRow}
          rows={nodes}
          isLoading={loading}
          totalRowCount={totalNumberFound}
          withPagination
          onSortChange={setSortBy}
        />
      </Paper>
    </>
  );
};

export default SiteStaffTab;
