import { Form, Formik } from 'formik';
import React from 'react';
import { omit, pick } from 'lodash';
import {
  buildDateTime,
  formatDate,
  GoalSchema,
  goalValidationSchema,
} from '@fdha/web-ui-library';
import { ApolloCache } from '@apollo/client';
import {
  Activity,
  ActivityFrequency,
  Goal,
  GoalInput,
  GoalUpdateInput,
  useAddGoalMutation,
  useUnscheduleGoalMutation,
  useUpdateGoalMutation,
} from '@fdha/graphql-api-admin';

import ActivityFormActions from '../Forms/ActivityFormActions';
import ActivityFormGeneral from '../Forms/ActivityFormGeneral';
import ActivityFormPreferences from '../Forms/ActivityFormPreferences';
import { resetSelectedActivity } from '../../../../../../states/activitiesState';
import { getSchemaInitialValues } from '../Forms/schema';

import GoalSteps from './GoalSteps';

const buildCreateGoalPayload = (values: GoalSchema): GoalInput => {
  return {
    title: values.title,
    description: values.description,
    reminder: values.reminder,
    steps: values.steps.filter((s) => s.title),
    time: buildDateTime(formatDate(values.date), values.time, values.dayPeriod),
    frequency: ActivityFrequency.Once,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

const buildUpdateGoalPayload = (
  initialValues: GoalSchema,
  values: GoalSchema
): GoalUpdateInput => {
  const time = buildDateTime(
    formatDate(values.date),
    values.time,
    values.dayPeriod
  );
  const initialTime = buildDateTime(
    formatDate(initialValues.date),
    initialValues.time,
    initialValues.dayPeriod
  );

  return {
    title: values.title,
    description: values.description,
    reminder:
      values.reminder !== initialValues.reminder ? values.reminder : undefined,
    time: time !== initialTime ? time : undefined,
    steps: values.steps.filter((s) => s.title),
  };
};

interface AddOrEditGoalProps {
  data?: Goal;
  patientId: string;
  onCanceled: () => void;
  onSaved: (data?: Partial<Activity>, error?: string) => void;
  onUnscheduled?: (error?: string) => void;
}

const AddOrEditGoal: React.FC<AddOrEditGoalProps> = ({
  data,
  patientId,
  onCanceled,
  onSaved,
  onUnscheduled,
}) => {
  const [addGoal] = useAddGoalMutation();
  const [updateGoal] = useUpdateGoalMutation();
  const [unscheduleGoal] = useUnscheduleGoalMutation();

  const emptyStep = { title: '' };
  const isEditing = !!data;
  const titleLabel = isEditing ? 'Goal Title' : undefined;

  const initialValues: GoalSchema = {
    ...pick(getSchemaInitialValues(data), [
      'title',
      'description',
      'reminder',
      'date',
      'time',
      'dayPeriod',
      'frequency',
    ]),
    steps: data?.steps
      ? [...data.steps.map((s) => ({ title: s.title })), emptyStep]
      : [emptyStep],
  };

  const updateCache = (cache: ApolloCache<any>) => {
    cache.evict({ fieldName: 'activitiesInRange' });
    cache.gc();
  };

  const saveGoal = async (values: GoalSchema) => {
    if (data) {
      const payload = buildUpdateGoalPayload(initialValues, values);
      return updateGoal({
        variables: { id: data.id, props: payload },
        update(cache) {
          updateCache(cache);
        },
      });
    } else {
      const payload = buildCreateGoalPayload(values);
      return addGoal({
        variables: { patientId: patientId, props: payload },
        update(cache) {
          updateCache(cache);
        },
      });
    }
  };

  const handleUnschedule = async () => {
    if (!data) {
      return;
    }
    const { errors } = await unscheduleGoal({
      variables: { id: data.id },
      update(cache) {
        updateCache(cache);
      },
    });
    onUnscheduled && onUnscheduled(errors && errors[0].message);
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={goalValidationSchema()}
      onSubmit={async (values) => {
        try {
          await saveGoal(values);
          resetSelectedActivity();
          onSaved({ ...omit(values, 'steps') });
        } catch (error) {
          onSaved(undefined, error as string);
        }
      }}
    >
      {({ handleSubmit, isValid, isSubmitting }) => {
        return (
          <Form>
            <ActivityFormGeneral>
              <ActivityFormGeneral.Title label={titleLabel} />
              <GoalSteps />
              <ActivityFormGeneral.Details />
            </ActivityFormGeneral>
            <ActivityFormPreferences>
              <ActivityFormPreferences.Date />
              <ActivityFormPreferences.Time />
              <ActivityFormPreferences.Reminder />
            </ActivityFormPreferences>
            <ActivityFormActions
              isEditing={isEditing}
              onCancel={onCanceled}
              onUnschedule={handleUnschedule}
              disableSave={!isValid || isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddOrEditGoal;
