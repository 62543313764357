import React, { useEffect, useMemo } from 'react';
import {
  addDateValidation,
  Button,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FieldArray, Form, Formik, FormikState } from 'formik';
import { omit } from 'lodash';
import Icon from 'react-eva-icons';
import {
  NoteQuestion,
  ListNotesDocument,
  useAddChartMutation,
  useDeleteChartMutation,
  useUpdateChartMutation,
  FormBuilderQuestionType,
  DateQuestionInitialState,
  DateQuestion,
} from '@fdha/graphql-api-admin';
import { convertQuestionGroupedPropsToQuestionSplittedProps } from '@fdha/common-utils';
import { isValid } from 'date-fns';
import * as Yup from 'yup';

import {
  editNote,
  hasDraftNote,
  NotesProps,
  removeDraftNote,
  removeEditNote,
} from '../../../../states/notesState';
import { useShouldAutoSave, useLocalStorage } from '../../../../hooks';

import ChartQuestionField from './ChartQuestionField';
import Card from './Card';

const validationSchema = Yup.object().shape({
  questions: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        answer: Yup.array()
          .nullable()
          .when(['type'], {
            is: (type: FormBuilderQuestionType) =>
              type === FormBuilderQuestionType.Date,
            then: Yup.array()
              .nullable()
              .of(addDateValidation(undefined, undefined, false, true)),
          }),
      })
    ),
});

export interface ChartForm {
  name: string;
  questions: NoteQuestion[];
}

interface Props {
  chart: NotesProps;
  patientId: string;
  preview?: boolean;
  index?: number;
}

const ChartCard = ({ chart, patientId, preview = false, index = 0 }: Props) => {
  const initialValues: ChartForm = useMemo(() => {
    const questions = preview
      ? chart.questions
      : chart?.questions?.map((question) => {
          let answer = question.answer;

          if (question.type === FormBuilderQuestionType.Date) {
            if (question.answer?.[0] != null) {
              // Date saved may be a string "Invalid Date"
              if (!isValid(new Date(question.answer[0]))) {
                answer = [''];
              }
            } else {
              const customProps = question.customProps as
                | DateQuestion
                | undefined;

              if (
                customProps?.initialState ===
                DateQuestionInitialState.PreloadedWithCurrentDate
              ) {
                const initialValue = new Date().toISOString();

                answer = [initialValue];
              }
            }
          }

          question = { ...question, answer };
          return question;
        });

    const values = {
      name: chart.name ?? '',
      questions: questions ?? [],
    };
    return values;
  }, [preview, chart.questions, chart.name]);

  let formValues = initialValues;

  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const { shouldAutoSave } = useShouldAutoSave();
  const { saveToLocalStorage, removeFromLocalStorage } =
    useLocalStorage('notes');

  const [deleteNote] = useDeleteChartMutation();
  const [updateNote] = useUpdateChartMutation();
  const [addNote] = useAddChartMutation();

  useEffect(() => {
    if (shouldAutoSave && !preview) {
      const data = {
        [patientId]: {
          index,
          ...chart,
          ...formValues,
        },
      };

      saveToLocalStorage(data);
    }
  }, [
    shouldAutoSave,
    formValues,
    index,
    chart,
    patientId,
    preview,
    saveToLocalStorage,
  ]);

  const showErrorSnackbar = (message: string) => {
    showSnackbar({ message: message, severity: 'error' });
  };

  const showSuccessSnackbar = (message: string) => {
    showSnackbar({ message: message, severity: 'success' });
  };

  const handleCancel = (
    resetForm: (nextState?: Partial<FormikState<ChartForm>> | undefined) => void
  ) => {
    if (chart.id) {
      removeEditNote(chart);
    } else {
      removeDraftNote(chart);
    }
    resetForm({ values: initialValues });
    removeFromLocalStorage(patientId);
    showSnackbar({ message: 'Changes not saved', severity: 'warning' });
  };

  const handleDelete = async () => {
    if (chart.id) {
      openDialog({
        title:
          'Are you sure you want to delete this note? You will not be able to recover it.',
        content: null,
        confirmButtonLabel: 'Delete',
        cancelButtonLabel: 'Keep',
        handleConfirm: async () => {
          try {
            removeEditNote(chart);
            await deleteNote({
              variables: { id: chart.id },
              refetchQueries: [ListNotesDocument],
            });
            removeFromLocalStorage(patientId);
            showSuccessSnackbar('Note deleted');
          } catch (error) {
            showErrorSnackbar('Error deleting note');
          } finally {
            closeDialog();
          }
        },
      });
    } else {
      removeFromLocalStorage(patientId);
      removeDraftNote(chart);
    }
  };

  const handleEdit = () => {
    if (hasDraftNote(patientId)) {
      return showSnackbar({
        message: `There are notes that haven't been saved yet`,
        severity: 'error',
      });
    }

    editNote(chart.id, patientId);
  };

  const handleSubmit = async (values: ChartForm) => {
    const questions = values.questions.map((question) => ({
      ...omit(
        convertQuestionGroupedPropsToQuestionSplittedProps(question),
        'id'
      ),
      answer: question.answer,
    }));

    try {
      if (chart.id) {
        await updateNote({
          variables: {
            id: chart.id,
            chart: {
              name: values.name,
              questions,
            },
          },
          refetchQueries: [ListNotesDocument],
          awaitRefetchQueries: true,
        });
        removeEditNote(chart);
      } else {
        await addNote({
          variables: { chart: { ...values, questions }, patientId },
          refetchQueries: [ListNotesDocument],
          awaitRefetchQueries: true,
        });
        removeDraftNote(chart);
      }
      removeFromLocalStorage(patientId);
      showSuccessSnackbar('Note saved!');
    } catch (error) {
      console.error(error);
      showErrorSnackbar('Error editing/saving note');
    }
  };

  return (
    <Paper data-testid={`CHART_CARD_${chart.id}`}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, resetForm, isSubmitting }) => {
          formValues = values;

          return (
            <Form data-testid={`CHART_CARD_FORM_${chart.name}`}>
              <Card note={chart} canFlag={false}>
                <Stack spacing={8} margin={2}>
                  <FieldArray name="questions">
                    {() =>
                      values.questions.map((question, index) => (
                        <ChartQuestionField
                          key={question.id}
                          question={question}
                          index={index}
                          preview={preview}
                        />
                      ))
                    }
                  </FieldArray>
                </Stack>
              </Card>
              <Box
                display="flex"
                justifyContent={preview ? 'flex-end' : 'space-between'}
                alignItems="center"
                padding={2}
                paddingTop={0}
                data-testid={`${preview ? 'PREVIEW' : 'EDIT'}_CARD`}
              >
                {!preview && (
                  <>
                    <IconButton
                      onClick={handleDelete}
                      data-testid="DELETE_CHART_ICON"
                    >
                      <Icon
                        name="trash-2-outline"
                        size="large"
                        fill={grey[600]}
                      />
                    </IconButton>
                    <Box>
                      <Button
                        onClick={handleCancel.bind(null, resetForm)}
                        data-testid="CANCEL_CHART_BUTTON"
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="secondary"
                      >
                        Save
                      </Button>
                    </Box>
                  </>
                )}
                {preview && (
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton
                      onClick={handleEdit}
                      data-testid="EDIT_CHART_ICON"
                    >
                      <Icon name="edit-outline" size="large" fill={grey[600]} />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default ChartCard;
