import { Box, Grid } from '@mui/material';
import React, { FC, Dispatch, SetStateAction } from 'react';
import { parseISO } from 'date-fns';
import {
  addPhoneNumberValidation,
  Button,
  formatDate,
  MaskedTextField,
  maskPhone,
  requiredMessage,
  TextField,
  useSnackbar,
} from '@fdha/web-ui-library';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';

import { CoachData } from './CoachCard';

interface EditModeCoachCardProps {
  user: CoachData;
  handleEditMode: Dispatch<SetStateAction<boolean>>;
  handleUpdateData: (data: {
    name?: string;
    email?: string;
    phone_number?: string;
  }) => Promise<void>;
}

interface Information {
  name?: string;
  email?: string;
  phone_number?: string;
}

const EditModeCoachCard: FC<EditModeCoachCardProps> = (props) => {
  const { showSnackbar } = useSnackbar();

  const initialValues: Information = {
    name: props.user?.name,
    email: props.user?.email,
    phone_number: maskPhone(props.user?.phone_number),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(requiredMessage),
    email: Yup.string().trim().email().required(requiredMessage),
    phone_number: addPhoneNumberValidation().required(requiredMessage),
  });

  const handleSave = async (values: Information) => {
    try {
      props.handleUpdateData(values);
      props.handleEditMode(false);
      showSnackbar({
        severity: 'success',
        message: 'Changes saved!',
      });
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update data',
      });
    }
  };

  const handleCancel = () => {
    props.handleEditMode(false);
    showSnackbar({
      severity: 'warning',
      message: 'Changes not saved',
    });
  };

  const handleErrors = (
    errors: FormikErrors<Information>,
    touched: FormikTouched<Information>
  ) => {
    return {
      name: touched.name && errors.name,
      email: touched.email && errors.email,
      phone_number: touched.phone_number && errors.phone_number,
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({ values, handleChange, isSubmitting, errors, touched }) => {
        const errorState = handleErrors(errors, touched);

        return (
          <Form>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={6}>
                <TextField
                  title="Name"
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  error={!!errorState.name}
                  helperText={errorState.name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  title="Email"
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  error={!!errorState.email}
                  helperText={errorState.email}
                />
              </Grid>
              <Grid item xs={6}>
                <MaskedTextField
                  title="Phone number"
                  mask="phoneNumber"
                  placeholder="000-000-0000"
                  value={values.phone_number}
                  name="phone_number"
                  onChange={handleChange}
                  error={!!errorState.phone_number}
                  helperText={errorState.phone_number}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  title="Joined on"
                  value={
                    props.user?.created_at &&
                    formatDate(parseISO(props.user.created_at))
                  }
                  disabled
                />
              </Grid>
            </Grid>

            <Box textAlign="end" marginTop={3}>
              <Button onClick={handleCancel} sx={{ padding: '8px 34px' }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: '8px 34px' }}
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditModeCoachCard;
