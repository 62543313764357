import { Box } from '@mui/material';
import React from 'react';
import { Button } from '@fdha/web-ui-library';

interface ActivityFormActionsProps {
  isEditing?: boolean;
  disableSave?: boolean;
  onCancel?: () => void;
  onUnschedule?: () => void;
}

const ActivityFormActions: React.FC<ActivityFormActionsProps> = ({
  isEditing,
  disableSave,
  onCancel,
  onUnschedule,
}) => {
  return (
    <Box
      display="flex"
      justifyContent={isEditing ? 'space-between' : 'flex-end'}
    >
      {isEditing && (
        <Box>
          <Box>
            <Button
              color="error"
              size="large"
              variant="outlined"
              onClick={onUnschedule}
            >
              UNSCHEDULE
            </Button>
          </Box>
        </Box>
      )}

      <Box>
        <Button onClick={onCancel} color="primary" size="large">
          {isEditing ? 'CLOSE' : 'CANCEL'}
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          size="large"
          sx={{ marginRight: 1 }}
          disabled={disableSave}
        >
          SAVE
        </Button>
      </Box>
    </Box>
  );
};

export default ActivityFormActions;
