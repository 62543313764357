import { useNavigate, useLocation, useParams } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import {
  CommentCard,
  DeletedCommentCard,
  Loader,
  Post as PostComponent,
  useSnackbar,
  useDialog,
  InfiniteScrollList,
  CommentBox,
  CommentSchema,
  commentValidationSchema,
  commentInitialValues,
} from '@fdha/web-ui-library';
import { uniqueId } from 'lodash';
import { NetworkStatus, Reference } from '@apollo/client';
import { useFormik } from 'formik';
import {
  Comment,
  CommunityRole,
  ListPostsDocument,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useGetCommunityUserQuery,
  useMarkCommentAsRemovedMutation,
  useDeletePostMutation,
  useMarkPostAsRemovedMutation,
  PostStatus,
  useGetPostWithCommentsQuery,
  GetPostWithCommentsDocument,
} from '@fdha/graphql-api-admin';

import BasePage from '../../components/BasePage/BasePage';
import { useCommunity } from '../../hooks';

import CommunityHeader from './CommunityHeader';
import CommunityWrapper from './CommunityWrapper';

interface StateProps {
  focusOnInput: boolean;
  backRoute?: string;
}

// The optimistic response requires that all fields from the referenced type
// exist when adding an item, while editing required only the originally required fields
type OptimisticResponseAddComment = Required<Comment>;
type OptimisticResponseEditComment = Required<
  Omit<Comment, 'removedBy' | 'removedAt'>
>;

const PostPage = () => {
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const { onliftRestrictionButton, onRestrictButton } = useCommunity([
    GetPostWithCommentsDocument,
  ]);

  const location = useLocation();
  const state = location.state as StateProps;
  const params = useParams();

  const id = params.id ?? '';
  const backRoute = state?.backRoute ?? '';
  const focusOnInput = state?.focusOnInput;
  const isFromFeed = backRoute === '/community';

  const { data: userData, loading: loadingUser } = useGetCommunityUserQuery();
  const { data, networkStatus, fetchMore } = useGetPostWithCommentsQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });
  const [deletePost] = useDeletePostMutation({
    refetchQueries: [ListPostsDocument],
  });

  const [moderationRemovePost] = useMarkPostAsRemovedMutation();

  const [addCommentMutation] = useAddCommentMutation();
  const [deleteCommentMutation] = useDeleteCommentMutation();
  const [markCommentAsRemovedMutation] = useMarkCommentAsRemovedMutation();
  const [editCommentMutation] = useEditCommentMutation();

  const inputRef = useRef<HTMLDivElement>(null);
  const [editedComment, setEditComment] = useState<Comment | undefined>(
    undefined
  );

  const post = data?.postWithComments?.post;
  const comments = data?.postWithComments?.comments ?? [];
  const user = userData?.getCommunityUser;
  const name = user?.name;
  const picture = user?.picture;
  const isModerator = user?.role === CommunityRole.Moderator;
  const isPostRemoved = post?.status === PostStatus.Removed;

  const onEditButton = (postId: string) => {
    navigate(`/community/post/${postId}/edit`);
  };

  const onDeleteButton = (id: string) => {
    openDialog({
      title: 'Are you sure you want to delete this post?',
      content: 'This action can’t be undone.',
      handleConfirm: async () => {
        navigate('../');
        await handleDeletePost(id);
      },
      confirmButtonLabel: 'Delete',
      cancelButtonLabel: 'Cancel',
    });
  };

  const onRemovePostButton = (id: string) => {
    openDialog({
      title: 'Are you sure you want to remove this user’s post?',
      content: 'This action can’t be undone.',

      handleConfirm: async () => {
        closeDialog();
        navigate('../');
        handleRemovePost(id);
      },
      confirmButtonLabel: 'Remove',
      cancelButtonLabel: 'Cancel',
    });
  };

  const handleDeletePost = async (postId: string) => {
    try {
      await deletePost({
        variables: {
          postId,
        },
      });
      showSnackbar({
        severity: 'success',
        message: 'Post Deleted',
      });
    } catch (e) {
      showSnackbar({
        severity: 'error',
        message: 'Unable to Delete Post',
      });
    } finally {
      closeDialog();
    }
  };

  const handleRemovePost = async (postId: string) => {
    if (post == null) {
      return;
    }

    try {
      moderationRemovePost({
        variables: {
          postId,
        },
        optimisticResponse: {
          markPostAsRemoved: {
            ...post,
            __typename: 'Post',
            status: PostStatus.Removed,
            isPersisted: false,
          },
        },
      });
      showSnackbar({
        severity: 'success',
        message: 'Post successfully removed',
      });
    } catch (e) {
      showSnackbar({
        severity: 'error',
        message: 'Unable to Remove Post',
      });
    }
  };

  const handleSendComment = async (text: string, editedComment?: Comment) => {
    if (post == null || user == null) {
      return;
    }

    const postId = post.id;

    if (editedComment) {
      const editCommentResponse: OptimisticResponseEditComment = {
        __typename: 'Comment',
        id: editedComment.id,
        postId,
        user: editedComment.user,
        text,
        time: editedComment.time,
        isEdited: true,
        isRemoved: false,
        isPersisted: false,
      };

      const editComment = editCommentMutation({
        variables: {
          edit: {
            commentId: editedComment.id,
            text,
          },
        },
        optimisticResponse: {
          editComment: editCommentResponse,
        },
        update(cache, mutationResult) {
          const comment = mutationResult.data?.editComment;

          if (comment == null || data?.postWithComments == null) {
            return;
          }

          cache.modify({
            id: cache.identify(data.postWithComments),
            fields: {
              comments(existingComments: Comment[] = []) {
                return existingComments.map((c) =>
                  c.id === comment.id ? comment : c
                );
              },
            },
          });
        },
      });
      try {
        await editComment;
        showSnackbar({ severity: 'success', message: 'Changes Saved' });
      } catch {
        showSnackbar({ severity: 'error', message: 'Unable to Edit Comment' });
      }
    } else {
      const addCommentResponse: OptimisticResponseAddComment = {
        __typename: 'Comment',
        id: uniqueId('__ADDED_COMMENT_'),
        postId,
        user,
        text,
        time: '',
        isEdited: false,
        isRemoved: false,
        removedBy: null,
        removedAt: '',
        isPersisted: false,
      };

      const addComment = addCommentMutation({
        variables: {
          comment: {
            postId,
            text,
          },
        },
        optimisticResponse: {
          addComment: addCommentResponse,
        },
        update(cache, mutationResult) {
          const comment = mutationResult.data?.addComment;

          if (comment == null || data?.postWithComments == null) {
            return;
          }

          cache.modify({
            id: cache.identify(data.postWithComments),
            fields: {
              comments() {
                return [comment, ...comments];
              },
            },
          });

          cache.modify({
            id: cache.identify(post),
            fields: {
              numComments(previousNumComments) {
                return previousNumComments + 1;
              },
            },
          });
        },
      });

      try {
        await addComment;
        showSnackbar({ severity: 'success', message: 'New Comment Published' });
      } catch {
        showSnackbar({
          severity: 'error',
          message: 'Unable to Publish New Comment',
        });
      }
    }
  };

  const deleteComment = async (comment: Comment): Promise<void> => {
    if (post == null) {
      return;
    }

    const { id: commentId } = comment;

    try {
      await deleteCommentMutation({
        variables: {
          commentId,
        },
        optimisticResponse: {
          deleteComment: true,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({ __typename: 'PostWithComments' }),
            fields: {
              comments(existingCommentRefs: Reference[] = [], { readField }) {
                return existingCommentRefs.filter(
                  (cr) => commentId !== readField('id', cr)
                );
              },
            },
          });

          cache.modify({
            id: cache.identify(post),
            fields: {
              numComments(previousNumComments) {
                return previousNumComments - 1;
              },
            },
          });
        },
      });
      showSnackbar({ severity: 'info', message: 'Comment Deleted' });
    } catch {
      showSnackbar({ severity: 'error', message: 'Unable to Delete Comment' });
    }
  };

  const markCommentAsRemoved = async (comment: Comment): Promise<void> => {
    if (post == null || user == null) {
      return;
    }

    const markCommentAsRemoved = markCommentAsRemovedMutation({
      variables: {
        commentId: comment.id,
      },
      optimisticResponse: {
        markCommentAsRemoved: {
          __typename: 'Comment',
          id: comment.id,
          postId: comment.postId,
          removedBy: user,
          user: comment.user,
          text: comment.text,
          time: comment.time,
          isEdited: comment.isEdited,
          isRemoved: true,
          removedAt: '',
          isPersisted: false,
        },
      },
      update(cache, mutationResult) {
        const comment = mutationResult.data?.markCommentAsRemoved;

        if (comment == null) {
          return;
        }

        cache.modify({
          id: cache.identify(post),
          fields: {
            comments(existingComments: Comment[] = []) {
              return existingComments.map((c) =>
                c.id === comment.id ? comment : c
              );
            },
          },
        });
      },
    });

    try {
      await markCommentAsRemoved;
      showSnackbar({ severity: 'info', message: 'Comment Removed' });
    } catch {
      showSnackbar({ severity: 'error', message: 'Unable to Remove Comment' });
    }
  };

  const handleDeleteComment = async (comment: Comment): Promise<void> => {
    openDialog({
      title: 'Are you sure you want to delete this comment?',
      content: 'This action can’t be undone.',
      confirmButtonLabel: 'DELETE',
      cancelButtonLabel: 'CANCEL',
      handleConfirm: async () => {
        await deleteComment(comment);
        closeDialog();
      },
    });
  };

  const handleMarkCommentAsRemoved = async (
    comment: Comment
  ): Promise<void> => {
    openDialog({
      title: 'Remove Comment',
      content: 'Are you sure you want to remove this user’s comment?',
      confirmButtonLabel: 'REMOVE COMMENT',
      cancelButtonLabel: 'CANCEL',
      handleConfirm: async () => {
        markCommentAsRemoved(comment);
        closeDialog();
      },
    });
  };

  const handleEditComment = async (values: CommentSchema) => {
    const { text } = values;
    if (!text) {
      return;
    }

    resetForm({ values: { text: '' } });

    setEditComment(undefined);

    await handleSendComment(text, editedComment);
  };

  const {
    setFieldValue,
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
  } = useFormik({
    initialValues: commentInitialValues,
    validationSchema: commentValidationSchema,
    onSubmit: handleEditComment,
  });

  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const loadingPost = networkStatus === NetworkStatus.refetch;

  const loadMore = () => {
    if (!post?.numComments) return;

    const hasNextPage = comments.length < post.numComments;

    if (isLoadingMore || !hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        beforeComment: comments[comments.length - 1].id,
      },
    });
  };

  if ((isFromFeed && isLoading) || loadingUser || loadingPost || !post) {
    return <Loader />;
  }

  const renderItem = (comment: Comment) => {
    return comment.isRemoved ? (
      <DeletedCommentCard
        key={comment.id}
        comment={comment}
        isModerator={isModerator}
        isMyComment={comment.user.id === user?.id}
      />
    ) : (
      <CommentCard
        key={comment.id}
        comment={comment}
        showModeratorLabel={comment.user.role === CommunityRole.Moderator}
        onEdit={() => {
          setFieldValue('text', comment.text);
          setEditComment(comment);
        }}
        onDelete={handleDeleteComment}
        onRemove={handleMarkCommentAsRemoved}
        onRestrict={onRestrictButton}
        onLiftRestriction={onliftRestrictionButton}
        isMyComment={comment.user.id === user?.id}
        isEditing={comment.id === editedComment?.id}
        isModerator={isModerator}
        editError={errors.text}
        editText={values.text}
        editOnChange={handleChange}
        editOnCancel={() => setEditComment(undefined)}
        editOnSave={handleSubmit}
        onClickProfile={navigate}
      />
    );
  };

  return (
    <BasePage data-testid="COMMUNITY_POST">
      <CommunityHeader
        name={name}
        picture={picture}
        leftItem={<BasePage.BackButton to="/community" />}
      />
      <CommunityWrapper>
        <PostComponent
          post={post}
          onDelete={onDeleteButton}
          onRemovePost={onRemovePostButton}
          onEdit={onEditButton}
          isMine={post.user.id === user?.id}
          isPostRemoved={isPostRemoved}
          isModerator={isModerator}
          onRestrict={onRestrictButton}
          onLiftRestriction={onliftRestrictionButton}
          onClickComment={() => inputRef.current && inputRef.current.focus()}
          onClickProfile={navigate}
        />
        {!isPostRemoved && (
          <Box
            p={2}
            sx={{
              backgroundColor: theme.palette.background.paper,
              border: '1px solid',
              borderTop: 'none',
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
              borderColor: theme.palette.divider,
            }}
          >
            <CommentBox
              isPostRemoved={isPostRemoved}
              onSendComment={handleSendComment}
              focusOnInput={focusOnInput}
              inputRef={inputRef}
            />
            <Box mt={4}>
              <InfiniteScrollList
                items={comments}
                renderItem={renderItem}
                isLoading={isLoading}
                isLoadingMore={isLoadingMore}
                loadMore={loadMore}
              />
            </Box>
          </Box>
        )}
      </CommunityWrapper>
    </BasePage>
  );
};

export default PostPage;
