import { Paper, styled } from '@mui/material';

const StyledChannelWrapper = styled(Paper)(({ theme }) => ({
  '.str-chat-channel.messaging .str-chat__main-panel': {
    padding: 0,
  },
  '.str-chat__message-simple--me .str-chat__message-inner': {
    maxWidth: '80%',
  },
  '.str-chat__message-text-inner': {
    backgroundColor: theme.palette.background.default,
  },
  '.str-chat__message--me .str-chat__message-text-inner': {
    backgroundColor: theme.palette.secondary.main,
    maxWidth: '100%',
  },
  '.str-chat__message-attachment-file--item-text': {
    maxWidth: '85%',
  },
  '.quoted-message-preview .quoted-message-preview-content': {
    maxWidth: '100%',
  },
  '.quoted-message-preview-content-inner': {
    maxWidth: '100%',
  },
  '.str-chat__message-simple__actions': {
    height: '100%',
  },
  '.str-chat__li .quoted-message.mine .quoted-message-inner': {
    maxWidth: '85%',
  },
  '.str-chat__date-separator': {
    justifyContent: 'center',
  },
  '.str-chat__date-separator-line': {
    display: 'none',
  },
  '.str-chat__date-separator-date': {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.primary.main,
    padding: '3px 10px',
    borderRadius: 25,
  },
  flex: 3,
  minWidth: 0,
}));

export default StyledChannelWrapper;
