import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  InfiniteScrollList,
  Loader,
  Post,
  PostCard,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import { Typography } from '@mui/material';
import {
  GetCommunityUserDocument,
  ListPostsDocument,
  useGetCommunityUserQuery,
  useJoinCommunityMutation,
  useListPostsQuery,
  useDeletePostMutation,
  CommunityFeedTypes,
  CommunityRole,
  useMarkPostAsRemovedMutation,
  PostStatus,
} from '@fdha/graphql-api-admin';

import BasePage from '../../components/BasePage/BasePage';
import { useCommunity } from '../../hooks';

import CommunityHeader from './CommunityHeader';
import CommunityWrapper from './CommunityWrapper';

const Feed = () => {
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const { data: userData, loading: loadingProfile } =
    useGetCommunityUserQuery();

  const { onliftRestrictionButton, onRestrictButton } = useCommunity([
    ListPostsDocument,
  ]);

  const [deletePost] = useDeletePostMutation({
    refetchQueries: [ListPostsDocument],
  });
  const [joinCommunity] = useJoinCommunityMutation({
    refetchQueries: [GetCommunityUserDocument],
  });

  const [moderationRemovePost] = useMarkPostAsRemovedMutation();

  const {
    data: postsData,
    networkStatus,
    fetchMore,
  } = useListPostsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      feedType: CommunityFeedTypes.General,
    },
  });

  useEffect(() => {
    const joinCommunityUser = async () => {
      try {
        await joinCommunity();
      } catch (e) {
        console.log('Error joining community', e);
      }
    };

    if (!userData?.getCommunityUser) {
      joinCommunityUser();
    }
  }, [userData?.getCommunityUser, joinCommunity]);

  const posts = postsData?.posts?.posts ?? [];
  const hasMoreResults = postsData?.posts.hasNextPage;

  const isLoading = networkStatus === NetworkStatus.loading && !posts.length;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  if (isLoading || loadingProfile) {
    return <Loader />;
  }

  const loadMore = async () => {
    if (networkStatus !== NetworkStatus.fetchMore && hasMoreResults) {
      try {
        await fetchMore({
          variables: { offset: posts.length },
        });
      } catch (e) {}
    }
  };

  const coachName = userData?.getCommunityUser?.name;
  const picture = userData?.getCommunityUser?.picture;
  const isModerator =
    userData?.getCommunityUser?.role === CommunityRole.Moderator;

  const handleDeletePost = async (postId: string) => {
    try {
      await deletePost({
        variables: {
          postId,
        },
      });
      showSnackbar({
        severity: 'success',
        message: 'Post Deleted',
      });
    } catch (e) {
      showSnackbar({
        severity: 'error',
        message: 'Unable to Delete Post',
      });
    } finally {
      closeDialog();
    }
  };

  const handleRemovePost = async (postId: string) => {
    const post = posts.find((p) => p.id === postId);

    if (post == null) {
      return;
    }

    try {
      await moderationRemovePost({
        variables: {
          postId,
        },
        optimisticResponse: {
          markPostAsRemoved: {
            ...post,
            status: PostStatus.Removed,
            isPersisted: false,
          },
        },
      });
      showSnackbar({
        severity: 'success',
        message: 'Post successfully removed',
      });
    } catch (e) {
      showSnackbar({
        severity: 'error',
        message: 'Unable to Remove Post',
      });
    } finally {
      closeDialog();
    }
  };

  const onDeleteButton = (id: string) => {
    openDialog({
      title: 'Are you sure you want to delete this post?',
      content: 'This action can’t be undone.',
      handleConfirm: () => handleDeletePost(id),
      confirmButtonLabel: 'Delete',
      cancelButtonLabel: 'Cancel',
    });
  };

  const onRemovePostButton = (id: string) => {
    openDialog({
      title: 'Are you sure you want to remove this user’s post?',
      content: 'This action can’t be undone.',
      handleConfirm: async () => handleRemovePost(id),
      confirmButtonLabel: 'Remove',
      cancelButtonLabel: 'Cancel',
    });
  };

  const onEditButton = (postId: string) => {
    navigate(`/community/post/${postId}/edit`, {
      state: { backRoute: '/community' },
    });
  };

  const onWriteButton = () => {
    navigate('/community/write-post', { state: { backRoute: '/community' } });
  };

  const onPhotoButton = () => {
    navigate('/community/write-post', {
      state: { openImagePicker: true, backRoute: '/community' },
    });
  };

  return (
    <BasePage data-testid="COMMUNITY_PANEL">
      <CommunityHeader
        name={coachName}
        picture={picture}
        leftItem={<Typography variant="h4">Community</Typography>}
      />
      <CommunityWrapper spacing={3}>
        <PostCard
          userName={coachName}
          picture={picture}
          handleWriteButton={onWriteButton}
          handlePhotoButton={onPhotoButton}
        />
        <InfiniteScrollList
          items={posts}
          renderItem={(post) => (
            <Post
              isOnFeed
              post={post}
              onDelete={onDeleteButton}
              onRemovePost={onRemovePostButton}
              onEdit={onEditButton}
              onRestrict={onRestrictButton}
              onLiftRestriction={onliftRestrictionButton}
              isMine={post.user.id === userData?.getCommunityUser?.id}
              isPostRemoved={post.status === PostStatus.Removed}
              isModerator={isModerator}
              onClick={() =>
                navigate(`/community/post/${post.id}`, {
                  state: { backRoute: '/community' },
                })
              }
              onClickComment={() =>
                navigate(`/community/post/${post.id}`, {
                  state: { focusOnInput: true, backRoute: '/community' },
                })
              }
              onClickProfile={navigate}
            />
          )}
          loadMore={loadMore}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
        />
      </CommunityWrapper>
    </BasePage>
  );
};

export default Feed;
