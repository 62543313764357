import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';

import InfoDrawer from '../InfoDrawer/InfoDrawer';
import {
  selectedChatDetailsKind,
  setSelectedChatDetailsKind,
} from '../../states/chatDetailsState';

import ChatMedia from './ChatMedia/ChatMedia';

const ChatDetails = () => {
  const selectedKind = useReactiveVar(selectedChatDetailsKind);
  const isOpen = !!selectedKind;

  useEffect(() => {
    setSelectedChatDetailsKind();
  }, []);

  return (
    <InfoDrawer
      title="Chat Media"
      isOpen={isOpen}
      onClose={() => setSelectedChatDetailsKind()}
    >
      {isOpen && (
        <Box width="400px" data-testid="CHAT_DETAILS_CONTAINER">
          <ChatMedia />
        </Box>
      )}
    </InfoDrawer>
  );
};

export default ChatDetails;
