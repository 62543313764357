import {
  AutomationItem,
  AutomationItemStatus,
  AutomationRequirement,
  AutomationRequirementStatus,
} from '@fdha/graphql-api-admin';
import {
  Animation,
  HoverPopover,
  Icon,
  Typography,
  pluralize,
} from '@fdha/web-ui-library';
import { Box, Card, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useMemo } from 'react';

import {
  automationItemLabelByValue,
  automationItemStatusLabelByValue,
  automationRequirementLabelByValue,
  colorVariantAutomationByStatus,
  getCardDescription,
} from '../../utils';

interface AutomationIconProps {
  automationItems: AutomationItem[];
  showCount?: boolean;
}

const iconNameByRequirementStatus: Record<AutomationRequirementStatus, string> =
  {
    [AutomationRequirementStatus.Checking]: 'settings-2-outline',
    [AutomationRequirementStatus.Done]: 'checkmark-outline',
    [AutomationRequirementStatus.Error]: 'slash-outline',
    [AutomationRequirementStatus.Incomplete]: 'clock-outline',
    [AutomationRequirementStatus.Invalid]: 'slash-outline',
  };

const AutomationIcon: React.FC<AutomationIconProps> = ({
  automationItems,
  showCount = true,
}) => {
  const theme = useTheme();

  const automationItemsLabel = useMemo(() => {
    const itemsCount = automationItems.length;

    return `This item ${itemsCount === 1 ? 'has' : 'have'} ${pluralize(
      itemsCount,
      'automation'
    )}`;
  }, [automationItems.length]);

  const getIconAnimation = (
    status: AutomationItemStatus | AutomationRequirementStatus
  ): Animation | undefined => {
    if (
      status === AutomationItemStatus.Checking ||
      status === AutomationRequirementStatus.Checking
    ) {
      return { type: 'spin', animateOn: 'always' };
    }

    return undefined;
  };

  const renderAutomationIcons = (automationItems: AutomationItem[]) => (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      padding={0.2}
      sx={{
        borderRadius: '100px',
        border: `1px solid ${grey[300]}`,
        '&:hover': {
          backgroundColor: grey[200],
        },
      }}
    >
      {automationItems.map((item) => renderAutomationIcon(item.status, false))}
    </Box>
  );

  const renderAutomationIcon = (
    automationStatus: AutomationItemStatus,
    showBorder: boolean
  ) => {
    const colorVariant = colorVariantAutomationByStatus[automationStatus];
    const isChecking = automationStatus === AutomationItemStatus.Checking;
    const iconAnimation = getIconAnimation(automationStatus);
    const iconName = isChecking ? 'settings-2-outline' : 'flash-outline';

    return (
      <Icon
        data-testid="AUTOMATION_ICON"
        name={iconName}
        size="medium"
        fill={theme.palette[colorVariant].main}
        animation={iconAnimation}
        showBorder={showBorder}
      />
    );
  };

  const renderRequirementIcon = (requirement: AutomationRequirement) => {
    const colorVariant = colorVariantAutomationByStatus[requirement.status];
    const iconAnimation = getIconAnimation(requirement.status);
    const iconName = iconNameByRequirementStatus[requirement.status];

    return (
      <Icon
        name={iconName}
        size="medium"
        fill={theme.palette[colorVariant].main}
        animation={iconAnimation}
      />
    );
  };

  const renderAutomationRequirement = (requirement: AutomationRequirement) => {
    return (
      <Box display="flex" rowGap={0.5} columnGap={0.5}>
        <Box
          display="flex"
          alignItems="center"
          columnGap={0.5}
          data-testid="AUTOMATION_ITEM_REQUIREMENT"
        >
          {renderRequirementIcon(requirement)}
          <Typography variant="caption" color={theme.palette.text.secondary}>
            {automationRequirementLabelByValue[requirement.type]}
          </Typography>
        </Box>
      </Box>
    );
  };

  const AutomationDescription = (props: { item: AutomationItem }) => {
    const { item } = props;
    const description = getCardDescription(item);

    if (!description) return null;

    return (
      <Box
        paddingY={1}
        paddingX={1.5}
        sx={{ backgroundColor: theme.palette.grey[200] }}
      >
        <Typography variant="caption" data-testid="AUTOMATION_DETAILS">
          {description}
        </Typography>
      </Box>
    );
  };

  const renderAutomationItem = (item: AutomationItem) => {
    const colorVariant = colorVariantAutomationByStatus[item.status];
    const shouldRenderRequirements = item.requirements.length > 1;

    return (
      <Card
        sx={{
          backgroundColor: grey[50],
          border: `1px solid ${theme.palette[colorVariant].main}`,
        }}
        key={item.type}
        data-testid={`AUTOMATION_CARD_${item.type}`}
      >
        <Box
          display="flex"
          rowGap={1}
          flexDirection="column"
          padding={`${theme.spacing(1)} ${theme.spacing(1)}`}
        >
          <Box
            display="flex"
            alignItems="center"
            columnGap={1}
            justifyContent="space-between"
          >
            <Box display="flex" columnGap={0.5}>
              <Box display="flex" alignItems="center">
                {renderAutomationIcon(item.status, true)}
              </Box>
              <Typography data-testid="AUTOMATION_ITEM_LABEL" variant="body1">
                {automationItemLabelByValue[item.type]}
              </Typography>
            </Box>
            <Typography
              data-testid="AUTOMATION_ITEM_STATUS"
              variant="caption"
              color={theme.palette.text.secondary}
            >
              {automationItemStatusLabelByValue[item.status]}
            </Typography>
          </Box>
          {shouldRenderRequirements && (
            <Box>{item.requirements.map(renderAutomationRequirement)}</Box>
          )}
        </Box>
        <AutomationDescription item={item} />
      </Card>
    );
  };

  const PopoverContent = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        rowGap={1}
        maxWidth="392px"
        data-testid="AUTOMATION_DETAILS_POPOVER_CONTENT"
      >
        {showCount && <Typography>{automationItemsLabel}</Typography>}
        {automationItems.map((item) => renderAutomationItem(item))}
      </Box>
    );
  };

  return (
    <HoverPopover content={<PopoverContent />} data-testid="POPOVER_CONTENT">
      {renderAutomationIcons(automationItems)}
    </HoverPopover>
  );
};

export default AutomationIcon;
