import {
  AutomationItem,
  AutomationRequirementStatus,
} from '@fdha/graphql-api-admin';
import {
  Button,
  Icon,
  IconButton,
  LabelBox,
  Typography,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  Badge,
  Box,
  Card,
  Grid,
  Paper,
  RegularBreakpoints,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { PropsWithChildren, useMemo, useState } from 'react';

import { AutomationDetails } from '..';
import { colorVariantAutomationByStatus } from '../../utils';

interface DependencyFields {
  name: string;
  isSet: boolean;
}
interface DependencyFieldStatus {
  fields: DependencyFields[];
  customMessage?: string;
}

export interface EditableCardProps extends PropsWithChildren {
  title: string;
  label?: string;
  canEdit?: boolean;
  breakpoints?: RegularBreakpoints;
  isLoading?: boolean;
  dependenciesStatus?: DependencyFieldStatus;
  showInfoCard?: boolean;
  automationItems?: AutomationItem[];
  automationRequirementStatus?: AutomationRequirementStatus;
}

export const EditableCard = ({
  children,
  title,
  canEdit = true,
  label: labelProp,
  breakpoints,
  isLoading,
  dependenciesStatus,
  showInfoCard,
  automationItems = [],
  automationRequirementStatus,
}: EditableCardProps) => {
  const theme = useTheme();

  const [isEditing, setIsEditing] = useState(false);

  const { submitForm, isSubmitting, resetForm } = useFormikContext();
  const { showSnackbar } = useSnackbar();

  const titleId = title.toUpperCase().replaceAll(' ', '_');

  const missingDependencies = useMemo(
    () => dependenciesStatus?.fields.filter((item) => !item.isSet),
    [dependenciesStatus]
  );

  const isMissingDependencies = useMemo(
    () => !!missingDependencies?.length,
    [missingDependencies]
  );

  const shouldShowAutomationDetails = useMemo(
    () => automationItems?.length && !isEditing,
    [automationItems?.length, isEditing]
  );

  const shouldShowAutomationRequirementStatus = useMemo(
    () => automationItems?.length && automationRequirementStatus && !isEditing,
    [automationItems?.length, automationRequirementStatus, isEditing]
  );

  const tooltipMessage = useMemo(() => {
    if (missingDependencies?.length) {
      if (dependenciesStatus?.customMessage) {
        return dependenciesStatus.customMessage;
      }

      let missingFields = missingDependencies
        .map((field) => field.name)
        .join(', ');

      //Replace last comma with and
      const lastCommaIndex = missingFields.lastIndexOf(',');
      if (lastCommaIndex !== -1) {
        missingFields =
          missingFields.substring(0, lastCommaIndex) +
          ' and' +
          missingFields.substring(lastCommaIndex + 1);
      }

      return `This item can only be edited once the patient is assigned a ${missingFields}`;
    }
    return null;
  }, [dependenciesStatus?.customMessage, missingDependencies]);

  const handleSubmit = async () => {
    await submitForm();
    setIsEditing(false);
  };

  const handleCancel = () => {
    resetForm();
    setIsEditing(false);
    showSnackbar({
      severity: 'warning',
      message: 'Changes not saved',
    });
  };

  const label = useMemo(() => {
    if (labelProp === undefined || labelProp === null) {
      return `${title} is not defined yet`;
    }
    return labelProp;
  }, [labelProp, title]);

  const renderAutRequirementStatus = () => {
    if (shouldShowAutomationRequirementStatus && automationRequirementStatus) {
      return (
        <Badge
          data-testid="AUTOMATION_STATUS_BADGE"
          color={colorVariantAutomationByStatus[automationRequirementStatus]}
          variant="dot"
          sx={{ mr: 1, ml: 0.5 }}
        />
      );
    }

    return null;
  };

  const renderInfoCard = () => {
    return (
      <Card
        sx={{
          display: 'flex',
          columnGap: 1,
          mt: 1,
          border: 'none',
          backgroundColor: theme.palette.info.lightBg,
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        }}
      >
        <Icon name="info-outline" size="large" fill={theme.palette.info.main} />
        <Typography variant="body2">
          Any changes to this item will notify the Food Operations team at Faeth
          by email.
        </Typography>
      </Card>
    );
  };

  const renderLabelValue = () => {
    if (!isEditing) {
      return label;
    } else {
      return <>{children}</>;
    }
  };

  return (
    <Grid item {...breakpoints}>
      <Paper
        sx={{
          px: 3,
          py: 2,
        }}
        data-testid={`${titleId}_CARD`}
      >
        <Grid container>
          <LabelBox
            hideMargin
            breakpoints={{ xs: canEdit ? 10 : 12 }}
            label={title}
            value={renderLabelValue()}
            startAdornment={renderAutRequirementStatus() || undefined}
            endAdornment={
              shouldShowAutomationDetails ? (
                <AutomationDetails automationItems={automationItems} />
              ) : undefined
            }
          />
          {canEdit && (
            <Grid
              item
              xs={2}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {!isEditing && (
                <Tooltip title={tooltipMessage} data-testid="EDIT_TOOLTIP">
                  <Box>
                    <IconButton
                      disabled={isMissingDependencies || isLoading}
                      onClick={() => setIsEditing(true)}
                      data-testid="EDIT_BUTTON"
                      iconProps={{
                        name: 'edit-outline',
                        size: 'large',
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
        {isEditing && (
          <>
            {showInfoCard && renderInfoCard()}
            <Stack
              flexDirection="row"
              columnGap={1}
              mt={2}
              justifyContent="flex-end"
            >
              <Button
                onClick={handleCancel}
                data-testid="CANCEL_EDITION_BUTTON"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                data-testid="SAVE_EDITION_BUTTON"
                disabled={isSubmitting}
              >
                Save changes
              </Button>
            </Stack>
          </>
        )}
      </Paper>
    </Grid>
  );
};
