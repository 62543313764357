import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ActivityDetailsCard } from '@fdha/web-ui-library';
import {
  Task,
  useUpdateTaskCompletedStateMutation,
} from '@fdha/graphql-api-admin';

import { updateSelectedActivity } from '../../../../../../states';

interface TaskDetailsProps {
  data: Task;
  isCreatedByCoach: boolean;
  onEdit: () => void;
  isCsr?: boolean;
}

const TaskDetails: React.FC<TaskDetailsProps> = ({
  data,
  isCreatedByCoach,
  onEdit,
  isCsr,
}) => {
  const [updateTaskCompletedState] = useUpdateTaskCompletedStateMutation();

  const handleTaskStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const completed = e.target.checked;

    updateTaskCompletedState({
      variables: { instanceId: data.instanceId!, completed: completed },
      update(cache) {
        cache.modify({
          id: cache.identify(data),
          fields: {
            completed() {
              return completed;
            },
          },
        });
      },
    });

    updateSelectedActivity({ completed } as Task);
  };

  return (
    <ActivityDetailsCard>
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        {!isCsr ? (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.completed}
                  onChange={handleTaskStateChange}
                />
              }
              label={
                <Typography variant="h6" sx={{ overflowWrap: 'anywhere' }}>
                  {data.title}
                </Typography>
              }
            />
            <ActivityDetailsCard.EditButton onEdit={onEdit} />
          </>
        ) : (
          <Typography variant="h6" sx={{ overflowWrap: 'anywhere' }}>
            {data.title}
          </Typography>
        )}
      </Box>
      <Box>
        <ActivityDetailsCard.Details details={data.description} />
        <ActivityDetailsCard.Date date={data.time} />
        <ActivityDetailsCard.EndDate date={data.ends_at} />
        <ActivityDetailsCard.Time time={data.time} />
        <ActivityDetailsCard.Frequency
          frequency={data.frequency}
          weekDays={data.weekDays}
        />
        <ActivityDetailsCard.Reminder reminder={data.reminder} />
      </Box>
      <ActivityDetailsCard.CreatedBy isCreatedByCoach={isCreatedByCoach} />
    </ActivityDetailsCard>
  );
};

export default TaskDetails;
