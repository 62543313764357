import { NetworkStatus } from '@apollo/client';
import {
  Button,
  Icon,
  InfiniteScrollList,
  UserCard,
  NotificationItem,
} from '@fdha/web-ui-library';
import {
  Box,
  Stack,
  useTheme,
  Popper,
  ClickAwayListener,
  Typography,
  Badge,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { FC, useState } from 'react';
import {
  useCommunityMarkAllNotificationsAsSeenMutation,
  useCommunityMarkNotificationAsReadMutation,
  useListCommunityNotificationsQuery,
  ListCommunityNotificationsDocument,
} from '@fdha/graphql-api-admin';

import { useGetUserType } from '../../hooks';

export interface CommunityHeaderProps {
  name?: string;
  picture?: string | null;
  leftItem?: JSX.Element;
}

const CommunityHeader: FC<CommunityHeaderProps> = ({
  name,
  picture,
  leftItem,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { data, networkStatus, fetchMore } = useListCommunityNotificationsQuery(
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only',
      notifyOnNetworkStatusChange: true,
    }
  );
  const [communityMarkNotificationAsRead] =
    useCommunityMarkNotificationAsReadMutation();
  const [communityMarkAllNotificationsAsSeen] =
    useCommunityMarkAllNotificationsAsSeenMutation({
      refetchQueries: [ListCommunityNotificationsDocument],
    });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = (event: Event | React.SyntheticEvent) => {
    if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
      return;
    }

    setAnchorEl(null);
  };

  const { isCsr } = useGetUserType();

  const notifications = data?.communityNotifications.notifications || [];
  const unseenCount = data?.communityNotifications.numUnseen;
  type CommunityNotificationGeneric = (typeof notifications)[0];

  const open = !!anchorEl;
  const hasNextPage = data?.communityNotifications.hasNextPage;
  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  const buttonBgColor = open
    ? theme.palette.primary.main
    : theme.palette.background.default;
  const buttonHoverBgColor = open ? theme.palette.primary.main : undefined;
  const buttonIcon = open ? 'bell' : 'bell-outline';
  const buttonIconFill = open
    ? theme.palette.primary.contrastText
    : theme.palette.primary.main;

  const loadMore = async () => {
    if (isLoadingMore || !hasNextPage) {
      return;
    }

    await fetchMore({
      variables: { before: notifications[notifications.length - 1].id },
    });
  };

  const handleNotificationClick = (item: CommunityNotificationGeneric) => {
    switch (item.__typename) {
      case 'CommunityNotificationComment':
      case 'CommunityNotificationRemoveComment':
      case 'CommunityNotificationRemovePost':
        if (!item.postId) {
          return;
        }

        navigate(`/community/post/${item.postId}`);
        break;

      default:
        navigate('/community');
        break;
    }

    communityMarkNotificationAsRead({ variables: { notificationId: item.id } });
  };

  const renderItem = (item: CommunityNotificationGeneric) => {
    const name =
      'commenterUsers' in item ? item.commenterUsers[0].name : undefined;
    const picture =
      'commenterUsers' in item ? item.commenterUsers[0].picture : undefined;

    return (
      <NotificationItem
        name={name}
        picture={picture}
        handleClick={() => handleNotificationClick(item)}
        read={item.isRead}
        time={item.time}
        type={item.__typename}
      />
    );
  };

  const renderEmptyState = () => {
    return (
      <Box marginY={3} paddingLeft={4}>
        <Typography variant="h6">No Notifications Yet</Typography>
        <Typography variant="body1" color={theme.palette.text.secondary}>
          When you get notifications, they will show up here.
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      flexDirection="row"
      display="flex"
      mb={3}
      justifyContent="space-between"
      height="56px"
    >
      {leftItem}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Stack direction="row" spacing={3}>
          <Badge badgeContent={unseenCount} color="error">
            <Button
              variant="outlined"
              sx={{
                borderRadius: '15px',
                backgroundColor: buttonBgColor,
                '&:hover': {
                  backgroundColor: buttonHoverBgColor,
                },
              }}
              onClick={(event) => {
                handleOpenMenu(event);
                if (unseenCount) {
                  communityMarkAllNotificationsAsSeen();
                }
              }}
            >
              <Icon name={buttonIcon} fill={buttonIconFill} size="large" />
            </Button>
          </Badge>
          <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
            <Box
              mt={1}
              bgcolor={theme.palette.background.paper}
              height="560px"
              width="600px"
              overflow="auto"
              borderRadius="15px"
              boxShadow={theme.shadows[3]}
            >
              {!notifications.length && !isLoading ? (
                renderEmptyState()
              ) : (
                <>
                  <Box marginY={3} paddingLeft={4}>
                    <Typography variant="h4">Notifications</Typography>
                  </Box>
                  <InfiniteScrollList
                    items={notifications}
                    renderItem={renderItem}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    loadMore={loadMore}
                    disableRowGap
                  />
                </>
              )}
            </Box>
          </Popper>
          <UserCard
            name={name}
            picture={picture}
            showUserType={!isCsr}
            handleClick={() => navigate('/profile')}
          />
        </Stack>
      </ClickAwayListener>
    </Box>
  );
};

export default CommunityHeader;
