import React, { FC, PropsWithChildren, useState } from 'react';
import { Box, Collapse, Stack, useTheme } from '@mui/material';

import { Typography } from '../Typography/Typography';
import { Icon } from '../Icon/Icon';
import { ButtonUnstyled } from '../Button/ButtonUnstyled';

export interface CollapsedWrapperProps {
  title: string;
  initialOpenState?: boolean;
  testId?: string;
}

export const CollapsedWrapper: FC<PropsWithChildren<CollapsedWrapperProps>> = ({
  children,
  title,
  initialOpenState = false,
  testId,
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(initialOpenState);

  const iconName = open
    ? 'arrow-ios-upward-outline'
    : 'arrow-ios-downward-outline';

  return (
    <Stack data-testid={testId} spacing={2}>
      <ButtonUnstyled onClick={() => setOpen(!open)}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6">{title}</Typography>
          <Icon
            name={iconName}
            size="large"
            fill={theme.palette.text.primary}
          />
        </Stack>
      </ButtonUnstyled>
      <Collapse in={open}>
        <Box display="flex" flexDirection="row" flex={1} flexWrap="wrap">
          {children}
        </Box>
      </Collapse>
    </Stack>
  );
};
