import React from 'react';
import { Formik } from 'formik';
import { WeekDaysPicker, WeekDaysPickerProps } from '@fdha/web-ui-library';

import { EditableCard, EditableCardProps } from './EditableCard';

export interface EditableCardWeekDaysSchema {
  selectedDays: WeekDaysPickerProps['selectedDays'];
}

interface EditableCardWeekDaysProps extends EditableCardProps {
  initialValue: WeekDaysPickerProps['selectedDays'];
  onSave: (value: WeekDaysPickerProps['selectedDays']) => Promise<void>;
  weekDaysPickerProps?: Omit<
    WeekDaysPickerProps,
    'selectedDays' | 'onPressDay'
  >;
}

export const EditableCardWeekDays: React.FC<EditableCardWeekDaysProps> = ({
  onSave,
  initialValue,
  weekDaysPickerProps,
  ...editableCardProps
}) => {
  const initialValues: EditableCardWeekDaysSchema = {
    selectedDays: initialValue,
  };

  const handleSubmit = async (values: EditableCardWeekDaysSchema) => {
    await onSave(values.selectedDays);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <EditableCard {...editableCardProps}>
          <WeekDaysPicker
            showSelectedDaysQtd
            selectedDays={values.selectedDays}
            onPressDay={(days) => setFieldValue('selectedDays', days)}
            {...weekDaysPickerProps}
          />
        </EditableCard>
      )}
    </Formik>
  );
};

export default EditableCardWeekDays;
