import { Box, Paper, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface FooterProps {
  showAlert?: boolean;
  children: ReactNode | undefined;
}
const Footer: FC<FooterProps> = ({ children, showAlert }) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        position: 'sticky',
        bottom: 0,
        border: 'none',
        boxShadow: theme.shadows[2],
        width: '99%',
        alignSelf: 'center',
      }}
    >
      {showAlert && (
        <Box
          py={2}
          px={3}
          textAlign="center"
          sx={{
            backgroundColor: theme.palette.info.lightBg,
            borderTopLeftRadius: '14px',
            borderTopRightRadius: '14px',
          }}
        >
          The current meal selection differs from what is specified in the Food
          Program Information.
        </Box>
      )}
      <Box py={2} px={3}>
        {children}
      </Box>
    </Paper>
  );
};

export default Footer;
