import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import React, { useMemo, useRef } from 'react';
import { useGetTrialQuery } from '@fdha/graphql-api-admin';
import { Loader } from '@fdha/web-ui-library';

import BasePage from '../../components/BasePage/BasePage';

interface StateProps {
  backRoute?: string;
}

const Trial = () => {
  const location = useLocation();
  const params = useParams();

  const state = location.state as StateProps;
  const trialId = params.trialId || '';

  const { data, loading } = useGetTrialQuery({
    variables: {
      id: trialId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const trial = data?.trial;

  const tabs = useMemo(
    () => [
      {
        value: 'about',
        label: 'About',
        to: './',
        href: '/trials',
      },
      {
        value: 'patients',
        label: `Patients (${trial?.number_of_patients})`,
        to: 'patients',
        href: `/trials/trials-list/trial/${trialId}/patients`,
        'data-testid': 'TRIAL_PATIENTS_TAB',
      },
      {
        value: 'sites',
        label: `Sites (${trial?.numberOfSites})`,
        to: 'sites',
        href: `/trials/trials-list/trial/${trialId}/sites`,
      },
    ],
    [trialId, trial?.number_of_patients, trial?.numberOfSites]
  );

  const backRouteRef = useRef(state ? state.backRoute : '/trials/trials-list');

  const selectedTab =
    tabs.find((tab) => tab.href === location.pathname)?.value || 'about';

  if (loading) {
    return <Loader />;
  }

  return (
    <BasePage>
      <BasePage.BackButton to={backRouteRef.current} />
      <BasePage.Title>{trial?.protocol_number}</BasePage.Title>
      <TabContext value={selectedTab}>
        <Box borderBottom={1} borderColor="divider">
          <TabList orientation="horizontal" value={selectedTab}>
            {tabs.map((tab, index) => (
              <Tab
                data-testid={`TRIALS_VIEW_TAB_${tab.value.toUpperCase()}`}
                key={index}
                label={tab.label}
                value={tab.value}
                component={Link}
                to={tab.to}
              />
            ))}
          </TabList>
        </Box>
        <TabPanel
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: 0,
            paddingTop: 4,
          }}
          value={selectedTab}
        >
          <Outlet />
        </TabPanel>
      </TabContext>
    </BasePage>
  );
};

export default Trial;
