import { useCallback, useEffect, useState } from 'react';
import { useGetProfileQuery } from '@fdha/graphql-api-admin';

import { addItemListener, removeItemListener } from '../utils';

import { useCryptography } from './useCryptography';

export const useReadLocalStorage = (
  key: string,
  encrypted: boolean = true
): any => {
  const { decrypt } = useCryptography();

  const { data: profileData } = useGetProfileQuery();
  const userId = profileData?.me.id || '';

  const readValue = useCallback(async () => {
    try {
      const item = localStorage.getItem(`${key}_${userId}`);

      if (item) {
        const data = JSON.parse(item);
        const userData = data || null;

        if (encrypted) {
          const decryptedData = await decrypt(userData);
          setStoredValue(decryptedData);
        } else {
          setStoredValue(userData);
        }
      } else {
        setStoredValue(null);
      }
    } catch (error) {
      console.log(`Error reading localStorage key “${key}_${userId}”:`, error);
      return null;
    }
  }, [key, userId, encrypted, decrypt]);

  const [storedValue, setStoredValue] = useState<Record<
    string,
    unknown
  > | null>();

  const handleStorageChange = useCallback(() => {
    readValue();
  }, [readValue]);

  useEffect(() => {
    addItemListener(key, handleStorageChange);

    return () => {
      removeItemListener(key, handleStorageChange);
    };
  }, [handleStorageChange, key]);

  useEffect(() => {
    readValue();
  }, [readValue]);

  return storedValue;
};
