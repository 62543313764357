import React, { PropsWithChildren, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useFeatureFlag } from '@fdha/common-hooks';
import { LeftNavTab, LeftNav, Loader } from '@fdha/web-ui-library';
import { Box, useTheme } from '@mui/material';

import { useGetUserType } from '../../hooks';
import { MainContainer } from '..';

const getTabs = (
  isAdmin: boolean,
  showCommunity: boolean,
  showStreamChat: boolean
): LeftNavTab[] => [
  {
    id: 'tab-home',
    label: 'Home',
    'aria-controls': 'tabpanel-tab-home',
    activeIcon: 'home',
    inactiveIcon: 'home-outline',
    to: '/',
    component: Link,
    'data-testId': 'TAB_HOME',
  },
  ...(isAdmin
    ? [
        {
          id: 'tab-trials',
          label: 'Trials',
          'aria-controls': 'tabpanel-tab-trials',
          activeIcon: 'trending-up',
          inactiveIcon: 'trending-up-outline',
          to: '/trials',
          component: Link,
          'data-testId': 'TAB_TRIALS',
        },
      ]
    : []),
  ...(showStreamChat
    ? [
        {
          id: 'tab-chat',
          label: 'Chat',
          'aria-controls': 'tabpanel-tab-chat',
          activeIcon: 'message-circle',
          inactiveIcon: 'message-circle-outline',
          to: '/chat',
          component: Link,
          'data-testId': 'TAB_CHAT',
        },
      ]
    : []),
  ...(showCommunity
    ? [
        {
          id: 'tab-community',
          label: 'Community',
          'aria-controls': 'tabpanel-tab-community',
          activeIcon: 'people',
          inactiveIcon: 'people-outline',
          to: '/community',
          component: Link,
          'data-testId': 'TAB_COMMUNITY',
        },
      ]
    : []),
  ...(isAdmin
    ? [
        {
          id: 'tab-settings',
          label: 'Settings',
          'aria-controls': 'tabpanel-tab-settings',
          activeIcon: 'settings-2',
          inactiveIcon: 'settings-2-outline',
          to: '/settings',
          component: Link,
          'data-testId': 'TAB_SETTINGS',
        },
      ]
    : []),
];

const AppLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAdmin, isLoading: loadingIsAdmin } = useGetUserType();
  const { pathname } = useLocation();
  const theme = useTheme();

  const { isFeatureEnabled, isLoading: loadingFeatureFlag } = useFeatureFlag();
  const showCommunity = isFeatureEnabled('show_community');
  const showStreamChat = isFeatureEnabled('show_stream_chat');

  const tabs = getTabs(!!isAdmin, showCommunity, showStreamChat);

  const selectedTabId = useMemo(() => {
    const regex = /\/([^/]+)/;
    const [firstPath] = pathname.match(regex) || [];

    return tabs.find((tab) => firstPath === tab.to)?.id || 'tab-home';
  }, [tabs, pathname]);

  const isLoading = loadingIsAdmin || loadingFeatureFlag;

  return (
    <Box height="100vh">
      {isLoading ? (
        <Loader />
      ) : (
        <Box bgcolor={theme.palette.primary.main} display="flex" height="100%">
          <LeftNav
            tabs={tabs}
            activeTabId={selectedTabId}
            logo="/images/Faeth_Logo_IconOnly.png"
            data-testId="APP_BAR"
          />
          <MainContainer activeTabId={selectedTabId}>{children}</MainContainer>
        </Box>
      )}
    </Box>
  );
};

export default AppLayout;
