import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { TextField } from '@fdha/web-ui-library';
import { FormBuilderQuestionType } from '@fdha/graphql-api-admin';

import TabPanel from '../../../TabPanel/TabPanel';
import {
  ErrorStateProps,
  QuestionCardType,
} from '../../../../models/FormBuilderProps';

import ChoiceFieldArray from './ChoiceFieldArray';
import ScaleField from './ScaleField';

interface EditTabPanelProps {
  hidden: boolean;
  values: QuestionCardType;
  errorState: ErrorStateProps;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditTabPanel: FC<EditTabPanelProps> = ({
  hidden,
  values,
  errorState,
  handleChange,
}) => {
  const getQuestions = () => {
    switch (values.type) {
      case FormBuilderQuestionType.SingleChoice:
      case FormBuilderQuestionType.Binary:
      case FormBuilderQuestionType.MultipleChoice: {
        return (
          <ChoiceFieldArray
            type={values.type}
            options={values.possibleAnswers}
            handleChange={handleChange}
            errorState={errorState.possibleAnswers}
          />
        );
      }
      case FormBuilderQuestionType.Scale: {
        return (
          <ScaleField
            maxScale={values.maxScale}
            handleChange={handleChange}
            errorState={errorState}
          />
        );
      }
    }
  };

  return (
    <TabPanel hidden={hidden}>
      <Stack spacing={1}>
        <TextField
          name="title"
          placeholder="Enter your question"
          value={values.title}
          onChange={handleChange}
          error={!!errorState.title}
          helperText={errorState.title}
        />
        <TextField
          name="helpText"
          placeholder="Add optional help text"
          value={values.helpText || ''}
          onChange={handleChange}
          size="small"
        />
      </Stack>
      <Box marginTop="12px">{getQuestions()}</Box>
    </TabPanel>
  );
};

export default EditTabPanel;
