import React from 'react';
import { Loader } from '@fdha/web-ui-library';

import { useCommunity } from '../../hooks';
import { BasePage } from '../../components';

import RestrictedPage from './RestrictedPage';
import Feed from './Feed';

const CommunityInitial = () => {
  const { isBanned } = useCommunity();

  if (isBanned === undefined) {
    return (
      <BasePage>
        <Loader />
      </BasePage>
    );
  }

  return isBanned ? <RestrictedPage /> : <Feed />;
};

export default CommunityInitial;
