import React from 'react';
import { Box, Divider, Link } from '@mui/material';
import {
  Block,
  BLOCKS,
  Document,
  Inline,
  INLINES,
} from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Button, Video } from '..';

interface RTEContentRenderProps {
  richTextDocument: Document;
}

const CustomAttachment: React.FC<{ url: string; filename: string }> = ({
  url,
  filename,
}) => {
  return (
    <Box marginY={1}>
      <Button
        component={Link}
        href={url}
        target="_blank"
        variant="text"
        size="large"
        startEvaIcon={{
          name: 'attach-outline',
        }}
      >
        {filename}
      </Button>
    </Box>
  );
};

const CustomImage: React.FC<{ url: string; filename: string }> = ({
  url,
  filename,
}) => {
  return (
    <img
      src={url || ''}
      alt={filename || ''}
      style={{
        maxHeight: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: '8px 0',
      }}
    />
  );
};

const CustomLink: React.FC<{ content: React.ReactNode; url: string }> = ({
  content,
  url,
}) => {
  return (
    <Link href={url} target="_blank">
      {content}
    </Link>
  );
};

export const RTEContentRender: React.FC<RTEContentRenderProps> = ({
  richTextDocument,
}) => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (
        node: Block | Inline,
        children: React.ReactNode
      ) => {
        return <CustomLink content={children} url={node.data.uri} />;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
        const { file } = node.data.target.fields;
        const url = `https:${file.url}`;

        const isImage = file.contentType.startsWith('image');
        const isVideo = file.contentType.startsWith('video');

        if (isImage) {
          return <CustomImage url={url} filename={file.fileName} />;
        }
        if (isVideo) {
          return (
            <Video url={url} style={{ maxHeight: '350px', margin: '8px 0' }} />
          );
        }

        return <CustomAttachment url={url} filename={file.fileName} />;
      },
      [BLOCKS.HR]: () => {
        return <Divider sx={{ marginY: 1 }} />;
      },
    },
  };

  const nodes = documentToReactComponents(richTextDocument, options);

  return <>{nodes || null}</>;
};
