import { SurveyQuestion, SurveyQuestionType } from '@fdha/graphql-api-admin';
import { CheckboxGroupState } from '@fdha/web-ui-library';
import React, { useMemo } from 'react';

import { QuestionField } from '../../../../components';

interface SurveyQuestionPreviewProps {
  questionNumber: number;
  item: SurveyQuestion;
}

export const SurveyQuestionPreview: React.FC<SurveyQuestionPreviewProps> = ({
  questionNumber,
  item,
}) => {
  const possibleAnswers = useMemo(
    () =>
      item.possibleAnswers?.map((possibleAnswer) => ({
        label: possibleAnswer,
        value: possibleAnswer,
      })) ?? [],
    [item.possibleAnswers]
  );

  const renderQuestion = () => {
    const singleAnswer = item.answer?.[0] || '';
    const placeholder = item.placeholderText || '';

    const commonProps = {
      number: questionNumber + 1,
      title: item.title,
      helperText: item.helpText || undefined,
      dataTestIdPrefix: 'SURVEY',
    };

    switch (item.type) {
      case SurveyQuestionType.OpenText:
      case SurveyQuestionType.Decimal:
      case SurveyQuestionType.Meal:
      case SurveyQuestionType.Time:
      case SurveyQuestionType.Number:
      case SurveyQuestionType.Snack: {
        const type =
          item.type !== SurveyQuestionType.Time ? item.type : undefined;
        const multiline = item.type === SurveyQuestionType.OpenText;

        return (
          <QuestionField
            type="openText"
            textFieldProps={{
              value: singleAnswer,
              type,
              multiline,
              placeholder,
            }}
            {...commonProps}
          />
        );
      }
      case SurveyQuestionType.MultipleChoice:
        const multipleChoiceAnswer =
          possibleAnswers?.reduce<CheckboxGroupState>((acc, { value }) => {
            acc[value] = item.answer?.includes(value) ?? false;
            return acc;
          }, {}) ?? {};

        return (
          <QuestionField
            type="multipleChoice"
            checkboxGroupProps={{
              options: possibleAnswers,
              value: multipleChoiceAnswer,
            }}
            {...commonProps}
          />
        );
      case SurveyQuestionType.SingleChoice:
      case SurveyQuestionType.Binary:
        return (
          <QuestionField
            type="singleChoice"
            radioGroupProps={{
              options: possibleAnswers,
              value: singleAnswer,
              placeholder,
            }}
            {...commonProps}
          />
        );
      case SurveyQuestionType.Scale:
        return (
          <QuestionField
            type="scale"
            scaleProps={{
              options: possibleAnswers,
              value: singleAnswer,
            }}
            {...commonProps}
          />
        );
    }
  };

  return <>{renderQuestion()}</>;
};
