import React, { FC } from 'react';
import { Box, IconButton, Paper } from '@mui/material';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';
import { useSnackbar } from '@fdha/web-ui-library';
import ReactQuill from 'react-quill';

import {
  hasDraftNote,
  editNote,
  NotesProps,
} from '../../../../states/notesState';

import Card from './Card';

interface NoteCardPreviewProps {
  disableEdit?: boolean;
  note: NotesProps;
  patientId: string;
}

const NoteCardPreview: FC<NoteCardPreviewProps> = ({
  disableEdit,
  note,
  patientId,
}) => {
  const { showSnackbar } = useSnackbar();

  const handleEdit = () => {
    if (hasDraftNote(patientId)) {
      return showSnackbar({
        message: `There are notes that haven't been saved yet`,
        severity: 'error',
      });
    }

    editNote(note.id, patientId);
  };

  return (
    <Paper data-testid={`NOTE_CARD_${note.id}`}>
      <Card note={note} disableEdit={disableEdit}>
        <ReactQuill value={note.content} theme="bubble" readOnly />
      </Card>
      {!disableEdit && (
        <Box display="flex" justifyContent="flex-end" padding={2}>
          <IconButton onClick={handleEdit} data-testid="EDIT_NOTE_ICON">
            <Icon name="edit-outline" size="large" fill={grey[600]} />
          </IconButton>
        </Box>
      )}
    </Paper>
  );
};

export default NoteCardPreview;
