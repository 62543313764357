import { Accordion } from '@fdha/web-ui-library';
import { Box, Stack } from '@mui/material';
import React from 'react';
import {
  LearningItem,
  DetailedLearningModule,
  LearningQuiz as LearningQuizType,
} from '@fdha/graphql-api-admin';

import LearningContent from './LearningContent';
import LearningQuiz from './LearningQuiz';

interface LearningModuleProps {
  data: DetailedLearningModule;
}

interface Item {
  type: 'content' | 'quiz';
  title: string;
  subtitle?: string;
  data: LearningItem | LearningQuizType;
}

const getLearningItems = (learningModule: DetailedLearningModule) => {
  const items: Item[] = [];

  learningModule.learningItems.forEach((item, index) => {
    const totalContentItems = learningModule.learningItems.length;
    const totalContentNumber = index + 1;

    items.push({
      type: 'content',
      title: item.title,
      subtitle: `${totalContentNumber}/${totalContentItems}`,
      data: item,
    });

    if (item.quiz) {
      items.push({ type: 'quiz', data: item.quiz, title: item.quiz.title });
    }
  });

  if (learningModule.quiz) {
    items.push({
      type: 'quiz',
      data: learningModule.quiz,
      title: `Final Quiz: ${learningModule.quiz.title}`,
    });
  }

  return items;
};

const LearningModule: React.FC<LearningModuleProps> = ({ data }) => {
  const items = getLearningItems(data);

  const renderItem = (item: Item) => {
    if (item.type === 'content') {
      return <LearningContent data={item.data as LearningItem} />;
    }

    return <LearningQuiz data={item.data as LearningQuizType} />;
  };

  const renderItemContainer = (item: Item) => {
    return (
      <Accordion
        key={item.data.id}
        title={item.title}
        subtitle={item.subtitle}
        data-testid={`ACCORDION_${item.title}`}
        collapsedMode={item.type === 'content' ? 'partial' : 'full'}
        hasHeaderDivider
      >
        <Box margin={3}>{renderItem(item)}</Box>
      </Accordion>
    );
  };

  return <Stack spacing={2}>{items.map(renderItemContainer)}</Stack>;
};

export default LearningModule;
