import { Card, Switch, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';

interface CoachAvailabilityProps {
  isAvailable?: boolean;
  handleChange: (prop: string, newValue: boolean, errorMessage: string) => void;
}

const CoachAvailability: FC<CoachAvailabilityProps> = ({
  isAvailable,
  handleChange,
}) => {
  const theme = useTheme();

  return (
    <Card
      data-testid="AVAILABILITY_TOGGLE"
      sx={{
        paddingY: 1,
        paddingX: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="body1" color={theme.palette.secondary.contrastText}>
        Available for Digital Health Patients
      </Typography>
      <Switch
        checked={isAvailable}
        data-testid={`SWITCH_IS_${isAvailable}`}
        color="secondary"
        onChange={(_, checked) =>
          handleChange('new_coachings_available', checked, 'availability')
        }
      />
    </Card>
  );
};

export default CoachAvailability;
