import React from 'react';
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { GoalStep } from '@fdha/graphql-api-admin';

interface GoalStepsDetailsProps {
  steps: GoalStep[];
  isCsr?: boolean;
  onStepStateChange: (stepId: string, completed: boolean) => void;
}

const GoalStepsDetails: React.FC<GoalStepsDetailsProps> = ({
  steps,
  isCsr,
  onStepStateChange,
}) => {
  return (
    <Box mb={2}>
      {steps.map((step) => (
        <Card sx={{ paddingY: 1, paddingX: 2, marginY: 1 }}>
          {!isCsr ? (
            <FormControlLabel
              control={<Checkbox checked={step.completed} />}
              label={step.title}
              onChange={(_, value) => onStepStateChange(step.id, value)}
            />
          ) : (
            <Typography>{step.title}</Typography>
          )}
        </Card>
      ))}
    </Box>
  );
};

export default GoalStepsDetails;
