import React from 'react';
import { Link } from '@mui/material';
import { RTEContentRender } from '@fdha/web-ui-library';
import { LearningItem } from '@fdha/graphql-api-admin';

interface LearningContentProps {
  data: LearningItem;
}

const LearningContent: React.FC<LearningContentProps> = ({ data }) => {
  if (data.externalContent) {
    return (
      <Link href={data.externalContent} target="_blank">
        {data.externalContent}
      </Link>
    );
  }

  const content = data.content && JSON.parse(data.content);

  return <RTEContentRender richTextDocument={content} />;
};

export default LearningContent;
