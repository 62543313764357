import React, { FC, useState } from 'react';
import { Paper, Box, IconButton, useTheme, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';
import { CoachUserWithPatients } from '@fdha/graphql-api-admin';

import EditDescription from './EditDescription';

interface DescriptionCardProps {
  user?: CoachUserWithPatients | null;
  editable?: boolean;
}

const DescriptionCard: FC<DescriptionCardProps> = ({
  user,
  editable = true,
}) => {
  const theme = useTheme();

  const [isEditing, setIsEditing] = useState(false);

  const handleIsEditing = () => {
    setIsEditing(false);
  };

  return (
    <Paper sx={{ boxShadow: theme.shadows[2] }} data-testid="COACH_DESCRIPTION">
      <Box
        padding={2}
        bgcolor={editable ? grey[200] : theme.palette.secondary.light}
        borderRadius="15px 15px 0px 0px"
        borderBottom={`0.5px solid ${theme.palette.divider}`}
      >
        <Typography variant="h6">
          {editable ? 'My description' : 'Description'}
        </Typography>
      </Box>
      <Box padding={3}>
        {!isEditing ? (
          <>
            <Typography
              variant="body1"
              sx={{ whiteSpace: 'pre-line' }}
              data-testid="COACH_DESCRIPTION_TEXT"
            >
              {user?.description}
            </Typography>
            {editable && (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <IconButton
                  onClick={() => setIsEditing(true)}
                  data-testid="DESCRIPTION_EDIT_BUTTON"
                >
                  <Icon name="edit-outline" size="large" fill={grey[600]} />
                </IconButton>
              </Box>
            )}
          </>
        ) : (
          <EditDescription user={user} handleIsEditing={handleIsEditing} />
        )}
      </Box>
    </Paper>
  );
};

export default DescriptionCard;
