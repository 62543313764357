import React from 'react';
import {
  QuotedMessagePreview as StreamQuotedMessagePreview,
  QuotedMessagePreviewProps,
} from 'stream-chat-react/dist/components/MessageInput/QuotedMessagePreview';

import StyledQuotedMessagePreview from './styles';

const QuotedMessagePreview: React.FC<QuotedMessagePreviewProps> = (props) => (
  <StyledQuotedMessagePreview>
    <StreamQuotedMessagePreview {...props} />
  </StyledQuotedMessagePreview>
);

export default QuotedMessagePreview;
