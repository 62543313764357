import { useMemo } from 'react';
import {
  useGetDeliveryV2Query,
  DeliveryStatus as DeliveryStatusEnum,
} from '@fdha/graphql-api-admin';
import {
  BasePage,
  DeliveryStatus,
  Loader,
  Typography,
  formatUTCDate,
} from '@fdha/web-ui-library';
import { Box, Stack, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { TabValues } from '../tabs/Medical/deliveryInformation/DeliveryInformation';

import Delivery from './Delivery';

const ActiveDelivery = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();

  const patientId = params.patientId || '';
  const deliveryId = params.deliveryId || '';

  const { data, loading } = useGetDeliveryV2Query({
    variables: {
      deliveryId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const delivery = useMemo(() => data?.deliveryV2, [data?.deliveryV2]);

  const isDeliveryOpen = useMemo(
    () => delivery?.status === DeliveryStatusEnum.Open,
    [delivery]
  );

  const showDeliveryStatus =
    delivery?.openChanges && isDeliveryOpen && !delivery.isCanceled;

  if (loading) {
    return <Loader />;
  }

  const Header = () => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Stack direction="row" spacing={1.5}>
          <Typography variant="h5">
            Delivery for {formatUTCDate(delivery?.deliveryDate, 'monthWeekDay')}
          </Typography>
          <Typography
            variant="overline"
            color={theme.palette.text.hint}
            lineHeight={2}
            textTransform="uppercase"
          >
            {`Week ${delivery?.weekOfCycle}`}
          </Typography>
        </Stack>
        {showDeliveryStatus && (
          <DeliveryStatus
            openChangesDate={delivery.openChanges}
            status={delivery.status}
            isOwner={false}
            showBoldDate
          />
        )}
      </Box>
    );
  };

  return (
    <BasePage data-testid="PATIENT_DELIVERY_CONTAINER">
      <BasePage.BackButton
        handleClick={() =>
          navigate(`/patient/${patientId}`, {
            state: { initialTab: TabValues.ACTIVE_DELIVERIES },
            replace: true,
          })
        }
      />
      <BasePage.Wrapper spacing={3}>
        <Header />
        <Delivery
          patientId={patientId}
          subjectId={delivery?.foodProgramUser.patient.subject_id ?? undefined}
          deliveryId={delivery?.id}
          foodProgramUserId={delivery?.foodProgramUser.id}
          deliveryMenuId={delivery?.dietPlanDeliveryMenu.id}
          status={delivery?.status}
          openChangesDate={delivery?.openChanges}
          isCanceled={delivery?.isCanceled}
          isCanceledUpdateComment={delivery?.isCanceledUpdateComment}
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default ActiveDelivery;
