import React, { Dispatch, SetStateAction, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { Trial, useGetTrialQuery } from '@fdha/graphql-api-admin';
import { Loader } from '@fdha/web-ui-library';
import { useParams } from 'react-router-dom';

import ViewModeAbout from './ViewModeAbout';
import EditModeAbout from './EditModeAbout';

export interface TrialViewOrEditProps {
  trial?: Trial | null;
  onEditMode: Dispatch<SetStateAction<boolean>>;
}

const About = () => {
  const params = useParams();

  const trialId = params.trialId || '';
  const [editMode, setEditMode] = useState(false);

  const { data, loading } = useGetTrialQuery({
    variables: {
      id: trialId,
    },
  });

  const trial = data?.trial;

  if (loading) {
    return <Loader />;
  }

  return (
    <Card>
      <CardContent>
        {editMode ? (
          <EditModeAbout trial={trial} onEditMode={setEditMode} />
        ) : (
          <ViewModeAbout trial={trial} onEditMode={setEditMode} />
        )}
      </CardContent>
    </Card>
  );
};

export default About;
