import React from 'react';
import { Loader } from '@fdha/web-ui-library';
import { Stack } from '@mui/material';
import { useFeatureFlag } from '@fdha/common-hooks';
import { useGetPatientUserQuery, UserType } from '@fdha/graphql-api-admin';
import { useParams } from 'react-router-dom';
import { isMissingOnboardingCall } from '@fdha/common-utils';

import { useGetUserType } from '../../../../hooks/';

import OnboardingInformation from './onboardingInformation/OnboardingInformation';
import MedicalInformation from './medicalinformation/MedicalInformation';
import PlannedPause from './plannedPause/PlannedPause';
import DeliveryInformation from './deliveryInformation/DeliveryInformation';
import FoodProgramInformation from './foodProgramInformation/FoodProgramInformation';
import AccountInformation from './accountInformation/AccountInformation';
import TrialInformation from './trialInformation/TrialInformation';
import Dashboard from './dashboard/Dashboard';
import { OnboardingReminder } from './onboardingReminder/OnboardingReminder';

const MedicalTab = () => {
  const { patientId } = useParams();
  const { isFeatureEnabled, isLoading: loadingFF } = useFeatureFlag();

  const showFoodOps = isFeatureEnabled('show_food_ops');
  const showSiteFeatures = isFeatureEnabled('show_site_features');
  const { isAdmin, isCsr, isLoading: isLoadingUserType } = useGetUserType();

  const { data, loading: loadingPatientData } = useGetPatientUserQuery({
    variables: {
      id: patientId || '',
    },
  });

  const patientUser = data?.patientUser;

  const showFoodOpsInfo =
    showFoodOps && data?.patientUser?.type === UserType.ClinicalTrialPatient;
  const showSiteInfo =
    showSiteFeatures &&
    data?.patientUser?.type === UserType.ClinicalTrialPatient;
  const showOnboardingCallReminder = isMissingOnboardingCall(
    patientUser?.subjectTrialStatus,
    patientUser?.onboardingCallNeeded,
    patientUser?.onboardingCallScheduled
  );
  const isLoading = loadingPatientData || loadingFF || isLoadingUserType;

  return (
    <Stack spacing={2}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {showSiteInfo ? (
            <>
              {showOnboardingCallReminder && !isCsr && (
                <OnboardingReminder patientId={patientId || ''} />
              )}
              <AccountInformation patientUser={patientUser} isCsr={isCsr} />
              <TrialInformation
                patientUser={patientUser}
                isCsr={isCsr}
                isAdmin={!!isAdmin}
              />
              {!isCsr && <Dashboard patientId={patientId || ''} />}
            </>
          ) : (
            <>
              <OnboardingInformation patientUser={patientUser} isCsr={isCsr} />
              {!isCsr && <MedicalInformation patientUser={patientUser} />}
            </>
          )}
          {showFoodOpsInfo && (
            <>
              <FoodProgramInformation patientUser={patientUser} isCsr={isCsr} />
              <PlannedPause isCsr={isCsr} />
              <DeliveryInformation patientUser={patientUser} />
            </>
          )}
        </>
      )}
    </Stack>
  );
};

export default MedicalTab;
