import React from 'react';
import { useParams } from 'react-router-dom';

import { SitesTable } from '../../../../components';

const Sites = () => {
  const params = useParams();
  const trialId = params.trialId || '';

  return <SitesTable tableKey="trialSites" trialId={trialId} />;
};

export default Sites;
