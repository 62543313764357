import React, { FC, useState } from 'react';
import {
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  PatientUser,
  useGetFoodProgramUserQuery,
} from '@fdha/graphql-api-admin';
import { Icon, HoverPopover, IconButton } from '@fdha/web-ui-library';
import { useLocation, useNavigate, useParams } from 'react-router';
import { TabContext, TabPanel } from '@mui/lab';
import { getConcatWeekdaysOnDiet } from '@fdha/common-utils';

import ActiveDeliveries from './ActiveDeliveries';
import WeekMenus from './WeekMenus';
import PopoverInformation from './PopoverInformation';

export enum TabValues {
  ACTIVE_DELIVERIES = 'activeDeliveries',
  WEEK_MENUS = 'weekMenus',
}

export interface DeliveryInformationProps {
  patientUser?: PatientUser | null;
}

const DeliveryInformation: FC<DeliveryInformationProps> = ({ patientUser }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const patientId = params.patientId || '';

  const { data: foodProgramData, loading } = useGetFoodProgramUserQuery({
    variables: {
      patientId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !patientId,
  });

  const userDeliveryMenu =
    foodProgramData?.foodProgramUserByPatientIdV2?.userDeliveryMenus;

  const hasDaysOnDiet = () => {
    if (!userDeliveryMenu) {
      return false;
    }

    const weekDays = getConcatWeekdaysOnDiet(userDeliveryMenu);

    return weekDays.length > 0;
  };

  const disabled = !hasDaysOnDiet();

  const initialTab = location.state?.initialTab;

  const [tab, setTab] = useState(initialTab || TabValues.ACTIVE_DELIVERIES);

  return (
    <Box>
      <Typography variant="h6">Deliveries</Typography>
      <Paper sx={{ mt: 1 }}>
        <TabContext value={tab}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingX={3}
            paddingTop={3}
          >
            <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)}>
              <Tab
                value={TabValues.ACTIVE_DELIVERIES}
                label="Active Deliveries"
              />
              <Tab value={TabValues.WEEK_MENUS} label="Week Menus" />
            </Tabs>
            <Box display="flex" gap={1} alignItems="center">
              <HoverPopover content={<PopoverInformation />}>
                <Icon
                  name="alert-circle-outline"
                  size="medium"
                  fill={theme.palette.primary.main}
                  showHover
                />
              </HoverPopover>
              <Tooltip
                title={
                  disabled
                    ? 'Delivery settings can only be edited once the diet plan and days on diet are established.'
                    : 'Delivery Settings'
                }
              >
                <Box>
                  <IconButton
                    iconProps={{
                      name: 'settings-2-outline',
                      size: 'medium',
                    }}
                    disabled={disabled || loading}
                    onClick={() => navigate('./delivery-settings')}
                  />
                </Box>
              </Tooltip>
            </Box>
          </Stack>
          <TabPanel value={TabValues.ACTIVE_DELIVERIES}>
            <ActiveDeliveries
              patientId={patientUser?.id}
              subjectId={patientUser?.subject_id}
            />
          </TabPanel>
          <TabPanel value={TabValues.WEEK_MENUS}>
            <WeekMenus patientId={patientUser?.id} />
          </TabPanel>
        </TabContext>
      </Paper>
    </Box>
  );
};

export default DeliveryInformation;
