import {
  addDateValidation,
  formatUTCDate,
  MaskedTextField,
  useSnackbar,
} from '@fdha/web-ui-library';
import { Box, Button, IconButton, useTheme } from '@mui/material';
import { isAfter, isSameDay, isValid, parse, startOfToday } from 'date-fns';
import {
  FieldArray,
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  getIn,
} from 'formik';
import React from 'react';
import Icon from 'react-eva-icons';
import * as Yup from 'yup';
import { uniqWith, isEqual } from 'lodash';
import {
  PlannedPauseV2,
  useSetPlannedPausesMutation,
} from '@fdha/graphql-api-admin';

interface PlannedPauseEditProps {
  foodProgramUserId?: string;
  pauses: PlannedPauseV2[];
  onSuccess?: () => void;
  onFinish?: () => void;
}

const validationSchema = Yup.object().shape({
  pauses: Yup.array().of(
    Yup.object().shape(
      {
        startDate: Yup.date().when(['endDate', 'date'], (endDate) => {
          return isValid(endDate)
            ? addDateValidation().required('Start date is required')
            : addDateValidation();
        }),
        endDate: Yup.date().when(['startDate', 'date'], (startDate) => {
          return isValid(startDate)
            ? Yup.date()
                .transform((_value, originalValue) =>
                  parse(originalValue, 'MM/dd/yyyy', startOfToday())
                )
                .typeError('Please insert a valid date')
                .test(
                  'is-after-start',
                  'End date must be after start date',
                  (value) => {
                    if (!value) {
                      return true;
                    }
                    return (
                      isValid(value) &&
                      (isSameDay(value, startDate) || isAfter(value, startDate))
                    );
                  }
                )
            : addDateValidation();
        }),
      },
      [['startDate', 'endDate']]
    )
  ),
});

interface PausesSchema {
  pauses: {
    id: number;
    startDate: string;
    endDate: string;
  }[];
}

const PlannedPauseEdit: React.FC<PlannedPauseEditProps> = ({
  pauses,
  foodProgramUserId,
  onFinish,
  onSuccess,
}) => {
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  const [setPlannedPauses] = useSetPlannedPausesMutation();

  const defaultValue = { id: Math.random(), startDate: '', endDate: '' };
  const initialValues: PausesSchema = pauses.length
    ? {
        pauses: pauses.map((value) => ({
          id: Math.random(),
          startDate: formatUTCDate(value.startDate) || '',
          endDate: formatUTCDate(value.endDate || undefined) || '',
        })),
      }
    : { pauses: [defaultValue] };

  const getError = (
    name: string,
    touched?: FormikTouched<{ startDate: string; endDate: string }>,
    error?: string | FormikErrors<{ startDate: string; endDate: string }>
  ) => {
    if (typeof error !== 'string') {
      const errorMsg = getIn(error, name);
      const hasTouched = getIn(touched, name);

      return hasTouched && errorMsg ? errorMsg : null;
    }
  };

  const handleSubmit = async (values: PausesSchema) => {
    const pauses: PlannedPauseV2[] = uniqWith(
      values.pauses.filter((pause) => !!pause.startDate),
      isEqual
    ).map((pause) => ({
      startDate: pause.startDate,
      endDate: pause.endDate || null,
    }));

    try {
      await setPlannedPauses({
        variables: {
          input: { foodProgramUserId: foodProgramUserId || '', pauses },
        },
      });

      showSnackbar({
        message: 'Planned pauses saved with success',
        severity: 'success',
      });
      onSuccess && onSuccess();
    } catch {
      showSnackbar({
        message: 'Error to set planned pauses',
        severity: 'error',
      });
    } finally {
      onFinish && onFinish();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({
        isSubmitting,
        handleBlur,
        handleChange,
        values,
        errors,
        touched,
      }) => {
        return (
          <Form>
            <FieldArray
              name="pauses"
              render={(arrayHelpers) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  rowGap={3}
                  width="fit-content"
                >
                  {values.pauses.map((pause, index) => (
                    <Box key={pause.id} display="flex">
                      <Box display="flex" columnGap={3}>
                        <MaskedTextField
                          title="Start Date"
                          placeholder="mm/dd/yyyy"
                          name={`pauses.${index}.startDate`}
                          value={values.pauses[index].startDate}
                          mask="date"
                          error={
                            !!getError(
                              'startDate',
                              touched.pauses?.[index],
                              errors.pauses?.[index]
                            )
                          }
                          helperText={getError(
                            'startDate',
                            touched.pauses?.[index],
                            errors.pauses?.[index]
                          )}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <MaskedTextField
                          title="End Date"
                          placeholder="mm/dd/yyyy"
                          value={values.pauses[index].endDate}
                          mask="date"
                          name={`pauses.${index}.endDate`}
                          error={
                            !!getError(
                              'endDate',
                              touched.pauses?.[index],
                              errors.pauses?.[index]
                            )
                          }
                          helperText={getError(
                            'endDate',
                            touched.pauses?.[index],
                            errors.pauses?.[index]
                          )}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box marginTop={5} marginLeft={4}>
                        {index === values.pauses.length - 1 && (
                          <IconButton
                            data-testid="ADD_ICON_BUTTON"
                            onClick={() => arrayHelpers.push(defaultValue)}
                          >
                            <Icon
                              name="plus-circle-outline"
                              size="large"
                              fill={theme.palette.primary.main}
                            />
                          </IconButton>
                        )}
                      </Box>
                      <Box marginTop={5}>
                        <IconButton
                          data-testid="REMOVE_ICON_BUTTON"
                          onClick={() => {
                            if (values.pauses.length === 1) {
                              arrayHelpers.replace(index, defaultValue);
                            } else {
                              arrayHelpers.remove(index);
                            }
                          }}
                        >
                          <Icon
                            name="minus-circle-outline"
                            size="large"
                            fill={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            />
            <Box display="flex" justifyContent="flex-end" marginTop={5}>
              <Button
                color="primary"
                size="large"
                onClick={onFinish}
                data-testid="CANCEL_BUTTON"
              >
                CANCEL
              </Button>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                data-testid="SAVE_BUTTON"
              >
                SAVE
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlannedPauseEdit;
