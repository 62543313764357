import React, { FC } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { format } from 'date-fns';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

interface DeletedByModeratorProps {
  entityName: string;
  isModerator?: boolean;
  removedAt?: string;
  removedBy?: string;
  isPersisted?: boolean;
}

export const DeletedByModerator: FC<DeletedByModeratorProps> = ({
  entityName,
  isModerator = false,
  removedAt,
  removedBy,
  isPersisted = true,
}) => {
  const theme = useTheme();
  const message = `This ${entityName} has been removed by a moderator`;

  const getModeratorRemovedByMessage = () => {
    if (!isPersisted) {
      return 'Removing...';
    }

    if (!removedAt) {
      return message;
    }

    return (
      <>
        This {entityName} was removed by&nbsp;<b>{removedBy}</b>&nbsp;on&nbsp;
        {format(new Date(removedAt), 'MM/dd/yyyy h:mm a')}
      </>
    );
  };

  return (
    <Stack spacing={1} direction="row">
      <Box marginTop="3px">
        <Icon name="shield-outline" size="medium" fill={grey[800]} />
      </Box>
      <Typography display="flex" color={theme.palette.text.secondary}>
        {isModerator || !isPersisted ? getModeratorRemovedByMessage() : message}
      </Typography>
    </Stack>
  );
};
