import { styled } from '@mui/material';

const StyledQuotedMessagePreview = styled('div')(({ theme }) => ({
  bgcolor: theme.palette.background.paper,
  borderTop: 'solid 1px rgba(0, 0, 0, .12)',
  padding: '8px 40px',
  position: 'relative',
  width: '100%',

  '.quoted-message-preview': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '.quoted-message-preview-header': {
    fontSize: '0.875rem',
    marginBottom: '8px',

    '.str-chat__square-button': {
      position: 'absolute',
      right: '0',
      top: '0',
      margin: '8px',
      borderRadius: '25px',
      cursor: 'pointer',
    },
  },
  '.quoted-message-preview-content': {
    display: 'flex',
    alignItems: 'flex-end',
    maxWidth: '60%',
  },
  '.quoted-message-preview-content-inner': {
    display: 'flex',
    textAlign: 'start',
    alignItems: 'flex-end',
    background: 'var(--grey-whisper)',
    borderRadius: '25px 25px 25px 0',
    padding: '8px 16px',

    'div:nth-child(2)': {
      marginLeft: '8px',
    },
    '.str-chat__message-attachment': {
      margin: '0',
    },
    '.str-chat__message-attachment--image': {
      width: '48px',
    },
    '.str-chat__message-attachment--img': {
      borderRadius: '25px',
      maxHeight: '48px',
      maxWidth: '48px',
      width: '100%',
    },
    '.str-chat__message-attachment--card': {
      borderRadius: '25px',
      maxHeight: '48px',
      maxWidth: '48px',
      width: '100%',
    },
    '.str-chat__message-attachment--file': {
      minWidth: '33%',
    },
    '.str-chat__message-attachment-file--item': {
      background: 'var(--white)',
      borderRadius: '25px',
      borderLeft: 'none',
      padding: '0 8px',
    },
    '.str-chat__message-attachment-card--image': {
      border: 'none',
      borderRadius: '25px',
      margin: '0',
    },
    '.str-chat__message-attachment-card--giphy': {
      border: 'none',
      borderRadius: '25px',
      margin: '0',
    },
  },
}));

export default StyledQuotedMessagePreview;
