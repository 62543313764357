import { Box, Card, CardActionArea, IconButton, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import Icon from 'react-eva-icons';

import { Activity, Appointment, getDurationValues } from '../../../utils';
import { OverflowText } from '../../OverflowText/OverflowText';
import { Typography } from '../../Typography/Typography';

interface AppointmentCardProps {
  v2?: boolean;
  data: Appointment;
  handleSelectActivity: (activity: Activity) => void;
}

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  v2,
  data,
  handleSelectActivity,
}) => {
  const theme = useTheme();

  const { startTime, endTime } = getDurationValues(data.time, data.duration);

  /************ V2 updates *********************/

  const cardBackgroundColor = v2 ? undefined : theme.palette.background.default;
  const cardHeight = v2 ? 79 : 72;

  const renderCardContentV2 = () => (
    <CardActionArea
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: cardHeight,
        p: '12px',
        color: theme.palette.text.secondary,
        '&:hover': {
          backgroundColor: theme.palette.action.background,
        },
      }}
      onClick={() => handleSelectActivity(data)}
    >
      <Typography
        color={theme.palette.text.secondary}
        fontSize="16px"
        fontWeight="bold"
        i18nKey="activities:timeline.duration"
        i18nParams={{ startTime, endTime }}
      >
        {`${startTime} to ${endTime}`}
      </Typography>
      <OverflowText label={data.title} maxWidth="90%">
        {data.title}
      </OverflowText>
    </CardActionArea>
  );

  /*********************************************/

  const renderCardContent = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height={72}
      marginX={2}
    >
      <Box>
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          {`${startTime} to ${endTime}`}
        </Typography>
        <Typography noWrap>{data.title}</Typography>
      </Box>
      <IconButton onClick={() => handleSelectActivity(data)}>
        <Icon name="arrow-ios-forward-outline" size="medium" fill={grey[600]} />
      </IconButton>
    </Box>
  );

  return (
    <Card
      sx={{
        backgroundColor: cardBackgroundColor,
        boxShadow: 2,
        height: cardHeight,
      }}
    >
      {v2 ? renderCardContentV2() : renderCardContent()}
    </Card>
  );
};

export default AppointmentCard;
