import { formatUTCDate, LabelBox } from '@fdha/web-ui-library';
import { Grid } from '@mui/material';
import React from 'react';
import { PlannedPauseV2 } from '@fdha/graphql-api-admin';

interface PlannedPauseViewProps {
  pauses: PlannedPauseV2[];
}

const PlannedPauseView: React.FC<PlannedPauseViewProps> = ({ pauses }) => {
  const defaultPauses: PlannedPauseV2[] = [{ startDate: '', endDate: '' }];
  const currentPauses = pauses.length ? pauses : defaultPauses;

  const PauseItem = ({ pause }: { pause: PlannedPauseV2 }) => {
    return (
      <>
        <LabelBox
          size={6}
          label="Start Date"
          value={formatUTCDate(pause.startDate)}
        />
        <LabelBox
          size={6}
          label="End Date"
          value={formatUTCDate(pause.endDate || undefined)}
        />
      </>
    );
  };

  return (
    <Grid container>
      {currentPauses.map((pause, index) => (
        <PauseItem pause={pause} key={index} />
      ))}
    </Grid>
  );
};

export default PlannedPauseView;
