import {
  formatDateWithoutTimezone,
  formatDateWithTimezone,
  HeadCell,
} from '@fdha/web-ui-library';
import { utcToZonedTime } from 'date-fns-tz';
import { BhbData, BhbSurveyStatus } from '@fdha/graphql-api-admin';

export type BhbDataSurvey = BhbData & {
  type?: string;
};

export const getDataHeaderCells = (isCsr?: boolean) => {
  const headCells: HeadCell<BhbDataSurvey>[] = [
    {
      id: 'type',
      label: 'Data type',
      sortable: false,
      searchable: false,
    },
    {
      id: 'due_at',
      label: 'Due time',
      sortable: true,
      searchable: false,
    },
    ...Object.assign(
      !isCsr
        ? [
            {
              id: 'measured_at',
              label: 'Time measured',
              sortable: true,
              searchable: false,
            },
            {
              id: 'bhb_value',
              label: 'Value',
              sortable: true,
              searchable: false,
            },
            {
              id: 'situation',
              label: 'Situation',
              sortable: false,
              searchable: false,
            },
          ]
        : []
    ),
    {
      id: 'status',
      label: 'Status',
      sortable: false,
      searchable: false,
    },
  ];

  return headCells;
};

export const getDataChipColor = (status?: BhbSurveyStatus) => {
  switch (status) {
    case BhbSurveyStatus.Done:
      return 'success';
    case BhbSurveyStatus.Missing:
      return 'error';
    default:
      return 'warning';
  }
};

export const getDate = (value?: string | null, timezone?: string | null) => {
  if (!value) {
    return '-';
  }

  const currentTimezone =
    timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  return formatDateWithTimezone(
    utcToZonedTime(value, currentTimezone),
    currentTimezone
  );
};

export const getUTCDate = (value?: string) => {
  if (!value) {
    return '-';
  }
  return formatDateWithoutTimezone(utcToZonedTime(value, 'UTC'));
};
