import React, { ChangeEvent, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  Paper,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import {
  Button,
  formatDate,
  HeadCell,
  IconMenu,
  RowsPerPageOptions,
  SearchableTableHeader,
  Table,
  useDialog,
  useLoadingBar,
  useSnackbar,
} from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import { FileIcon } from 'react-file-utils';
import { parseISO } from 'date-fns';
import {
  DocumentInfo,
  DocumentOwnerFilter,
  useListDocumentsQuery,
  useAddDocumentMutation,
  AddDocumentInput,
  DocumentOverwriteOption,
  useDeleteDocumentMutation,
  useGetDocumentInfoLazyQuery,
  useDownloadDocumentLazyQuery,
  DownloadDocumentQuery,
  DocumentAccess,
} from '@fdha/graphql-api-admin';

import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
  useGetUserType,
} from '../../../../hooks';
import { doPut } from '../../../../utils/commonApi';

const getHeadCells = () => {
  const headCells: HeadCell<DocumentInfo>[] = [
    { id: 'filename', label: 'File name', sortable: true, searchable: true },
    {
      id: 'created_at',
      label: 'Date',
      sortable: true,
      searchable: false,
    },
    {
      id: 'is_patient_file',
      label: 'Added by',
      sortable: false,
      searchable: false,
    },
  ];

  return headCells;
};

const Folder = () => {
  const params = useParams();
  const theme = useTheme();
  const dialog = useDialog();
  const { showSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoadingBar();
  const [addDocumentMutation] = useAddDocumentMutation();
  const [deleteDocument] = useDeleteDocumentMutation();

  const { isCsr } = useGetUserType();

  const isCoach = params.type === 'coaches';
  const patientId = params.patientId || '';
  const title =
    params.type && params.type.charAt(0).toUpperCase() + params.type.slice(1);
  const owner = isCoach
    ? DocumentOwnerFilter.Coach
    : DocumentOwnerFilter.Patient;

  // Table

  const {
    page,
    setPage,
    rowsPerPage,
    changeRowsPerPage,
    loading: loadingTablePreferences,
  } = useTable({
    key: `documents_${title}`,
  });

  const [filterBy, setFilterBy] = useFilterBy<DocumentInfo>('filename', '');
  const [sortBy, setSortBy] = useSortBy<DocumentInfo>('filename', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const { data, error, fetchMore, loading, networkStatus, refetch } =
    useListDocumentsQuery({
      variables: {
        patientId,
        ownerFilter: owner,
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      skip: loadingTablePreferences,
    });

  const nodes = data?.documents?.edges.map((edge) => edge.node);
  const pageInfo = data?.documents?.pageInfo;
  const totalNumberFound = data?.documents?.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          patientId: patientId,
          ownerFilter: owner,
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const onRowsPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
    changeRowsPerPage(rowsPerPage);
  };

  const headCells = getHeadCells();
  const emptyState =
    filterBy.value !== ''
      ? undefined
      : isCoach
      ? 'No document yet. Add a new file to share with this patient.'
      : 'No document yet. Files added by this patient will be shown here.';

  // Download / Delete

  const handleDownloadData = (downloadData?: DownloadDocumentQuery) => {
    if (downloadData?.downloadDocument?.url) {
      const link = document.createElement('a');
      link.href = downloadData.downloadDocument.url;
      link.setAttribute(
        'download',
        downloadData.downloadDocument.document.filename
      );

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const [downloadDocument, { data: downloadData }] =
    useDownloadDocumentLazyQuery({
      onCompleted: () => handleDownloadData(downloadData),
      fetchPolicy: 'no-cache',
    });

  const handleDownload = async (id: string) => {
    try {
      await downloadDocument({
        variables: {
          patientId,
          id,
        },
      });
    } catch (e) {
      showSnackbar({
        message: 'Unable to download file. Try again.',
        severity: 'error',
      });
      console.log(e);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDocument({
        variables: {
          patientId,
          id,
        },
      });
      showSnackbar({
        message: 'File deleted',
      });
      refetch();
    } catch (e) {
      showSnackbar({
        message: 'Unable to delete file. Try again.',
        severity: 'error',
      });
      console.log(e);
    }
  };

  const renderRow = (row: DocumentInfo) => {
    const actions = [
      {
        label: 'Download',
        testId: 'DOWNLOAD_MENU_OPTION',
        icon: 'download-outline',
        handleClick: () => handleDownload(row.id),
      },
      ...(!isCsr
        ? [
            {
              label: 'Delete',
              testId: 'DELETE_MENU_OPTION',
              icon: 'trash-2-outline',
              handleClick: () => handleDelete(row.id),
            },
          ]
        : []),
    ];

    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="FOLDER_ICON_CELL">
          <FileIcon mimeType={row.mimetype || ''} big size={46} />
        </TableCell>
        <TableCell>{row.filename}</TableCell>
        <TableCell>{formatDate(parseISO(row.created_at))}</TableCell>
        <TableCell>{row.creator.name}</TableCell>
        <TableCell>
          <IconMenu items={actions} />
        </TableCell>
      </TableRow>
    );
  };

  // Upload

  const [getDocumentInfo, { refetch: refetchDocumentsInfo }] =
    useGetDocumentInfoLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: ({ document }) => handleGetDocumentInfo(document),
    });

  const handleAddDocument = async (
    doc: AddDocumentInput,
    overwriteOption?: DocumentOverwriteOption
  ) => {
    try {
      const response = await addDocumentMutation({
        variables: {
          doc: {
            ...doc,
            overwriteOption,
          },
          patientId,
        },
      });
      return response?.data?.addDocument;
    } catch (e) {
      showSnackbar({
        message: 'Unable to upload file. Try again.',
        severity: 'error',
      });
      console.log(e);
    }
  };

  const uploadFileToS3 = async (
    file: File,
    presignedData?: DocumentAccess | null
  ) => {
    if (presignedData) {
      const id = presignedData.document.id || '';
      const url = presignedData.url || '';
      await doPut(url, file, false);
      await getDocumentInfo({
        variables: { id, patientId },
      });
    }
  };

  const handleGetDocumentInfo = async (document?: DocumentInfo | null) => {
    setTimeout(async () => {
      try {
        if (!document) {
          await refetchDocumentsInfo();
        } else {
          hideLoading();
          refetch();
          showSnackbar({
            message: 'File Uploaded',
          });
        }
      } catch (e) {
        console.log(e);
      }
    }, 2000);
  };

  const handleOverwriteFile = async (
    doc: AddDocumentInput,
    overwriteOption: DocumentOverwriteOption,
    file: File
  ) => {
    try {
      dialog.closeDialog();
      showLoading();
      const documentData = await handleAddDocument(doc, overwriteOption);
      await uploadFileToS3(file, documentData);
    } catch (e) {
      hideLoading();
      console.log(e);
      showSnackbar({
        message: 'Unable to upload file. Try again.',
        severity: 'error',
      });
    }
  };

  const uploadDocument = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files) {
        return;
      }
      const file = e.target.files[0];

      const doc: AddDocumentInput = {
        filename: file.name,
        filesize: file.size,
        mimetype: file.type,
      };

      showLoading();

      const documentData = await handleAddDocument(doc);

      if (documentData?.overwrite) {
        hideLoading();
        dialog.openDialog({
          title:
            'A file with this name already exists. Would you like to replace the existing one?',
          content: '',
          cancelButtonLabel: 'KEEP BOTH FILES',
          confirmButtonLabel: 'REPLACE',
          handleCancel: async () => {
            await handleOverwriteFile(
              doc,
              DocumentOverwriteOption.Preserve,
              file
            );
            dialog.closeDialog();
          },
          handleConfirm: async () => {
            await handleOverwriteFile(
              doc,
              DocumentOverwriteOption.Overwrite,
              file
            );
            dialog.closeDialog();
          },
          handleDismiss: () => {
            dialog.closeDialog();
          },
        });
      } else {
        await uploadFileToS3(file, documentData);
      }
    } catch (error) {
      showSnackbar({
        message: 'Unable to upload file. Try again.',
        severity: 'error',
      });
      hideLoading();
      console.log(error);
    } finally {
      e.target.value = '';
    }
  };

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  return (
    <Box data-testid="FOLDER_CONTAINER">
      <Breadcrumbs
        data-testid="FOLDERS_BREADCRUMBS"
        separator={
          <Typography variant="h5" color={theme.palette.text.secondary}>
            /
          </Typography>
        }
      >
        <Link
          to={`/patient/${patientId}/documents`}
          style={{ textDecoration: 'none' }}
          data-testid="FOLDERS_LINK"
        >
          <Typography variant="h5" color={theme.palette.text.secondary}>
            Folders
          </Typography>
        </Link>
        <Typography variant="h5" color={theme.palette.text.primary}>
          {title} Uploads
        </Typography>
      </Breadcrumbs>
      <Box display="flex" marginTop={2} marginBottom={2} alignItems="center">
        <SearchableTableHeader<DocumentInfo>
          headCells={headCells}
          defaultSearchField="filename"
          onSearchChange={setFilterBy}
          disableMargin
        />
        {isCoach && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ padding: '8px 22px', marginLeft: '24px', height: 42 }}
            startIcon={
              <Icon name="plus" fill={theme.palette.secondary.contrastText} />
            }
            component="label"
          >
            ADD
            <input type="file" hidden onChange={uploadDocument} />
          </Button>
        )}
      </Box>
      <Paper data-testid="DOCUMENTS_INFO_TABLE">
        <Table<DocumentInfo>
          actions="both"
          headCells={headCells}
          isLoading={loading}
          initialOrderBy="filename"
          totalRowCount={totalNumberFound}
          renderRow={renderRow}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          rows={nodes || []}
          withPagination
          onSortChange={setSortBy}
          emptyState={emptyState}
        />
      </Paper>
    </Box>
  );
};

export default Folder;
