import React from 'react';
import { parseISO } from 'date-fns';
import { DialogContentText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ActionCard,
  ActionItem,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  NotesTemplate,
  useDeleteNotesTemplateMutation,
  useDuplicateNotesTemplateMutation,
  ListNotesTemplatesDocument,
  useArchiveNotesTemplateMutation,
} from '@fdha/graphql-api-admin';
import { parseBackendError } from '@fdha/common-utils';

const NotesTemplateCard = (props: { notesTemplate: NotesTemplate }) => {
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [deleteNotesTemplate] = useDeleteNotesTemplateMutation();
  const [duplicateNotesTemplate] = useDuplicateNotesTemplateMutation();
  const [archiveNotesTemplate] = useArchiveNotesTemplateMutation();

  const updateDate =
    props.notesTemplate.updated_at || props.notesTemplate.created_at;
  const isArchived = props.notesTemplate.archived;

  const handleEdit = () => {
    navigate('/settings/notes/create-notes', {
      state: { notesTemplate: props.notesTemplate },
    });
  };

  const handleDelete = () => {
    openDialog({
      title: 'Are you sure you want to delete this template?',
      content: (
        <DialogContentText>
          You will not be able to recover it.
        </DialogContentText>
      ),
      confirmButtonLabel: 'Delete',
      cancelButtonLabel: 'Keep',
      handleConfirm: async () => {
        try {
          await deleteNotesTemplate({
            variables: { id: props.notesTemplate.id },
            refetchQueries: [ListNotesTemplatesDocument],
          });
          showSnackbar({ message: 'Template deleted!', severity: 'success' });
        } catch (error) {
          showSnackbar({
            message: 'Error to delete template',
            severity: 'error',
          });
        } finally {
          closeDialog();
        }
      },
    });
  };

  const handlePreview = () => {
    navigate(`/settings/notes/${props.notesTemplate.id}`, {
      state: { row: props.notesTemplate },
    });
  };

  const handleDuplicate = async () => {
    openDialog({
      title: 'Are you sure you want to duplicate this template?',
      content: (
        <DialogContentText>
          Your new template will be displayed on the list.
        </DialogContentText>
      ),
      confirmButtonLabel: 'Duplicate',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        try {
          await duplicateNotesTemplate({
            variables: { id: props.notesTemplate.id },
            refetchQueries: [ListNotesTemplatesDocument],
          });
          showSnackbar({
            message: 'Template duplicated!',
            severity: 'success',
          });
        } catch (error) {
          showSnackbar({
            message: 'Error to duplicate template',
            severity: 'error',
          });
        } finally {
          closeDialog();
        }
      },
    });
  };

  const handleConfirmArchive = async () => {
    try {
      await archiveNotesTemplate({
        variables: { id: props.notesTemplate.id },
        refetchQueries: [ListNotesTemplatesDocument],
      });
    } catch (error: any) {
      console.error(error);
      const message = parseBackendError(error, 'Error to archive template');

      showSnackbar({
        message,
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };

  const handleArchive = () => {
    openDialog({
      title: 'Are you sure you want to archive this note template?',
      content: (
        <DialogContentText>
          You will not be able to reactivate it.
        </DialogContentText>
      ),
      confirmButtonLabel: 'Archive',
      cancelButtonLabel: 'Keep',
      handleConfirm: handleConfirmArchive,
    });
  };

  const actions = [
    { type: 'preview', onClick: handlePreview },
    ...(!isArchived ? [{ type: 'edit', onClick: handleEdit }] : []),
    { type: 'duplicate', onClick: handleDuplicate },
    ...(!isArchived ? [{ type: 'archive', onClick: handleArchive }] : []),
    { type: 'delete', onClick: handleDelete },
  ];

  return (
    <ActionCard
      title={props.notesTemplate.name}
      actionDate={parseISO(updateDate)}
      actions={actions as ActionItem[]}
    />
  );
};

export default NotesTemplateCard;
