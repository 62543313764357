import {
  FormBuilderQuestionType,
  SurveyQuestion,
  SurveyQuestionInput,
  SurveyQuestionType,
} from '@fdha/graphql-api-admin';

import { QuestionCardType } from '../../../../models/FormBuilderProps';

export const convertSurveyQuestionToQuestion = (
  questions: SurveyQuestion[]
): QuestionCardType[] => {
  return questions.map((q) => {
    if (
      Object.values(FormBuilderQuestionType.valueOf()).includes(
        q.type.valueOf()
      ) === false
    ) {
      throw new Error(`Invalid question type: ${q.type}`);
    }

    const question = {
      ...q,
      type: q.type as unknown as FormBuilderQuestionType,
      name: q.title,
      required: !!q.required,
      helpText: q.helpText || undefined,
      placeholderText: q.placeholderText || undefined,
      maxLength: q.maxLength || undefined,
      possibleAnswers: q.possibleAnswers || undefined,
      conditionQuestion: q.logic?.conditionQuestion || undefined,
      condition: q.logic?.condition || undefined,
      conditionAnswer: q.logic?.conditionAnswer || undefined,
      action: q.logic?.action || undefined,
    };

    return question;
  });
};

export const buildSurveyQuestionsPayload = (
  questions: QuestionCardType[]
): SurveyQuestionInput[] => {
  return questions.map((q) => {
    let question: SurveyQuestionInput;

    if (
      Object.values(SurveyQuestionType.valueOf()).includes(q.type.valueOf()) ===
      false
    ) {
      throw new Error(`Invalid question type: ${q.type}`);
    }

    question = {
      type: q.type as unknown as SurveyQuestionType,
      title: q.title,
      helpText: q.helpText,
      placeholderText: q.placeholderText,
      maxLength: q.maxLength,
      required: q.required,
      possibleAnswers: q.possibleAnswers,
      logic: {
        conditionQuestion: {
          value: q.conditionQuestion?.value,
          index: q.conditionQuestion?.index,
          type: q.conditionQuestion?.type,
        },
        condition: q.condition || null,
        conditionAnswer: q.conditionAnswer,
        action: q.action || null,
      },
    };

    return question;
  });
};
