import React from 'react';
import { Paper, TableCell, TableRow, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import {
  Button,
  useDialog,
  formatDate,
  Table,
  formatUTCDate,
  completeWithinLabelByValue,
  HeadCell,
  formatHour,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  AvailableDataSurveys,
  DataSurveyType,
  useGetAvailableDataSurveysQuery,
  useGetPatientUserQuery,
  useUnassignBhbSurveyToPatientMutation,
} from '@fdha/graphql-api-admin';
import { useParams } from 'react-router';

import { getFrequency } from '../../../../../utils';
import { AssignSurvey, BasePage, Chip } from '../../../../../components';
import { useGetUserType } from '../../../../../hooks';

export const getDataSchedulesHeadCells = (isCsr?: boolean) => {
  const headCells: HeadCell<AvailableDataSurveys>[] = [
    { id: 'type', label: 'Data type', sortable: false, searchable: false },
    { id: 'starts_at', label: 'Starts on', sortable: true, searchable: false },
    { id: 'ends_at', label: 'Ends on', sortable: true, searchable: false },
    { id: 'frequency', label: 'Frequency', sortable: true, searchable: false },
    {
      id: 'complete_within_days',
      label: 'Close after',
      sortable: false,
      searchable: false,
    },
    ...(!isCsr
      ? [
          {
            id: 'is_assigned',
            label: '',
            sortable: false,
          } as HeadCell<AvailableDataSurveys>,
        ]
      : []),
  ];

  return headCells;
};

const DataSchedules = () => {
  const params = useParams();
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const patientId = params.patientId || '';
  const [unassignBhbSurveyToPatient] = useUnassignBhbSurveyToPatientMutation();

  const { data: patientData } = useGetPatientUserQuery({
    variables: {
      id: patientId,
    },
  });

  const { data, refetch, loading } = useGetAvailableDataSurveysQuery({
    variables: {
      patientId: patientId,
    },
  });

  const { isCsr } = useGetUserType();

  const patientName = patientData?.patientUser?.name || '';
  const firstName = patientName?.split(' ')[0];

  const getChip = (label: string) => {
    return <Chip label={label} />;
  };

  const handleUnassignBhb = async (row: AvailableDataSurveys) => {
    try {
      await unassignBhbSurveyToPatient({
        variables: {
          patientId: patientId,
        },
      });
      refetch();
    } catch (error) {
      showSnackbar({
        message: 'Error to unassign survey',
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };

  const getButton = (row: AvailableDataSurveys) => {
    const type = row.type;

    if (row.is_assigned) {
      return (
        <Button
          color="primary"
          variant="contained"
          startEvaIcon={{ name: 'close-outline' }}
          onClick={() => {
            openDialog({
              title: 'Are you sure you want to unassign this data measure?',
              content: (
                <Typography>
                  {firstName} will no longer receive this.
                </Typography>
              ),
              cancelButtonLabel: 'Cancel',
              confirmButtonLabel: 'Unassign',
              handleConfirm: () => handleUnassignBhb(row),
            });
          }}
          data-testid="UNASSIGN_BUTTON"
        >
          Unassign
        </Button>
      );
    }

    return (
      <Button
        color="secondary"
        variant="contained"
        startEvaIcon={{ name: 'plus-outline' }}
        onClick={() => {
          openDialog({
            title: '',
            content: (
              <AssignSurvey
                mode="toPatient"
                patientData={{ id: patientId, name: firstName }}
                surveyData={{
                  name: type.toUpperCase() || '',
                  isDataSurvey: true,
                }}
                onFinish={closeDialog}
                onSuccess={refetch}
              />
            ),
          });
        }}
        data-testid="ASSIGN_BUTTON"
      >
        Assign
      </Button>
    );
  };

  const renderRow = (row: AvailableDataSurveys, index: number) => {
    const startsOn = row.starts_at && parseISO(row.starts_at);
    if (startsOn) {
      startsOn.setHours(startsOn.getUTCHours());
    }
    const date = startsOn
      ? `${formatDate(startsOn)} - ${formatHour(startsOn)}`
      : getChip('unassigned');
    const type =
      row.type === DataSurveyType.Bhb ? 'SER-ISD1-001 - BHB' : row.type;

    return (
      <TableRow hover key={index}>
        <TableCell>{type}</TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>
          {row.ends_at
            ? formatUTCDate(row.ends_at)
            : row.is_assigned
            ? getChip('never')
            : getChip('none')}
        </TableCell>
        <TableCell>
          {getFrequency(
            row.starts_at || '',
            row.frequency || undefined,
            row.weekDays
          ) || getChip('none')}
        </TableCell>
        <TableCell>
          {row.complete_within_days
            ? completeWithinLabelByValue[row.complete_within_days]
            : getChip('none')}
        </TableCell>
        {!isCsr && (
          <TableCell data-testid="ROW_BUTTON">{getButton(row)}</TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <BasePage>
      <BasePage.BackButton to={`/patient/${patientId}/data`} />
      <Typography variant="h5" sx={{ mb: 3 }}>
        Data measure schedules for {patientName}
      </Typography>
      <Paper>
        <Table<AvailableDataSurveys>
          headCells={getDataSchedulesHeadCells(isCsr)}
          initialOrderBy="starts_at"
          renderRow={renderRow}
          isLoading={loading}
          rows={data?.getAvailableDataSurveys ?? []}
        />
      </Paper>
    </BasePage>
  );
};

export default DataSchedules;
