import { SelectOption } from '@fdha/web-ui-library';

export const perDayOptions = (
  quantity: number,
  showZero = true
): SelectOption[] => {
  const start = showZero ? 0 : 1;
  return Array.from(Array(quantity + 1 - start), (_, index) => {
    const opt = index + start;
    return {
      label: opt.toString(),
      value: opt.toString(),
    };
  });
};
