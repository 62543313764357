"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.baseSubjectInput = exports.buildSubjectId = void 0;
var graphql_api_sitestaff_1 = require("@fdha/graphql-api-sitestaff");
var date_fns_1 = require("date-fns");
var buildSubjectId = function (siteTrial, suffix) { var _a; return "".concat((_a = siteTrial === null || siteTrial === void 0 ? void 0 : siteTrial.trial) === null || _a === void 0 ? void 0 : _a.protocol_abbreviation, "-").concat(siteTrial.site_identification, "-").concat(suffix); };
exports.buildSubjectId = buildSubjectId;
var formatDate = function (date) { return (0, date_fns_1.format)(date, 'yyyy-MM-dd HH:mm:ss.SSS'); };
var baseSubjectInput = function (site, siteTrial, subjectIdSuffix, subjectId) {
    var _a;
    var formattedSubjectId = subjectId !== null && subjectId !== void 0 ? subjectId : (0, exports.buildSubjectId)(siteTrial, subjectIdSuffix);
    return {
        address: {
            country: 'US',
            formatted: '123 Main St, New York, NY 10001, USA',
            locality: 'New York',
            postalCode: '10001',
            streetAddress: '123 Main St',
            region: 'NY',
            complement: 'Apt 1',
        },
        addressValidationStatus: graphql_api_sitestaff_1.AddressValidationStatus.Ok,
        birthdate: '1990-01-01',
        email: "A_".concat(formattedSubjectId, "@faethtest.com"),
        caregiver: {
            name: 'Caregiver',
            primary_caregiver_email: 'primary_caregiver@faethtest.com',
        },
        gender: graphql_api_sitestaff_1.UserGender.Male,
        height: 170,
        name: "A ".concat(formattedSubjectId),
        phoneNumber: '+16502425609',
        physicalActivityLevel: graphql_api_sitestaff_1.PhysicalActivityLevel.Moderately,
        siteId: site.id,
        trialId: ((_a = siteTrial === null || siteTrial === void 0 ? void 0 : siteTrial.trial) === null || _a === void 0 ? void 0 : _a.id) || '',
        weight: 70,
        subjectId: formattedSubjectId,
        treatmentStartDate: formatDate((0, date_fns_1.addDays)(new Date(), 14)),
    };
};
exports.baseSubjectInput = baseSubjectInput;
