import React from 'react';
import { Box, useTheme } from '@mui/material';

export interface StepsProps {
  totalSteps: number;
  currentStep: number;
  backgroundColor: string;
}

export const Steps: React.FC<StepsProps> = ({
  totalSteps,
  currentStep,
  backgroundColor,
}) => {
  const theme = useTheme();

  const renderStepItem = (index: number) => {
    const isSelected = index + 1 <= currentStep;

    return (
      <Box
        key={index}
        height={theme.spacing(1)}
        width="100%"
        sx={{
          backgroundColor: isSelected
            ? backgroundColor
            : theme.palette.grey[300],
        }}
      />
    );
  };

  return (
    <Box display="flex" columnGap={1}>
      {Array.from({ length: totalSteps }, (_, index) => renderStepItem(index))}
    </Box>
  );
};
