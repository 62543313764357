import React, { useState } from 'react';
import { SelectChangeEvent, SelectProps } from '@mui/material';
import { useSnackbar, Select, mainGoalList } from '@fdha/web-ui-library';
import { MainGoalType } from '@fdha/graphql-api-admin';

type SelectMainGoalProps = {
  userId: string;
  mainGoal?: MainGoalType;
  handleUpdateGoal?: (userId: string, newType: MainGoalType) => void;
} & Omit<
  SelectProps,
  'name' | 'onChange' | 'value' | 'options' | 'showEmptyValue'
>;

const SelectMainGoal: React.FC<SelectMainGoalProps> = (props) => {
  const { showSnackbar } = useSnackbar();

  const { userId, mainGoal, ...muiProps } = props;

  const [selectedGoal, setSelectedGoal] = useState((mainGoal as string) || '');

  const handleGoal = async (newGoal: string, oldGoal?: string) => {
    try {
      setSelectedGoal(newGoal);
      props.handleUpdateGoal &&
        (await props.handleUpdateGoal(userId, newGoal as MainGoalType));
    } catch {
      setSelectedGoal(oldGoal || '');
      showSnackbar({
        message: `Error to update patient main goal`,
        severity: 'error',
      });
    }
  };

  return (
    <Select
      name="mainGoal"
      title="Goal with the Faeth App"
      placeholder="None"
      onChange={(event) =>
        handleGoal(
          (event as SelectChangeEvent<string>).target.value,
          selectedGoal
        )
      }
      value={selectedGoal}
      options={mainGoalList}
      displayEmpty
      {...muiProps}
    />
  );
};

export default SelectMainGoal;
