import React, { FC } from 'react';
import { FormBuilderQuestionType } from '@fdha/graphql-api-admin';
import { Box } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Toolbar from './Toolbar/Toolbar';
import QuestionsContainer from './QuestionsContainer';

interface Props {
  hasLogic?: boolean;
  hasRequiredOption?: boolean;
  notAllowedTypes?: FormBuilderQuestionType[];
}

export const FormBuilderContext = React.createContext({} as Props);

const FormBuilder: FC<Props> = ({
  hasLogic,
  hasRequiredOption,
  notAllowedTypes,
}) => {
  const formBuilderContextValue = { hasLogic, hasRequiredOption };

  return (
    <FormBuilderContext.Provider value={formBuilderContextValue}>
      <Box display="flex" flexDirection="row" data-testid="FORM_BUILDER">
        <DndProvider backend={HTML5Backend}>
          <Toolbar notAllowedTypes={notAllowedTypes} />
          <QuestionsContainer />
        </DndProvider>
      </Box>
    </FormBuilderContext.Provider>
  );
};

export default FormBuilder;
