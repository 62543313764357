import { Box, Stack, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { capitalize } from 'lodash';

import { Typography } from '../Typography/Typography';

export interface MealCardProps {
  imageSize?: string;
  name: string;
  tags?: string[];
  photoUrl?: string | null;
}

export const MealCard: FC<MealCardProps> = ({
  imageSize = '80px',
  name,
  tags,
  photoUrl,
}) => {
  const theme = useTheme();

  return (
    <Stack spacing={2} direction="row" alignItems="center" flex={1}>
      <img
        src={photoUrl || '/images/empty_image_small.png'}
        alt={name}
        height={imageSize}
        width={imageSize}
        style={{ objectFit: 'cover' }}
      />
      <Box flex="1 1 0" overflow="hidden">
        <Typography>{name}</Typography>
        {tags && (
          <Typography
            display="block"
            variant="caption"
            color={theme.palette.text.secondary}
            noWrap
          >
            {tags.map((tag) => capitalize(tag)).join(', ')}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
