import {
  Box,
  Card,
  CardActionArea,
  Checkbox,
  FormControlLabel,
  IconButton,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import Icon from 'react-eva-icons';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';

import { Activity, Task } from '../../../utils';
import { OverflowText } from '../../OverflowText/OverflowText';
import { Typography } from '../../Typography/Typography';

export interface TaskCardProps {
  v2?: boolean;
  data: Task;
  readOnly?: boolean;
  handleSelectActivity: (activity: Activity) => void;
  updateSelectedActivity: (activity: Partial<Activity>) => void;
  onUpdateTask: (data: Task, completed: boolean) => void;
}

const TaskCard: React.FC<TaskCardProps> = ({
  v2,
  data,
  readOnly,
  handleSelectActivity,
  updateSelectedActivity,
  onUpdateTask,
}) => {
  const theme = useTheme();

  const handleTaskStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const completed = e.target.checked;
    onUpdateTask(data, completed);
    updateSelectedActivity({ completed } as Task);
  };

  /************ V2 updates *********************/

  const cardHeight = v2 ? 79 : 72;

  const renderCardContentV2 = () => (
    <Box display="flex" alignItems="center">
      <Box>
        <Checkbox
          checked={data.completed}
          onChange={handleTaskStateChange}
          onClick={(event) => event.stopPropagation()}
        />
      </Box>
      <CardActionArea
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: cardHeight,
          color: theme.palette.text.secondary,
          '&:hover': {
            backgroundColor: theme.palette.action.background,
          },
        }}
        onClick={() => handleSelectActivity(data)}
      >
        <Typography fontSize="16px" fontWeight="bold">
          {format(parseISO(data.time), 'h:mm a')}
        </Typography>
        <OverflowText label={data.title} maxWidth="90%">
          {data.title}
        </OverflowText>
      </CardActionArea>
    </Box>
  );

  /*********************************************/

  const renderCardContent = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height={72}
      marginX={2}
    >
      {!readOnly ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={data.completed}
              onChange={handleTaskStateChange}
              onClick={(event) => event.stopPropagation()}
            />
          }
          label={<Typography noWrap>{data.title}</Typography>}
        />
      ) : (
        <Typography noWrap>{data.title}</Typography>
      )}
      <IconButton onClick={() => handleSelectActivity(data)}>
        <Icon name="arrow-ios-forward-outline" size="medium" fill={grey[600]} />
      </IconButton>
    </Box>
  );

  return (
    <Card
      sx={{
        boxShadow: 2,
        height: cardHeight,
      }}
    >
      {v2 ? renderCardContentV2() : renderCardContent()}
    </Card>
  );
};

export default TaskCard;
