import { Button, useDialog, useSnackbar } from '@fdha/web-ui-library';
import { Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import {
  GetLearningModuleDocument,
  ListLearningModulesDocument,
  useAssignLearningModuleMutation,
  useGetPatientUserQuery,
  useUnassignLearningModuleMutation,
} from '@fdha/graphql-api-admin';

interface AssignUnassignButtonProps {
  moduleId: string;
  patientId?: string;
  isAssigned?: boolean;
  isAutoAssigned?: boolean;
}

const AssignUnassignButton: FC<AssignUnassignButtonProps> = ({
  moduleId,
  patientId,
  isAssigned,
  isAutoAssigned,
}) => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [assignLearningModule] = useAssignLearningModuleMutation();
  const [unassignLearningModule] = useUnassignLearningModuleMutation();

  const [disabled, setDisabled] = useState(false);

  const { data: patientData } = useGetPatientUserQuery({
    variables: {
      id: patientId || '',
    },
  });
  const patientName = patientData?.patientUser?.name;
  const firstName = patientName?.split(' ')[0];

  const refetchQueries = [
    ListLearningModulesDocument,
    GetLearningModuleDocument,
  ];

  const handleAssignLearningModule = async () => {
    try {
      setDisabled(true);
      await assignLearningModule({
        variables: {
          userId: patientId || '',
          moduleId,
        },
        refetchQueries: refetchQueries,
      });
    } catch (error) {
      showSnackbar({
        message: 'Error to assign course',
        severity: 'error',
      });
    } finally {
      setDisabled(false);
    }
  };

  const handleUnassignLearningModule = async () => {
    try {
      setDisabled(true);
      await unassignLearningModule({
        variables: {
          userId: patientId || '',
          moduleId,
        },
        refetchQueries: refetchQueries,
      });
    } catch (error) {
      showSnackbar({
        message: 'Error to unassign course',
        severity: 'error',
      });
    } finally {
      setDisabled(false);
      closeDialog();
    }
  };

  const openUnassignDialog = () => {
    openDialog({
      title: 'Are you sure you want to unassign this course?',
      content: (
        <Typography variant="body1">
          {firstName} will no longer be able to see this content.
        </Typography>
      ),
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Unassign',
      handleConfirm: () => handleUnassignLearningModule(),
    });
  };

  if (isAssigned || isAutoAssigned) {
    return (
      <Button
        color="primary"
        variant="contained"
        startEvaIcon={{ name: 'close-outline' }}
        onClick={openUnassignDialog}
        disabled={disabled || isAutoAssigned}
        data-testid="COURSE_UNASSIGN_BUTTON"
      >
        Unassign
      </Button>
    );
  }

  return (
    <Button
      color="secondary"
      variant="contained"
      startEvaIcon={{ name: 'plus-outline' }}
      onClick={handleAssignLearningModule}
      disabled={disabled}
      data-testid="COURSE_ASSIGN_BUTTON"
    >
      Assign
    </Button>
  );
};

export default AssignUnassignButton;
