import React, { useMemo } from 'react';
import { HeadCell, Table, formatDate } from '@fdha/web-ui-library';
import {
  Paper as MuiPaper,
  styled,
  TableCell,
  TableRow,
  Box,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { convertQuestionGroupedPropsToQuestionSpreadProps } from '@fdha/common-utils';
import { NotesTemplate } from '@fdha/graphql-api-admin';

import BasePage from '../../../../components/BasePage/BasePage';
import PreviewQuestionContent from '../../../../components/FormBuilder/PreviewQuestionCard/PreviewQuestionContent';

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: '12px 24px',
  maxWidth: '857px',
  width: '100%',
  marginBottom: '24px',
  boxShadow: theme.shadows[2],
  alignSelf: 'center',
}));

const headCells: HeadCell<NotesTemplate>[] = [
  { id: 'name', label: 'Template name', sortable: false },
  { id: 'created_at', label: 'Creation date', sortable: false },
];

const NotesTemplatePreview = () => {
  const location = useLocation();

  const state = location.state as { row: NotesTemplate };
  const row = state.row;

  const formBuilderQuestions = useMemo(
    () =>
      row.questions.map((q) => ({
        ...convertQuestionGroupedPropsToQuestionSpreadProps(q),
        name: q.title,
      })),
    [row.questions]
  );

  const renderRow = (row?: NotesTemplate) => {
    return (
      <TableRow hover key={row?.name} data-testid="TABLE_ROW">
        <TableCell>{row?.name}</TableCell>
        <TableCell>
          {row?.created_at && formatDate(parseISO(row.created_at))}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <BasePage data-testid="NOTES_TEMPLATE_PREVIEW_CONTAINER">
      <BasePage.BackButton to=".." />
      <Box display="flex" alignItems="center" flexDirection="column">
        <MuiPaper
          data-testid="NOTES_TEMPLATE_PREVIEW_TABLE"
          sx={{ marginBottom: '56px', maxWidth: '857px', width: '100%' }}
        >
          <Table<NotesTemplate>
            initialOrderBy="name"
            headCells={headCells}
            renderRow={renderRow}
            rows={[row]}
          />
        </MuiPaper>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth="857px"
        >
          {formBuilderQuestions.map((item, index: number) => (
            <Paper key={item.id}>
              <PreviewQuestionContent item={item} index={index} />
            </Paper>
          ))}
        </Box>
      </Box>
    </BasePage>
  );
};

export default NotesTemplatePreview;
