import { NetworkStatus } from '@apollo/client';
import {
  Button,
  HeadCell,
  SearchableTableHeader,
  Table,
  useDialog,
} from '@fdha/web-ui-library';
import { Box, Paper, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import Icon from 'react-eva-icons';
import { grey } from '@mui/material/colors';
import { GroupListing, useListGroupsQuery } from '@fdha/graphql-api-admin';

import {
  useDebouncedValue,
  useFilterBy,
  useGetUserType,
  useSortBy,
  useTable,
} from '../../../../hooks';
import AddOrEditGroup from '../../../../components/AddOrEditGroup/AddOrEditGroup';

const headCells: HeadCell<GroupListing>[] = [
  { id: 'name', label: 'Group Name', sortable: true, searchable: true },
];

const GroupsTab = () => {
  const { openDialog, closeDialog } = useDialog();
  const { isAdmin } = useGetUserType();

  const [queryFilterBy, setQueryFilterBy] = useFilterBy<GroupListing>(
    'name',
    ''
  );
  const [sortBy, setSortBy] = useSortBy<GroupListing>('name', 'asc');
  const {
    page,
    setPage,
    rowsPerPage,
    changeRowsPerPage,
    loading: loadingTablePreferences,
  } = useTable({
    key: 'groups',
  });

  const queryFilterByDebounced = useDebouncedValue(queryFilterBy);
  const { data, fetchMore, networkStatus, loading } = useListGroupsQuery({
    variables: {
      first: rowsPerPage,
      sortBy: {
        sortBy: [sortBy],
      },
      filterBy: {
        filterBy: [queryFilterByDebounced],
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: loadingTablePreferences,
  });

  const groups = data?.groups.edges.map((edge) => edge.node) || [];
  const totalNumberFound = data?.groups.totalNumberFound;
  const pageInfo = data?.groups.pageInfo;

  const isLoading = loading || loadingTablePreferences;
  const emptyState = queryFilterByDebounced.value
    ? 'No results found'
    : 'No groups yet';

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onAdd = () => {
    openDialog({
      title: '',
      content: <AddOrEditGroup onFinish={closeDialog} />,
    });
  };

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const renderRow = (row: GroupListing) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="GROUP_NAME_CELL">{row.name}</TableCell>
        <TableCell padding="checkbox">
          <Link to={`/group/${row.id}`} data-testid="GROUP_PAGE_BUTTON">
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        columnGap={3}
        data-testid="GROUPS_CONTAINER"
      >
        <SearchableTableHeader<GroupListing>
          headCells={headCells}
          defaultSearchField="name"
          onSearchChange={setQueryFilterBy}
          disableMargin
        />
        {isAdmin && (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startEvaIcon={{ name: 'plus-outline' }}
            onClick={onAdd}
            data-testid="NEW_BUTTON"
          >
            NEW
          </Button>
        )}
      </Box>
      <Paper data-testid="GROUPS_TABLE" sx={{ marginTop: 2 }}>
        <Table<GroupListing>
          actions="right"
          initialOrderBy="name"
          headCells={headCells}
          emptyState={emptyState}
          isLoading={isLoading}
          page={page}
          renderRow={renderRow}
          rows={groups}
          totalRowCount={totalNumberFound}
          withPagination
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={setSortBy}
          onPageChange={onPageChange}
        />
      </Paper>
    </>
  );
};

export default GroupsTab;
