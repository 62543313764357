import { useTheme } from '@mui/material';
import React, { FC } from 'react';
import { getSectionTypeLabel } from '@fdha/common-utils';

import { SectionType } from '../../utils';
import { Typography } from '../Typography/Typography';

export interface SelectedSubtitleProps {
  type: SectionType;
  amount?: number;
  selectedQuantity: number;
  hasSides: boolean;
  isFull?: boolean;
}

const SelectedSubtitle: FC<SelectedSubtitleProps> = ({
  type,
  amount = 0,
  selectedQuantity,
  hasSides,
  isFull,
}) => {
  const theme = useTheme();

  const title = getSectionTypeLabel(type, hasSides);

  const formattedFullText = `You have selected all ${title}`;
  const i18nKeyFull =
    type === 'snacks'
      ? hasSides
        ? 'meals:editDelivery.selection.subtitle.allSnacksSides'
        : 'meals:editDelivery.selection.subtitle.allSnacks'
      : 'meals:editDelivery.selection.subtitle.allEntrees';

  const i18nKeyPartial =
    type === 'snacks'
      ? hasSides
        ? 'temporary:meals.editDelivery.selection.subtitle.partialSnacksSides'
        : 'temporary:meals.editDelivery.selection.subtitle.partialSnacks'
      : 'temporary:meals.editDelivery.selection.subtitle.partialEntrees';

  return isFull ? (
    <Typography
      variant="body2"
      color={theme.palette.text.secondary}
      i18nKey={i18nKeyFull}
    >
      {formattedFullText}
    </Typography>
  ) : (
    <Typography
      variant="body2"
      color={theme.palette.text.secondary}
      i18nKey={i18nKeyPartial}
      i18nParams={{ selected: selectedQuantity, total: amount }}
    >
      {`You have selected <strong>${selectedQuantity} of ${amount}</strong> ${title}`}
    </Typography>
  );
};

export default SelectedSubtitle;
