import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { Popover, PopoverProps } from './Popover';

export interface HoverPopoverProps
  extends Omit<PopoverProps, 'anchorEl' | 'open' | 'onClose'> {
  content: React.ReactNode;
}

export const HoverPopover: React.FC<PropsWithChildren<HoverPopoverProps>> = ({
  children,
  content,
  ...popoverProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'hover-popover' : undefined;

  return (
    <>
      <Box
        display="flex"
        width="fit-content"
        aria-owns={id}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        data-testid="HOVER_POPOVER_TRIGGER"
      >
        {children}
      </Box>
      <Popover
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        {...popoverProps}
        sx={{ pointerEvents: 'none', ...popoverProps.sx }}
      >
        {content}
      </Popover>
    </>
  );
};
