import React, { FC, useCallback, useEffect } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import {
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfToday,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfToday,
} from 'date-fns';

import {
  Button,
  DatePeriodSelect,
  DateRangePicker,
  DayRange,
  Loader,
  RangePeriod,
  Timeline,
} from '..';
import { Activity, Task } from '../..';

export interface ActivitiesViewProps {
  v2?: boolean;
  pathname: string;
  activityPreferences: any;
  previousActivityPreferences: any;
  dateFromUrl: string | null;
  loading: boolean;
  activityDetailsIdFromUrl: string;
  selectedRange?: DayRange;
  previousDate?: string;
  dateToScroll?: string;
  activitiesInRange?: Activity[];
  readOnly?: boolean;
  onAdd: () => void;
  setSelectedRange: (selectedRange: DayRange) => void;
  onUpdateTask: (data: Task, completed: boolean) => void;
  saveToLocalStorage: (
    data: Record<string, unknown>,
    clearData?: boolean
  ) => Promise<void>;
  setDateToScroll: (date: Date | undefined) => void;
  handleSelectActivity: (activity: Activity) => void;
  updateSelectedActivity: (activity: Partial<Activity>) => void;
}

export const ActivitiesView: FC<ActivitiesViewProps> = ({
  v2,
  pathname,
  activityPreferences,
  previousActivityPreferences,
  dateFromUrl,
  loading,
  activityDetailsIdFromUrl,
  selectedRange,
  previousDate,
  dateToScroll,
  activitiesInRange,
  readOnly,
  onAdd,
  setSelectedRange,
  onUpdateTask,
  saveToLocalStorage,
  setDateToScroll,
  handleSelectActivity,
  updateSelectedActivity,
}) => {
  const setInitialRangeByPeriod = useCallback(
    (datePeriod: RangePeriod, referenceDate?: Date) => {
      const date = referenceDate ?? startOfToday();
      switch (datePeriod) {
        case RangePeriod.WEEK: {
          setSelectedRange({
            from: startOfISOWeek(date),
            to: endOfISOWeek(date),
          });
          break;
        }
        case RangePeriod.MONTH: {
          setSelectedRange({
            from: startOfMonth(date),
            to: endOfMonth(date),
          });
          break;
        }
        case RangePeriod.DAY:
        default: {
          setSelectedRange({
            from: startOfDay(date),
            to: endOfDay(date),
          });
          break;
        }
      }
    },
    [setSelectedRange]
  );

  useEffect(() => {
    if (
      previousActivityPreferences === undefined &&
      activityPreferences !== undefined
    ) {
      const referenceDate = dateFromUrl
        ? parseISO(dateFromUrl)
        : startOfToday();

      setInitialRangeByPeriod(activityPreferences?.datePeriod, referenceDate);
    }
  }, [
    activityPreferences,
    dateFromUrl,
    previousActivityPreferences,
    setInitialRangeByPeriod,
  ]);

  const onDatePeriodSelected = (option: SelectChangeEvent<unknown>) => {
    const datePeriod = option.target.value as RangePeriod;
    saveToLocalStorage({ datePeriod });

    setInitialRangeByPeriod(datePeriod);
    setDateToScroll(new Date());
  };

  const onDateRangeSelected = (start: Date, end: Date) => {
    setSelectedRange({ from: start, to: end });

    if (isWithinInterval(endOfToday(), { start, end })) {
      setDateToScroll(new Date());
    } else {
      setDateToScroll(undefined);
    }
  };

  const onBackToTodayClicked = () => {
    setInitialRangeByPeriod(activityPreferences?.datePeriod);
    setDateToScroll(new Date());
  };

  /************ V2 updates *********************/

  const padding = v2 ? 2 : 4;
  const todayButtonText = v2 ? 'Today' : 'BACK TO TODAY';
  const todayButtonVariant = v2 ? 'text' : 'contained';
  const todayButtonColor = v2 ? 'secondary' : 'primary';
  const addButtonText = v2 ? 'Add' : 'ADD';
  const addButtonColor = v2 ? 'primary' : 'secondary';
  const dateRangePickerWidth = v2 ? '100%' : '80%';

  /*********************************************/

  return (
    <>
      {!selectedRange || activityPreferences === undefined ? (
        <Loader />
      ) : (
        <Box display="flex" p={padding} flex="1 1 0" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            columnGap={1}
          >
            <DatePeriodSelect
              v2={v2}
              rangePeriod={activityPreferences?.datePeriod}
              onChange={onDatePeriodSelected}
            />
            <Box display="flex" columnGap={3} rowGap={1}>
              <Button
                variant={todayButtonVariant}
                size="large"
                color={todayButtonColor}
                startEvaIcon={{ name: 'calendar-outline' }}
                onClick={onBackToTodayClicked}
                i18nKey="activities:today"
              >
                {todayButtonText}
              </Button>
              {!readOnly && (
                <Button
                  variant="contained"
                  size="large"
                  color={addButtonColor}
                  startEvaIcon={{ name: 'plus-outline' }}
                  onClick={onAdd}
                  i18nKey="common:button.add"
                >
                  {addButtonText}
                </Button>
              )}
            </Box>
          </Box>
          <Box marginTop={3} alignSelf="center" width={dateRangePickerWidth}>
            <DateRangePicker
              v2={v2}
              rangePeriod={activityPreferences?.datePeriod}
              startingDate={selectedRange.from}
              onChange={onDateRangeSelected}
            />
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            overflow="hidden"
            mt={3}
          >
            <Timeline
              v2={v2}
              pathname={pathname}
              from={selectedRange.from.toISOString()}
              to={selectedRange.to.toISOString()}
              mode={activityPreferences?.datePeriod}
              dateToScroll={dateToScroll}
              previousDate={previousDate}
              activitiesInRange={activitiesInRange}
              activityDetailsId={activityDetailsIdFromUrl}
              loading={loading}
              readOnly={readOnly}
              handleSelectActivity={handleSelectActivity}
              updateSelectedActivity={updateSelectedActivity}
              onUpdateTask={onUpdateTask}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
