import React, { FC } from 'react';
import { Accordion } from '@fdha/web-ui-library';
import { AccordionProps, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import { NotesProps } from '../../../../states/notesState';

import NoteCardHeader from './NoteCardHeader';

interface Props extends AccordionProps {
  note: NotesProps;
  canFlag?: boolean;
  disableEdit?: boolean;
  isEditing?: boolean;
}

const Card: FC<Props> = ({ isEditing, ...props }) => {
  const theme = useTheme();

  const { note, canFlag = true, disableEdit, children } = props;

  return (
    <Accordion
      collapsedMode="partial"
      summary={
        <NoteCardHeader
          note={note}
          canFlag={canFlag}
          disableEdit={disableEdit}
        />
      }
      sx={{
        border: 'none',
      }}
      accordionStyle={{ border: 'none' }}
      expanded={isEditing ? true : undefined}
      summaryStyle={{
        bgcolor: note.flagged ? theme.palette.secondary.border : grey[200],
        borderRadius: '15px 15px 0px 0px',
        borderBottom: `0.5px solid ${theme.palette.divider}`,
        '.MuiAccordionSummary-expandIconWrapper': {
          display: isEditing ? 'none' : 'unset',
        },
      }}
      {...props}
    >
      {children}
    </Accordion>
  );
};

export default Card;
