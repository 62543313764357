import { IconMenu } from '@fdha/web-ui-library';
import React from 'react';

import {
  ChatDetailsKind,
  setSelectedChatDetailsKind,
} from '../../../states/chatDetailsState';

const HeaderMenu = () => {
  const options = [
    {
      label: 'Chat Media',
      handleClick: () => setSelectedChatDetailsKind(ChatDetailsKind.Media),
      testId: 'CHAT_MEDIA_BUTTON',
    },
  ];

  return <IconMenu items={options} />;
};

export default HeaderMenu;
