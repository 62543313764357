import { Box } from '@mui/material';
import { Button, Select, useSnackbar } from '@fdha/web-ui-library';
import { Form, Formik } from 'formik';
import React from 'react';
import {
  GetAllPatientsDocument,
  useAddUserToGroupMutation,
  useListGroupsQuery,
} from '@fdha/graphql-api-admin';

export const NUMBER_OF_GROUPS = 10000;

interface PatientGroupSchema {
  groupId: string;
}

interface AddOrEditPatientGroupProps {
  patientId: string;
  onFinish: () => void;
}

const AddPatientGroup: React.FC<AddOrEditPatientGroupProps> = ({
  patientId,
  onFinish,
}) => {
  const { showSnackbar } = useSnackbar();
  const [addUserToGroup] = useAddUserToGroupMutation();

  const { data } = useListGroupsQuery({
    variables: { first: NUMBER_OF_GROUPS },
    fetchPolicy: 'cache-and-network',
  });

  const options =
    data?.groups.edges.map((group) => ({
      label: group.node.name,
      value: group.node.id,
    })) || [];

  const initialValues: PatientGroupSchema = {
    groupId: '',
  };

  const addOrEditPatientGroup = (groupId: string) => {
    return addUserToGroup({
      variables: { userId: patientId, groupId: groupId },
      refetchQueries: [GetAllPatientsDocument],
    });
  };

  const onCancel = () => {
    showSnackbar({
      message: 'Changes not saved',
      severity: 'warning',
    });
    onFinish();
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      onSubmit={async (values) => {
        try {
          await addOrEditPatientGroup(values.groupId);
          showSnackbar({
            message: 'Group assigned with success',
            severity: 'success',
          });
        } catch {
          showSnackbar({
            message: 'Error to assign patient to group',
            severity: 'error',
          });
        } finally {
          onFinish();
        }
      }}
    >
      {({ dirty, handleChange, values }) => {
        return (
          <Form>
            <Select
              name="groupId"
              title="Available Groups"
              titleVariant="h6"
              onChange={handleChange}
              value={values.groupId}
              options={options}
              placeholder="Choose one group..."
              displayEmpty
              data-testid="SELECT_MENU"
            />
            <Box display="flex" justifyContent="flex-end" marginTop={3}>
              <Button color="primary" onClick={onCancel} size="large">
                CANCEL
              </Button>
              <Button
                disabled={!dirty}
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
              >
                SAVE
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddPatientGroup;
