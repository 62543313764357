import { useLocation, Outlet, useParams, useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Loader } from '@fdha/web-ui-library';
import { useGetSiteQuery } from '@fdha/graphql-api-admin';

import { BasePage } from '../../components';

const Site = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const siteId = params.siteId || '';

  const { data, loading } = useGetSiteQuery({
    variables: {
      id: siteId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const site = data?.site;

  const tabs = useMemo(
    () => [
      {
        value: 'about',
        label: 'About',
        to: 'about',
        'data-testid': 'SITE_ABOUT_TAB',
      },
      {
        value: 'patients',
        label: `Patients (${site?.number_of_patients})`,
        to: 'patients',
        'data-testid': 'SITE_PATIENTS_TAB',
      },
      {
        value: 'siteStaff',
        label: `Site Staff (${site?.numberOfSiteStaff})`,
        to: 'site-staff',
        'data-testid': 'SITE_SITE_STAFF_TAB',
      },
    ],
    [site?.number_of_patients, site?.numberOfSiteStaff]
  );

  const tab = useMemo(() => {
    return tabs.find((tab) => {
      return location.pathname.split('/').pop() === tab.to;
    })?.value;
  }, [location.pathname, tabs]);

  const selectedTab = useMemo(() => tab || 'About', [tab]);
  const showHeader = useMemo(() => !!tab, [tab]);

  if (loading) {
    return <Loader />;
  }

  return (
    <BasePage data-testid="SITE_PANEL">
      {showHeader ? (
        <>
          <BasePage.BackButton />
          <BasePage.Title>{site?.name}</BasePage.Title>
          <TabContext value={selectedTab}>
            <TabList
              orientation="horizontal"
              value={selectedTab}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  value={tab.value}
                  label={tab.label}
                  onClick={() => navigate(tab.to, { replace: true })}
                  key={tab.value}
                  data-testid={tab['data-testid']}
                />
              ))}
            </TabList>
            <TabPanel
              sx={{ padding: 0, paddingTop: 4, height: '100%' }}
              value={selectedTab}
            >
              <Outlet />
            </TabPanel>
          </TabContext>
        </>
      ) : (
        <Outlet />
      )}
    </BasePage>
  );
};

export default Site;
