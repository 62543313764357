import { Box, IconButton, useTheme } from '@mui/material';
import React, { FC } from 'react';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

export interface QuantitySelectorProps {
  value: number;
  onChange: (value: number) => void;
  isDisabled: boolean;
}

const QuantitySelector: FC<QuantitySelectorProps> = ({
  value,
  onChange,
  isDisabled,
}) => {
  const theme = useTheme();

  const handleSubtract = () => {
    onChange(value - 1);
  };

  const handleAdd = () => {
    onChange(value + 1);
  };

  const renderIconButton = (
    name: string,
    onClick: () => void,
    disabled?: boolean
  ) => (
    <IconButton onClick={onClick} disabled={disabled}>
      <Icon
        name={name}
        fill={
          disabled
            ? theme.palette.action.disabledBackground
            : theme.palette.primary.main
        }
        size="xlarge"
      />
    </IconButton>
  );

  return (
    <Box display="flex" alignItems="center">
      {value > 0 && (
        <Box display="flex" alignItems="center">
          {renderIconButton('minus-circle', handleSubtract)}
          <Typography>{value}</Typography>
        </Box>
      )}
      {renderIconButton('plus-circle', handleAdd, isDisabled)}
    </Box>
  );
};

export default QuantitySelector;
