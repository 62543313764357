import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useDrop } from 'react-dnd';
import { grey } from '@mui/material/colors';

import { addNewQuestion } from '../../states/questionBuilderState';
import { OptionProps } from '../../models/FormBuilderProps';

const EmptyContainer = () => {
  const theme = useTheme();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ['card', 'optionCard'],
    drop: (item: OptionProps) => {
      addNewQuestion(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const getBackgroundColor = () => {
    if (isOver && canDrop) {
      return grey[200];
    }
    return theme.palette.background.paper;
  };

  return (
    <Box
      ref={drop}
      height="100%"
      borderRadius="15px"
      marginTop="24px"
      bgcolor={getBackgroundColor()}
      border="0.5px solid"
      borderColor={theme.palette.divider}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h6">Drag and drop a question type here</Typography>
    </Box>
  );
};

export default EmptyContainer;
