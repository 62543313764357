import { ActivityDetailsCard } from '@fdha/web-ui-library';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Appointment } from '@fdha/graphql-api-admin';

interface AppointmentDetailsProps {
  data: Appointment;
  isCreatedByCoach: boolean;
  onEdit: () => void;
  isCsr?: boolean;
}

const AppointmentDetails: React.FC<AppointmentDetailsProps> = ({
  data,
  onEdit,
  isCreatedByCoach,
  isCsr,
}) => {
  return (
    <ActivityDetailsCard>
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Typography variant="h6" sx={{ overflowWrap: 'anywhere' }}>
          {data.title}
        </Typography>
        {!isCsr && <ActivityDetailsCard.EditButton onEdit={onEdit} />}
      </Box>
      <Box>
        <ActivityDetailsCard.Details details={data.description} />
        <ActivityDetailsCard.Date date={data.time} />
        <ActivityDetailsCard.EndDate date={data.ends_at} />
        <ActivityDetailsCard.Time time={data.time} />
        <ActivityDetailsCard.Duration duration={data.duration} />
        <ActivityDetailsCard.Frequency
          frequency={data.frequency}
          weekDays={data.weekDays}
        />
        <ActivityDetailsCard.Location location={data.location} />
        <ActivityDetailsCard.Reminder reminder={data.reminder} />
      </Box>
      <ActivityDetailsCard.CreatedBy isCreatedByCoach={isCreatedByCoach} />
    </ActivityDetailsCard>
  );
};

export default AppointmentDetails;
