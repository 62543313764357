import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { Select, SelectProps } from '@fdha/web-ui-library';

import { EditableCard, EditableCardProps } from './EditableCard';

type SelectValue = SelectProps['value'];

export interface EditableCardSelectSchema {
  option?: SelectValue;
}

export interface EditableCardSelectProps extends EditableCardProps {
  initialValue: SelectValue;
  onSave: (value: SelectValue) => Promise<void>;
  selectProps: Omit<SelectProps, 'value' | 'onChange'>;
}

export const EditableCardSelect: React.FC<EditableCardSelectProps> = ({
  initialValue,
  onSave,
  selectProps,
  ...editableCardProps
}) => {
  const testId = useMemo(
    () =>
      `${editableCardProps.title.toUpperCase().replaceAll(' ', '_')}_SELECT`,
    [editableCardProps.title]
  );

  const initialValues: EditableCardSelectSchema = {
    option: initialValue,
  };

  const handleSubmit = async (values: EditableCardSelectSchema) => {
    await onSave(values.option);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => (
        <EditableCard {...editableCardProps}>
          <Select
            testId={testId}
            name="option"
            fullWidth
            displayEmpty
            {...selectProps}
            value={values.option}
            onChange={handleChange}
          />
        </EditableCard>
      )}
    </Formik>
  );
};

export default EditableCardSelect;
