import React, { useMemo } from 'react';
import {
  DeliveryShipmentStatus,
  getShipmentCurrentStep,
  getShipmentStatusDescriptionLabelByValue,
  I18n,
  shipmentStatusSteps,
} from '@fdha/common-utils';
import { Box, Divider, Paper, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import { ShipmentStatusIcon } from '../ShipmentStatusIcon/ShipmentStatusIcon';
import { Typography } from '../Typography/Typography';
import { Button } from '../Button/Button';
import { Steps } from '../Steps/Steps';
import { isI18n } from '../../utils';

export interface ShipmentStatusCardProps {
  status: DeliveryShipmentStatus;
  isOwnDelivery: boolean;
  onTrackDelivery?: () => void;
  trackingId?: string;
  isDelayed?: boolean;
}

export const ShipmentStatusCard: React.FC<ShipmentStatusCardProps> = ({
  status,
  isOwnDelivery,
  onTrackDelivery,
  trackingId,
  isDelayed,
}) => {
  const theme = useTheme();

  const trackingIdLabel = useMemo(() => {
    return trackingId || 'Available soon';
  }, [trackingId]);

  const trackingIdI18n = useMemo(() => {
    return trackingId
      ? undefined
      : {
          key: 'temporary:meals.shipmentStatusCard.availableSoon',
          fallback: 'Available soon',
        };
  }, [trackingId]);

  const shipmentDescription = useMemo(() => {
    const description = getShipmentStatusDescriptionLabelByValue('web');
    if (isOwnDelivery) {
      return description[status];
    }

    if (status === DeliveryShipmentStatus.Delivered) {
      return 'The delivery has arrived.';
    }

    if (status === DeliveryShipmentStatus.Transit) {
      return 'The delivery is en route.';
    }

    if (status === DeliveryShipmentStatus.BeingPrepared) {
      return 'The food is being prepared.';
    }

    return description[status];
  }, [isOwnDelivery, status]);

  const shipmentDescriptionLabel = useMemo(() => {
    return isI18n(shipmentDescription)
      ? shipmentDescription.fallback
      : shipmentDescription;
  }, [shipmentDescription]);

  const shipmentDescriptionI18n = useMemo(() => {
    return isI18n(shipmentDescription) ? shipmentDescription : undefined;
  }, [shipmentDescription]);

  const trackingButton: I18n | string = useMemo(() => {
    if (isOwnDelivery) {
      return {
        key: 'temporary:meals.shipmentStatusCard.trackButton',
        fallback: 'Track Your Delivery',
      };
    }
    return 'Track Delivery';
  }, [isOwnDelivery]);

  const trackingButtonI18n = useMemo(() => {
    return isI18n(trackingButton) ? trackingButton : undefined;
  }, [trackingButton]);

  const trackingButtonLabel = useMemo(() => {
    return isI18n(trackingButton) ? trackingButton.fallback : trackingButton;
  }, [trackingButton]);

  const isValidStatus = useMemo(
    () => shipmentStatusSteps.includes(status),
    [status]
  );

  const hasNoTrackingError = useMemo(() => {
    return !trackingId && !isValidStatus;
  }, [isValidStatus, trackingId]);

  const currentStep = useMemo(() => getShipmentCurrentStep(status), [status]);

  const stepColorByStep: Record<number, string> = useMemo(() => {
    return {
      1: theme.palette.info.main,
      2: theme.palette.info.main,
      3: theme.palette.success.main,
    };
  }, [theme.palette.info.main, theme.palette.success.main]);

  const iconBackground = useMemo(() => {
    switch (status) {
      case DeliveryShipmentStatus.BeingPrepared:
      case DeliveryShipmentStatus.Transit:
        return theme.palette.info.lightBg;
      case DeliveryShipmentStatus.Delivered:
        return theme.palette.success.lightBg;
      case DeliveryShipmentStatus.Failure:
      case DeliveryShipmentStatus.Returned:
      case DeliveryShipmentStatus.Unknown:
        return grey[200];
    }
  }, [status, theme.palette.info.lightBg, theme.palette.success.lightBg]);

  const shipmentDelayedMessage: I18n | string = isOwnDelivery
    ? {
        key: 'temporary:meals.delayed',
        fallback: 'Your delivery might be delayed.',
      }
    : 'This delivery might be delayed.';

  const shipmentDelayedMessageI18n = isI18n(shipmentDelayedMessage)
    ? shipmentDelayedMessage
    : undefined;

  const shipmentDelayedMessageLabel = isI18n(shipmentDelayedMessage)
    ? shipmentDelayedMessage.fallback
    : shipmentDelayedMessage;

  const containerStyle = { paddingX: 2, paddingY: 3 };

  const renderDescription = () => {
    return (
      <Typography variant="body1" i18nKey={shipmentDescriptionI18n?.key}>
        {shipmentDescriptionLabel}
      </Typography>
    );
  };

  const renderShipmentStatusIcon = () => {
    return (
      <ShipmentStatusIcon
        status={status}
        size="xlarge"
        backgroundColor={iconBackground}
      />
    );
  };

  const renderNoTrackingError = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ ...containerStyle }}
      >
        {renderDescription()}
        {renderShipmentStatusIcon()}
      </Box>
    );
  };

  return (
    <Paper sx={{ padding: 0 }} variant="elevation" elevation={0}>
      {hasNoTrackingError ? (
        renderNoTrackingError()
      ) : (
        <>
          <Box
            sx={{
              ...containerStyle,
              display: 'flex',
              flexDirection: 'column',
              rowGap: 3,
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Typography
                  variant="body1"
                  color={theme.palette.text.hint}
                  i18nKey="temporary:meals.shipmentStatusCard.trackingId"
                >
                  Tracking ID
                </Typography>
                <Typography
                  variant="h6"
                  color={
                    trackingId
                      ? theme.palette.text.primary
                      : theme.palette.text.disabled
                  }
                  i18nKey={trackingIdI18n?.key}
                >
                  {trackingIdLabel}
                </Typography>
              </Box>
              {renderShipmentStatusIcon()}
            </Box>
            {isValidStatus && (
              <Steps
                totalSteps={shipmentStatusSteps.length}
                currentStep={currentStep}
                backgroundColor={stepColorByStep[currentStep]}
              />
            )}
            {renderDescription()}
          </Box>
          {isDelayed && (
            <Box
              sx={{
                paddingX: 3,
                paddingY: 2,
                backgroundColor: theme.palette.warning.light,
              }}
            >
              <Typography
                variant="body2"
                i18nKey={shipmentDelayedMessageI18n?.key}
                textAlign="center"
              >
                {shipmentDelayedMessageLabel}
              </Typography>
            </Box>
          )}
          {!!onTrackDelivery && (
            <>
              <Divider />
              <Box
                sx={{
                  ...containerStyle,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button i18n={trackingButtonI18n} onClick={onTrackDelivery}>
                  {trackingButtonLabel}
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </Paper>
  );
};
