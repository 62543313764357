import {
  addPhoneNumberValidation,
  MaskedTextField,
  requiredMessage,
  TextField,
} from '@fdha/web-ui-library';
import { Box, styled } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC, PropsWithChildren } from 'react';
import * as Yup from 'yup';

export interface UserPreferences {
  name: string;
  email: string;
  phoneNumber: string;
}

export const FormItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(),
  email: Yup.string().email('Please insert a valid email').required(),
  phoneNumber: addPhoneNumberValidation().required(requiredMessage),
});

const Name = () => {
  const {
    values: { name },
    handleChange,
  } = useFormikContext<UserPreferences>();

  return (
    <FormItem>
      <TextField
        name="name"
        value={name}
        onChange={handleChange}
        title="Full name"
        placeholder="Add full name..."
      />
    </FormItem>
  );
};

const Email = () => {
  const {
    values: { email },
    errors,
    handleChange,
  } = useFormikContext<UserPreferences>();

  const hasEmailError = !!email && !!errors.email;

  return (
    <FormItem>
      <TextField
        name="email"
        type="email"
        value={email}
        onChange={handleChange}
        title="Email"
        placeholder="Add email..."
        error={hasEmailError}
        helperText={hasEmailError && errors.email}
      />
    </FormItem>
  );
};

const PhoneNumber = () => {
  const {
    values: { phoneNumber },
    errors,
    handleChange,
    handleBlur,
    touched,
  } = useFormikContext<UserPreferences>();

  return (
    <FormItem>
      <MaskedTextField
        name="phoneNumber"
        value={phoneNumber}
        onChange={handleChange}
        title="Phone number"
        placeholder="000-000-0000"
        error={touched.phoneNumber && !!errors.phoneNumber}
        helperText={touched.phoneNumber && errors.phoneNumber}
        onBlur={handleBlur}
        mask="phoneNumber"
      />
    </FormItem>
  );
};

const AddUserPreferences: FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

export default Object.assign(AddUserPreferences, {
  Name,
  Email,
  PhoneNumber,
});
