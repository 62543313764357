import React, { useState } from 'react';
import { Attachment, logChatPromiseExecution, UserResponse } from 'stream-chat';
import {
  MessageInput,
  MessageList,
  StreamMessage,
  useChannelActionContext,
  Window,
} from 'stream-chat-react';

import ChannelHeader from './ChannelHeader';

export const GiphyContext = React.createContext(
  {} as {
    giphyState: boolean;
    setGiphyState: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

const messageActions = ['delete', 'edit', 'quote', 'react'];

interface ChannelInnerProps {
  isWidget: boolean;
}

const ChannelInner: React.FC<ChannelInnerProps> = ({ isWidget }) => {
  const { sendMessage } = useChannelActionContext();

  const [giphyState, setGiphyState] = useState(false);
  const giphyContextValue = { giphyState, setGiphyState };

  const overrideSubmitHandler = (message: {
    attachments?: Attachment[];
    mentioned_users?: UserResponse[];
    text?: string;
    parent?: StreamMessage;
  }) => {
    let updatedMessage;

    if (message.attachments?.length && message.text?.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '');
      updatedMessage = { ...message, text: updatedText };
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = { ...message, text: updatedText };
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      };

      const sendMessagePromise = sendMessage(messageToSend);
      logChatPromiseExecution(sendMessagePromise, 'send message');
    }

    setGiphyState(false);
  };

  return (
    <GiphyContext.Provider value={giphyContextValue}>
      <Window>
        <ChannelHeader isWidget={isWidget} />
        <MessageList messageActions={messageActions} />
        <MessageInput grow overrideSubmitHandler={overrideSubmitHandler} />
      </Window>
    </GiphyContext.Provider>
  );
};

export default ChannelInner;
