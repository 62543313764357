import React, { useState } from 'react';
import { Accordion, cancerTypes } from '@fdha/web-ui-library';
import { PatientUser } from '@fdha/graphql-api-admin';

import EditModeMedicalInformation from './EditModeMedicalInformation';
import ViewModeMedicalInformation from './ViewModeMedicalInformation';

interface Props {
  patientUser?: PatientUser | null;
}

const MedicalInformation = ({ patientUser }: Props) => {
  const [editMode, setEditMode] = useState(false);

  const getCancerType = (type?: string | null) => {
    return (
      cancerTypes.find((cancer) => cancer.value === type)?.label || 'Other'
    );
  };

  return (
    <Accordion
      title="Medical information"
      data-testid="MEDICAL_INFO"
      sx={{ px: 1 }}
    >
      {editMode ? (
        <EditModeMedicalInformation
          patient={patientUser}
          getCancerType={getCancerType}
          handleEditMode={setEditMode}
        />
      ) : (
        <ViewModeMedicalInformation
          patient={patientUser}
          getCancerType={getCancerType}
          handleEditMode={setEditMode}
        />
      )}
    </Accordion>
  );
};

export default MedicalInformation;
