import React, { useEffect, useRef } from 'react';
import { groupBy } from 'lodash';
import {
  parseISO,
  isToday,
  startOfToday,
  startOfDay,
  isWithinInterval,
} from 'date-fns';

import { RangePeriod } from '../DateRangePicker/DateRangePicker';
import { Activity, Task } from '../../utils';
import Loader from '../Loader/Loader';

import TimelineList from './TimelineList';

interface TimelineProps {
  v2?: boolean;
  from: string;
  to: string;
  pathname: string;
  mode?: RangePeriod;
  dateToScroll?: string;
  previousDate?: string;
  activityDetailsId: string;
  activitiesInRange?: Activity[];
  loading: boolean;
  readOnly?: boolean;
  handleSelectActivity: (activity: Activity) => void;
  updateSelectedActivity: (activity: Partial<Activity>) => void;
  onUpdateTask: (data: Task, completed: boolean) => void;
}

export const Timeline: React.FC<TimelineProps> = ({
  v2,
  from,
  to,
  pathname,
  mode = RangePeriod.DAY,
  dateToScroll,
  previousDate,
  activityDetailsId,
  activitiesInRange,
  loading,
  readOnly,
  handleSelectActivity,
  updateSelectedActivity,
  onUpdateTask,
}) => {
  const lastActivityDetailsId = useRef(activityDetailsId);

  useEffect(() => {
    if (!lastActivityDetailsId.current || !activitiesInRange) {
      return;
    }

    const activityFound = activitiesInRange.find(
      (activity) => activity.id === lastActivityDetailsId.current
    );

    // Removes the current URL query parameters
    if (activityFound) {
      handleSelectActivity(activityFound);
    }
    window.history.pushState({}, document.title, pathname);
    lastActivityDetailsId.current = '';
  }, [activitiesInRange, pathname, handleSelectActivity]);

  const maybeAddToday = (
    activitiesByDate: {
      date: string;
      activities: Activity[];
    }[]
  ) => {
    if (mode === 'day') {
      return activitiesByDate;
    }

    const hasToday =
      activitiesByDate.findIndex((activity) =>
        isToday(parseISO(activity.date))
      ) !== -1;

    const isTodayInRange = isWithinInterval(startOfToday(), {
      start: parseISO(from),
      end: parseISO(to),
    });

    if (!hasToday && isTodayInRange) {
      activitiesByDate.push({
        date: startOfToday().toISOString(),
        activities: [],
      });
    }

    return activitiesByDate;
  };

  const groupedActivities = maybeAddToday(
    Object.entries(
      groupBy(activitiesInRange, (item) => {
        const time = parseISO(item.time);

        if (mode !== RangePeriod.DAY) {
          return startOfDay(time).toISOString();
        }

        if (time.getMinutes() >= 30) {
          time.setMinutes(30);
        } else {
          time.setMinutes(0);
        }

        return time.toISOString();
      })
    ).map(([date, items]) => ({
      date,
      activities: items,
    }))
  ).sort((a, b) => a.date.localeCompare(b.date));

  if (loading) {
    return <Loader />;
  }

  return (
    <TimelineList
      v2={v2}
      from={from}
      to={to}
      activitiesByDateList={groupedActivities}
      mode={mode}
      dateToScroll={dateToScroll}
      previousDate={previousDate}
      readOnly={readOnly}
      handleSelectActivity={handleSelectActivity}
      updateSelectedActivity={updateSelectedActivity}
      onUpdateTask={onUpdateTask}
    />
  );
};
