import React from 'react';
import { Box, Typography } from '@mui/material';
import { ActivityDetailsCard, CircularProgress } from '@fdha/web-ui-library';
import {
  Goal,
  useUpdateGoalStepCompletedStateMutation,
} from '@fdha/graphql-api-admin';

import { updateSelectedActivity } from '../../../../../../states';

import GoalStepsDetails from './GoalStepsDetails';

interface GoalDetailsProps {
  data: Goal;
  isCreatedByCoach: boolean;
  onEdit: () => void;
  isCsr?: boolean;
}

const GoalDetails: React.FC<GoalDetailsProps> = ({
  data,
  isCreatedByCoach,
  onEdit,
  isCsr,
}) => {
  const [updateGoalStepCompletedState] =
    useUpdateGoalStepCompletedStateMutation();

  const handleChangeGoalStepState = async (
    stepId: string,
    completed: boolean
  ) => {
    await updateGoalStepCompletedState({
      variables: { completed: completed, stepId, instanceId: data.instanceId! },
      update(cache, { data: updatedData }) {
        if (!updatedData) {
          return;
        }
        const { updateGoalStepCompletedState: updatedSteps } = updatedData;
        cache.modify({
          id: cache.identify(data),
          fields: {
            steps() {
              return updatedSteps;
            },
          },
        });
        updateSelectedActivity({ steps: updatedSteps } as Goal);
      },
    });
  };

  const completed = data.steps.filter((step) => step.completed);
  const completedRatio = completed.length / data.steps.length;
  const progress = Math.round((completedRatio + Number.EPSILON) * 100);

  return (
    <ActivityDetailsCard>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <CircularProgress color="success" value={progress} />
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography
            variant="h6"
            sx={{ overflowWrap: 'anywhere', marginLeft: 2 }}
          >
            {data.title}
          </Typography>
          {!isCsr && <ActivityDetailsCard.EditButton onEdit={onEdit} />}
        </Box>
      </Box>
      <Box>
        <GoalStepsDetails
          steps={data.steps}
          isCsr={isCsr}
          onStepStateChange={handleChangeGoalStepState}
        />
        <ActivityDetailsCard.Details details={data.description} />
        <ActivityDetailsCard.Date date={data.time} />
        <ActivityDetailsCard.EndDate date={data.ends_at} />
        <ActivityDetailsCard.Time time={data.time} />
        <ActivityDetailsCard.Frequency
          frequency={data.frequency}
          weekDays={data.weekDays}
        />
        <ActivityDetailsCard.Reminder reminder={data.reminder} />
      </Box>

      <ActivityDetailsCard.CreatedBy isCreatedByCoach={isCreatedByCoach} />
    </ActivityDetailsCard>
  );
};

export default GoalDetails;
