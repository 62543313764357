import React, { useEffect } from 'react';
import { parseISO } from 'date-fns';
import Icon from 'react-eva-icons';
import { FileIcon } from 'react-file-utils';
import { useChannelStateContext } from 'stream-chat-react';
import prettyBytes from 'pretty-bytes';
import { NetworkStatus, useApolloClient } from '@apollo/client';
import { formatDate, InfiniteScrollList } from '@fdha/web-ui-library';
import {
  Box,
  Card,
  Typography,
  CardActionArea,
  useTheme,
  ListItem,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  ChannelAttachment,
  useGetChannelAttachmentsQuery,
} from '@fdha/graphql-api-admin';

const ChatMedia = () => {
  const theme = useTheme();
  const client = useApolloClient();
  const { channel } = useChannelStateContext();

  const { data, fetchMore, networkStatus } = useGetChannelAttachmentsQuery({
    variables: { id: channel.id || '', limit: 10 },
    notifyOnNetworkStatusChange: true,
  });

  const attachments = data?.channelAttachments.attachments || [];
  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;

  useEffect(() => {
    return () => {
      client.cache.evict({
        fieldName: 'channelAttachments',
      });
    };
  }, [client.cache]);

  const loadMore = () => {
    if (
      !attachments.length ||
      networkStatus === NetworkStatus.fetchMore ||
      !data?.channelAttachments.next
    ) {
      return;
    }

    fetchMore({
      variables: {
        next: data?.channelAttachments.next,
      },
    });
  };

  const getFileDateAndSize = (
    fileSize?: number | null,
    createdAt?: string | null
  ) => {
    const date = createdAt && formatDate(parseISO(createdAt));
    const size = fileSize && prettyBytes(fileSize);

    if (!fileSize) {
      return date;
    }

    return `${date} | ${size}`;
  };

  const renderEmptyState = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingTop={8}
      >
        <Typography variant="h6" color={theme.palette.text.disabled}>
          No media file
        </Typography>
      </Box>
    );
  };

  const renderFileType = (item: ChannelAttachment) => {
    if (item.type === 'image') {
      return (
        <img
          src={item.url || ''}
          alt={item.title || ''}
          width="46px"
          height="46px"
        />
      );
    }
    return <FileIcon mimeType={item.mimeType || ''} big size={46} />;
  };

  const renderListItem = (item: ChannelAttachment, index: number) => {
    return (
      <ListItem
        disablePadding
        key={index}
        sx={{
          marginY: 1,
          '&:first-child': {
            marginTop: 0,
          },
        }}
      >
        <Card sx={{ width: '100%' }} data-testid="CHAT_MEDIA_ITEM">
          <CardActionArea href={item.url || ''} target="_blank" download>
            <Box
              paddingY={1}
              paddingRight={3}
              paddingLeft={1}
              display="flex"
              alignItems="center"
            >
              <Box>{renderFileType(item)}</Box>
              <Box
                justifyContent="flex-start"
                flex="1 1 auto"
                display="flex"
                paddingLeft={2}
                paddingRight={1}
                overflow="hidden"
                flexDirection="column"
              >
                <Typography
                  textOverflow="ellipsis"
                  overflow="hidden"
                  variant="subtitle2"
                  whiteSpace="nowrap"
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="caption"
                  color={theme.palette.text.secondary}
                >
                  {getFileDateAndSize(item.fileSize, item.createdAt)}
                </Typography>
              </Box>
              <Icon name="download-outline" fill={grey[800]} size="large" />
            </Box>
          </CardActionArea>
        </Card>
      </ListItem>
    );
  };

  return (
    <Box paddingX={4} paddingY={2}>
      <InfiniteScrollList
        items={attachments}
        renderItem={renderListItem}
        renderEmptyState={renderEmptyState}
        loadMore={loadMore}
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
      />
    </Box>
  );
};

export default ChatMedia;
