import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import { Avatar } from '../Avatar/Avatar';
import ModeratorLabel from '../ModeratorLabel/ModeratorLabel';
import RestrictedLabel from '../RestrictedLabel/RestrictedLabel';
import { UserType } from '../SelectType/SelectType';
import { CommunityRole, handleClickProfile } from '../../utils';
import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

export type PostAuthorProps = {
  isMine?: boolean;
  userId?: string | null;
  type?: UserType | null;
  name: string;
  picture?: string;
  subtitle?: JSX.Element;
  isBanned?: boolean;
  role: CommunityRole;
  isModerator?: boolean;
  isRemoved?: boolean;
  onClickProfile?: (url: string) => void;
};

const PostAuthor: React.FC<PostAuthorProps> = ({
  isMine,
  isBanned = false,
  userId,
  type,
  name,
  role,
  picture,
  subtitle,
  isRemoved,
  isModerator,
  onClickProfile,
}) => {
  const theme = useTheme();
  const cursor = isModerator ? 'pointer' : 'default';

  const handleClick = () => {
    if (isModerator) {
      handleClickProfile(type, userId, isMine, onClickProfile);
    }
  };

  return (
    <Box display="flex" flexDirection="row">
      {isModerator || !isRemoved || isMine ? (
        <Avatar
          picture={picture}
          name={name}
          size={52}
          onClick={handleClick}
          sx={{ cursor }}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={10}
          sx={{
            backgroundColor: grey[300],
            height: '52px',
            width: '52px',
          }}
        >
          <Icon name="person-outline" size="xlarge" fill={grey[800]} />
        </Box>
      )}
      <Box ml={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            variant="body1"
            color={
              isRemoved
                ? theme.palette.text.secondary
                : theme.palette.text.primary
            }
            fontWeight="medium"
            onClick={handleClick}
            sx={{ cursor }}
          >
            {isModerator || !isRemoved || isMine ? name : 'User'}
          </Typography>
          {role === CommunityRole.Moderator && !isBanned && <ModeratorLabel />}
          {isBanned && isModerator && <RestrictedLabel />}
        </Stack>
        {subtitle}
      </Box>
    </Box>
  );
};

export default PostAuthor;
