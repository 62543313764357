import { parseISO } from 'date-fns';
import React from 'react';
import { TextField, Typography, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { formatDate } from '@fdha/web-ui-library';

import { NotesProps } from '../../../../states/notesState';

interface EditHeaderProps {
  note: NotesProps;
}

const EditHeader = ({ note }: EditHeaderProps) => {
  const theme = useTheme();

  const {
    values: { name },
    handleChange,
  } = useFormikContext<{ name: string; content: string }>();

  return (
    <>
      <TextField
        variant="standard"
        placeholder="Add a title here"
        InputProps={{ disableUnderline: true }}
        value={name}
        name="name"
        onChange={handleChange}
        onClick={(event) => event.stopPropagation()}
        data-testid="EDIT_HEADER_TITLE"
      />
      {note.created_at ? (
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
        >
          created on {formatDate(parseISO(note.created_at))}
        </Typography>
      ) : null}
    </>
  );
};

export default EditHeader;
