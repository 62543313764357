import React, { useMemo } from 'react';
import {
  FieldArray,
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  getIn,
} from 'formik';
import { Grid, Paper, Stack } from '@mui/material';
import {
  Button,
  Option,
  TextField,
  requiredMessage,
  useSnackbar,
  Loader,
} from '@fdha/web-ui-library';
import {
  AddSiteInput,
  GetSiteDocument,
  Site,
  SiteStatus,
  useAddSiteMutation,
  useGetSupportedLanguagesQuery,
  useListTrialsQuery,
  useUpdateSiteMutation,
} from '@fdha/graphql-api-admin';
import { parseBackendError } from '@fdha/common-utils';
import * as Yup from 'yup';

import { SiteTrialsForm } from './SiteTrialsForm';

interface TrialSchema {
  id?: string;
  trial: Option | null;
  status: SiteStatus;
  siteId: string;
  languages: string[];
  onboardingCallNeeded: string;
}

export interface SiteSchema {
  name: string;
  abbreviation: string;
  trials: TrialSchema[];
}

const initialTrial = {
  trial: null,
  status: SiteStatus.Active,
  siteId: '',
  languages: [],
  onboardingCallNeeded: 'true',
};

const initialSite: SiteSchema = {
  name: '',
  abbreviation: '',
  trials: [initialTrial],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(requiredMessage),
  trials: Yup.array().of(
    Yup.object().shape({
      trial: Yup.object()
        .nullable()
        .test('empty', requiredMessage, (val) => !!val?.id && !!val?.label)
        .required(requiredMessage),
      status: Yup.string().required(requiredMessage),
      siteId: Yup.string()
        .trim()
        .test(
          'len',
          'Site ID must have 2 characters',
          (val) => val?.length === 2
        )
        .required(requiredMessage),
      languages: Yup.array()
        .min(1, 'At least one language must be selected')
        .required(requiredMessage),
    })
  ),
});

interface AddOrEditSiteProps {
  isLoading?: boolean;
  data?: Site;
  onSuccess?: (siteId: string) => void;
  onCancel?: () => void;
}

export const AddOrEditSite: React.FC<AddOrEditSiteProps> = ({
  isLoading,
  data,
  onSuccess,
  onCancel,
}) => {
  const { showSnackbar } = useSnackbar();

  const isEditing = !!data;

  const initialValues: SiteSchema = useMemo(() => {
    if (!data) {
      return initialSite;
    }

    return {
      name: data.name,
      abbreviation: data.abbreviation || '',
      trials: data.siteTrials?.map((trial) => ({
        id: trial.id,
        trial: {
          label: trial.trial?.protocol_number || '',
          id: trial.trial?.id || '',
        },
        status: trial.status,
        siteId: trial.site_identification,
        languages: trial.languages || [],
        onboardingCallNeeded: trial.onboardingCallNeeded.toString(),
      })) || [initialTrial],
    };
  }, [data]);

  const { data: languagesData, loading: loadingLanguages } =
    useGetSupportedLanguagesQuery();

  const { data: trialData, loading: loadingTrials } = useListTrialsQuery();

  const [addSite] = useAddSiteMutation();

  const [updateSite] = useUpdateSiteMutation({
    awaitRefetchQueries: true,
    refetchQueries: [GetSiteDocument],
  });

  const getSitePayload = (values: SiteSchema): AddSiteInput => ({
    name: values.name,
    abbreviation: values.abbreviation,
    siteTrials: values.trials.map((t) => ({
      id: t.id,
      siteIdentification: t.siteId,
      status: t.status,
      trialId: t.trial?.id ?? '',
      languages: t.languages,
      onboardingCallNeeded: t.onboardingCallNeeded === 'true',
    })),
  });

  const handleSubmit = async (values: SiteSchema) => {
    const action = isEditing ? 'updated' : 'added';
    let siteId = data?.id;

    try {
      if (isEditing) {
        await updateSite({
          variables: { siteId: data?.id || '', input: getSitePayload(values) },
        });
      } else {
        const result = await addSite({
          variables: {
            site: getSitePayload(values),
          },
        });

        siteId = result.data?.addSite?.id;
      }

      if (!siteId) {
        throw new Error('Error to add or update Site');
      }

      onSuccess?.(siteId);
      showSnackbar({
        message: `Site ${action} with success`,
        severity: 'success',
      });
    } catch (error: any) {
      console.error(error);

      const message = parseBackendError(error, 'Error to add Site');

      showSnackbar({
        message,
        severity: 'error',
      });
    }
  };

  const getSiteErrors = (
    name: string,
    errors?: FormikErrors<SiteSchema> | string | string[],
    touched?: FormikTouched<SiteSchema>
  ) => {
    if (typeof errors !== 'string') {
      const errorMsg = getIn(errors, name);
      const hasTouched = getIn(touched, name);

      return hasTouched && errorMsg ? errorMsg : null;
    }
  };

  const handleCancel = () => {
    onCancel?.();
    showSnackbar({
      message: 'Changes not saved',
      severity: 'warning',
    });
  };

  const showLoader = useMemo(
    () => isLoading || loadingLanguages || loadingTrials,
    [isLoading, loadingLanguages, loadingTrials]
  );

  if (showLoader) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
      }) => {
        const getError = (field: string) =>
          getSiteErrors(field, errors, touched);

        return (
          <Form>
            <Paper sx={{ p: 4 }}>
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={6}>
                  <TextField
                    title="Site name"
                    name="name"
                    placeholder="Research Institute"
                    value={values.name}
                    error={!!getError('name')}
                    helperText={getError('name')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    title="Site abbreviation"
                    name="abbreviation"
                    placeholder="ABC"
                    value={values.abbreviation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldArray
                    name="trials"
                    render={(arrayHelpers) => (
                      <SiteTrialsForm
                        languages={languagesData?.supportedLanguages}
                        trials={trialData?.trials}
                        onAddTrial={() => arrayHelpers.push(initialTrial)}
                        onRemoveTrial={(index) => arrayHelpers.remove(index)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                mt={4}
              >
                <Button size="large" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  data-testid="CREATE_SITE_BUTTON"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={isSubmitting}
                >
                  {isEditing ? 'Save changes' : 'Create site'}
                </Button>
              </Stack>
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};
