import { Breadcrumbs, Link, useTheme } from '@mui/material';
import React, { FC } from 'react';

import { Icon, Typography } from '..';

interface PageNameByRoute {
  route: string;
  name: string;
  i18nKey?: string;
}

export interface BreadcrumbProps {
  currentRouteName?: { text?: string; i18nKey?: string };
  pageNamesByRoute: PageNameByRoute[];
  pathname: string;
  onClick: (route: string) => void;
  hasCustomHome?: boolean;
}

const CustomLink: React.FC<{
  to: string;
  name: string;
  i18nKey?: string;
  onClick: (route: string) => void;
}> = ({ to, name, i18nKey, onClick }) => {
  const theme = useTheme();

  return (
    <Link
      style={{ textDecoration: 'none' }}
      href={to}
      onClick={(event) => {
        event.preventDefault();
        onClick(to);
      }}
    >
      <Typography
        variant="body1"
        color={theme.palette.text.secondary}
        i18nKey={i18nKey}
      >
        {name}
      </Typography>
    </Link>
  );
};

export const Breadcrumb: FC<BreadcrumbProps> = ({
  currentRouteName,
  pathname,
  pageNamesByRoute,
  onClick,
  hasCustomHome,
}) => {
  const theme = useTheme();

  const pathnames = pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs
      separator={
        <Icon
          name="chevron-right-outline"
          size="medium"
          fill={theme.palette.text.secondary}
        />
      }
    >
      {!hasCustomHome && (
        <CustomLink
          to="/"
          name="Home"
          i18nKey="home:pageTitle"
          onClick={onClick}
        />
      )}
      {pathnames.map((_, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        const matched = pageNamesByRoute.find(({ route }) => {
          const idsPattern = /\[:[^/]*/g;
          const wildCardRegex = '[^/]*';

          // Transforming routes (with ids notation [:MyCustomID]) into a "regexed" route
          const regexedRoute = `^${route.replace(idsPattern, wildCardRegex)}$`;

          return to.match(regexedRoute);
        });

        const routeName = currentRouteName?.text || matched?.name;
        const routeI18nKey = currentRouteName?.i18nKey || matched?.i18nKey;

        return last ? (
          <Typography
            variant="body1"
            key={to}
            color={theme.palette.text.primary}
            i18nKey={routeI18nKey}
          >
            {routeName}
          </Typography>
        ) : (
          <CustomLink
            to={to}
            key={to}
            name={matched?.name || ''}
            i18nKey={matched?.i18nKey}
            onClick={onClick}
          />
        );
      })}
    </Breadcrumbs>
  );
};
