import { Accordion, Loader } from '@fdha/web-ui-library';
import { Box, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';
import Icon from 'react-eva-icons';
import {
  FoodProgramStatus,
  useGetFoodProgramUserQuery,
} from '@fdha/graphql-api-admin';
import { useParams } from 'react-router-dom';

import PlannedPauseEdit from './PlannedPauseEdit';
import PlannedPauseView from './PlannedPauseView';

interface PlannedPauseProps {
  isCsr?: boolean;
}

const PlannedPause = ({ isCsr }: PlannedPauseProps) => {
  const params = useParams();
  const patientId = params.patientId || '';

  const [isEditing, setIsEditing] = useState(false);

  const {
    data: foodProgramData,
    loading,
    refetch,
  } = useGetFoodProgramUserQuery({
    variables: {
      patientId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const foodProgramUser = foodProgramData?.foodProgramUserByPatientIdV2;
  const showEdit =
    !isEditing &&
    !isCsr &&
    foodProgramUser?.status === FoodProgramStatus.Active;

  return (
    <Accordion
      title="Planned Pause on Deliveries"
      data-testid="PLANNED_PAUSES"
      sx={{ px: 1 }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {isEditing ? (
            <PlannedPauseEdit
              pauses={foodProgramUser?.plannedPauses || []}
              foodProgramUserId={foodProgramUser?.id}
              onFinish={() => setIsEditing(false)}
              onSuccess={() => refetch()}
            />
          ) : (
            <PlannedPauseView pauses={foodProgramUser?.plannedPauses || []} />
          )}
          {showEdit && (
            <Box display="flex" justifyContent="flex-end" marginTop={5}>
              <IconButton
                onClick={() => setIsEditing(true)}
                data-testid="EDIT_BUTTON"
              >
                <Icon
                  name="edit-outline"
                  width={24}
                  height={24}
                  fill={grey[600]}
                />
              </IconButton>
            </Box>
          )}
        </>
      )}
    </Accordion>
  );
};

export default PlannedPause;
