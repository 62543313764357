import React, { useMemo } from 'react';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import {
  BasePage,
  Button,
  Select,
  TextField,
  requiredMessage,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  AddSiteStaffUserInput,
  SiteStaffRole,
  SiteStatus,
  useAddSiteStaffUserMutation,
  useListSitesQuery,
} from '@fdha/graphql-api-admin';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { STATUS_OPTIONS, siteStaffRoleLabelByValue } from '../../../../utils';

interface SiteStaffUserSchema {
  email: string;
  name: string;
  associatedSite: string;
  role: string;
  status: SiteStatus;
}

const initialValues: SiteStaffUserSchema = {
  name: '',
  email: '',
  associatedSite: '',
  role: '',
  status: SiteStatus.Active,
};

const siteStaffValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required(requiredMessage),
  email: Yup.string().trim().email().required(requiredMessage),
  associatedSite: Yup.string().trim().required(requiredMessage),
  role: Yup.string().trim().required(requiredMessage),
});

const NUMBER_OF_SITES = 10000;

const AddSiteStaff = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const [addSiteStaffUser] = useAddSiteStaffUserMutation();

  const { data: sitesData } = useListSitesQuery({
    variables: { first: NUMBER_OF_SITES },
    fetchPolicy: 'cache-and-network',
  });

  const siteOptions = useMemo(
    () =>
      sitesData?.sites.edges.map(({ node }) => ({
        label: node.name,
        value: node.id,
      })) || [],
    [sitesData]
  );

  const roleOptions = useMemo(
    () =>
      Object.values(SiteStaffRole).map((type) => ({
        label: siteStaffRoleLabelByValue[type],
        value: type,
      })),
    []
  );

  const handleSubmit = async (values: SiteStaffUserSchema) => {
    try {
      const role = values.role as SiteStaffRole;

      const siteStaffUserPayload: AddSiteStaffUserInput = {
        name: values.name,
        email: values.email,
        siteId: values.associatedSite,
        role,
      };

      await addSiteStaffUser({
        variables: { newUser: siteStaffUserPayload },
      });
      showSnackbar({
        severity: 'success',
        message: 'Site Staff user added with success',
      });
      navigate(-1);
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error while adding Site Staff User',
      });
    }
  };

  const handleErrors = (
    errors: FormikErrors<SiteStaffUserSchema>,
    touched: FormikTouched<SiteStaffUserSchema>
  ) => {
    return {
      name: touched.name && errors.name,
      email: touched.email && errors.email,
      associatedSite: touched.associatedSite && errors.associatedSite,
      role: touched.role && errors.role,
    };
  };

  const handleCancel = () => {
    showSnackbar({
      message: 'Changes not saved',
      severity: 'warning',
    });
    navigate(-1);
  };

  return (
    <BasePage>
      <BasePage.BackButton handleClick={() => navigate(-1)} />
      <BasePage.Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={siteStaffValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
          }) => {
            const errorState = handleErrors(errors, touched);

            return (
              <Form>
                <Typography variant="h5" mb={2}>
                  Create new site staff user
                </Typography>
                <Paper sx={{ p: 4 }}>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        title="Full name"
                        name="name"
                        placeholder="Add full name..."
                        value={values.name}
                        error={!!errorState.name}
                        helperText={errorState.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        title="Email"
                        name="email"
                        placeholder="user@email.com"
                        value={values.email}
                        error={!!errorState.email}
                        helperText={errorState.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        title="Associated site"
                        name="associatedSite"
                        placeholder="Select a site"
                        displayEmpty
                        options={siteOptions}
                        value={values.associatedSite}
                        error={!!errorState.associatedSite}
                        helperText={errorState.associatedSite}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        title="Staff role"
                        name="role"
                        placeholder="Select a role"
                        displayEmpty
                        value={values.role}
                        options={roleOptions}
                        error={!!errorState.role}
                        helperText={errorState.role}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        title="Site staff status"
                        name="status"
                        disabled
                        value={values.status}
                        options={STATUS_OPTIONS}
                      />
                    </Grid>
                  </Grid>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={1}
                    mt={4}
                  >
                    <Button color="primary" size="large" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      color="secondary"
                      size="large"
                      type="submit"
                      sx={{ py: 1 }}
                    >
                      Create new user
                    </Button>
                  </Stack>
                </Paper>
              </Form>
            );
          }}
        </Formik>
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default AddSiteStaff;
