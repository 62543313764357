import React from 'react';
import { Box, Switch, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import {
  Button,
  convertMaskToE164,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  ListCoachUsersDocument,
  useAddCoachUserMutation,
} from '@fdha/graphql-api-admin';

import AddCoachFormPreferences, {
  UserPreferences,
  validationSchema,
} from '../AddUserFormPreferences';

interface ProvisioningCoachDialogProps extends UserPreferences {
  userType: boolean;
}

const ProvisioningCoachDialog = () => {
  const { showSnackbar } = useSnackbar();
  const { closeDialog } = useDialog();

  const [addCoachUser] = useAddCoachUserMutation();

  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    userType: false,
  };

  const handleSubmit = async (values: ProvisioningCoachDialogProps) => {
    try {
      const user = {
        name: values.name,
        email: values.email,
        phone_number: convertMaskToE164(values.phoneNumber),
        is_admin: values.userType,
      };

      await addCoachUser({
        variables: { newUser: user },
        refetchQueries: [ListCoachUsersDocument],
      });
      showSnackbar({
        severity: 'success',
        message: 'Coach added with success',
      });
    } catch (error: any) {
      console.log(error?.message);
      showSnackbar({ severity: 'error', message: error?.message });
    } finally {
      closeDialog();
    }
  };

  const onCancel = () => {
    showSnackbar({
      message: 'Changes not saved',
      severity: 'warning',
    });
    closeDialog();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, isValid }) => (
        <Form>
          <AddCoachFormPreferences>
            <AddCoachFormPreferences.Name />
            <AddCoachFormPreferences.Email />
            <AddCoachFormPreferences.PhoneNumber />
          </AddCoachFormPreferences>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            data-testid="IS_ADMIN_TOGGLE_BUTTON"
          >
            <Switch
              name="userType"
              checked={values.userType}
              color="secondary"
              onChange={handleChange}
            />
            <Typography variant="body1" fontWeight="500">
              Is Admin?
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={onCancel} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!isValid}
              sx={{ paddingY: 1, paddingX: '22px' }}
            >
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ProvisioningCoachDialog;
