import { WeekDay } from '@fdha/graphql-api-admin';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { FC, PropsWithChildren, useMemo } from 'react';

interface CommonProps {
  title: string;
}

interface DeliveryDayProps extends CommonProps {
  type: 'deliveryDay';
  isSelected: boolean;
  label: 'TUE' | 'FRI';
}

interface DietDayProps extends CommonProps {
  type: 'dietDay';
  days: WeekDay[];
}

type DeliveryCardProps = DeliveryDayProps | DietDayProps;

const DeliveryCard: FC<PropsWithChildren<DeliveryCardProps>> = (props) => {
  const theme = useTheme();

  const { type, title } = props;

  const selected = useMemo(
    () => 'isSelected' in props && props.isSelected,
    [props]
  );

  const Card: FC<{ value: string | WeekDay }> = ({ value }) => (
    <Box
      paddingX={2}
      paddingY={0.5}
      width="100%"
      minWidth="90px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="0 0 5px 5px"
      borderColor={theme.palette.divider}
      sx={{
        borderWidth: '0 1px 1px 1px',
        borderStyle: 'solid',
        backgroundColor:
          type === 'dietDay'
            ? theme.palette.success.main
            : selected
            ? theme.palette.info.main
            : theme.palette.info.lightBg,
      }}
    >
      <Typography
        variant="caption"
        color={
          type === 'dietDay'
            ? theme.palette.background.default
            : selected
            ? theme.palette.background.paper
            : theme.palette.info.main
        }
        textTransform="uppercase"
      >
        {value}
      </Typography>
    </Box>
  );

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        paddingX={1}
        paddingY={0.5}
        borderRadius="5px 5px 0 0"
        borderColor={theme.palette.divider}
        sx={{
          borderWidth: '1px 1px 0 1px',
          borderStyle: 'solid',
          backgroundColor:
            type === 'deliveryDay'
              ? theme.palette.info.lightBg
              : theme.palette.success.lightBg,
        }}
      >
        <Typography
          variant="caption"
          color={
            type === 'deliveryDay'
              ? theme.palette.info.main
              : theme.palette.success.dark
          }
          textTransform="uppercase"
        >
          {title}
        </Typography>
      </Box>
      {'days' in props && (
        <Stack spacing={1} direction="row">
          {props.days.map((day) => (
            <Card key={day} value={day} />
          ))}
        </Stack>
      )}
      {'label' in props && <Card value={props.label} />}
    </Box>
  );
};

export default DeliveryCard;
